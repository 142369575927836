import React from "react";

type Props = {
  placeholder: string;
  id: string;
  disable?: boolean;
};

function SearchBar({ placeholder, id, disable }: Props) {
  return (
    <div className="w-full flex">
      <input
        type="search"
        disabled={disable??false}
        id={id}
        className="w-full p-2 border-2 border-primary"
        placeholder={placeholder}
      />
    </div>
  );
}

export default SearchBar;

import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";
import CheckIcon from '@mui/icons-material/Check';

function AirConsumptionCalculator() {
  const [cisnGorne, setCisnGorne] = useState("");
  const [cisnDolne, setCisnDolne] = useState("");
  const [pojemnZbiorn, setPojemnZbiorn] = useState("");
  const [czasOprozniania, setCzasOprozniania] = useState("");
  const [result, setResult] = useState("");

  const calculateAirConsumption = () => {
    const zapotrzebowanie =
      ((parseFloat(cisnGorne) - parseFloat(cisnDolne)) *
        parseFloat(pojemnZbiorn)) /
      parseFloat(czasOprozniania);
    setResult(
      `Wynik: ${zapotrzebowanie.toFixed(1).toString()} m³/s = ${(
        zapotrzebowanie * 60
      )
        .toFixed(1)
        .toString()} m³/min`
    );
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Pobór powietrza
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center ">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie górne (bar)</span>
          <input
            type="number"
            value={cisnGorne}
            onChange={(e) => setCisnGorne(e.target.value)}
            placeholder="Ciśnienie górne (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie dolne (bar)</span>
          <input
            type="number"
            value={cisnDolne}
            onChange={(e) => setCisnDolne(e.target.value)}
            placeholder="Ciśnienie dolne (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Pojemność zbiornika (m³)</span>
          <input
            type="number"
            value={pojemnZbiorn}
            onChange={(e) => setPojemnZbiorn(e.target.value)}
            placeholder="Pojemność zbiornika (m3)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Czas opróżniania (sek.)</span>
          <input
            type="number"
            value={czasOprozniania}
            onChange={(e) => setCzasOprozniania(e.target.value)}
            placeholder="Czas opróżniania (sek.)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculateAirConsumption}
          Icon={CheckIcon}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default AirConsumptionCalculator;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Store/store';
import { fetchOffers, selectOffers } from '../../../../Store/Slices/wishlist/wishlistSlice';
import CopyToClipboard from 'react-copy-to-clipboard';

export default function AllOffers() {

    const dispatch = useDispatch<AppDispatch>();

    const offers = useSelector(selectOffers);

    useEffect(()=>{
        dispatch(fetchOffers());
    }, [dispatch]);
    
    return (
        <div>
            <p>Oferty:</p>
            {offers&&offers.map(element=>{
                return(
                    <p>
                        <span style={{marginRight: '20px'}}>{element.Company_name}</span>
                        <span style={{marginRight: '20px'}}>{element.Date}</span>
                        <CopyToClipboard text={element.Url??''} onCopy={()=>{}}>
                            <button className="p-2 : hover:bg-primary hover:text-white">
                                Kopiuj link do oferty
                            </button>
                        </CopyToClipboard>
                    </p>
                )
            })}
        </div>
    )
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { StateType } from "../../../Models/State";
import { ProductCart, ProductDisplay } from "../../../Models/Product";
import Cookies from "js-cookie";
import { CartAddProduct, CartAddProducts, getLowestPrice, productCartToCartAddProduct } from "../../../Models/Cart";

export type CartModel = {
  products: ProductCart[];
  price: number;
};

type cartSliceState = {
  cart: CartModel;
  state: StateType;
  addState: StateType;
  saveAsListState: StateType;
  updateQuantitiesState: StateType;
};

export const copyCartToNewList = createAsyncThunk(
  'cart/copyCartToNewList',
  async()=>{
    try{
      const result = await axios.post(`${server}/api/Lists/cart/copy`, {}, {withCredentials: true});
      return result.data;
    }
    catch(e){
      throw e;
    }
  }
)

export const saveCartAsList = createAsyncThunk(
  "cart/saveCartAsList",
  async () => {
    try {
      const products = JSON.parse(
        localStorage.getItem("cart") || "null"
      ).products;
      const productList = [];
      for (const element of products) {
        productList.push({
          Product_id: element.product.Id,
          Quantity: element.quantity,
        });
      }
      /*const response = await axios.post(
        `${server}/api/Lists/product/add/new`,
        {
          name:
            "Koszyk " + (new Date().getHours() + ":" + new Date().getMinutes()),
          product: productList,
        },
        {
          withCredentials: true,
        }
      );*/
      const response = await axios.post(
        `${server}/api/Carts/add`,
        {
          products: productList,
        },
        {
          withCredentials: true,
        }
      );
      localStorage.removeItem("cart");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addListToCart = createAsyncThunk(
  "cart/addListToCart",
  async (listId: string) => {
    try {
      const response = await axios.post(
        `${server}/api/Carts/list/${listId}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCart = createAsyncThunk("cart/fetchCart", async () => {
  try {
    const user = Cookies.get("user");
    if (!user) throw new Error("User not logged in");
    const response = await axios.get(`${server}/api/Carts/cart`, {
      withCredentials: true,
    });
    return response.data as CartModel;
  } catch (error) {
    throw error;
  }
});

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (products: CartAddProducts[]) => {
    try {
      const productsToAdd = productCartToCartAddProduct(products);
      const response = await axios.post(
        `${server}/api/Carts/add`,
        {
          products: productsToAdd,
        },
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (productId: number) => {
    try {
      const response = await axios.delete(
        `${server}/api/Carts/remove/${productId}`,
        {
          withCredentials: true,
        }
      );
      return productId;
    } catch (error) {
      throw error;
    }
  }
);

export const clearCart = createAsyncThunk("cart/clearCart", async () => {
  try {
    const response = await axios.delete(`${server}/api/Carts/clear`, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const changeProductQuantityCart = createAsyncThunk(
  "cart/changeProductQuantityCart",
  async (products: CartAddProduct[]) => {
    try {
      const response = await axios.post(
        `${server}/api/Carts/update`,
        { products: products },
        { withCredentials: true }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addActuatorLocal = createAsyncThunk(
  'cart/addActuatorLocal', 
  async({product}:{product: ProductCart} ) => {
    const storedCart: CartModel = JSON.parse(
      localStorage.getItem("cart") || "null"
    );
    if (storedCart) {
      const index = storedCart.products.findIndex(
        (productElement: ProductCart) =>
          productElement.product.Catalog_name === product.product.Catalog_name
          &&product.product.Id!==undefined
      );
      if (index !== -1) {
        storedCart.products[index].quantity += product.quantity;
      } 
      else {
        const response = await axios.post(
          `${server}/api/Configurator/cart/add`,
          {
            catalogName: product.product.Catalog_name,
            quantity: product.quantity
          }
        );
        const responseData = response.data.product as ProductDisplay;
        storedCart.products.push({quantity: product.quantity, product: responseData});
      }
      storedCart.price += product.quantity * getLowestPrice(product);
      localStorage.setItem("cart", JSON.stringify(storedCart));
      return storedCart;

    } 
    else {
      let price = 0;
      const productsToAdd = productCartToCartAddProduct([{Product: product.product, Quantity: product.quantity}]);
      const response = await axios.post(
        `${server}/api/Configurator/cart/add`,
        {
          catalogName: product.product.Catalog_name,
          quantity: product.quantity
        }
      );
      const responseData = response.data.product as ProductDisplay;
      price += product.quantity * responseData.Price;

      const cart: CartModel = {
        products: [{quantity: product.quantity, product: responseData}],
        price: price as number,
      };
      localStorage.setItem("cart", JSON.stringify(cart));
      return cart;
    }
  });

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: {
      products: [],
      price: 0,
    },
    state: {
      isLoading: false,
      hasLoaded: false,
      failedLoading: false,
      error: null,
    },
    addState: {
      isLoading: false,
      hasLoaded: false,
      failedLoading: false,
      error: null,
    },
    saveAsListState: {
      isLoading: false,
      hasLoaded: false,
      failedLoading: false,
      error: null,
    },
    updateQuantitiesState: {
      isLoading: false,
      hasLoaded: true,
      failedLoading: false,
      error: null,
    },
  } as cartSliceState,
  reducers: {
    addProductsLocal: (
      state,
      action: { type: string; payload: ProductCart[] }
    ) => {
      const storedCart: CartModel = JSON.parse(
        localStorage.getItem("cart") || "null"
      );
      const user = Cookies.get("user");
      if (!user) {
        if (storedCart) {
          for (let i = 0; i < action.payload.length; i++) {
            const index = storedCart.products.findIndex(
              (product: ProductCart) =>
                product.product.Id === action.payload[i].product.Id
            );
            if (index !== -1) {
              storedCart.products[index].quantity += action.payload[i].quantity;
            } else {
              storedCart.products.push(action.payload[i]);
            }
            storedCart.price +=
              action.payload[i].quantity * getLowestPrice(action.payload[i]);
          }
          localStorage.setItem("cart", JSON.stringify(storedCart));
          state.cart = storedCart;
        } else {
          let price = 0;
          for (let i = 0; i < action.payload.length; i++) {
            price +=
              action.payload[i].quantity * getLowestPrice(action.payload[i]);
          }
          const cart: CartModel = {
            products: action.payload as ProductCart[],
            price: price as number,
          };
          localStorage.setItem("cart", JSON.stringify(cart));
          state.cart = cart;
        }
      }
    },
    removeProductLocal: (
      state,
      action: { type: string; payload: ProductCart[] }
    ) => {
      const storedCart: CartModel = JSON.parse(
        localStorage.getItem("cart") || "null"
      );
      const user = Cookies.get("user");
      if (!user) {
        if (storedCart) {
          for (let i = 0; i < action.payload.length; i++) {
            storedCart.products = storedCart.products.filter(
              (product: ProductCart) =>
                product.product.Id !== action.payload[i].product.Id
            );
            storedCart.price -=
              action.payload[i].quantity * getLowestPrice(action.payload[i]);
            localStorage.setItem("cart", JSON.stringify(storedCart));
            state.cart = storedCart;
          }
        }
      }
    },
    removeAllProductsLocal: (state) => {
      localStorage.removeItem("cart");
      state.cart = {
        products: [],
        price: 0,
      };
    },
    changeQuantityLocal: (
      state,
      action: {
        type: string;
        payload: {
          productId: number;
          quantity: number;
          oldQuantity?: number;
          plus?: boolean;
        };
      }
    ) => {
      const storedCart: CartModel = JSON.parse(
        localStorage.getItem("cart") || "null"
      );
      const user = Cookies.get("user");
      if (!user) {
        if (storedCart) {
          const index = storedCart.products.findIndex(
            (product: ProductCart) =>
              product.product.Id === action.payload.productId
          );
          if (index !== -1) {
            storedCart.price -=
              storedCart.products[index].quantity *
              getLowestPrice(storedCart.products[index]);
            storedCart.products[index].quantity = action.payload.quantity;
            storedCart.price +=
              storedCart.products[index].quantity *
              getLowestPrice(storedCart.products[index]);
            localStorage.setItem("cart", JSON.stringify(storedCart));
            state.cart = storedCart;
          }
        }
      }
      for (const element of state.cart.products) {
        if (element.product.Id == action.payload.productId) {
          //if(element.quantity!=action.payload.quantity){
          const price =
            element.product.Price_Individual != undefined
              ? element.product.Price_Individual
              : element.product.Price_Discount != undefined
              ? element.product.Price_Discount
              : element.product.Category_Discount != undefined
              ? element.product.Category_Discount
              : element.product.Amount ?? element.product.Price;
          const difference =
            action.payload.oldQuantity != undefined
              ? -action.payload.quantity + action.payload.oldQuantity
              : 1;
          if (action.payload.plus === true) {
            state.cart.price = state.cart.price + difference * price;
          } else {
            state.cart.price = state.cart.price - difference * price;
          }
          if (action.payload.plus === true) {
            element.product.Price = element.product.Price + difference * price;
          } else {
            element.product.Price = element.product.Price - difference * price;
          }
          break;
        }
      }
      //}
    },
    changeUpdateQuantitiesState: (state, action: { payload: boolean }) => {
      state.updateQuantitiesState.hasLoaded = !action.payload;
      state.updateQuantitiesState.error = null;
      state.updateQuantitiesState.failedLoading = false;
      state.updateQuantitiesState.isLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCart.pending, (state) => {
      state.state.isLoading = true;
      state.state.hasLoaded = false;
      state.state.failedLoading = false;
      state.state.error = null;
    });
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      state.state.isLoading = false;
      state.state.hasLoaded = true;
      state.state.failedLoading = false;
      state.state.error = null;
      state.cart = action.payload;
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.state.isLoading = false;
      state.state.hasLoaded = false;
      state.state.failedLoading = true;
      state.state.error = action.error;
    });
    builder.addCase(addToCart.pending, (state) => {
      state.addState.isLoading = true;
      state.addState.hasLoaded = false;
      state.addState.failedLoading = false;
      state.addState.error = null;
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.addState.isLoading = false;
      state.addState.hasLoaded = false;
      state.addState.failedLoading = true;
      state.addState.error = action.error;
    });
    builder.addCase(addToCart.fulfilled, (state) => {
      state.addState.isLoading = false;
      state.addState.hasLoaded = true;
      state.addState.failedLoading = false;
      state.addState.error = null;
    });
    builder.addCase(removeFromCart.pending, (state) => {
      state.addState.isLoading = true;
      state.addState.hasLoaded = false;
      state.addState.failedLoading = false;
      state.addState.error = null;
    });
    builder.addCase(removeFromCart.rejected, (state, action) => {
      state.addState.isLoading = false;
      state.addState.hasLoaded = false;
      state.addState.failedLoading = true;
      state.addState.error = action.error;
    });
    builder.addCase(removeFromCart.fulfilled, (state, action) => {
      state.addState.isLoading = false;
      state.addState.hasLoaded = true;
      state.addState.failedLoading = false;
      state.addState.error = null;
      state.cart.products = state.cart.products.filter((element) => {
        if (element.product.Id === action.payload) {
          state.cart.price = state.cart.price - element.product.Price;
          return false;
        }
        return true;
      });
    });
    builder.addCase(changeProductQuantityCart.pending, (state) => {
      state.addState.isLoading = true;
      state.addState.hasLoaded = false;
      state.addState.failedLoading = false;
      state.addState.error = null;
    });
    builder.addCase(changeProductQuantityCart.rejected, (state, action) => {
      state.addState.isLoading = false;
      state.addState.hasLoaded = false;
      state.addState.failedLoading = true;
      state.addState.error = action.error;
    });
    builder.addCase(changeProductQuantityCart.fulfilled, (state) => {
      state.addState.isLoading = false;
      state.addState.hasLoaded = true;
      state.addState.failedLoading = false;
      state.addState.error = null;
      state.updateQuantitiesState.isLoading = false;
      state.updateQuantitiesState.hasLoaded = true;
    });
    builder.addCase(clearCart.fulfilled, (state) => {
      state.cart.products=[];
      state.cart.price=0;
    });
    
  },
});

export const selectProducts = (state: {
  cart: cartSliceState;
}): ProductCart[] => {
  if (Cookies.get("user")) {
    return state.cart.cart.products;
  } else {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "null");
    if (storedCart) {
      return storedCart.products;
    } else {
      return [];
    }
  }
};

export const selectCartState = (state: { cart: cartSliceState }): StateType =>
  state.cart.state;

export const selectAddCartState = (state: {
  cart: cartSliceState;
}): StateType => {
  return state.cart.addState;
};

export const selectNumberOfProducts = (state: {
  cart: cartSliceState;
}): number => {
  if (Cookies.get("user")) {
    return state.cart.cart.products.length;
  } else {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "null");
    if (storedCart) {
      return storedCart.products.length;
    } else {
      return 0;
    }
  }
};

export const selectCartPrice = (state: { cart: cartSliceState }): number => {
  if (Cookies.get("user")) {
    return state.cart.cart.price;
  } else {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "null");
    if (storedCart) {
      return storedCart.price;
    } else {
      return 0;
    }
  }
};

export const selectSaveAsListState = (state: {
  cart: cartSliceState;
}): StateType => {
  return state.cart.saveAsListState;
};

export const selectUpdateQuantitiesState = (state: {
  cart: cartSliceState;
}): StateType => {
  return state.cart.updateQuantitiesState;
};

export const {
  addProductsLocal,
  removeProductLocal,
  removeAllProductsLocal,
  changeQuantityLocal,
  changeUpdateQuantitiesState,
} = cartSlice.actions;

export default cartSlice.reducer;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

function TopLinks() {
  const options = [
    {
      name: "O nas",
      link: "/about",
      icon: "info",
    },
    {
      name: "Usługi",
      link: "/uslugi",
      icon: "build",
    },
    {
      name: "Wiedza",
      link: "/wiedza",
      icon: "calculate",
    },
    {
      name: "Do pobrania",
      link: "/downloads",
      icon: "download",
    },
    {
      name: "Kontakt",
      link: "/kontakt",
      icon: "contact_support",
    },
  ];

  const controls = useAnimation();
  const [scrollingDown, setScrollingDown] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleScroll = () => {
    const newScrollY = window.scrollY;
    if (windowWidth >= 768) {
      if (newScrollY > scrollY) {
        if (!scrollingDown) {
          setScrollingDown(true);
          controls.start({ height: 0 });
        }
      } else {
        if (scrollingDown) {
          setScrollingDown(false);
          controls.start({ height: "auto" });
        }
      }
    }

    setScrollY(newScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollingDown, controls, windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <motion.div className="hidden w-full md:flex items-center justify-end space-x-4 overflow-hidden">
      {options.map((option) => (
        <Link
          to={option.link}
          key={option.name}
          className="text-base font-bold border-b-2 border-neutral-50 hover:border-primary p-1 block whitespace-nowrap transition-all"
        >
          {option.name}
        </Link>
      ))}
    </motion.div>
  );
}

export default TopLinks;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecentOrders,
  selectAdminOrders,
  selectRecentOrdersState,
} from "../../../../Store/Slices/admin/Orders/Orders";
import { AppDispatch } from "../../../../Store/store";
import { OrderModel } from "../../../../Models/Order";
import OrderItem from "./Order/OrderItem";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SearchBar from "../../SearchBar/SearchBar";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../../../Common/Messages/ErrorMessage/ErrorMessage";

function AdminAllOrders() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchOrders = () => {
      dispatch(getRecentOrders());
    };

    fetchOrders();

    const interval = setInterval(fetchOrders, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigator.onLine]);

  const orders: OrderModel[] = useSelector(selectAdminOrders);
  const recentOrdersState = useSelector(selectRecentOrdersState);

  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <div className="w-full flex justify-between mt-10 space-x-10">
      <div className="w-1/2 flex flex-col">
        <div className="relative mb-10 flex items-center space-x-2">
          <h2 className="text-3xl font-semibold">Nowe zamówienia</h2>
          {isOnline ? (
            <div className="rounded-full w-4 h-4 animate-pulse bg-red-600"></div>
          ) : (
            <div className="rounded-full w-4 h-4 bg-neutral-400"></div>
          )}
          <HelpOutlineOutlinedIcon
            className="text-neutral-950 relative"
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
          />
          {showInfo && (
            <div className="absolute top-full bg-white p-2 shadow-lg rounded-md">
              <p>
                Zamówienia odświeżają się automatycznie co 60 sekund, jeśli
                chcesz odświeżyć ręcznie, kliknij na ikonę odświeżania w prawym
                górnym rogu.
              </p>
            </div>
          )}
        </div>
        <div className="relative flex flex-col">
          {recentOrdersState.isLoading && (
            <LoadingAnimation type="local" color="primary" />
          )}
          {recentOrdersState.isLoading && <div className="w-full h-32"></div>}
          {recentOrdersState.failedLoading && (
            <ErrorMessage
              error={recentOrdersState.error}
              message="Nie udało się załadować zamówień"
            />
          )}
          {recentOrdersState.hasLoaded &&
            orders &&
            orders.map((element) => {
              return (
                <OrderItem
                  orderData={{
                    order: element,
                    products: [],
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className="w-1/2 flex flex-col">
        <form
          className="flex flex-col space-y-2"
          onSubmit={(e) => {
            e.preventDefault();
            const search_username = (
              document.getElementById("search_username") as HTMLInputElement
            ).value;
            const search_order_number = (
              document.getElementById("search_order_number") as HTMLInputElement
            ).value;
            const min_date = (
              document.getElementById("min_date") as HTMLInputElement
            ).value;
            const max_date = (
              document.getElementById("max_date") as HTMLInputElement
            ).value;
            const is_paid = (
              document.getElementById("is_paid") as HTMLInputElement
            ).checked;
          }}
        >
          <SearchBar
            placeholder={"Szukaj gdzie nazwa użytkownika zawiera..."}
            id={"search_username"}
          />
          <SearchBar
            placeholder={"Szukaj gdzie numer zamówienia zawiera..."}
            id={"search_order_number"}
          />
          <div className="flex flex-wrap">
            <div className="flex items-center space-x-2 mr-2">
              <label htmlFor="min_date">Zamówienia od: </label>
              <input
                className="border-2 border-primary p-2 rounded-md"
                type="date"
                id="min_date"
                name="min_date"
              />
            </div>
            <div className="flex items-center space-x-2 mr-2">
              <label htmlFor="max_date">Do: </label>
              <input
                className="border-2 border-primary p-2 rounded-md"
                type="date"
                id="max_date"
                name="max_date"
              />
            </div>
            <div className="flex items-center space-x-2 mr-2">
              <label htmlFor="is_paid">Zamówienie opłacone: </label>
              <input
                className="border-2 border-primary p-2 rounded-md"
                type="checkbox"
                id="is_paid"
                name="is_paid"
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-primary text-white p-2 rounded-md mt-2"
          >
            Szukaj
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminAllOrders;

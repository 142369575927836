import React from "react";
import ReactDOM from "react-dom";
import AddToCart from "./AddToCart";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Dispatch } from "redux";
import { useAppDispatch } from "../../../Store/store";
import { ProductCart } from "../../../Models/Product";
import { addActuatorLocal, addProductsLocal } from "../../../Store/Slices/Cart/Cart";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";
import {
  productCartToCartAddProduct, productCarts,
} from "../../../Models/Cart";
import { AxiosResponse } from "axios";
import { AsyncThunk } from "@reduxjs/toolkit";

type Props = {
  children: React.ReactNode;
  products: ProductCart[];
  addToCart: AsyncThunk<AxiosResponse<any, any>, any, any>;
  parameters?: { srednica: number; skok: number; categoryId: number };
  width?: "w-full" | "w-auto";
};

function AddToCartButton({
  children,
  products,
  addToCart,
  parameters,
  width = "w-full",
}: Props) {
  const dispatch: Dispatch<any> = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleShowModal = () => {
    if (isLoggedIn) {
      dispatch(
        addToCart(
          parameters != undefined
            ? parameters
            : productCarts(products)
        )
      );
    } else {
      if(products[0].product.Id===-1){
        dispatch(addActuatorLocal({product:products[0]}));
      }
      else{
        dispatch(addProductsLocal(products));
      } 
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={width}>
      <button
        className="w-full"
        onClick={(e) => {
          if (!showModal) {
            handleShowModal();
          } else {
            handleCloseModal();
          }
        }}
      >
        {children}
      </button>

      {ReactDOM.createPortal(
        <AnimatePresence initial={false} mode="wait">
          {showModal && (
            <div className="fixed z-50 top-0 left-0 w-full h-full flex justify-center items-center">
              <AddToCart
                handleClose={handleCloseModal}
                products={products}
                type={isLoggedIn ? "server" : "local"}
              />
            </div>
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}
    </div>
  );
}

export default AddToCartButton;

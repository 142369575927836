import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../Common/Input/Input";
import ButtonPrimaryToLighter from "../../../../../Common/Buttons/ButtonPrimaryToLighter";
import {
  editAddress,
  fetchAddresses,
  selectAddresses,
  selectFetchAddressesState,
} from "../../../../../Store/Slices/User/User";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Store/store";
import LoadingAnimation from "../../../../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../../../../Common/Messages/ErrorMessage/ErrorMessage";
import SaveIcon from "@mui/icons-material/Save";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function EditAddress() {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const { id } = useParams();

  const addresses = useSelector(selectAddresses);
  const state = useSelector(selectFetchAddressesState);

  useEffect(() => {
    dispatch(fetchAddresses());
  }, [dispatch]);

  const [addressName, setAddressName] = useState(
    addresses.filter((address) => {
      return address.Id?.toString() === id;
    })[0].Address_name
  );
  const [address, setAddress] = useState(
    addresses.filter((address) => {
      return address.Id?.toString() === id;
    })[0].Address
  );
  const [city, setCity] = useState(
    addresses.filter((address) => {
      return address.Id?.toString() === id;
    })[0].City
  );
  const [zipCode, setZipCode] = useState(
    addresses.filter((address) => {
      return address.Id?.toString() === id;
    })[0].Zip_code
  );
  const [country, setCountry] = useState(
    addresses.filter((address) => {
      return address.Id?.toString() === id;
    })[0].Country
  );

  return (
    <div className="w-full">
      <div className="w-full items-center">
        <Link to="/profile/addresses" className="flex items-center space-x-1">
          <ChevronLeftIcon />
          <span className="text-xl">Powrót</span>
        </Link>
      </div>
      {state.isLoading && <LoadingAnimation type="local" color="primary" />}
      {state.failedLoading && (
        <ErrorMessage error={state.error} message={"Błąd ładowania adresów"} />
      )}
      {state.hasLoaded && (
        <form
          id="address-form"
          className="w-full items-center rounded-lg p-3 border-2 "
          onSubmit={(e) => {
            e.preventDefault();
            if (
              addressName === "" ||
              address === "" ||
              city === "" ||
              zipCode === "" ||
              country === ""
            ) {
              alert("Wszystkie pola muszą być wypełnione");
              return;
            }
            dispatch(
              editAddress({
                addressID: id!,
                address: {
                  Address_name: addressName,
                  Address: address,
                  City: city,
                  Zip_code: zipCode,
                  Country: country,
                },
              })
            );
            navigate("/profile/addresses");
          }}
        >
          <div className="flex flex-col lg:flex-row lg:space-y-0 space-y-3 lg:space-x-3 items-center">
            <Input
              name="Nazwa"
              label="Address_name"
              type={"text"}
              size="sm"
              placeholder="Dom"
              value={addressName}
              onChange={(e) => setAddressName(e.target.value)}
            />
            <Input
              name="Ulica i numer domu"
              label="Address"
              type={"text"}
              size="sm"
              placeholder="ul. Przykładowa 1"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              name="Miasto"
              label="City"
              type={"text"}
              size="sm"
              placeholder="Warszawa"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Input
              name="Kod pocztowy"
              label="Zip_code"
              type={"text"}
              size="sm"
              placeholder="00-000"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            <Input
              name="Kraj"
              label="Country"
              type={"text"}
              size="sm"
              placeholder="Polska"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <ButtonPrimaryToLighter
            text={"Zapisz"}
            size={"medium"}
            isSubmit={true}
            form="address-form"
            Icon={SaveIcon}
            customClasses="w-full mt-2"
          />
        </form>
      )}
    </div>
  );
}

export default EditAddress;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
  addToChosenCategories,
  clearChosenCategories,
  deleteCategoryDiscount,
  getCategories,
  getUserDiscounts,
  getUsers,
  getUsersSearchDiscounts,
  selectAllCategories,
  selectAllCategoriesMap,
  selectCategories,
  selectChosenCategories,
  selectChosenUser,
  selectChosenUserDiscounts,
  selectChosenUserSearchDiscounts,
  selectCurrentCategoryId,
  selectParentCategory,
  selectUsers,
  selectUsersSearchDiscounts,
  setChosenUser,
  setChosenUserSearchDiscounts,
} from "../../../../Store/Slices/admin/Categories/Categories";
import { AnimatePresence, motion } from "framer-motion";
import Category from "../../../../Common/Categories/Category/Category";
import { CategoryModel } from "../../../../Models/Category";
import { setCurrentCategoryId } from "../../../../Store/Slices/admin/Categories/Categories";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function CategoryDiscount() {
  const dispatch = useDispatch<AppDispatch>();
  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const allCategoriesMap = useSelector(selectAllCategoriesMap);
  const allCategories = useSelector(selectAllCategories);
  const currentCategory = useSelector(selectParentCategory);
  let parentCategory: CategoryModel = useSelector(selectParentCategory);
  const categories = useSelector(selectCategories);
  const chosenCategories = useSelector(selectChosenCategories);
  const foundUsers = useSelector(selectUsers);
  const chosenUser = useSelector(selectChosenUser);
  const foundUsersSearchDiscounts = useSelector(selectUsersSearchDiscounts);
  const chosenUserSearchDiscounts = useSelector(
    selectChosenUserSearchDiscounts
  );
  const chosenUserDiscounts = useSelector(selectChosenUserDiscounts);

  const [discount, setDiscount] = useState(0);
  const [client, setClient] = useState("");
  const [clientSearchDiscounts, setClientSearchDiscounts] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [chosenDiscountToDelete, setChosenDiscountToDelete] = useState(-1);
  const [chosenCategoryToDelete, setChosenCategoryToDelete] = useState(-1);

  useEffect(() => {
    dispatch(setChosenUser(-1));
    dispatch(setCurrentCategoryId(1));
    dispatch(clearChosenCategories());
  }, []);

  useEffect(() => {
    dispatch(
      getCategories({
        id: currentCategoryId,
        allCategoriesMap,
        currentCategory:
          allCategories.filter((element) => {
            if (element.Id.toString() == currentCategoryId) {
              return true;
            }
            return false;
          })[0] ?? currentCategory,
      })
    );
  }, [currentCategoryId]);

  return (
    <div>
      {parentCategory !== undefined && parentCategory?.Id !== 1 && (
        <div
          className="py-1 flex items-center cursor-pointer"
          onClick={() => {
            dispatch(setCurrentCategoryId(parentCategory.Parent_Category));
          }}
        >
          <ChevronLeftIcon />
          <span className="text-lg font-bold">{parentCategory.Name}</span>
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {categories && (
          <motion.div
            className="flex flex-col space-y-1"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            {categories.map((category) => (
              <div style={{ display: "flex" }}>
                <Category
                  key={category.Id}
                  category={category}
                  setCurrentCategoryId={setCurrentCategoryId}
                />
                <button
                  onClick={() => {
                    dispatch(
                      addToChosenCategories({
                        Id: category.Id,
                        Name: category.Name,
                      })
                    );
                  }}
                >
                  dodaj
                </button>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <p>
        {chosenCategories &&
          chosenCategories.map((element) => {
            return <p>{element.Name}</p>;
          })}
      </p>
      <br />
      <p>Znizka w procentach</p>
      <p>
        <input
          value={discount}
          onChange={(e) => {
            try {
              if (parseInt(e.target.value) < 100) {
                setDiscount(parseInt(e.target.value));
              } else {
                setDiscount(0);
              }
            } catch (e) {
              setDiscount(0);
            }
          }}
        />
      </p>
      <br />
      <p>
        <input
          value={client}
          onChange={(e) => {
            setClient(e.target.value);
          }}
          placeholder="nazwa lub NIP klienta"
        />
        <button
          onClick={() => {
            dispatch(getUsers({ name: client }));
          }}
        >
          Szukaj
        </button>
      </p>
      <br />
      <p>
        {foundUsers &&
          foundUsers.map((element) => {
            return (
              <p>
                <button
                  style={{
                    background: element.Id === chosenUser ? "blue" : "red",
                  }}
                  onClick={() => {
                    dispatch(setChosenUser(element.Id));
                  }}
                >
                  {element.Name}-{element.Email}
                </button>
              </p>
            );
          })}
      </p>
      <br />
      <br />
      <p>
        <button
          onClick={() => {
            var categoriesIds: number[] = chosenCategories.map((element) => {
              return element.Id;
            });
          }}
        >
          Zatwierdź
        </button>
      </p>
      <br />
      <br />
      <h2>Rabaty</h2>
      <p>
        <input
          value={clientSearchDiscounts}
          onChange={(e) => {
            setClientSearchDiscounts(e.target.value);
          }}
          placeholder="nazwa lub NIP klienta"
        />
        <button
          onClick={() => {
            dispatch(getUsersSearchDiscounts({ name: clientSearchDiscounts }));
          }}
        >
          Szukaj
        </button>
      </p>
      <p>
        {foundUsersSearchDiscounts &&
          foundUsersSearchDiscounts.map((element) => {
            return (
              <p>
                <button
                  style={{
                    background:
                      element.Id === chosenUserSearchDiscounts ? "blue" : "red",
                  }}
                  onClick={() => {
                    dispatch(setChosenUserSearchDiscounts(element.Id));
                  }}
                >
                  {element.Name}-{element.Email}
                </button>
              </p>
            );
          })}
      </p>
      {chosenUserSearchDiscounts != -1 && (
        <p>
          <button
            onClick={() => {
              dispatch(getUserDiscounts({ id: chosenUserSearchDiscounts }));
            }}
          >
            Zatwierdź i szukaj rabatów wybranego klienta
          </button>
        </p>
      )}
      <p>
        {chosenUserDiscounts &&
          chosenUserDiscounts.map((element) => {
            return (
              <p>
                <span>
                  {element.Name}-{element.Discount}%
                </span>
                <button
                  style={{ marginLeft: "30px" }}
                  onClick={() => {
                    setChosenCategoryToDelete(element.Category_id);
                    setChosenDiscountToDelete(element.Discount);
                    setShowConfirmDelete(true);
                  }}
                >
                  Usuń
                </button>
              </p>
            );
          })}
      </p>
      <AnimatePresence mode="wait">
        {showConfirmDelete && (
          <Confirm
            handleClose={function (): void {
              setShowConfirmDelete(false);
            }}
            handleConfirm={function (): void {
              setShowConfirmDelete(false);
              dispatch(
                deleteCategoryDiscount({
                  userId: chosenUserSearchDiscounts,
                  categoryId: chosenCategoryToDelete,
                  discount: chosenDiscountToDelete,
                })
              );
            }}
            text={"Czy na pewno chcesz usunać ten rabat?"}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

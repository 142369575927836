import React, { useState } from "react";
import { ProductModel } from "../../../Models/Product";
import { useSelector } from "react-redux";
import { selectProductsChoices } from "../../../Store/Slices/Products/Products";
import Parameter from "./Parameter/Parameter";
import ButtonTransparentToColor from "../../../Common/Buttons/ButtonTransparentToColor";
import { silowniki } from "../../Search/SearchResults/SearchResults";

type Props = {
  product: ProductModel;
};

function ProductParameters({ product }: Props) {
  const productParameters = useSelector(selectProductsChoices);

  const [parameterId, setParameterId] = useState<number | null>(null);

  return (
    <div className="relative z-30 space-y-2">
      {false&&silowniki.includes(product.details?.Category_id?.toString() || "") && (
            <div className="w-full p-2 flex items-center flex-wrap">
              <ButtonTransparentToColor
                link={"silowniki"}
                text={"Konfigurator"}
                size={"small"}
                customClasses="mr-2"
              />
              <p className="text-sm sm:text-base text-neutral-600">
                Znajdź swój siłownik
              </p>
            </div>
          )}
      <h2 className="text-xl lg:text-2xl font-bold">
        Produkty z serii o innych parametrach:
      </h2>
      {productParameters &&
        productParameters.map((element) => (
          <Parameter
            element={element}
            product={product}
            parameterId={parameterId}
            setParameterId={setParameterId}
          />
        ))}
    </div>
  );
}

export default ProductParameters;

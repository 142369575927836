import React, { useState } from "react";
import { Address } from "../../../../../../../Models/Address";
import AddressList from "./AddresList/AddressList";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../../Store/store";
import { setOrderAddress } from "../../../../../../../Store/Slices/Order/Order";

type Props = {
  address: Address;
};

function AddressInput({ address }: Props) {
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const handleAddressSelect = (id: any) => {
    setSelectedAddressId(id);
  };

  return (
    <div>
      <input
        type="radio"
        name="Address_id"
        value={address.Id}
        checked={selectedAddressId === address.Id}
        style={{ display: "none" }}
        onChange={() => {
          handleAddressSelect(address.Id);
        }}
      />
      <AddressList
        key={address.Id}
        address={address}
        onAddressSelect={handleAddressSelect}
      />
    </div>
  );
}

export default AddressInput;

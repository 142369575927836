import React, { useRef, useState } from "react";
import { ProductDisplay } from "../../../Models/Product";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";

type Props = {
  product: ProductDisplay;
};

function ProductInfo({ product }: Props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [showFullName, setShowFullName] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const MAXLEN: number = 41;

  const handleMouseEnter = () => {
    setShowFullName(true);
  };

  const handleMouseLeave = () => {
    setShowFullName(false);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (tooltipRef.current) {
      const { clientX, clientY } = event;
      const tooltipWidth = tooltipRef.current.clientWidth;
      const tooltipHeight = tooltipRef.current.clientHeight;

      setTooltipPosition({
        x: clientX - tooltipWidth / 2,
        y: clientY - tooltipHeight - 10, // Adjust as needed to position the tooltip above the cursor
      });
    }
  };
  return (
    <div
      className="flex flex-col justify-between h-full relative z-10 text-neutral-950 space-y-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <div className="flex items-center w-full space-x-2">
        <h1 className="block text-sm sm:text-base font-bold">          
          {product.Name}
        </h1>
        {false && showFullName && product.Name.length > MAXLEN && (
          <div
            ref={tooltipRef}
            style={{
              position: "fixed",
              left: `${tooltipPosition.x}px`,
              top: `${tooltipPosition.y}px`,
              zIndex: 9999,
            }}
            className="w-40 text-start whitespace-break-spaces rounded-lg shadow-md bg-neutral-100 bg-opacity-40 backdrop-blur-md p-2"
          >
            {product.Name}
          </div>
        )}
      </div>
      <div className="flex flex-col-reverse items-end">
        <div className="flex items-center justify-between space-x-2">          
          <h1 className="block whitespace-nowrap text-sm sm:text-base font-bold text-neutral-700">
            {product.Catalog_name}
          </h1>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <h1 className="flex items-center space-x-1 whitespace-nowrap text-sm sm:text-xl font-bold text-neutral-950">
            <span
              className={`${
                ((product.Price_Discount !== null &&
                  product.Price_Discount !== undefined) ||
                  (product.Price_Individual !== null &&
                    product.Price_Individual !== undefined) ||
                  (product.Category_Discount !== null &&
                    product.Category_Discount !== undefined)) &&
                isLoggedIn
                  ? "line-through text-neutral-500"
                  : ""
              }`}
            >
              {product.Price.toFixed(2)}
            </span>
            {isLoggedIn &&
              product.Price_Individual !== null &&
              product.Price_Individual !== undefined && (
                <span className="underline decoration-primary decoration-2">
                  {product.Price_Individual?.toFixed(2)}
                </span>
              )}
            {isLoggedIn &&
              (product.Price_Individual === null ||
                product.Price_Individual === undefined) &&
              product.Price_Discount !== null &&
              product.Price_Discount !== undefined && (
                <span className="underline decoration-green-600 decoration-2">
                  {(product.Price * (1 - product.Price_Discount / 100)).toFixed(
                    2
                  )}
                </span>
              )}
            {isLoggedIn &&
              (product.Price_Individual === null ||
                product.Price_Individual === undefined) &&
              (product.Price_Discount === null ||
                product.Price_Discount === undefined) &&
              product.Category_Discount !== null &&
              product.Category_Discount !== undefined && (
                <span className="underline decoration-orange-600 decoration-2">
                  {(
                    product.Price *
                    (1 - product.Category_Discount / 100)
                  ).toFixed(2)}
                </span>
              )}
            <span>PLN</span>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;

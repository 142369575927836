import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
    User,
  addCoworkerToCompany,
  getCompanyDetails,
  getCoworkers,
  selectCompanyData,
  selectCompanyDataState,
  selectCoworkers,
  selectUser,
} from "../../../../Store/Slices/User/User";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";
import { Button, ButtonBase, ToggleButton } from "@mui/material";

export default function CompanyProfile() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, []);

  const company = useSelector(selectCompanyData);
  const companyState = useSelector(selectCompanyDataState);
  const userId = useSelector(selectUser)?.Id;
  const coworkers = useSelector(selectCoworkers);
  
  return (
    <div className="w-full relative">
      {companyState.isLoading && <LoadingAnimation color="primary" />}
      {companyState.isLoading && <div className="w-full h-32"></div>}
      {company && companyState.hasLoaded && (
        <div className="flex flex-col space-y-4">
          <h2 className="text-2xl font-semibold">Dane firmy:</h2>
          <div className="w-full p-3 space-y-2 border-2 rounded-lg">
            <div className="text-base flex items-center space-x-2">
              <div className="flex items-center space-x-1 font-semibold">
                <span>Nazwa firmy: </span>
              </div>
              <span>{company.company.Name}</span>
            </div>
            <div className="text-base flex items-center space-x-2">
              <div className="flex items-center space-x-1 font-semibold">
                <span>Adres Firmy: </span>
              </div>
              <span>{company.company.Address}</span>
            </div>
            <div className="text-base flex items-center space-x-2">
              <div className="flex items-center space-x-1 font-semibold">
                <span>Kod pocztowy: </span>
              </div>
              <span>{company.company.PostalCode}</span>
            </div>
            <div className="text-base flex items-center space-x-2">
              <div className="flex items-center space-x-1 font-semibold">
                <span>NIP: </span>
              </div>
              <span>{company.company.NIP}</span>
            </div>
          </div>
          <h2 className="text-2xl font-semibold">Uzytkownicy:</h2>
          <div className="w-full p-3 flex flex-wrap items-stretch border-2 rounded-lg">
            {company.workers &&
              company.workers.map((element) => {
                return (
                  <div className="bg-neutral-50 rounded-lg shadow-sm mr-2 p-2 space-y-1 mb-1">
                    <p>
                      {element.Name} {element.Surname}
                    </p>
                    <p>{element.Email}</p>
                    <p>
                      {element.Id === company.company.Manager_id
                        ? "Ta osoba jest managerem"
                        : ""}
                    </p>
                  </div>
                );
              })}
          </div>

          {userId === company.company.Manager_id ? (
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(getCoworkers());
              }}
            >
              Szukaj współpracowników w bazie uzytkownikow
            </Button>
          ) : null}

          <div className="flex flex-col space-y-1">
            {coworkers &&
              coworkers.map((element) => {
                return (
                  <div className="flex flex-col bg-neutral-50 rounded-lg shadow-sm mr-2 p-2 space-y-1">
                    <p>
                      {element.Name} {element.Surname}
                    </p>
                    <p>{element.Email}</p>
                    <Button
                      onClick={() => {
                        dispatch(addCoworkerToCompany(element.Id));
                      }}
                    >
                      Dodaj uzytkownika do pracowników firmy i przypisz mu
                      warunki handlowe i rabaty przypisane dla firmy
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

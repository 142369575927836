import React from "react";
import DisplayButton from "./Buttons/DisplayButton";
import FiltersButton from "./Buttons/FiltersButton";
import SortButton from "./Buttons/SortButton";
import LimitButton from "./Buttons/LimitButton";

function SearchOptionsBar() {
  return (
    <div className="w-full flex flex-col items-center py-2">
      <div className="w-full flex justify-evenly items-center max-w-[1536px]">
        <DisplayButton />
        <FiltersButton />
        <SortButton />
        <LimitButton />
      </div>
    </div>
  );
}

export default SearchOptionsBar;

import React from "react";
import { Link } from "react-router-dom";
import { ButtonProps } from "../../Models/Button";

function ButtonPrimaryToLighter({
  text,
  Icon = null,
  link = null,
  onClick,
  size,
  isSubmit = false,
  customClasses = "",
  form = "",
}: ButtonProps) {
  if (link === null) {
    return (
      <button
        form={form}
        className={
          `
      ${size === "small" && "text-lg p-1"}
      ${size === "medium" && "text-xl p-2"}
      ${size === "large" && "text-2xl p-2"}
      shadow-md space-x-2 flex items-center justify-center bg-primary hover:opacity-80 text-white rounded-md font-bold transition-all ` +
          customClasses
        }
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
        type={isSubmit ? "submit" : "button"}
      >
        <span>{text}</span>
        {Icon != null && <Icon />}
      </button>
    );
  }

  return (
    <Link
      to={`${link}`}
      className={
        `
      ${size === "small" && "text-lg p-1"}
      ${size === "medium" && "text-xl p-2"}
      ${size === "large" && "text-2xl p-2"}
      shadow-md space-x-2 flex items-center justify-center bg-primary hover:opacity-80 text-white rounded-md font-bold transition-all ` +
        customClasses
      }
    >
      <span>{text}</span>
      {Icon != null && <Icon />}
    </Link>
  );
}

export default ButtonPrimaryToLighter;

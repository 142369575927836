import React from "react";
import { useSelector } from "react-redux";
import { selectProducts } from "../../../../Store/Slices/Cart/Cart";
import ProductList from "../../../../Common/ProductList/ProductList";
import { Link } from "react-router-dom";
import SearchOptionsBar from "../../../Search/SearchOptionsBar/SearchOptionsBar";
import SearchResults from "../../../Search/SearchResults/SearchResults";
import SearchBar from "../../../../Common/NavBar/SearchBar/SearchBar";

function Products() {
  const products = useSelector(selectProducts);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col">
        <Link to='add'><button>Dodaj produkt</button></Link>
        <SearchBar type="desktop" admin={true}/>
        <SearchOptionsBar />
        <SearchResults admin={true}/>
      </div>
    </div>
  );
}

export default Products;

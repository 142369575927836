import React from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

function PageIcon({ page, setPage }: Props) {
  const [searchParams] = useSearchParams();

  return (
    <div
      className={`${
        (searchParams.get("page") || "1") === page.toString()
          ? "bg-neutral-200"
          : ""
      } hover:border-neutral-950 hover:bg-neutral-200 border-2 cursor-pointer border-neutral-600 p-2 w-6 h-6 sm:w-10 sm:h-10 rounded-full  group flex items-center justify-center transition-all`}
      onClick={() => {
        setPage(page);
      }}
    >
      <span className="text-xs sm:text-sm md:text-base lg:text-xl text-neutral-600 group-hover:text-neutral-950 transition-all">
        {page}
      </span>
    </div>
  );
}

export default PageIcon;

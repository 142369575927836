import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {  
  fetchAvailability,
  fetchProduct,
  fetchSimilarProducts,
  selectAddCartQuantity,
  selectAvailability,
  selectDeliveryTime,
  selectProduct,
  selectProductDescriptionState,
  selectProductState,
  selectSimilarProducts,
  selectSimilarProductsState,
} from "../../../../Store/Slices/Products/Products";
import {
  ProductDisplay,
  ProductModel,
  productToDisplay,
} from "../../../../Models/Product";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";
import { motion } from "framer-motion";
import { StateType } from "../../../../Models/State";
import ProductCard from "../../../../Common/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../Product/Breadcrumbs/Breadcrumbs";
import Gallery from "../../../Product/Gallery/Gallery";
import ProductData from "../../../Product/ProductData/ProductData";
import InfoBelow from "../../../Product/InfoBelow/InfoBelow";
import ProductControls from "../../../Product/ProductControls/ProductControls";
import SimiliarProducts from "../../../Product/SimiliarProducts/SimiliarProducts";

function Product() {
  const dispatch = useDispatch<AppDispatch>();
  const productAvailability = useSelector(selectAvailability);
  const productTimeDelivery = useSelector(selectDeliveryTime);
  const addCartQuantity = useSelector(selectAddCartQuantity);
  const product: ProductModel = useSelector(selectProduct);
  const ProductState: StateType = useSelector(selectProductState);
  const productDescription = useSelector(selectProductDescriptionState);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      //dispatch(changeCartAddQuantity(1));
      dispatch(fetchProduct(id));
      dispatch(fetchSimilarProducts({id, mobile: false}));
    }
  }, [dispatch, id]);

  useEffect(() => {
    document.title = "Produkt - Press Bar Pneumatyka";
    if (ProductState.hasLoaded) {
      document.title = `${product.details.Name} - Press Bar Pneumatyka`;
    }
  }, [ProductState.hasLoaded]);
  return (
    <motion.div
      className="relative w-full min-h-96 flex flex-col items-center items-top justify-center max-w-[1536px] space-y-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {ProductState.hasLoaded && (
        <Breadcrumbs breadcrumbs={product.breadcrumbs} admin={true} />
      )}
      {ProductState.isLoading && (
        <LoadingAnimation color="primary" type="global" />
      )}
      {ProductState.hasLoaded && (
        <div className="space-y-4 lg:space-y-0 flex flex-col lg:flex-row justify-center items-top lg:space-x-4 w-full">
          <div className="w-full lg:w-3/4 h-fit px-2 sm:px-5 lg:pl-5 lg:pr-0">
            <div className="relative flex flex-col items-top justify-center space-y-1 lg:space-y-4 rounded-lg shadow-md bg-neutral-100 p-2">
              <div className="w-full flex flex-row justify-between items-center">
                <h1 className="text-xl lg:text-4xl w-2/3 font-bold text-neutral-950">
                  {product.details.Name}
                </h1>
                <Link
                  to={`/producer/${product.details.Producer}`}
                  className="w-1/3 flex items-center justify-end"
                >
                  <img
                    src={product.details.ProducerLogo}
                    alt={product.details.Producer}
                    className="max-h-32 w-full object-contain object-right"
                  />
                </Link>
              </div>
              <div className="w-full flex flex-col lg:flex-row items-start space-y-2 lg:space-y-0 lg:space-x-2">
                {product?.images !== undefined &&
                  product.images.length !== 0 && (
                    <div className="w-full flex justify-center items-center h-64 sm:h-72 xl:h-80 2xl:h-96 lg:w-1/2">
                      <Gallery images={product.images} />
                    </div>
                  )}
                <ProductData product={product} />
              </div>
              <InfoBelow product={product} />
              {productDescription && (
                <div dangerouslySetInnerHTML={{ __html: productDescription }} />
              )}
            </div>
          </div>
          <ProductControls
            product={productToDisplay(product)}
            deliveryTime={productTimeDelivery}
          />
        </div>
      )}
    </motion.div>
  );
}

export default Product;

import React from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type Props = {
  isAvailable: boolean;
  deliveryTime: string;
};

function OrderInfo({ isAvailable, deliveryTime }: Props) {
  return (
    <div className="w-full flex xl:flex-col items-start justify-between">
      <hr className="w-full border-neutral-200 hidden xl:block" />
      {isAvailable ? (
        <div className="flex items-center space-x-1 xl:space-x-2">
          <CheckCircleIcon />
          <span className="text-sm sm:text-base xl:text-xl">Dostępny</span>
        </div>
      ) : (
        <div className="flex items-center space-x-1 xl:space-x-2">
          <ErrorIcon />
          <span className="text-sm sm:text-base xl:text-xl">Na zamówienie</span>
        </div>
      )}
      <hr className="w-full border-neutral-200 hidden xl:block" />
      {isAvailable && (
        <div className="flex items-center space-x-1 xl:space-x-2">
          <ScheduleIcon />
          <span className="text-sm sm:text-base xl:text-xl">
            {deliveryTime}
          </span>
        </div>
      )}
    </div>
  );
}

export default OrderInfo;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { motion } from "framer-motion";
import {
  getCategories,
  selectActuatorsCategoriesState,
  selectCategories,
} from "../../../Store/Slices/Actuators/Actuators";
import LoadingAnimation from "../../../Common/LoadingAnimation/LoadingAnimation";
import CategoryCard from "./CategoryCard";

export default function ActuatorsCategories() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const categories = useSelector(selectCategories);
  const categoriesState = useSelector(selectActuatorsCategoriesState);

  return (
    <motion.div
      className="flex flex-col justify-center items-center w-full max-w-[1536px] space-y-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className="text-3xl sm:text-5xl font-semibold mt-10">Konfigurator</h1>
      {categoriesState.isLoading && (
        <LoadingAnimation color="primary" type="global" />
      )}

      <div className="w-full rounded-lg py-4 relative flex flex-nowrap space-x-2 lg:space-x-0 overflow-x-scroll lg:grid lg:grid-cols-4 lg:grid-rows-2 xl:grid-cols-5 gap-2">
        {categories.slice(0, 10).map((category) => (
          <CategoryCard key={category.Id} category={category} />
        ))}
      </div>
    </motion.div>
  );
}

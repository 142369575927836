import React from 'react'
import Input from '../../../../../../Common/Input/Input'

function Address() {
  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold">Podaj dane dostawy:</h1>
      <div className="w-full rounded-lg p-3 border-2 flex flex-col lg:flex-row lg:space-y-0 space-y-3 lg:space-x-3 items-center">
        <Input
          label={"address"}
          type={"text"}
          name={"Ulica i numer domu"}
          required={true}
          placeholder="ul. Przykładowa 1"
          size="sm"
        />
        <Input
          label={"city"}
          type={"text"}
          name={"Miasto"}
          required={true}
          placeholder="Warszawa"
          size="sm"
        />
        <Input
          label={"zipCode"}
          type={"text"}
          name={"Kod pocztowy"}
          required={true}
          placeholder="00-000"
          size="sm"
        />
      </div>
    </div>
  )
}

export default Address
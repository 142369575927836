import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Parameter } from "../../../Models/Parameter";
import CheckboxFilter from "./CheckboxFilter/CheckboxFilter";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "../../Input/Input";
import ButtonPrimaryToLighter from "../../Buttons/ButtonPrimaryToLighter";
import { useSearchParams } from "react-router-dom";


function Filter({
  filter,
  FiltersCounts,
  mobile,
}: {
  filter: Parameter;
  FiltersCounts?: { Choice_id: number; Count: number }[];
  mobile?: boolean;
}) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
  const [skokParam, setSkokParam]= useState('');
  const [wydajnoscParam, setWydajnoscParam]= useState('');
  const [wydajnoscLSParam, setWydajnoscLSParam]= useState('');

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [searchParams, setSearchParams] = useSearchParams({
    skok: '',
  });

  useEffect(()=>{
    setSkokParam(searchParams.get('skok')??'');
  }, [searchParams.get('skok')])

  useEffect(()=>{
    setWydajnoscParam(searchParams.get('wydajnosc')??'');
    setWydajnoscLSParam((parseFloat(searchParams.get('wydajnosc')??'0')/3.6).toFixed(2));
  }, [searchParams.get('wydajnosc')])

  return (
    <div>
      <div
        className="rounded-lg bg-neutral-100 hover:bg-neutral-200 p-1 flex space-x-1 items-center text-base cursor-pointer"
        onClick={() => {
          toggleDropdown();
        }}
      >
        {filter.Name}{" "}
        <div>{isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
      </div>
      <AnimatePresence>
        {isDropdownOpen && filter.Type==='CHOICE' && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <CheckboxFilter
              options={filter.Predefined_choices}
              FiltersCounts={FiltersCounts}
              mobile={mobile}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isDropdownOpen && filter.Type==='INPUT' && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <Input
              label="Skok (mm)"
              type="text"
              name=""
              onChange={(e)=>{
                setSkokParam(e.target.value);
              }}
              value={skokParam}
              placeholder="Skok (mm)"
            />
            <ButtonPrimaryToLighter
              text="Szukaj"
              onClick={()=>{
                setSearchParams((prev) => {
                  prev.set("skok", skokParam);
                  return prev;
                });
                /*else{
                  setSearchParams((prev) => {
                    var filters = JSON.parse((prev.get('filters')??'[{\"parameterId\":29, \"choicesIds\":[]}]').toString()) as {parameterId: number, choicesIds: number[]}[];
                    var changedFilters=false;
                    for(var i=0; i<filters.length; i++){
                      if(filters[i].parameterId===29){
                        filters[i].choicesIds.push(paramId);
                        changedFilters=true;
                        dispatch(addParameterChoice({parameterId: 29, choiceId: paramId}));
                        break;
                      }
                    }
                    if(changedFilters){
                      prev.set('filters', JSON.stringify(filters));
                    }
                    prev.delete("skok");
                    return prev;
                  });
                }*/
              }}
              size="small"
            />
            {false&&<CheckboxFilter
              options={filter.Predefined_choices}
              FiltersCounts={FiltersCounts}
              mobile={mobile}
            />}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isDropdownOpen && filter.Type==='INPUT_COMPRESSOR' && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Input
                label="Wydajność [m3/h]"
                type="text"
                name=""
                onChange={(e)=>{
                  try{
                    if(parseFloat(e.target.value).toString()==='NaN'){
                      setWydajnoscParam('');
                      setWydajnoscLSParam('');
                    }
                    else{
                      setWydajnoscParam(e.target.value);
                      setWydajnoscLSParam((parseFloat(e.target.value)/3.6).toFixed(2));
                    }
                  }
                  catch(e){
                    setWydajnoscParam('');
                    setWydajnoscLSParam('');
                  }
                  
                }}
                value={wydajnoscParam}
                placeholder="Wydajność [m3/h]"
              />
              <span style={{fontSize: '20px', fontWeight: 'bold', height: '100%'}}>m<sup>3</sup>/h</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Input
                label="Wydajność [l/s]"
                type="text"
                name=""
                onChange={(e)=>{
                  try{
                    if(parseFloat(e.target.value).toString()==='NaN'){
                      setWydajnoscParam('');
                      setWydajnoscLSParam('');
                    }
                    else{
                      setWydajnoscLSParam(e.target.value);
                      setWydajnoscParam((parseFloat(e.target.value)*3.6).toFixed(2));
                    }
                  }
                  catch(e){
                    setWydajnoscParam('');
                    setWydajnoscLSParam('');
                  }
                }}
                value={wydajnoscLSParam}
                placeholder="Wydajność [l/s]"
              />
              <span style={{fontSize: '20px', fontWeight: 'bold', height: '100%'}}>l/s</span>
            </div>
            <ButtonPrimaryToLighter
              text="Szukaj"
              onClick={()=>{
                setSearchParams((prev) => {
                  prev.set("wydajnosc", (parseFloat(wydajnoscParam)).toFixed(2));
                  return prev;
                });
              }}
              size="small"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Filter;

import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  addIndividualDiscountToProduct,
  addToChosenProducts,
  deleteProductDiscount,
  getProductsByName,
  getUserDiscounts,
  getUsers,
  getUsersSearchDiscounts,
  selectChosenProducts,
  selectChosenUser,
  selectChosenUserSearchDiscounts,
  selectProducts,
  selectUserDiscounts,
  selectUsers,
  selectUsersSearchDiscounts,
  setChosenUser,
  setChosenUserSearchDiscounts,
} from "../../../../Store/Slices/admin/Products/IndDiscounts";
import { AppDispatch } from "../../../../Store/store";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";
import SearchBar from "../../SearchBar/SearchBar";

export default function ProductDiscount() {
  const products = useSelector(selectProducts);
  const chosenProducts = useSelector(selectChosenProducts);
  const foundUsers = useSelector(selectUsers);
  const foundUsersSearchDiscounts = useSelector(selectUsersSearchDiscounts);
  const chosenUser = useSelector(selectChosenUser);
  const chosenUserSearchDiscounts = useSelector(
    selectChosenUserSearchDiscounts
  );
  const chosenUserDiscounts = useSelector(selectUserDiscounts);

  const dispatch = useDispatch<AppDispatch>();

  const [discount, setDiscount] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [chosenDiscountToDelete, setChosenDiscountToDelete] = useState(-1);
  const [chosenProductToDelete, setChosenProductToDelete] = useState(-1);

  return (
    <div className="w-full flex space-x-6">
      <div className="flex w-1/3 flex-col space-y-2">
        <div className="w-full">
          <span className="font-bold underline">Wybrane produkty</span>
          <div className="w-full max-h-96 overflow-scroll">
            {chosenProducts &&
              chosenProducts.map((element) => {
                return <div>{element.Name}</div>;
              })}
          </div>
        </div>
        <form
          className="flex"
          onSubmit={(e) => {
            e.preventDefault();
            const name = (document.getElementById("name") as HTMLInputElement)
              .value;
            dispatch(getProductsByName({ name: name }));
          }}
        >
          <SearchBar
            placeholder="Nazwa lub numer katalogowy produktu"
            id="name"
          />
          <button className="border-2 p-2" type="submit">
            Szukaj
          </button>
        </form>
        <div className="w-full max-h-96 overflow-scroll space-y-2">
          {products &&
            products.map((element) => {
              return (
                <div className="w-full flex justify-between">
                  <span>{element.Name}</span>
                  <button
                    onClick={() => {
                      dispatch(addToChosenProducts(element));
                    }}
                    className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                  >
                    dodaj
                  </button>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex w-1/3 flex-col space-y-2">
        <div className="w-full">
          <span className="font-bold underline">Zniżka (%)</span>
          <input
            value={discount}
            className="text-center"
            onChange={(e) => {
              try {
                if (parseInt(e.target.value) < 100) {
                  setDiscount(parseInt(e.target.value));
                } else {
                  setDiscount(0);
                }
              } catch (e) {
                setDiscount(0);
              }
            }}
          />
        </div>
        <form
          className="w-full flex"
          onSubmit={(e) => {
            e.preventDefault();
            const client = (
              document.getElementById("client") as HTMLInputElement
            ).value;
            dispatch(getUsers({ name: client }));
          }}
        >
          <SearchBar placeholder="nazwa lub NIP klienta" id="client" />
          <button className="border-2 p-2" type="submit">
            Szukaj
          </button>
        </form>
        <div className="w-full flex flex-col max-h-96 overflow-scroll space-y-1">
          {foundUsers &&
            foundUsers.map((element) => {
              return (
                <button
                  className={`p-2 text-sm                    
                    ${
                      element.Id === chosenUser
                        ? "bg-primary text-white"
                        : "hover:bg-primary hover:text-white"
                    }`}
                  onClick={() => {
                    dispatch(setChosenUser(element.Id));
                  }}
                >
                  {element.Name} | {element.Email}
                </button>
              );
            })}
        </div>
        <button
          className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
          onClick={() => {
            var productsIds: number[] = chosenProducts.map((element) => {
              return element.Id;
            });
          }}
        >
          Zatwierdź
        </button>
      </div>
      <div className="w-1/3 flex flex-col space-y-2">
        <span className="font-bold underline">Szukaj rabatów</span>
        <form
          className="w-full flex"
          onSubmit={(e) => {
            e.preventDefault();
            const client = (
              document.getElementById("client_discount") as HTMLInputElement
            ).value;
            dispatch(getUsersSearchDiscounts({ name: client }));
          }}
        >
          <SearchBar placeholder="nazwa lub NIP klienta" id="client_discount" />
          <button className="border-2 p-2" type="submit">
            Szukaj
          </button>
        </form>
        <div className="w-full flex flex-col space-y-1 max-h-96 overflow-scroll">
          {foundUsersSearchDiscounts &&
            foundUsersSearchDiscounts.map((element) => {
              return (
                <button
                  className={`p-2 text-sm                    
                ${
                  element.Id === chosenUser
                    ? "bg-primary text-white"
                    : "hover:bg-primary hover:text-white"
                }`}
                  onClick={() => {
                    dispatch(setChosenUserSearchDiscounts(element.Id));
                  }}
                >
                  {element.Name} | {element.Email}
                </button>
              );
            })}
        </div>
        {chosenUserSearchDiscounts !== -1 && (
          <button
            className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
            onClick={() => {
              dispatch(getUserDiscounts({ userId: chosenUserSearchDiscounts }));
            }}
          >
            Zatwierdź i szukaj rabatów wybranego klienta
          </button>
        )}
        <div className="w-full max-h-96 overflow-scroll">
          {chosenUserDiscounts &&
            chosenUserDiscounts.map((element) => {
              return (
                <div className="flex justify-between w-full space-y-1">
                  <span>
                    {element.Name}{" "}
                    <span className="underline font-semibold">
                      {element.Discount}%
                    </span>
                  </span>
                  <button
                    className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                    onClick={() => {
                      setChosenProductToDelete(element.Id);
                      setChosenDiscountToDelete(element.Discount);
                      setShowConfirmDelete(true);
                    }}
                  >
                    Usuń
                  </button>
                </div>
              );
            })}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {showConfirmDelete && (
          <Confirm
            handleClose={function (): void {
              setShowConfirmDelete(false);
            }}
            handleConfirm={function (): void {
              dispatch(
                deleteProductDiscount({
                  userId: chosenUserSearchDiscounts,
                  id: chosenProductToDelete,
                  discount: chosenDiscountToDelete,
                })
              );
              setShowConfirmDelete(false);
            }}
            text={"Czy na pewno chcesz usunać ten rabat?"}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

import React, { useState } from "react";
import ButtonPrimaryToLighter from "../../../Common/Buttons/ButtonPrimaryToLighter";
import {  useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { dropDown } from "../../../Animations/Animations";
import {
  selectCartPrice,
  selectProducts,
  selectUpdateQuantitiesState,
} from "../../../Store/Slices/Cart/Cart";
import { getDiscount, selectDiscount, selectPriceDetails } from "../../../Store/Slices/Order/Order";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import { AppDispatch } from "../../../Store/store";

type Props = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

function CartControls({ setShow }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [discountCode, setDiscountCode] = useState('');

  const priceProducts = useSelector(selectCartPrice);
  const priceDelivery = useSelector(selectPriceDetails).shipping;
  const discount = useSelector(selectDiscount);
  const updateQuantityState = useSelector(selectUpdateQuantitiesState);

  const products = useSelector(selectProducts);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <motion.div
      className="sticky top-28 flex flex-col justify-between overflow-hidden w-full xl:w-1/4 h-fit bg-neutral-100 rounded-lg shadow-md"
      variants={dropDown}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="p-2 flex flex-col space-y-1">
        <h1 className="text-2xl font-bold">Podsumowanie</h1>
        <div className="flex justify-between items-center">
          <h1 className="text-lg">Produkty:</h1>
          <h1 className="text-lg">{priceProducts.toFixed(2)} PLN</h1>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-lg">Dostawa:</h1>
          <h1 className="text-lg">{priceDelivery.toFixed(2)} PLN</h1>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-lg">VAT:</h1>
          <h1 className="text-lg">{(priceProducts * 0.23).toFixed(2)} PLN</h1>
        </div>
        <hr />
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold">Razem do zapłaty:</h1>
          <h1 className="text-lg font-bold">
            {((1-discount/100)*priceProducts * 1.23 + priceDelivery).toFixed(2)} PLN
          </h1>
        </div>
      </div>

      <div className="flex items-center justify-between space-x-2 p-2">
        <input
          onChange={(e)=>{
            setDiscountCode(e.target.value??'');
          }}
          defaultValue={discountCode}
          type="text"
          name="promo_code"
          id="promo_code"
          placeholder="Kod promocyjny"
          className="appearance-none block w-full p-2 text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all"
        />
        <ButtonPrimaryToLighter
          text="Zastosuj"
          size="medium"
          Icon={RedeemTwoToneIcon}
          onClick={() => {
            dispatch(getDiscount(discountCode))
          }}
        />
      </div>
      <div className="w-full p-2">
        <ButtonPrimaryToLighter
          text="Przejdź do kasy"
          size="large"
          Icon={ShoppingCartCheckoutIcon}
          customClasses={
            products.length === 0 || updateQuantityState.isLoading === true
              ? "cursor-not-allowed w-full"
              : "w-full"
          }
          onClick={() => {
            if (products.length === 0 || updateQuantityState.isLoading) return;            
            setTimeout(() => {
              navigate("/order");
            }, 300);
            setShow(false);
          }}
        />
      </div>

      {!isLoggedIn && (
        <div className="w-full h-full border-t-2 p-2">
          <h1 className="text-base font-bold">
            Zaloguj się aby otrzymać spersonalizowane ceny. Możesz również
            zakupić produkty bez rejestracji.
          </h1>
        </div>
      )}
    </motion.div>
  );
}

export default CartControls;

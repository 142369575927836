import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HistoryIcon from "@mui/icons-material/History";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

function RegisterInformational() {
  return (
    <div className="w-full lg:w-1/2 lg:sticky lg:top-28">
      <h1 className="text-5xl lg:text-7xl font-black py-5 lg:py-10">
        Dlaczego powinieneś utworzyć konto?
      </h1>
      <div className="flex flex-col space-y-4 lg:space-y-8">
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <ShoppingCartIcon />
          <span>
            Możliwość zapisania koszyka i kontynuacji zakupów w dowolnym
            momencie
          </span>
        </div>
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <FavoriteIcon color="error" />
          <span>Tworzenie list życzeń</span>
        </div>
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <HistoryIcon />
          <span>
            Śledzenie historii zakupów i sprawdzanie statusu
            zamówień
          </span>
        </div>
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <LocalOfferIcon />
          <span>Możliwość otrzymania rabatów na produkty</span>
        </div>
      </div>
    </div>
  );
}

export default RegisterInformational;

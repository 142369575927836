import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Store/store';
import { getCategories } from '../../../../Store/Slices/admin/Categories/Categories';
import { selectCategories, selectCategoriesState } from '../../../../Store/Slices/Categories/Categories';
import { CategoryModel } from '../../../../Models/Category';

type Props = {}

function Categories({}: Props) {

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    //dispatch(getCategories());    
  }, [dispatch]);

  //const categories: CategoryModel[] = useSelector(selectAdminCategories);
  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectCategoriesState);

  return (
    <div>
      <p>Categories</p>

    </div>
  )
}

export default Categories
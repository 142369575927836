import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./Producer.css";
import { motion } from "framer-motion";
import Info from "@mui/icons-material/Info";
import InfoMessage from "../../Common/Messages/InfoMessage/InfoMessage";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { getProducerCategories, selectProducerCategories } from "../../Store/Slices/Products/Products";
import ProductCard from "../../Common/ProductCard/ProductCard";

type ProducerType = {
  title: string;
  productList?: string[];
  texts: TextBlock[];
};

type TextBlock = {
  title?: string;
  text: string;
  img?: string;
};

const producers: { [id: string]: ProducerType } = {
  "AirTAC S.R.L.": {
    title:
      "AirTAC to tajwańska firma założona w 1988 roku specjalizująca się w produkcji elementów pneumatyki przemysłowej.",
    productList: ["Zawory i elektrozawory pneumatyczne", "Siłowniki pneumatyczne", 'Przygotowanie powietrza', 'Wyspy zaworowe', 'Osprzęt do siłowników', 'Złącza do przewodów'],
    texts: [
      {
        title: "Historia marki AirTAC",
        text: `AirTAC rozpoczął ekspansję na rynek chiński budując w 1998r. w Shinsan w okolicach Hong Kongu pierwszy zakład produkcyjny poza terenem Tajwanu. W 2010r. AirTAC wszedł na tajwańską giełdę, a jednym z jego głównych udziałowców stał się Bank of China. W 2021r. sprzedaż wynosi około 4 razy więcej niż w 2014r., a dzisiaj AirTAC jest trzecim największym producentem pneumatyki na świecie`,
        img: "https://pressbarimages.b-cdn.net/strona-glowna/2dbcebc7-2ad5-4362-b7e3-bf1dcb560126-2-1-scaled.webp",
      },
    ] as TextBlock[],
  },
  'Knocks':{
    title: 'Knocks Fluid-Technik to niemiecka firma specjalizująca się w produkcji elementów przygotowania powietrza ',
    productList:["Filtry", "Smarownice", 'Reduktory', 'Filtroreduktory', 'Filtry dokładne', 'Bloki przygotowania powietrza', 'Zawory kulowe', 'Elektrozawory', 'Zawory łagodnego startu', 'Elementy montażowe'],
    texts:[
      {
        title:'Historia firmy Knocks',
        text:`Od jednoosobowej firmy do lidera rynku – to chyba najlepiej opisuje rozwój KNOCKS FLUID-Technik GmbH. Firma założona w 1970 roku. Mała przydomowa firma rozrosła się do firmy działającej na całym świecie w dziedzinie technologii płynów i sprężonego powietrza. Firma rozwijała się poprzez ciągłą modernizację i badania.
        Jednocześnie wzrosło dążenie do doskonałości w zakresie standardów jakości. Logiczną konsekwencją były certyfikaty ISO 9001 i ISO 14001.`,
        img: "https://pressbarimages.b-cdn.net/strona-glowna/2dbcebc7-2ad5-4362-b7e3-bf1dcb560126-2-1-scaled.webp",
      }
    ]
  },
  'Airpol':{
    title: 'Airpol to polska firma specjalizująca się w produkcji sprężarek, osuszaczy sprężonego powietrza, zbiorników',
    productList:["Sprężarki śrubowe", "Kompresory tłokowe", 'Osuszacze powietrza', 'Zbiorniki', 'Sprężarki spiralne', 'Doprężacze powietrza', 'Filtry'],
    texts:[
      {
        title:'O firmie',
        text:`AIRPOL Sp. z o.o. to największy w Polsce producent sprężarek. Ponad 50-lat doświadczenia w branży pneumatycznej czyni z firmy fachowca w dziedzinie sprężonego powietrza`,
        img: "https://pressbarimages.b-cdn.net/airpol/srubowe/18-55/Airpol-sprezarki-1-1000x562.jpg.webp",
      }
    ]
  },
  'Atlas Copco':{
    title: 'Atlas copco to szwedzka firma specjalizująca się w produkcji sprężarek, osuszaczy sprężonego powietrza, zbiorników',
    productList:["Sprężarki śrubowe", "Kompresory tłokowe", 'Osuszacze powietrza', 'Zbiorniki', 'Generatory azotu', 'Generatory tlenu', 'Filtry', 'Bezolejowe dmuchawy powietrza'],
    texts:[
      {
        title:'O firmie',
        text:`Atlas Copco jest międzynarodową grupą przemysłową zajmującą pozycję światowego lidera w zakresie rozwiązań zwiększających wydajność z poszanowaniem zasad zrównoważonego rozwoju. Grupa oferuje klientom innowacyjne sprężarki, rozwiązania w zakresie technologii próżniowej, systemów uzdatniania powietrza, sprzęt budowlany , narzędzia elektryczne, pneumatyczne i hydrauliczne oraz systemy montażowe. Atlas Copco szczególną uwagę zwraca na wydajność, oszczędność energii, bezpieczeństwo i ergonomię. Firma została założona w 1873 r. Jej siedziba mieści się w Sztokholmie, w Szwecji, a działalność jest prowadzona w ponad 180 krajach świata.`,
        img: "https://pressbarimages.b-cdn.net/atlas/G%2Brange_groupshot_v01.webp",
      }
    ]
  },
};

function Producer() {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(()=>{
    dispatch(getProducerCategories(id??''));
  }, [id, dispatch]);

  const producerCategories = useSelector(selectProducerCategories);

  return (
    <div className="w-full" id="producer_content">
      {id !== undefined && producers[id] ? (
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center justify-center max-w-[1024px] px-2 sm:px-5">
            <h1 id='h1Producer'>{id}</h1>
            {producers[id].title && <h2 className="h2Producer">{producers[id].title}</h2>}
          </div>
          {producers[id].texts.map((text, index) =>
            text.img !== undefined ? (
              <div
                key={index}
                className="w-full my-10"
                style={{
                  backgroundImage: `url(${text.img})`,
                  backgroundSize: "cover",
                }}
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  id="producer_image"
                  className="flex flex-col items-center bg-primary-darker bg-opacity-50 backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
                >
                  {text.title && (
                    <h2 className="h2Producer max-w-[1024px] mt-10 sm:text-6xl text-5xl font-display text-center text-white mb-2">
                      {text.title}
                    </h2>
                  )}
                  <p className="">{text.text}</p>
                </motion.div>
              </div>
            ) : (
              <div
                key={index}
                className="flex flex-col items-center justify-center max-w-[1024px] px-2 sm:px-5"
              >
                {text.title && <h3 className="">{text.title}</h3>}
                <p className="">{text.text}</p>
              </div>
            )
          )}
          {producers[id].productList && (
            <div
              className="flex flex-col mb-10 w-full justify-center max-w-[1024px] px-2 sm:px-5 shadow-md"
              id="producer_products"
            >
              <h3 id="list_title" className="">
                Lista produktów producenta {id} dostępnych w naszym sklepie:
              </h3>
              <ul className="w-full">
                {producers[id].productList?.map((product, index) => (
                  <li key={index}>{product}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="w-full h-fit" style={{paddingLeft: '20px', paddingRight: '20px'}}>
      {true && (
        <div className="w-full h-fit rounded-lg overflow-x-hidden">
          {Array.isArray(producerCategories) &&
            producerCategories.length > 0 &&
            producerCategories.map(
              (type) =>
                type.Products.length > 0 && (
                  <div key={type.Category_name} className="w-full">
                    <span className="text-5xl font-display font-bold text-neutral-950 border-b-2 border-primary">
                      <Link to={`/search?category=${type.Category_id}`} style={{color: 'black', textDecoration: 'none'}}>{type.Category_name}</Link>
                    </span>
                    <div className="w-auto flex shrink-0 space-x-2 overflow-x-scroll flex-nowrap py-3 snap-start snap-mandatory snap-always">
                      {Array.isArray(type.Products) &&
                        type.Products.length > 0 &&
                        type.Products.map((product) => (
                          <ProductCard
                            key={product.Id}
                            product={product}
                            shrink={false}
                            grow={false}
                          />
                        ))}
                    </div>
                  </div>
                )
            )}
        </div>
      )}
    </div>
        </div>
      ) : (
        <InfoMessage message={"Brak informacji dotyczących tego producenta."} />
      )}
    </div>
  );
}

export default Producer;

import React, { useEffect } from "react";
import LoginRegister from "./LoginRegister/LoginRegister";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../../Store/Slices/User/User";
import { AppDispatch } from "../../../../../Store/store";
import { setStage } from "../../../../../Store/Slices/Order/Order";

function StageOne() {
  const dispatch = useDispatch<AppDispatch>();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setStage(2));
    }
  });

  return <LoginRegister redirect="/order"/>;
}

export default StageOne;

import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import Backdrop from "../Backdrop/Backdrop";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

type Props = {
  color: "primary" | "secondary";
  type?: "local" | "global";
  size?: "small" | "medium" | "large";
};

function LoadingAnimation({ color, type = "local", size = "large" }: Props) {
  if (type === "global") {
    return (
      <div>
        {ReactDOM.createPortal(
          <AnimatePresence mode="wait">
            <Backdrop onClick={() => {}}>
              <div className="flex items-center justify-center animate-spin-slow">
                <SettingsOutlinedIcon fontSize={size} color={color} />
              </div>
            </Backdrop>
          </AnimatePresence>,
          document.body
        )}
      </div>
    );
  }

  return (
    <motion.div
      className={`absolute z-50 top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-sm`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex items-center justify-center animate-spin-slow">
        <SettingsOutlinedIcon fontSize={size} color={color} />
      </div>
    </motion.div>
  );
}

export default LoadingAnimation;

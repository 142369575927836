import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Offer from "./Offer/Offer";
import Categories from "./Categories/Categories";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLandingPageProducts,
  selectLandingPageProducts,
  selectLandingPageProductsState,
} from "../../Store/Slices/Products/Products";
import { AppDispatch } from "../../Store/store";
import LandingPageProducts from "./Offer/LandingPageProducts";
import {
  fetchLandingPageArticles,
  selectLandingPageArticles,
  selectLandingPageArticlesState,
} from "../../Store/Slices/Articles/Article";
import LandingPageArticles from "./Offer/LandingPageArticles";
import Links from "../../Common/Links/Links";
import Banner from "./Banner/Banner";
import { Helmet } from "react-helmet";
import KonfiguratorLinki from "./Offer/KonfiguratorLinki";

export default function LandingPage() {
  useEffect(() => {
    document.title = "Press Bar Pneumatyka";
  }, []);

  const dispatch = useDispatch<AppDispatch>();
  const landingPageProducts = useSelector(selectLandingPageProducts);
  const landingPageProductsState = useSelector(selectLandingPageProductsState);
  const landingPageArticles = useSelector(selectLandingPageArticles);
  const landingPageArticlesState = useSelector(selectLandingPageArticlesState);

  useEffect(() => {
    dispatch(fetchLandingPageProducts());
    dispatch(fetchLandingPageArticles());
  }, []);

  return (
    <div className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full">
      <Helmet>
        <title>Press Bar Pneumatyka</title>
        <meta
          name="description"
          content="Sklep internetowy z pneumatyką. Usługi instalacyjne, montażowe. Spręarki śrubowe, osuszacze, generatory azotu i tlenu. Chcemy zaproponować Państwu nowoczesne produkty i szeroki wachlarz usług związanych z naprawą, modyfikacją oraz budową nowych maszyn i urządzeń z dziedziny automatyki przemysłowej."
        />
        <meta
          name="keywords"
          content="Sklep pneumatyka, pneumatyka, siłowniki pneumatyczne, airtac, Airpol, gudepol, zawory pneumatyczne, elektrozawory, napędy pneumatyczne, instalacje pneumatyczne, sprężarki, osuszacze, generatory azotu, generatory tlenu, instalacje sprężonego powietrza, sprężone powietrze, avs, knocks, futura, przygotowanie powietrza, reduktor powietrza, filtr powietrza, serwis kompresorów, serwis sprężarek, blok przygotowania powietrza, smarownica, manometry, armatura, złączka, złączka wtykowa"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="copyright" content="2024, Press Bar Sp. z o. o. " />
      </Helmet>
      <motion.div
        className="flex flex-col justify-center items-center w-full space-y-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="w-full px-2 sm:px-5 max-w-[1536px]">
          <Links />
          <div className="flex items-center lg:space-x-4 justify-center">
            <div className="hidden lg:block w-1/3 rounded-lg overflow-hidden">
              <KonfiguratorLinki />
            </div>
            <div className="w-full lg:w-2/3">
              <Banner />
            </div>
          </div>
          <Categories />
          <div className="w-full block lg:hidden ">
            <KonfiguratorLinki />
          </div>
        </div>
        <Offer />
        <div className="w-full px-2 sm:px-5 max-w-[1536px]">
          <LandingPageProducts landingPageProducts={landingPageProducts} />
          <LandingPageArticles landingPageArticles={landingPageArticles} />
        </div>
      </motion.div>
    </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductDisplay, ProductIdQuantity } from "../../../Models/Product";
import { Wishlist, WishlistName } from "../../../Models/Wishlist";
import { StateType } from "../../../Models/State";
import axios from "axios";
import { server } from "../../../server";
import { act } from "react-dom/test-utils";

type WishlistState = {
  wishlists: {
    lists: WishlistName[];
    state: StateType;
  };
  wishlist: {
    list: Wishlist;
    state: StateType;
    offerUrl: string;
  };
  addToList:{
    productId: number;
    listName: string;
    state: StateType;
  };
  offers: {
    Company_id: number;
    Company_name: string;
    Date: string;
    Url: string;
  }[]
};

export type offerProduct = {
  Id: number, 
  Name: string, 
  Catalog_name:string, 
  Delivery_time: string,
  Quantity: number,
  Price: number,
  Discount: number, 
  DisplayDiscount: boolean,
  Image_url:string,
}

export type OfferData = {
  products: offerProduct[],
  data: {
    Name: string,
    NIP: string,
    Address: string,
    City: string,
    Zamawiajacy: string,
    DataZlecenia: string,
    DataWaznosci: string,
    Tworca: string,
    TworcaEmail: string,
    TworcaPhone: string,
    Platnosc: string,
    Email: string,
    Tel: string,
    Company_id: number,
  }
};

export const getLists = createAsyncThunk("wishlists/getLists", async () => {
  try {
    const response = await axios.get<WishlistName[]>(
      `${server}/api/Lists/user`,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
});

export const getProductsToList = createAsyncThunk(
  "wishlists/getProductsToList",
  async (id: number) => {
    try {
      const response = await axios.get<ProductDisplay[]>(
        `${server}/api/Lists/products/${id}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const changeSelectedList = createAsyncThunk(
  "wishlists/changeSelectedList",
  ({ id, name }: { id: number; name: string }) => {
    return { id, name };
  }
);

export const clearFavouritesLists = createAsyncThunk(
  "wishlists/clearFavouritesLists",
  () => {
    return {};
  }
);

export const changeListName = createAsyncThunk(
  "wishlists/changeListName",
  async ({ id, name }: { id: number; name: string }) => {
    try {
      const response = await axios.post(
        `${server}/api/Lists/update/name`,
        {
          listId: id,
          name,
        },
        { withCredentials: true }
      );
      if (response.status == 200) {
        return { id, name };
      } else {
        throw response.data.error;
      }
    } catch (e) {
      throw e;
    }
  }
);

export const deleteList = createAsyncThunk(
  "wishlists/deleteList",
  async (id: number) => {
    try {
      const response = await axios.delete(`${server}/api/Lists/remove/${id}`, {
        withCredentials: true,
      });
      if (response.status == 200) {
        return id;
      } else {
        return -1;
      }
    } catch (e) {
      throw e;
    }
  }
);

export const copyListToCart = createAsyncThunk(
  `wishlists/copyListToCart`,
  async (id: number) => {
    try {
      const response = await axios.post(
        `${server}/api/Lists/copy/tocart/${id}`,
        {},
        { withCredentials: true }
      );
      if (response.status == 200) {
        return {};
      } else {
        throw response.data.error;
      }
    } catch (e) {
      throw e;
    }
  }
);

export const createOffer = createAsyncThunk(
  'wishlists/createOffer',
  async({products, data}: OfferData)=>{
    try{
      const response = await axios.post(`${server}/api/Lists/offer/make`,
      {data: data, products: products});
      return {url: response.data.url, name: data.Name, id: data.Company_id};

    }
    catch(e){
      throw e;
    }
  }
)

export const addProductToList = createAsyncThunk(
  `wishlists/addProductToList`,
  async ({
    listId,
    products,
  }: {
    listId: number;
    products: ProductIdQuantity[];
  }) => {
    try {
      const response = await axios.post(
        `${server}/api/Lists/product/add/${listId}`,
        { products },
        { withCredentials: true }
      );
      return {id: listId, product: products[0]};
    } catch (e) {
      throw e;
    }
  }
);

export const addNewList = createAsyncThunk(
  'wishlists/addNewList',
  async({
    name
  }:{
    name: string
  })=>{
    try{

        const response = await axios.post(`${server}/api/Lists/product/add/new`,
        {name: name, products: []},
        {withCredentials: true});

        return {name: name, id: response.data.listId};

    }
    catch (e) {
      throw e;
    }
  }
);

export const removeProductFromList = createAsyncThunk(
  'wishlists/removeProductFromList',
  async({
    productId,
    listId
  }:{productId:number, listId: number})=>{
    try{

      const response = await axios.post(`${server}/api/Lists/product/remove/products`,
      {products:[{Product_id:productId, List_id: listId}]}, {withCredentials: true});

      return {productId, listId}

    }
    catch (e) {
      throw e;
    }
  }
)

export const fetchOffers = createAsyncThunk(
  'wishlists/fetchOffers',
  async()=>{
    try{

      const response = await axios.get(`${server}/api/Lists/offers`, {withCredentials: true});

      return response.data;

    }
    catch (e) {
      throw e;
    }
  }
)

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlists: {
      lists: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    wishlist: {
      list: {
        id: -1,
        name: "",
        products: [],
      },
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
      offerUrl: ''
    },
    addToList:{
      productId:-1,
      listName: '',
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    offers:[]
  } as WishlistState,
  reducers: {
    changeProductDiscount(state, action: {payload: {id: number, discount: number}}){
      for(const element of state.wishlist.list.products){
        if(element.Id===action.payload.id){
          element.Discount=action.payload.discount;
          element.SpecialPrice=element.Price*(1-action.payload.discount/100);
        }
      }
    },
    changeProductSpecialPrice(state, action: {payload: {id: number, price: number}}){
      for(const element of state.wishlist.list.products){
        if(element.Id===action.payload.id){
          element.SpecialPrice=action.payload.price;
          //element.Discount=(1.0-(element.SpecialPrice/element.Price))*100.0;
          element.Discount=0;
        }
      }
    },
    changeProductQuantity(state, action: {payload: {id: number, quantity: number}}){
      for(const element of state.wishlist.list.products){
        if(element.Id===action.payload.id){
          element.Quantity=action.payload.quantity;
        }
      }
    },
    changeDeliveryTime(state, action: {payload: {id: number, deliveryTime: string}}){
      for(const element of state.wishlist.list.products){
        if(element.Id===action.payload.id){
          element.Delivery_time=action.payload.deliveryTime;
        }
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(getLists.pending, (state, action) => {
      state.wishlists.state.isLoading = true;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = null;
    });
    builder.addCase(getLists.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = true;
      state.wishlists.lists = action.payload;
    });
    builder.addCase(getLists.rejected, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = action.error;
    });
    builder.addCase(getProductsToList.pending, (state, action) => {
      state.wishlist.state.isLoading = true;
      state.wishlist.state.failedLoading = false;
      state.wishlist.state.hasLoaded = false;
      state.wishlist.state.error = null;
    });
    builder.addCase(getProductsToList.fulfilled, (state, action) => {
      state.wishlist.state.isLoading = false;
      state.wishlist.state.failedLoading = false;
      state.wishlist.state.hasLoaded = true;
      state.wishlist.list.products = action.payload;
      state.wishlist.list.products.forEach(element=>{
        if(element.Delivery_time===undefined){
          if((element.Stock_quantity??0)>=1){
            element.Delivery_time = '1-2 dni robocze';
          }
          else{
            element.Delivery_time = '3-25 dni roboczych';
          }
        }
      })
    });
    builder.addCase(getProductsToList.rejected, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = action.error;
    });
    builder.addCase(changeSelectedList.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlist.list.id = action.payload.id;
      state.wishlist.list.name = action.payload.name;
    });
    builder.addCase(clearFavouritesLists.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlist.list.id = -1;
      state.wishlist.list.name = "";
      state.wishlists.lists = [];
    });
    builder.addCase(changeListName.pending, (state, action) => {
      state.wishlists.state.isLoading = true;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = null;
    });
    builder.addCase(changeListName.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = true;
      for (const element of state.wishlists.lists) {
        if (element.Id === action.payload.id) {
          element.Name = action.payload.name;
          break;
        }
      }
      if (state.wishlist.list.id === action.payload.id) {
        state.wishlist.list.name = action.payload.name;
      }
    });
    builder.addCase(changeListName.rejected, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = action.error;
    });
    builder.addCase(deleteList.pending, (state, action) => {
      state.wishlists.state.isLoading = true;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = null;
    });
    builder.addCase(deleteList.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = true;
      if (action.payload !== -1) {
        var index = -1,
          i = 0;
        for (const element of state.wishlists.lists) {
          if (element.Id === action.payload) {
            index = i;
            break;
          }
          i++;
        }
        state.wishlists.lists.splice(index, 1);
        state.wishlist.list.id = -1;
        state.wishlist.list.name = "";
        state.wishlist.list.products = [];
      }
    });
    builder.addCase(deleteList.rejected, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = action.error;
    });
    builder.addCase(copyListToCart.pending, (state, action) => {
      state.wishlist.state.isLoading = true;
      state.wishlist.state.failedLoading = false;
      state.wishlist.state.hasLoaded = false;
      state.wishlist.state.error = null;
    });
    builder.addCase(copyListToCart.fulfilled, (state, action) => {
      state.wishlist.state.isLoading = false;
      state.wishlist.state.failedLoading = false;
      state.wishlist.state.hasLoaded = true;
      state.wishlist.list.id = -1;
      state.wishlist.list.name = "";
      state.wishlist.list.products = [];
    });
    builder.addCase(copyListToCart.rejected, (state, action) => {
      state.wishlist.state.isLoading = false;
      state.wishlist.state.failedLoading = true;
      state.wishlist.state.hasLoaded = false;
      state.wishlist.state.error = action.error;
    });
    builder.addCase(addProductToList.pending, (state, action) => {
      state.addToList.state.isLoading = true;
      state.addToList.state.failedLoading = false;
      state.addToList.state.hasLoaded = false;
      state.addToList.state.error = null;
    });
    builder.addCase(addProductToList.fulfilled, (state, action) => {
      state.addToList.state.isLoading = false;
      state.addToList.state.failedLoading = false;
      state.addToList.state.hasLoaded = true;
      var name = '';
      for(const element of state.wishlists.lists) {
        if(element.Id==action.payload.id){
          name=element.Name;
          break;
        }
      }
      state.addToList.listName=name;
      state.addToList.productId=action.payload.product.Product_id;
    });
    builder.addCase(addProductToList.rejected, (state, action) => {
      state.addToList.state.isLoading = false;
      state.addToList.state.failedLoading = true;
      state.addToList.state.hasLoaded = false;
      state.addToList.state.error = action.error;
    });
    builder.addCase(createOffer.pending, (state, action) => {
      state.wishlist.state.isLoading = true;
      state.wishlist.state.failedLoading = false;
      state.wishlist.state.hasLoaded = false;
      state.wishlist.state.error = null;
    });
    builder.addCase(createOffer.fulfilled, (state, action) => {
      state.wishlist.state.isLoading = false;
      state.wishlist.state.failedLoading = false;
      state.wishlist.state.hasLoaded = true;
      state.wishlist.offerUrl=action.payload.url;
      state.offers=[...state.offers, {Company_id: action.payload.id, Company_name: action.payload.name,
      Url: action.payload.url, Date: new Date().toString()}]
      state.wishlist.list.id = -1;
      state.wishlist.list.name = "";
      state.wishlist.list.products = [];
    });
    builder.addCase(createOffer.rejected, (state, action) => {
      state.wishlist.state.isLoading = false;
      state.wishlist.state.failedLoading = true;
      state.wishlist.state.hasLoaded = false;
      state.wishlist.state.error = action.error;
    });
    builder.addCase(addNewList.pending, (state, action) => {
      state.wishlists.state.isLoading = true;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = null;
    });
    builder.addCase(addNewList.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = true;
      var newList = [];
      for(const element of state.wishlists.lists){
        newList.push(element);
      }
      newList.push({Id: action.payload.id, Name: action.payload.name})
      state.wishlists.lists=newList
    });
    builder.addCase(addNewList.rejected, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = action.error;
    });
    builder.addCase(removeProductFromList.pending, (state, action) => {
      state.wishlists.state.isLoading = true;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = null;
    });
    builder.addCase(removeProductFromList.fulfilled, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = false;
      state.wishlists.state.hasLoaded = true;
      if(state.wishlist.list.id===action.payload.listId){
        state.wishlist.list.products=state.wishlist.list.products.filter(element=>{
          if(element.Id===action.payload.productId){
            return false;
          }
          return true;
        })
      }
    });
    builder.addCase(removeProductFromList.rejected, (state, action) => {
      state.wishlists.state.isLoading = false;
      state.wishlists.state.failedLoading = true;
      state.wishlists.state.hasLoaded = false;
      state.wishlists.state.error = action.error;
    });
    builder.addCase(fetchOffers.pending, (state, action) => {
      

    });
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.offers=action.payload;
      
    });
    builder.addCase(fetchOffers.rejected, (state, action) => {
      

    });
    
  },
});

export const selectProducts = (state: { wishlist: WishlistState }) => {
  return state.wishlist.wishlist.list.products;
};

export const selectNumberOfLists = (state: { wishlist: WishlistState }) => {
  return state.wishlist.wishlists.lists.length;
};

export const selectListId = (state: { wishlist: WishlistState }): number => {
  return state.wishlist.wishlist.list.id;
};

export const selectListName = (state: { wishlist: WishlistState }): string => {
  return state.wishlist.wishlist.list.name;
};

export const selectLists = (state: { wishlist: WishlistState }) => {
  return state.wishlist.wishlists.lists;
};

export const selectAddToList = (state: {wishlist: WishlistState})=>{
  return {name: state.wishlist.addToList.listName, id: state.wishlist.addToList.productId};
}

export const selectOfferUrl = (state: {wishlist: WishlistState})=>{
  return state.wishlist.wishlist.offerUrl
}

export const selectOffers = (state: {wishlist: WishlistState})=>{
  return state.wishlist.offers;
}


export const {changeProductDiscount, changeProductSpecialPrice, 
  changeProductQuantity, changeDeliveryTime} = wishlistSlice.actions;

export default wishlistSlice.reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppDispatch } from "../../../../Store/store";
import LoadingAnimation from "../../../LoadingAnimation/LoadingAnimation";
import ButtonPrimaryToLighter from "../../../Buttons/ButtonPrimaryToLighter";
import { AnimatePresence, motion, useAnimate } from "framer-motion";
import { login, selectLoginState } from "../../../../Store/Slices/User/User";
import ButtonTransparentToBlack from "../../../Buttons/ButtonTransparentToColor";
import LoginForm from "../../../Forms/LoginForm/LoginForm";

function LoginFormNav({ closeForm }: { closeForm: () => void }) {
  const dispatch = useDispatch<AppDispatch>();
  const [scope, animate] = useAnimate();

  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectLoginState);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (failedLoading) {
      animate(scope.current, { x: [-5, 5, -5, 5, 0] }, { duration: 0.2 });
    }
  }, [failedLoading, animate, scope]);

  /*useEffect(() => {
    if (hasLoaded) {
      closeForm();
    }
  }, [hasLoaded, closeForm]);*/

  function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const remember = formData.get("remember") as string;
    const rememberMe = remember === "on" ? true : false;
    dispatch(login({ email, password, rememberMe }));
  }

  return (
    <motion.div
      className="z-50 absolute top-full -right-28 w-[90vw] sm:w-96 sm:right-0 bg-neutral-100 bg-opacity-80 backdrop-blur-md rounded-b-lg rounded-tl-lg overflow-hidden shadow-md"
      onClick={stopPropagation}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      ref={scope}
      transition={{ type: "spring", duration: 0.5 }}
    >
      <LoginForm closeForm={closeForm}/>
    </motion.div>
  );
}

export default LoginFormNav;

import { marked } from "marked";
import React, { useEffect } from "react";

function PolitykaPrywatnosci() {
  useEffect(() => {
    document.title = "Polityka Prywatnosci";
  }, []);

  return (
    <div className="w-full max-w-[1280px] text-center p-2 flex flex-col">
      <h1 className="text-3xl sm:text-4xl md:text-6xl my-10 font-bold">
        Polityka Prywatnosci
      </h1>
      <div
        id="markdown_content"
        className="w-full flex flex-col items-center"
      >
        <p>Szanując prawa naszych Klientów oraz respektując przepisy dotyczące ochrony danych osobowych chcielibyśmy poinformować o naszych zasadach gromadzenia, przetwarzania, zabezpieczania, przekazywania i wykorzystania danych osobowych oraz poinformować do kogo można się zwrócić w kwestiach związanych z danymi osobowymi.</p>
<p>Zgodnie z wymaganiami ROZPORZĄDZENIA PARLAMENTU EUROPEJSKIEGO I RADY (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) zwanego dalej „Rozporządzeniem&quot; informujemy, że:</p>
<p><strong>1. Administrator danych osobowych</strong></p>
<p>Administratorem danych osobowych zwanym dalej „Administratorem&quot; lub „my&quot; jest firma <strong> Press Bar Sp. z o.o. z siedzibą we Częstochowie, kod pocztowy 42-280, ul. Główna 14, NIP: 5732940254, REGON: 523960502, zarejestrowana pod numerem KRS: 000602964 Sąd Rejonowy w Częstochowie, XVII Wydział Gospodarczy Krajowego Rejestru Sądowego.</strong></p>
<p>Administrator danych osobowych odpowiada za wykorzystanie danych osobowych w sposób bezpieczny, zgodny z celami w jakich zostały zebrane oraz zgodny z obowiązującymi przepisami prawa.\
<strong>2. Kontakt z Administratorem</strong></p>
<p>Dane kontaktowe Administratora:</p>
<ul>
<li><p>e-mail <a href="&#x6d;&#x61;&#x69;&#108;&#116;&#x6f;&#x3a;&#x62;&#105;&#117;&#114;&#111;&#x40;&#x70;&#114;&#x65;&#x73;&#x73;&#x62;&#97;&#114;&#46;&#x70;&#x6c;">&#x62;&#105;&#117;&#114;&#111;&#x40;&#x70;&#114;&#x65;&#x73;&#x73;&#x62;&#97;&#114;&#46;&#x70;&#x6c;</a></p>
</li>
<li><p>adres do korespondencji <strong>42-280 Częstochowa, ul. Główna 14</strong>\
<strong>3. Postanowienia ogólne</strong></p>
</li>
</ul>
<p>Uzyskane dane osobowe wykorzystujemy tylko w konkretnych, prawnie uzasadnionych celach w jakich dane te zostały zebrane. Zakres danych osobowych, cel ich przetwarzania, podstawa prawna takiego przetwarzania, okres przetwarzania i kategorie odbiorców danych wynikają z wymagań prawnych ciążących na Administratorze oraz z charakteru i zakresu działań podejmowanych przez osobę, której dane dotyczą. Zobowiązujemy się do zachowania bezpieczeństwa i poufności pozyskanych danych osobowych. Wszyscy pracownicy sklepu zostali odpowiednio przeszkoleni w zakresie przetwarzania danych osobowych.\
<strong>4. Zakres zbieranych danych</strong></p>
<p>W naszym sklepie zbieramy następujące dane osobowe:</p>
<ul>
<li><p>Imię i nazwisko, (nazwa firmy) -- podczas składania zamówienia prosimy naszych Klientów o podanie danych osobowych osób oraz danych firmy, jeżeli zakup jest dokonywany przez firmę, dzięki czemu realizacja zamówień przebiega w prawidłowy sposób.</p>
</li>
<li><p>Adres firmy lub adres dostawy -- jest nam potrzebny do wysyłki zamówionego towaru.</p>
</li>
<li><p>Nr telefonu -- zdarza się, że powiadamiamy naszych Klientów SMS-em o wysłaniu towaru. Czasami też telefonujemy w celu potwierdzenia zamówienia, lub w przypadku nieoczekiwanych zdarzeń jak np. brak towaru w magazynie jednocześnie proponując korzystne rozwiązanie.</p>
</li>
<li><p>Adres email -- poprzez email wysyłamy potwierdzenie zamówienia, oraz kontaktujemy się z naszymi Klientami. Na podany adres e-mail możemy też wysyłać newsletter, jeżeli nasz Klient jest abonentem naszego newslettera .</p>
</li>
<li><p>Pliki Cookies -- nasz sklep wykorzystuje technologię Cookies w celu dostosowania strony do indywidualnych potrzeb osób ją odwiedzających. Klient może wyrazić zgodę na to, aby wpisane przez niego dane zostały zapamiętane i aby mógł bez powtórnego wpisywania korzystać z nich przy następnych odwiedzinach na stronach sklepu internetowego. Właściciele innych witryn nie będą mieli dostępu do tych danych.\
<strong>5. Cel przetwarzania danych przez Administratora, podstawa prawna przetwarzania oraz okres, przez który dane osobowe będą przechowywane:</strong></p>
</li>
</ul>
<p>5.1 Cel przetwarzania danych: <strong>Zawarcie i wykonywanie umowy</strong> (w tym zapewnienie odpowiedniej jakości usług)</p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. b) Rozporządzenia („wykonanie umowy&quot;)</p>
<p>Okres przechowywania: Dane są przechowywane przez okres niezbędny do wykonywania umowy, a także  przez czas wymagany przez przepisy prawa podatkowego, rachunkowego. Część danych może być przechowywana dłużej tj. przez okres niezbędny do przedawnienia ewentualnych roszczeń, przysługujących Administratorowi czy Kupującemu. </p>
<p>5.2 Cel przetwarzania danych: <strong>Prowadzenie marketingu</strong></p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. a) Rozporządzenia („zgoda osoby, której dane dotyczą&quot;)</p>
<p>Okres przechowywania: Dane przechowywane są do momentu wycofania zgody przez osobę, której dane dotyczą, na dalsze przetwarzanie jej danych w celach marketingowych.</p>
<p>5.3 Cel przetwarzania danych:<strong>Wystawianie, gromadzenie i przechowywanie faktur i dokumentów księgowych oraz prowadzenie ksiąg rachunkowych</strong></p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. c) Rozporządzenia („wypełnienie obowiązku prawnego&quot;) w zawiązku z art. 74 ust. 2 Ustawy o rachunkowości oraz w związku z art. 86 § 1 Ustawy Ordynacja Podatkowa.</p>
<p>Okres przechowywania: Dane są przechowywane przez okres w którym przepisy nakazują przechowywanie ksiąg rachunkowych i dowodów księgowych (tj. przez 5 lat, licząc od początku roku następującego po roku obrotowym, którego dane dotyczą) oraz przez okres, po którym przedawnią się ewentualne zobowiązania podatkowe.\
5.4 Cel przetwarzania danych:<strong>Prowadzenie czynności serwisowych</strong></p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. b) Rozporządzenia („wykonanie umowy&quot;) lub Art. 6 ust. 1 lit. c) Rozporządzenia („wypełnienie obowiązku prawnego&quot;)</p>
<p>Okres przechowywania: Dane są przechowywane przez okres 1 roku po terminie upływu rękojmi lub rozliczeniu reklamacji, a później przez okres, po którym przedawnią się ewentualne roszczenia.</p>
<p>5.5 Cel przetwarzania danych: <strong>Udzielanie odpowiedzi na reklamacje w terminie i formie przewidzianych przepisami</strong></p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. c) Rozporządzenia („wypełnienie obowiązku prawnego&quot;)</p>
<p>Okres przechowywania: Dane są przechowywane przez okres 1 roku po terminie upływu rękojmi lub rozliczeniu reklamacji, a później przez okres, po którym przedawnią się ewentualne roszczenia.</p>
<p>5.6 Cel przetwarzania danych:<strong>Wykrywanie i zapobieganie nadużyciom</strong></p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. c) Rozporządzenia („wypełnienie obowiązku prawnego&quot;)</p>
<p>Okres przechowywania: Dane są przechowywane przez okres trwania umowy, a później przez okres, po którym przedawnią się roszczenia wynikające z umowy. W przypadku dochodzenia przez Administratora roszczeń lub zawiadamiania właściwych organów -- przez czas trwania takich postępowań oraz „przez 5 lat od początku roku następującego po roku obrotowym, w którym operacje, transakcje i postępowanie zostały ostatecznie zakończone, spłacone, rozliczone lub przedawnione&quot;. </p>
<p>5.7 Cel przetwarzania danych: <strong>Ustalenie, obrona i dochodzenie roszczeń, podnoszonych przez lub wobec Administratora</strong> (w tym sprzedaż wierzytelności innemu podmiotowi)</p>
<p>Podstawa prawna przetwarzania: Art. 6 ust. 1 lit. f) Rozporządzenia („prawnie uzasadniony interes administratora&quot;) w zawiązku z art. 74 ust. 2 Ustawy o rachunkowości</p>
<p>Okres przechowywania: Dane są przechowywane przez okres:</p>
<ul>
<li><p>po którym przedawnią się roszczenia wynikające z umowy,</p>
</li>
<li><p>w przypadku dochodzenia przez Administratora roszczeń w postępowaniu cywilnym lub objętych postępowaniem karnym albo podatkowym dowody księgowe (mogące zawierać dane osobowe) przechowywane muszą być „przez 5 lat od początku roku następującego po roku obrotowym, w którym operacje, transakcje i postępowanie zostały ostatecznie zakończone, spłacone, rozliczone lub przedawnione&quot;,</p>
</li>
<li><p>przez czas, w którym Administrator może ponieść konsekwencje prawne niewykonania obowiązku, np. otrzymać karę administracyjną.\
<strong>6. Odbiorcy danych</strong></p>
</li>
</ul>
<p>Administrator korzysta z usług współpracujących z nim podmiotów zewnętrznych w celu:</p>
<p><strong>- dostarczania i oferowania produktów i usług Administratora,</strong></p>
<p><strong>- informowania o aktualizacjach dotyczących oferowanych produktów i usług,</strong></p>
<p><strong>- rozwoju oferowanych produktów i usług,</strong></p>
<p><strong>- informowania o produktach i usługach,</strong></p>
<p><strong>- wypełniania obowiązków prawnych,</strong></p>
<p><strong>- realizacji umowy,</strong></p>
<p><strong>- zapewnienia prawidłowego działania stron internetowych Administratora.</strong></p>
<p>Ponadto dane osobowe mogą być przekazane następującym, współpracujących z Administratorem odbiorcom:</p>
<ul>
<li><p><strong>podmiotom prowadzącym działalność pocztową, kurierską i podobną</strong> (np. brokerzy kurierscy) - w zakresie niezbędnym do zrealizowania dostawy i korespondencji,</p>
</li>
<li><p><strong>podmiotom obsługującym płatności kartami płatniczymi lub obsługującym płatności elektroniczn</strong>e -- w zakresie niezbędnym do obsługi tych płatności,</p>
</li>
<li><p><strong>wybranym podmiotom działającym na zlecenie Administratora przy obsłudze spraw księgowych, podatkowych, doradczych, prawnych i windykacyjnych</strong> (w tym podmiotom nabywającym wierzytelności) - w zakresie niezbędnym do realizacji określonego celu przetwarzania,</p>
</li>
</ul>
<p>-<strong>podmiotom świadczącym usługi pomocy technicznej udzielanej Administratorowi i dostawcom rozwiązań informatycznych umożliwiających prowadzenie Administratorowi działalności</strong> (dla przykładu dostawcy oprogramowania, dostawcy poczty elektronicznej i hostingu) - Administrator udostępnia dane osobowe działającemu na jego zlecenie zaufanemu dostawcy wyłącznie w przypadku i w zakresie niezbędnym do realizacji określonego celu przetwarzania,</p>
<ul>
<li><p><strong>dostawcom rozwiązań służącym wyrażaniu/publikowaniu opinii klientów</strong> - w zakresie niezbędnym do wyrażenia opinii,</p>
</li>
<li><p><strong>wybranym podmiotom zewnętrznym, z usług których Administrator rutynowo korzysta w ramach zwykłej działalności biznesowej.</strong></p>
</li>
</ul>
<p>Dane osobowe przekazywane są do podmiotów zewnętrznych wyłącznie wtedy i w takim zakresie, w jakim jest to niezbędne do realizacji celu przetwarzania. Przekazane dane osobowe podmioty zewnętrzne mogą wykorzystać wyłącznie w celu realizacji zadania zleconego przez Administratora.</p>
<p><strong>7. Przekazywanie danych poza Europejski Obszar Gospodarczy</strong></p>
<p>W związku z korzystaniem z usługi Google Analitics dane osobowe mogą być przekazywane poza Europejski Obszar Gospodarczy (obejmujący Unię Europejską, Islandię, Liechtenstein i Norwegię) do Google LLC - zobacz też punkt „Analiza internetowa&quot;. Jeżeli Użytkownik nie chce aby jego dane osobowe były przetwarzane w systemach Google, może skorzystać z narzędzia Google i wybrać opcję: <a href="https://tools.google.com/dlpage/gaoptout?hl=pl">https://tools.google.com/dlpage/gaoptout?hl=pl</a>\
<strong>8. Prawa osoby, której dane są przetwarzane przez Administratora</strong></p>
<p>Przetwarzanie danych osobowych nie wymaga zgody jeżeli m.in.: przetwarzanie jest konieczne do wykonania umowy, czy podjęcia działań przed zawarciem umowy, wynika z obowiązku prawnego ciążącego na Administratorze, albo jest konieczne dla realizacji prawnie uzasadnionego interesu Administratora lub stron trzecich. W przypadku gdy wyrażenie zgody jest niezbędne aby móc przetwarzać dane osobowe w określonym celu Administrator prosi o wyrażenie takiej zgody. Udzieloną zgodę można wycofać w dowolnym momencie. W tym celu należy złożyć wiosek do Administratora za pomocą wiadomości e-mail na adres biuro@pressbar.pl, listownej lub składając wniosek osobiście w siedzibie Administratora.</p>
<p>W przypadku cofnięcia zgody, dane nie będą już przetwarzane w zakresie jakiego dotyczyła zgoda, ale wycofanie zgody pozostanie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</p>
<p>Na zasadach określonych w Rozporządzeniu, osobie, której dane dotyczą, przysługuje również prawo żądania od Administratora dostępu do dotyczących jej danych osobowych, ich sprostowania, usunięcia („bycia zapomnianym&quot;) lub ograniczenia ich przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych.</p>
<p>Jeżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, w dowolnym momencie można wnieść sprzeciw wobec przetwarzania tych danych na potrzeby marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z marketingiem bezpośrednim.</p>
<p>W celu realizacji powyższych uprawień należy złożyć wiosek do Administratora za pomocą wiadomości e-mail, listownej lub składając wniosek osobiście w siedzibie Administratora. Dane kontaktowe Administratora podane są na wstępie. Aby mieć pewność, że osoba składająca wiosek jest uprawniona do jego złożenia, Administrator może prosić o podanie dodatkowych informacji potwierdzających tożsamość składającego.</p>
<p>Z przepisów Rozporządzenia wynika w jakim zakresie z każdego z tych praw można skorzystać. Zależeć to będzie w szczególności od podstawy prawnej i celu przetwarzania danych osobowych przez Administratora. Z powyższych praw można skorzystać bezpłatnie nie częściej niż raz na 6 miesięcy. Zgodnie z Artykułem 12 Rozporządzenia jeżeli żądania osoby, której dane dotyczą, są ewidentnie nieuzasadnione lub nadmierne, w szczególności ze względu na swój ustawiczny charakter, administrator może pobrać opłatę.</p>
<p>Osoba, której dane dotyczą, ma prawo wniesienia skargi do organu nadzorczego tj. do Prezesa Urzędu Ochrony Danych Osobowych.\
<strong>9. Obowiązek lub brak obowiązku podania danych osobowych</strong></p>
<p>Korzystanie z usług Administratora i podanie mu danych osobowych jest dobrowolne. Osoba, której dane dotyczą, jest jednak zobowiązana do ich podania w związku z:</p>
<ul>
<li><p><strong>zawieraniem umowy z Administratorem</strong> - w tym przypadku podanie danych osobowych jest warunkiem umownym i osoba, której dane dotyczą jest zobowiązana do podania wymaganych danych, jeżeli chce zawrzeć umowę z Administratorem. Każdorazowo zakres danych wymaganych do zawarcia umowy jest zakomunikowany, osobie której dane dotyczą. Konsekwencją niepodania danych jest brak możliwości zawarcia umowy.</p>
</li>
<li><p><strong>realizacją ciążących na Administratorze obowiązków wynikających z przepisów prawa</strong> - w tym przypadku podanie danych osobowych jest warunkiem ustawowym wynikającym z przepisów nakładających na Administratora obowiązki przetwarzania danych osobowych (np. w związku z obowiązkiem wystawiania, gromadzenia i przechowywania faktur i dokumentów księgowych oraz prowadzenia ksiąg rachunkowych). Niepodanie tych danych uniemożliwi Administratorowi wykonanie wskazanych obowiązków czego konsekwencją będzie brak możliwości zawarcia umowy.\
<strong>10. Wykorzystanie danych w celach reklamowych</strong></p>
</li>
</ul>
<p><strong>10.1 Newsletter</strong></p>
<p>Newsletter jest wysyłany tylko tym Użytkownikom, którzy wyrazili na to zgodę i podali swój adres e-mail. W każdej chwili zgoda na otrzymywanie newslettera może być wycofana przez kliknięcie, podanego w każdym newsletterze, linku z rezygnacją lub kontaktując się z Administratorem na adres podany w punkcie 2.</p>
<p><strong>10.2 Ciasteczka (pliki cookie)</strong></p>
<p>Strony internetowe Administratora wykorzystują pliki cookies („ciasteczka&quot;) czyli pliki tekstowe zapisywane na urządzeniu Użytkownika. Pliki te umożliwiają analizę sposobu użytkowania strony internetowej oraz zidentyfikowanie przeglądarki internetowej Użytkownika. Wprowadzając odpowiednie ustawienia przeglądarki można zablokować instalację plików cookies -- może to spowodować ograniczenie funkcjonalności strony internetowej.</p>
<p>Administrator może przetwarzać dane zawarte w plikach cookies do anonimowej analizy działań osób odwiedzających, badania ich zachowania (np. otwieranie określonych stron) w celu dostarczenia im reklam dopasowanych do ich przewidywanych zainteresowań, również wtedy gdy odwiedzają oni inne strony www będące partnerami w sieci reklamowej firmy Google Inc. oraz Facebook Ireland Ltd. oraz w celu poprawy w administrowaniu witrynami Administratora.</p>
<p><strong>10.3 Onsite Targeting</strong></p>
<p>Administrator wykorzystuje technologię plików cookies do prowadzenia analizy działań osób odwiedzających (np. otwieranie określonych podstron) i może przedstawiać Użytkownikowi reklamy i/lub specjalne oferty. Celem tych działań jest przedstawienie użytkownikowi treści reklamowych jak najbardziej odpowiadających obszarowi jego poszukiwań.&quot;</p>
<p><strong>10.4 Retargeting, ciasteczka podmiotów trzecich i gromadzenie danych przez podmioty trzecie dla celów reklamy banerowej</strong></p>
<p>Strony internetowe Administratora wykorzystują technologię retargetingu (remarketinngu).</p>
<p>Administrator korzysta z usług podmiotów trzecich, które stosują na stronie Administratora pliki cookie, są to:</p>
<p><strong>Google Analytics</strong>, Universal Analytics oraz Google Remarketing dostarczanych przez firmę Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Szczegółowe informacje dotyczące działania powyższych usług dostępne pod adresem <a href="https://www.google.com/intl/pl/policies/privacy/partners/">www.google.com/intl/pl/policies/privacy/partners/</a> zobacz też <a href="https://policies.google.com/privacy/update?hl=pl&amp;gl=pl">https://policies.google.com/privacy/update?hl=pl&amp;gl=pl</a></p>
<p><strong>10.5 Jak można zablokować zapisywanie plików cookies?</strong></p>
<p>Aby zablokować zapisywanie ciasteczek Użytkownik powinien w przeglądarce internetowej włączyć ustawienia umożliwiające akceptację zapisywania ciasteczek jedynie wówczas, gdy wyrazi na to zgodę.</p>
<p>Aby zaakceptować stosowanie plików cookies Administratora, blokując jednoczenie użycie plików cookies firm trzecich, należy w ustawieniach przeglądarki wybrać opcję „Blokowanie ciasteczek witryn zewnętrznych podmiotów&quot;.</p>
<p><strong>11. Analiza internetowa</strong></p>
<p>Administrator korzysta z usługi analizy oglądalności stron internetowych Google Analytics udostępnianej przez firmę Google. Google Analytics analizuje zachowanie użytkownika na stronie internetowej dzięki użyciu plików cookies. Informacje generowane przez cookies dotyczące korzystania ze strony internetowej przez użytkownika (włącznie z jego adresem IP) są przekazywane Google i przechowywane przez nią na serwerach w Stanach Zjednoczonych. Google będzie korzystał z tych informacji w celu analizy korzystania ze strony internetowej przez użytkownika, tworzenia raportów dla stron korzystających z Google Analytics oraz świadczenia innych usług. Google może również przekazywać te informacje podmiotom trzecim, o ile jest to wymagane przez prawo lub w przypadku, gdy podmioty trzecie przetwarzają te informacje w imieniu Google.</p>
<p>Poprzez korzystanie ze strony internetowej Użytkownik wyraża zgodę na przetwarzanie przez Google dotyczących go danych w sposób i w celach określonych powyżej.</p>
<p>Strona internetowa analizowana jest przez Google Analytics z rozszerzeniem „_anonymizeIp()&quot; i dlatego adresy IP są przetwarzane wyłącznie w formie skróconej, co uniemożliwia bezpośrednie powiązanie adresu z danym Użytkownikiem.</p>
<p>Użytkownik może zrezygnować z plików cookies wprowadzając odpowiednie ustawienia przeglądarki. Może to spowodować ograniczenie funkcjonalności strony i korzystanie z wszystkich jej funkcji może okazać się niemożliwe.</p>
<p>Zgodę na pobieranie i gromadzenie danych osobowych można w każdej chwili wycofać ze skutkiem na przyszłość.</p>
<p>Jeżeli Użytkownik nie chce aby jego dane osobowe były przetwarzane w systemach Google, może skorzystać z narzędzia Google i wybrać opcję: <a href="https://tools.google.com/dlpage/gaoptout?hl=pl">https://tools.google.com/dlpage/gaoptout?hl=pl</a></p>
<p><strong>12. Pliki logu serwera</strong></p>
<p>Przeglądarka internetowa udostępnia dane o działaniach użytkownika na stronach internetowych Administratora, które są zapisywane w plikach logu serwera. Zapisane w ten sposób rekordy danych zawierają następujące dane: data i godzina pobrania, nazwa otwieranej strony, pobrana ilość danych, a także informacje o wersji produktu stosowanej przeglądarki internetowej, adres IP, URL strony referencyjnej (adres strony, z której użytkownik został przekierowany),.</p>
<p>Rekordy danych pliku logu serwera są poddawane analizie w celu usuwania błędów, zarządzania wydajnością serwera, ochrony przed atakami typu DDoS i dostosowywania oferty.</p>
<p><strong>13. Zautomatyzowane podejmowanie decyzji i profilowanie</strong></p>
<p>Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji wywołujących wobec osoby, której dane dotyczą skutki prawne w tym do profilowania.</p>
<p><strong>14. Postanowienia końcowe</strong></p>
<p>Strony internetowe Administratora mogą zawierać linki (odnośniki) do innych stron internetowych. Takie strony internetowe działają niezależnie od Administratora i nie są w żaden sposób nadzorowane przez Administratora. Administrator zaleca, by po przejściu na inne strony, zapoznać się z ich własnymi politykami dotyczącymi prywatności. Administrator nie ponosi odpowiedzialności za zasady postępowania z danymi w ramach tych stron.</p>

      </div>
    </div>
  );
}

export default PolitykaPrywatnosci;

import React from "react";
import { Link } from "react-router-dom";
import { CategoryModel } from "../../../../Models/Category";
import { selectParentCategory, setCurrentCategoryId } from "../../../../Store/Slices/Categories/Categories";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";

type Props = {
  category: CategoryModel;
};

function CategoryCard({ category }: Props) {

  const dispatch = useDispatch<AppDispatch>();

  return (
    <Link
      className={`p-2 bg-white shrink-0 shadow-md hover:shadow-lg flex flex-col w-60 lg:w-auto justify-end snap-center relative z-10 overflow-hidden rounded-lg h-60 transition-all`}
      to={`/search?category=${category.Id}`}
      onClick={()=>{
        dispatch(setCurrentCategoryId(category.Id));
      }}
    >
      <div
        className="w-full h-3/4 z-0 bg-white"
        style={{
          position: "relative",
          backgroundImage: `url(${
            category.Image_url !== ""
              ? category.Image_url
              : "/Images/no_photography_FILL0_wght400_GRAD0_opsz24.png"
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="w-full h-1/4 p-4 flex flex-col justify-center bg-white">
        <h1 className="text-base font-bold text-center">{category.Name}</h1>
      </div>
    </Link>
  );
}

export default CategoryCard;

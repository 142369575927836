import React from "react";
import { Route, Routes } from "react-router-dom";
import CompanyProfile from "./CompanyProfile";
import CoworkerProfile from "./CoworkerProfile";

export default function Company() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<CompanyProfile />} />
        <Route path="/user/:id" element={<CoworkerProfile />} />
      </Routes>
    </div>
  );
}

import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import CategoriesFilters from "../CategoriesFilters";
import {
  slideFromBottom,
  slideFromRight,
} from "../../../Animations/Animations";
import Backdrop from "../../Backdrop/Backdrop";
import { useSelector } from "react-redux";
import {
  selectCurrentPrice,
  selectParameterChoices,
} from "../../../Store/Slices/Parameters/Parameters";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { selectCurrentCategoryId } from "../../../Store/Slices/Categories/Categories";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  children: React.ReactNode;
  customStyle?: string;
};
function CategoriesFiltersButton({ children, customStyle = "" }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams({
    category: "1",
  });

  const [showMenu, setShowMenu] = React.useState<boolean>(false);

  const parameterChoices = useSelector(selectParameterChoices);
  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const price = useSelector(selectCurrentPrice);

  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={`w-full ` + customStyle}>
      <div
        className="text-center flex flex-col cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
        }}
      >
        {children}
      </div>
      {ReactDOM.createPortal(
        <AnimatePresence initial={false}>
          {showMenu && (
            <Backdrop
              onClick={() => {
                setShowMenu(false);
              }}
            >
              <motion.div
                variants={windowWidth > 768 ? slideFromRight : slideFromBottom}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="flex flex-col items-center fixed bottom-0 z-50 right-0 w-full md:w-[400px] xl:w-[30%] h-full p-5 bg-neutral-100"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="flex justify-between mb-4 w-full">
                  <button
                    className="flex items-center space-x-1 justify-center text-neutral-950 rounded-lg cursor-pointer font-bold transition-all"
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  >
                    <span className="text-2xl">Zamknij</span>
                    <CloseIcon />
                  </button>
                  {!location.pathname.startsWith("/search") && (
                    <button
                      className={` flex items-center space-x-1 justify-center text-neutral-950 rounded-lg cursor-pointer font-bold transition-all`}
                      onClick={() => {
                        setShowMenu(false);
                        navigate(
                          `/search?filters=${encodeURIComponent(
                            JSON.stringify(parameterChoices)
                          )}&category=${currentCategoryId}&price_min=${
                            price.min_price
                          }&price_max=${price.max_price}`
                        );
                      }}
                    >
                      <span className="text-2xl">Szukaj</span>
                      <SearchIcon />
                    </button>
                  )}
                </div>

                <CategoriesFilters mobile={windowWidth < 768} />
                {windowWidth < 768 && (
                  <button
                    className="absolute bottom-0 p-4 backdrop-blur-md w-full bg-transparent bg-opacity-80 hover:bg-primary hover:text-neutral-50 transition-all"
                    onClick={() => {
                      setSearchParams((prev) => {
                        prev.set("category", currentCategoryId);
                        prev.set(
                          "filters",
                          JSON.stringify([...parameterChoices])
                        );
                        return prev;
                      });
                      setShowMenu(false);
                    }}
                  >
                    <span className="font-semibold text-xl">Zatwierdź</span>
                  </button>
                )}
              </motion.div>
            </Backdrop>
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}
    </div>
  );
}

export default CategoriesFiltersButton;

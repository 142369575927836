import React from "react";
import LoginForm from "../../../../../../Common/Forms/LoginForm/LoginForm";
import ButtonTransparentToColor from "../../../../../../Common/Buttons/ButtonTransparentToColor";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../Store/store";
import { setStage } from "../../../../../../Store/Slices/Order/Order";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";

function LoginRegister({ redirect }: { redirect: string }) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="flex flex-col lg:flex-row lg:items-top space-y-4 lg:space-x-4 lg:space-y-0 w-full max-w-[1536px]">
      <div className="h-fit w-full lg:w-1/2 flex flex-col items-center justify-center rounded-lg bg-neutral-100 p-2 shadow-md">
        <div className="w-full text-start p-2 flex items-center justify-center space-x-2">
          <h1 className="text-4xl font-bold">Zaloguj się</h1>
          <LoginIcon />
        </div>
        <LoginForm redirect={redirect} />
      </div>
      <div className="w-full lg:w-1/2 flex flex-col items-center justify-center rounded-lg space-y-4">
        <div className="w-full flex flex-col  justify-center space-y-2 p-2">
          <h1 className="text-4xl font-bold text-center">
            Nie masz konta? Zarejestruj się!
          </h1>
          <div className="">
            <h3 className="text-xl font-bold">
              Dlaczego powinieneś utworzyć konto?
            </h3>
            <ul className="list-disc list-inside text-base p-2 rounded-lg border-2 flex flex-col space-y-1">
              <li>
                Możliwość zapisania koszyka i kontynuacji zakupów w dowolnym
                momencie
              </li>
              <li>Tworzenie list życzeń</li>
              <li>Śledzenie historii zakupów i sprawdzanie statusu zamówień</li>
              <li>Możliwość otrzymania rabatów na produkty</li>
            </ul>
          </div>
          <ButtonTransparentToColor
            text={"Zarejestruj się"}
            size={"large"}
            Icon={PersonAddIcon}
            customClasses="w-full"
            link={"register?redirect=/order/"}
          />
        </div>
        <div className="w-full flex flex-col items-center justify-center space-y-2 p-2">
          <h1 className="text-4xl font-bold text-center">
            Lub kontynuuj jako gość
          </h1>
          <ButtonTransparentToColor
            text={"Kontynuuj jako gość"}
            size={"large"}
            customClasses="w-full"
            onClick={() => {
              dispatch(setStage(2));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginRegister;

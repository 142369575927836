import React from "react";
import { ProductModel } from "../../../Models/Product";
import { motion } from "framer-motion";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

type Props = {
  product: ProductModel;
};

function ProductData({ product }: Props) {
  return (
    <div className="w-full h-full flex flex-col xl:w-1/2">
      {product?.pdfs !== undefined && product?.pdfs?.length !== 0 && (
        <div className="w-full">
          <h2 className="text-2xl font-bold text-neutral-950:">
            Pliki do pobrania:
          </h2>
          <div className="flex flex-col xl:flex-row space-y-1 xl:space-y-0 xl:space-x-2 p-2">
            {product?.pdfs !== undefined &&
              product?.pdfs !== null &&
              product?.pdfs.map((pdf) => {
                return (
                  <motion.a
                    key={pdf.Name}
                    href={pdf.Url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center space-x-2 p-2 bg-gradient-to-t from-transparent via-neutral-200 bg-opacity-80 backdrop-blur-md rounded-lg shadow-md hover:shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <PictureAsPdfIcon />
                    <span className="text-neutral-700">{pdf.Name}</span>
                  </motion.a>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductData;

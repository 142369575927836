import React from "react";
import { Link } from "react-router-dom";
import ButtonPrimaryToLighter from "../../../../../Common/Buttons/ButtonPrimaryToLighter";
import Input from "../../../../../Common/Input/Input";
import Cookies from "js-cookie";
import SaveIcon from "@mui/icons-material/Save";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function EditPersonalData() {
  const user = JSON.parse(Cookies.get("user") ?? "{}") as {
    Name: string;
    Surname: string;
    Email: string;
    Phone_number: string;
  };

  const [name, setName] = React.useState(user.Name);
  const [surname, setSurname] = React.useState(user.Surname);
  const [email, setEmail] = React.useState(user.Email);
  const [phoneNumber, setPhoneNumber] = React.useState(user.Phone_number);

  return (
    <div className="w-full">
      <div className="w-full items-center">
        <Link
          to="/profile/personal-data"
          className="flex items-center space-x-1"
        >
          <ChevronLeftIcon />
          <span className="text-xl">Powrót</span>
        </Link>
      </div>

      <form>
        <div className="flex flex-col lg:flex-row lg:space-y-0 space-y-3 lg:space-x-3 items-center">
          <Input
            name="Imię"
            label="Name"
            type={"text"}
            size="sm"
            placeholder="Jan"
            required={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            name="Nazwisko"
            label="Surname"
            type={"text"}
            size="sm"
            placeholder="Kowalski"
            required={true}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          <Input
            name="Email"
            label="Email"
            type={"email"}
            size="sm"
            placeholder="your@mail.com"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            name="Telefon"
            label="Phone_number"
            type={"text"}
            size="sm"
            placeholder="123456789"
            required={true}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <ButtonPrimaryToLighter
          text={"Zapisz"}
          size={"medium"}
          isSubmit={true}
          form="address-form"
          Icon={SaveIcon}
          customClasses="w-full mt-2"
        />
      </form>
    </div>
  );
}

export default EditPersonalData;

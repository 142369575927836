import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar/SearchBar";
import { Link } from "react-router-dom";
import NavBarIcons from "./NavBarIcons/NavBarIcons";
import TopLinks from "./TopLinks/TopLinks";
import PromoCode from "./PromoCode/PromoCode";

function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`transition-all sticky top-0 w-full flex flex-col items-center justify-center z-50 bg-neutral-50 ${
        isScrolled ? "shadow-md" : ""
      }`}
    >
      <PromoCode show={true} />
      <div className="px-3 sm:px-5 py-1 w-full max-w-[1536px] z-50 flex flex-col items-center justify-center space-y-1">
        <TopLinks />
        <div className="h-full w-full flex items-center justify-between space-x-2 lg:space-x-10">
          <Link
            to="/"
            className="max-h-full w-fit flex items-center justify-center"
          >
            <img
              src="/Images/PRESS_BAR_LOGO.png"
              alt="logo"
              className="h-full max-h-16"
            />
          </Link>
          <SearchBar type="desktop" />
          <NavBarIcons />
        </div>
        <SearchBar type="mobile" />
      </div>
    </div>
  );
}

export default NavBar;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Store/store";
import { useNavigate } from "react-router-dom";
import {
  createOrderLogged,
  createOrderNotLogged,
  fetchPaymentTypes,
  getDeliveryTime,
  selectChosenDeliveryTime,
  selectCreateOrderState,
  selectDeliveryTimes,
  selectDeliveryTimesState,
  selectDiscountCode,
  selectFetchPaymentTypesState,
  selectOrderData,
  selectOrderId,
  selectPaymentTypes,
  selectPriceDetails,
  selectStage,
  setChosenDeliveryTime,
  setCompanyInfo,
  setOrderPayTypeStageThree,
} from "../../../../../Store/Slices/Order/Order";
import { selectCartPrice } from "../../../../../Store/Slices/Cart/Cart";
import { selectIsLoggedIn } from "../../../../../Store/Slices/User/User";
import LoadingAnimation from "../../../../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../../../../Common/Messages/ErrorMessage/ErrorMessage";
import Products from "../StageTwo/Products/Products";
import CompanyInfo from "./CompanyInfo/CompanyInfo";

function StageThree() {
  const dispatch = useDispatch<AppDispatch>();

  const price = useSelector(selectCartPrice);
  const stage = useSelector(selectStage);
  const priceDetails = useSelector(selectPriceDetails);

  const navigate = useNavigate();
  const chosenDeliveryTime = useSelector(selectChosenDeliveryTime);

  const deliveryTimes = useSelector(selectDeliveryTimes);
  const deliveryTimesState = useSelector(selectDeliveryTimesState);
  const orderId = useSelector(selectOrderId);
  const orderData = useSelector(selectOrderData);
  useEffect(() => {
    dispatch(fetchPaymentTypes(price));
    dispatch(getDeliveryTime({online: isLoggedIn}))
  }, [dispatch, price]);

  const state = useSelector(selectFetchPaymentTypesState);
  const paymentTypes = useSelector(selectPaymentTypes);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  
  const createOrderState = useSelector(selectCreateOrderState);
  
  const discountCode = useSelector(selectDiscountCode);

  useEffect(() => {
    if (createOrderState.hasLoaded) {
      const id: string = orderId;
      navigate(`/order/summary/${id}`);
    }
  }, [createOrderState, orderId]);

  const [companyBuy, setCompanyBuy] = React.useState(false);

  return (
    <form
      id="payment-form"
      className="relative w-full space-y-5"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        if (companyBuy) {
          dispatch(
            setCompanyInfo({
              companyName: formData.get("company_name") as string,
              companyAddress: formData.get("company_address") as string,
              companyCity: formData.get("company_city") as string,
              companyPostalCode: formData.get("company_postal_code") as string,
              companyNIP: formData.get("nip") as string,
            })
          );
        }
        if (isLoggedIn) {
          dispatch(
            createOrderLogged({
              ...orderData,
              CompanyName: formData.get("company_name") as string,
              CompanyAddress: formData.get("company_address") as string,
              CompanyCity: formData.get("company_city") as string,
              CompanyPostalCode: formData.get("company_postal_code") as string,
              CompanyNIP: formData.get("nip") as string,
              discount_code: discountCode,
              chosenDeliveryTime: chosenDeliveryTime
            })
          );
        } else {
          dispatch(
            createOrderNotLogged({
              ...orderData,
              CompanyName: formData.get("company_name") as string,
              CompanyAddress: formData.get("company_address") as string,
              CompanyCity: formData.get("company_city") as string,
              CompanyPostalCode: formData.get("company_postal_code") as string,
              CompanyNIP: formData.get("nip") as string,
              discount_code: discountCode
            })
          );
        }
      }}
    >
      {createOrderState.isLoading && <LoadingAnimation color={"primary"} />}
      {createOrderState.failedLoading && (
        <ErrorMessage
          message={"Błąd podczas tworzenia zamówienia."}
          error={createOrderState.error}
        />
      )}
      {
        deliveryTimesState.hasLoaded && deliveryTimes.deliveries==2&&<div className="w-full">
          <h1 className="text-xl font-semibold">Czy chcesz rozdzielić dostawę na dwie części:</h1>
          <div className="w-full rounded-lg p-3 border-2 flex items-center flex-wrap">
          {deliveryTimesState.isLoading && <LoadingAnimation color={"primary"} />}
          {deliveryTimesState.failedLoading && (
            <ErrorMessage
              message={"Błąd podczas pobierania opcji płatności."}
              error={state.error}
            />
          )}
          {deliveryTimesState.hasLoaded &&
            deliveryTimes.deliveries==2 &&
            <div>
              <div className="flex items-center mr-3">
                <input
                  onChange={(e) => {
                    dispatch(setChosenDeliveryTime(1));
                  }}
                  type="radio"
                  name="deliveryTimeType"
                  id={'jedna'}
                  required={true}
                  className="w-5 h-5"
                />
                <label
                  htmlFor={'jedna'}
                  className="flex items-center space-x-1 cursor-pointer"
                >
                  <span className="text-xl font-semibold whitespace-nowrap">
                    Jedna dostawa ({priceDetails.shipping.toFixed(2)} PLN)
                  </span>
                </label>
              </div>
              <div className="flex items-center mr-3">
                <input
                  onChange={(e) => {
                    dispatch(setChosenDeliveryTime(2));
                  }}
                  type="radio"
                  name="deliveryTimeType"
                  id={'dwie'}
                  required={true}
                  className="w-5 h-5"
                />
                <label
                  htmlFor={'dwie'}
                  className="flex items-center space-x-1 cursor-pointer"
                >
                  <span className="text-xl font-semibold whitespace-nowrap">
                    Dwie dostawy ({deliveryTimes.priceFirstDelivery==15&&deliveryTimes.priceSecondDelivery==15?
                    '2x15.00 PLN':deliveryTimes.priceFirstDelivery==15||deliveryTimes.priceSecondDelivery==15?
                    '15.00 PLN': '0.00 PLN'})
                  </span>
                </label>
              </div>
            </div>
          }
          </div>
        </div>
      }
      <div className="w-full">
        <h1 className="text-xl font-semibold">Wybierz opcję płatności:</h1>
        <div className="w-full rounded-lg p-3 border-2 flex items-center flex-wrap">
          {state.isLoading && <LoadingAnimation color={"primary"} />}
          {state.failedLoading && (
            <ErrorMessage
              message={"Błąd podczas pobierania opcji płatności."}
              error={state.error}
            />
          )}
          {state.hasLoaded &&
            paymentTypes.length > 0 &&
            paymentTypes
              .filter((paymentType) => {
                if (orderData.delivery_type === 4) {
                  return true;
                } else {
                  return paymentType.Id !== 1;
                }
              })
              .map((paymentType) => (
                <div key={paymentType.Id} className="flex items-center mr-3">
                  <input
                    onChange={(e) => {
                      dispatch(setOrderPayTypeStageThree(paymentType.Id));
                    }}
                    type="radio"
                    name="paymentType"
                    value={paymentType.Id.toString()}
                    id={paymentType.Id.toString()}
                    required={true}
                    className="w-5 h-5"
                  />
                  <label
                    htmlFor={paymentType.Id.toString()}
                    className="flex items-center space-x-1 cursor-pointer"
                  >
                    <span className="text-xl font-semibold whitespace-nowrap">
                      {paymentType.Name}
                    </span>
                    <span className="material-symbols-outlined text-2xl whitespace-nowrap">
                      {paymentType.Icon}
                    </span>
                  </label>
                </div>
              ))}
        </div>
      </div>
      <div className="w-full">
        <h1 className="text-xl font-semibold">Wybierz formę zakupu:</h1>
        <div className="w-full rounded-lg p-3 border-2 flex items-center flex-wrap">
          <div className="flex flex-row items-center space-x-2 mr-2">
            <input
              type="radio"
              name="register_as_company"
              id={"tak"}
              required={true}
              className="w-5 h-5"
              onClick={() => {
                setCompanyBuy(true);
              }}
            />
            <label
              className="flex items-center space-x-1 cursor-pointer"
              htmlFor="tak"
            >
              <span className="material-symbols-outlined text-sm sm:text-xl">
                business
              </span>
              <span className="text-sm sm:text-xl font-semibold whitespace-nowrap">
                Kupuję jako firma
              </span>
            </label>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <input
              type="radio"
              name="register_as_company"
              id={"nie"}
              required={true}
              className="w-5 h-5"
              onClick={() => {
                setCompanyBuy(false);
              }}
            />
            <label
              className="flex items-center space-x-1 cursor-pointer"
              htmlFor="nie"
            >
              <span className="material-symbols-outlined text-sm sm:text-xl">
                person
              </span>
              <span className="text-sm sm:text-xl font-semibold whitespace-nowrap">
                Kupuję jako osoba prywatna
              </span>
            </label>
          </div>
        </div>
      </div>
      {companyBuy && <CompanyInfo />}
      <div className="flex flex-row items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          name="dane_osobowe"
          id="dane_osobowe"
          className="rounded-md"
          required={true}
        />
        <label
          htmlFor="dane_osobowe"
          className="cursor-pointer text-xs sm:text-sm"
        >
          <span className="text-red-600">*</span> Wyrazam zgodę na przetwarzanie
          moich danych osobowych oraz akceptuję przez Press Bar Sp. z o.o.{" "}
          <a
            href="/regulamin"
            className="underline hover:no-underline font-bold"
          >
            regulamin
          </a>
        </label>
      </div>
      <div className="flex flex-row items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          name="regulamin"
          id="regulamin"
          className="rounded-md"
          required={true}
        />
        <label
          htmlFor="regulamin"
          className="cursor-pointer text-xs sm:text-sm"
        >
          <span className="text-red-600">*</span> Akceptuję regulamin sklepu
          internetowego{" "}
          <a
            href="/regulamin"
            className="underline hover:no-underline font-bold"
          >
            regulamin
          </a>
        </label>
      </div>
      <div className="flex flex-row items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          name="kontakt_tel"
          id="kontakt_tel"
          className="rounded-md"
          required={true}
        />
        <label
          htmlFor="kontakt_tel"
          className="cursor-pointer text-xs sm:text-sm"
        >
          <span className="text-red-600">*</span> Wyrażam zgodę na kontakt
          telefoniczny w celu realizacji zamówienia
          <a
            href="/regulamin"
            className="underline hover:no-underline font-bold"
          >
            regulamin
          </a>
        </label>
      </div>
      <div className="flex flex-row items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          name="faktura"
          id="faktura"
          className="rounded-md"
          required={true}
        />
        <label
          htmlFor="faktura"
          className="cursor-pointer text-sm sm:text-base font-semibold"
        >
          <span className="text-red-600">*</span> Wyrażam zgodę na wysyłke faktury VAT w formie elektronicznej
        </label>
      </div>
      <div className="w-full">
        <h1 className="text-xl font-semibold">Podsumowanie:</h1>
        <div className="w-full rounded-lg p-3 border-2 border-primary-darker">
          <Products />
          {/* <div className="w-full">
            <div className="flex items-center justify-between">
              <span>Cena produktów:</span>
              <span>{price.toFixed(2)} PLN</span>
            </div>
            <div className="flex items-center justify-between">
              <span>Cena dostawy:</span>
              <span>{price.toFixed(2)} PLN</span>
            </div>
          </div> */}
        </div>
      </div>
    </form>
  );
}

export default StageThree;

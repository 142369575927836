import React from 'react'

export default function WarunkiHandlowe() {
  return (
    <div className="w-full max-w-[1280px] text-left p-2 flex flex-col">
      <h1 className="text-3xl sm:text-4xl md:text-6xl my-10 font-bold">
        Ogólne warunki handlowe
      </h1>
      <div
        id="markdown_content"
        className="w-full flex flex-col items-center"
      >
        <h2 id="i-definicje">I. Definicje</h2>
<p>W kontekście niniejszych Standardów Handlowych, określanych dalej jako Warunki, przyjmuje się następujące definicje:</p>
<ul>
<li>Sprzedawca – Press Bar Sp. z o.o.;</li>
<li>Nabywca – jednostka organizacyjna nieposiadająca osobowości prawnej lub indywidualny przedsiębiorca, nabywający Produkt od Sprzedawcy;</li>
<li>Produkt – przedmiot(y) oferowany(e) do sprzedaży przez Sprzedawcę;</li>
<li>Zapytanie ofertowe – formalne zapytanie Nabywcy skierowane do Sprzedawcy w celu uzyskania propozycji cenowej;</li>
<li>Propozycja – pisemna deklaracja Sprzedawcy przekazywana Nabywcy w odpowiedzi na Zapytanie Ofertowe, będąca ofertą w rozumieniu art. 66 Kodeksu cywilnego;</li>
<li>Zamówienie – pisemne zobowiązanie Nabywcy do zakupu Produktu na podstawie Propozycji;</li>
<li>Potwierdzenie Zamówienia – pisemne potwierdzenie przez Sprzedawcę zawarcia Umowy oraz określenie jej warunków;</li>
<li>Umowa/Umowy – umowa kupna-sprzedaży Produktu, zawarta pomiędzy Sprzedającym a Nabywcą w dowolny sposób;</li>
<li>Strony – Sprzedawca i Nabywca;</li>
<li>Forma pisemna – dostępne metody komunikacji: fax, e-mail, poczta.</li>
</ul>
<h2 id="ii-postanowienia-og-lne">II. Postanowienia ogólne</h2>
<ol>
<li>Warunki regulują prawa i obowiązki Stron Umów i stanowią ich integralną część.</li>
<li>Warunki dotyczą sprzedaży Towaru na terytorium Rzeczypospolitej Polskiej. W przypadku, gdy ze względu na miejsce wykonania Umowy konieczne będzie zastosowanie regulacji dotyczących eksportu z terytorium Rzeczypospolitej Polskiej, Warunki znajdą zastosowanie w zakresie niewyłączonym takimi regulacjami, a Umowa określi sposób wykonania obowiązków z tym związanych.</li>
<li>Kupujący, dokonując zakupu Towaru, wyraża zgodę na stosowanie Warunków. Wszelkie informacje lub uściślenia ustne pochodzące od Sprzedającego muszą być potwierdzone przez niego pisemnie by stały się wiążące. Nieważność lub wyłączenie, także umowne, któregokolwiek z postanowień Warunków, nie powoduje nieważności lub wyłączenia pozostałych postanowień Warunków.</li>
<li>Warunki stosuje się do wszystkich Umów, gdzie Kupującym nie jest konsument, o ile bezwzględnie obowiązujące przepisy prawa lub indywidualnie uzgodnione postanowienia Umowy nie stanowią inaczej.</li>
<li>W przypadku rozbieżności między postanowieniami Warunków i Umowy, znaczenie rozstrzygające mają postanowienia Umowy.</li>
<li>Sprzedający nie jest związany jakimikolwiek warunkami zakupu stosowanymi przez Kupującego.</li>
<li>Informacje udzielane przez Sprzedającego w jakiejkolwiek formie i postaci, w szczególności ogłoszenia, reklamy oraz cenniki nie mają charakteru oferty w rozumieniu Kodeksu Cywilnego, lecz stanowią zaproszenie do składania zamówień i zawarcia Umowy.</li>
<li>Rysunki i wszelka dokumentacja przekazane Kupującemu przez Sprzedającego są przeznaczone wyłącznie do użytku Kupującego i bez wyraźnej zgody Sprzedającego nie mogą być powielane ani udostępniane osobom trzecim.</li>
<li>Rysunki zawarte w katalogach oraz na stronie internetowej Sprzedającego mają wyłącznie poglądowy charakter i nie mogą być podstawą do wykonywania projektów. Na prośbę Kupującego Sprzedający może dostarczyć pełną dokumentację każdego z produktów oferowanych przez Sprzedającego, zalecany jest jednak wtedy kontakt z Działem Technologicznym Sprzedającego.</li>
<li>Oczywiste pomyłki, błędy drukarskie, maszynowe, rachunkowe rysunków oraz wszelkiej dokumentacji przekazanej Kupującemu przez Sprzedającego nie mogą stanowić podstawy do wnoszenia roszczeń przez Kupującego.</li>
</ol>
<h2 id="iii-zawarcie-umowy-">III. Zawarcie Umowy.</h2>
<ol>
<li>Do zawarcia Umowy dochodzi w szczególności przez:
a.  doręczenie Kupującemu przez Sprzedającego Potwierdzenia Zamówienia w odpowiedzi na pisemne Zamówienie, złożone przez Kupującego po otrzymaniu Oferty;
b.  zawarcie Umowy na piśmie przez osoby upoważnione ze strony Sprzedającego i Kupującego;
c.  wydanie Towaru Kupującemu przez Sprzedającego na podstawie faktury.</li>
<li>Strony wyłączają zastosowanie art. 682 Kodeksu Cywilnego.</li>
<li>W przypadku sprzedaży Towaru na rzecz osoby fizycznej prowadzącej działalność gospodarczą, Sprzedający może uzależnić sprzedaż od zgody współmałżonka Kupującego na zawarcie Umowy.</li>
</ol>
<h2 id="iv-cena">IV. Cena</h2>
<ol>
<li>Na życzenie Kupującego, Sprzedający poinformuje Kupującego o aktualnych cenach Towaru.</li>
<li>W przypadku zawarcia Umowy w sposób określony w Rozdziale III pk1 lit. a), tj. poprzez Potwierdzenie Zamówienia przez Sprzedającego, sprzedaż Towaru odbywa się po cenach obowiązujących u Sprzedającego w chwili składania Zamówienia.</li>
<li>W przypadku zawarcia Umowy w sposób określony w Rozdziale III pk1 lit. b) i c) sprzedaż Towaru odbywa się w cenie określonej w Umowie lub w fakturze.</li>
<li>Ceny wskazywane przez Sprzedającego są cenami netto – nie zawierają podatku Vat, a także kosztów przesyłki i opakowania. Na prośbę Kupującego Sprzedający może określić te koszty.</li>
<li>Cenę wraz ze wszelkimi dodatkowymi płatnościami Kupujący powinien zapłacić gotówką przy odbiorze Towaru, z uwzględnieniem obowiązujących w tym zakresie przepisów prawa.</li>
<li>Za zgodą Sprzedającego wyrażoną w formie pisemnej, przez co rozumie się również wystawienie faktury, zapłata ceny wraz ze wszelkimi dodatkowymi płatnościami może być dokonana w terminie odroczonym od daty odebrania Towaru.</li>
<li>Jeżeli zapłata ceny ma być dokonana w odroczonym terminie, Sprzedający może żądać od Kupującego zabezpieczenia płatności, w szczególności w postaci:<ol>
<li>przedpłaty;</li>
<li>weksla własnego in blanco wraz z deklaracją wekslową;</li>
<li>poręczenia;</li>
<li>gwarancji bankowej lub ubezpieczeniowej zabezpieczającej płatności z tytułu ceny sprzedaży i kosztów dostarczenia Towaru.</li>
</ol>
</li>
<li>Jeżeli cenę Towaru ustalono jako równowartość oznaczonej kwoty w walucie obcej a w trakcie wykonywania Umowy nastąpiła zmiana wartości tej waluty w stosunku do złotego polskiego, zapłata będzie dokonana według kursu średniego ogłoszonego przez Prezesa NBP i obowiązującego w dniu wystawiania faktury przez Sprzedającego.</li>
<li>Za moment zapłaty ceny uważa się moment, w którym cała należność będzie mogła być wykorzystana przez Sprzedającego, w szczególności chwilą taką jest moment wpłynięcia należności na rachunek bankowy Sprzedającego (rzeczywista zapłata należności).</li>
<li>W przypadku opóźnienia Kupującego z zapłatą ceny za Towar, Sprzedający może naliczyć odsetki w wysokości ustawowej.</li>
<li>W przypadku zwłoki Kupującego w zapłacie ceny ponad termin określony w fakturze, Sprzedającemu przysługuje prawo odstąpienia od Umowy bez wyznaczania terminu dodatkowego (art. 492 Kodeksu Cywilnego).</li>
<li>W razie opóźnienia Kupującego z zapłatą ceny za sprzedany Towar, także inne należności Kupującego wobec Sprzedającego stają się natychmiast wymagalne po otrzymaniu przez Kupującego stosownego zawiadomienia od Sprzedającego. Ponadto w przypadku opóźnienia Kupującego w zapłacie należności za sprzedany Towar, Sprzedający może wedle swego wyboru:<ol>
<li>odmówić wydania innego Towaru do tej pory nie wydanego Kupującemu, także w przypadku, gdy obowiązek wydania tego Towaru wynikał z innej Umowy, niż ta, której warunki płatności nie zostały dotrzymane lub odstąpić bez zachowania terminu dodatkowego od innych Umów zawartych z Kupującym;</li>
<li>żądać naprawienia wynikłej stąd szkody.</li>
</ol>
</li>
<li>Nie jest dopuszczalne potrącenie przez Kupującego wierzytelności przysługujących mu wobec Sprzedającego, z wzajemnymi wierzytelnościami Sprzedającego wobec Kupującego wynikającymi z Umowy lub związanych z niewykonaniem lub nienależytym wykonaniem Umowy, chyba że wierzytelności Kupującego zostały zasądzone prawomocnym wyrokiem.</li>
</ol>
<h2 id="v-wykonanie-umowy-wydanie-i-odbi-r-towaru">V. Wykonanie Umowy, wydanie i odbiór Towaru</h2>
<ol>
<li>Miejscem wydania Towaru jest magazyn Sprzedającego.</li>
<li>Kupujący może w każdej chwili uzyskać informację o aktualnym etapie wykonywania Umowy.</li>
<li>Ryzyko przypadkowej utraty, uszkodzenia lub zniszczenia Towaru przechodzi na Kupującego wraz z jego wydaniem Kupującemu. Ma to miejsce również wówczas, gdy następują dostawy częściowe oraz gdy Sprzedający przyjął na siebie obwiązek spełnienia jeszcze innych świadczeń, np. wysyłki lub montażu Towaru.</li>
<li>Na życzenie Kupującego Sprzedający może ubezpieczyć na jego koszt przesyłkę z Towarem przed kradzieżą, włamaniem, uszkodzeniami podczas transportu, przed pożarem i zalaniem wodą oraz innymi ryzykami podlegającymi ubezpieczeniu. Jeśli wysyłka Towaru ulega opóźnieniu wskutek okoliczności leżących po stronie Kupującego, to ryzyko, o którym mowa wyżej, przechodzi na niego z dniem gotowości Sprzedającego do wysłania Towaru. Na życzenie i koszt Kupującego Sprzedający ubezpieczy w takiej sytuacji Towar przygotowany do wysyłki do Kupującego.</li>
<li>W przypadku opóźnienia Kupującego z odbiorem Towaru, ciężary związane z Towarem oraz niebezpieczeństwo przypadkowej utraty lub uszkodzenia przechodzą z momentem postawienia Towaru do dyspozycji Kupującego w magazynie Sprzedającego.</li>
<li>Przy odbiorze Towaru Kupujący zobowiązany jest zbadać jego stan.</li>
<li>W przypadku stwierdzenia przy odbiorze Towaru jakichkolwiek niezgodności Towaru z Umową, Kupujący zobowiązany jest poinformować o tym Sprzedającego w Formie pisemnej w terminie 3 dni roboczych od dnia odbioru Towaru, pod rygorem możliwości późniejszego zgłaszania roszczeń w tym zakresie.</li>
<li>Sprzedający zastrzega sobie prawo odstąpienia od Umowy w przypadku, gdy Kupujący nie odbierze Towaru w wyznaczonym przez Sprzedawcę dodatkowym terminie.</li>
<li>Kupujący nie może odmówić przyjęcia opóźnionej dostawy Towaru, ani też odstąpić od Umowy, z zastrzeżeniem ust. 10.</li>
<li>W razie zwłoki, tj. opóźnienia, za które Sprzedający ponosi odpowiedzialność w dostarczeniu Towaru, przekraczającej cztery tygodnie, Kupujący może wyznaczyć Sprzedającemu dodatkowy, późniejszy termin na spełnienie świadczenia, nie krótszy jednak niż 7 dni. Po bezskutecznym upływie tego terminu, Kupujący może na piśmie od Umowy odstąpić w terminie jednego tygodnia.</li>
</ol>
<h2 id="vi-wy-czenie-odpowiedzialno-ci-sprzedaj-cego">VI. Wyłączenie odpowiedzialności Sprzedającego</h2>
<ol>
<li>Odpowiedzialność Sprzedającego za niewykonanie lub nienależyte wykonanie Umowy, nie obejmuje rzeczywistych strat poniesionych przez Kupującego, oraz utraconych przez niego korzyści.</li>
<li>Sprzedający nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie Umowy, w tym również za opóźnienie lub zwłokę jeśli takie niewykonanie lub nienależyte wykonanie Umowy wyniknęło wskutek działania siły wyższej.</li>
<li>Ustala się, że „siła wyższa” oznacza wszelkie okoliczności, w wyniku których wykonanie Umowy przez Sprzedającego jest niemożliwe lub niewspółmiernie kosztowne w stosunku do wartości niedostarczonego Towaru w szczególności spowodowane:<ol>
<li>strajkiem;</li>
<li>brakiem dostaw lub opóźnień w dostawach: produktów, surowców, materiałów i usług koniecznych do wykonania Umowy;</li>
<li>wojną, buntem, rebelią;</li>
<li>klęską żywiołową;</li>
<li>zakłóceniami w transporcie;</li>
<li>epidemią i/lub pandemią.</li>
</ol>
</li>
</ol>
<h2 id="vii-gwarancja">VII. Gwarancja</h2>
<ol>
<li>Odpowiedzialność Sprzedającego za wady Towaru z tytułu rękojmi zostaje wyłączona.</li>
<li>Sprzedający udziela gwarancji na Towar na okres jednego roku od dnia jego wydania Kupującemu.</li>
<li>W przypadku ujawnienia się wad fizycznych Towaru w okresie gwarancji, Sprzedający zobowiązuje się względem Kupującego, wedle swego wyboru, do usunięcia wad fizycznych Towaru lub do dostarczenia Towaru wolnego od wad.</li>
<li>Sprzedający odpowiada tylko za wady, które powstały podczas prawidłowego korzystania z Towaru. W razie wątpliwości za prawidłowe korzystanie z Towaru uważa się normalne korzystanie z Towaru zgodnie z warunkami określonymi przez Sprzedającego. Sprzedający nie odpowiada za uszkodzenia lub zniszczenie Towaru, jeżeli nastąpiło ono w wyniku nieprawidłowego korzystania oraz nieprawidłowego zainstalowania, a także w przypadku naprawy lub modyfikacji Towaru dokonanej poza punktami serwisowymi wyznaczonymi przez Sprzedającego chyba, że zostały one dokonane za uprzednią zgodą Sprzedającego.</li>
<li>Gwarancja nie obejmuje Towaru, który, choćby częściowo, został wytworzony z materiałów lub według specyfikacji dostarczonych przez Kupującego. Gwarancja nie obejmuje także uszkodzeń Towaru, które powstały w wyniku:<ol>
<li>normalnego zużycia Towaru;</li>
<li>wydarzenia przypadkowego lub działania siły wyższej.</li>
</ol>
</li>
<li>Gwarancja wygasa w przypadku nieprzestrzegania przez Kupującego zobowiązań wynikających z Warunków lub z Umowy, w szczególności w przypadku nie dokonania zapłaty ceny za Towar w terminie płatności.</li>
<li>W przypadku, gdy niemożliwym okaże się usunięcie wad Towaru, Sprzedający zobowiązuje się zwrócić Kupującemu równowartość wadliwych elementów lub części, a gdy wskutek wad Towaru nie jest możliwe korzystanie z niego zgodnie z jego przeznaczeniem, zobowiązuje się zwrócić cenę za Towar. Kupującemu nie przysługuje w takim wypadku roszczenie o zwrot kosztów napraw, dopasowania czy też wymiany.</li>
<li>W celu skorzystania z Gwarancji Kupujący zobowiązany jest do zawiadomienia Sprzedającego o wadzie Towaru w ciągu 7 dni od ujawnienia się wady. Zawiadomienie powinno zostać dokonane w Formie pisemnej lub osobiście w punkcie handlowym Sprzedającego. Zawiadomienie powinno zawierać opis okoliczności ujawnienia się wady.</li>
<li>W razie potrzeby Kupujący zobowiązany jest udzielić Sprzedającego wszelkiej pomocy w celu prawidłowej oceny wady Towaru oraz ustalenia sposobu jej usunięcia.</li>
<li>Po otrzymaniu zgłoszenia wady Sprzedający podejmuje decyzję o sposobie rozpatrzenia reklamacji.</li>
<li>Kupujący zobowiązany jest dostarczyć wadliwy Towar do najbliższego punktu handlowego Sprzedającego lub zorganizować wysyłkę firmą kurierską (po wcześniejszym wyrażeniu zgody przez Sprzedającego).</li>
<li>Koszty dostarczenia Towaru do Sprzedającego ponosi Kupujący.</li>
<li>Reklamowany Towar powinien być odpowiednio zabezpieczony na czas transportu.</li>
<li>Sprzedający nie odpowiada za zniszczenia lub uszkodzenia Towaru wynikające z niewłaściwego opakowania lub zabezpieczenia Towaru przez Kupującego.</li>
<li>W przypadku uznania roszczeń gwarancyjnych koszty transportu Towaru pokrywa Sprzedający. W przypadkach gdy wada Towaru powstała z winy Kupującego, lub nie jest objęta gwarancją koszty transportu Towaru obciążają Kupującego. Sprzedający może na czas rozpatrywania reklamacji dostarczyć Kupującemu Towar o zbliżonych parametrach technicznych, jeżeli dysponuje w danej chwili odpowiednikiem lub zamiennikiem takiego Towaru.</li>
</ol>
<h2 id="viii-pr-by-towaru">VIII. Próby Towaru</h2>
<p>Wszelkie próby (testy) Towaru są możliwe wyłącznie za zgodą Sprzedającego. Ewentualne koszty prób (testów) będą ustalane indywidualnie pomiędzy Stronami.</p>
<h2 id="ix-zwrot-wymiana-towaru-nowego-">IX. Zwrot/wymiana Towaru nowego.</h2>
<ol>
<li>Kupujący może dokonać zwrotu/wymiany Towaru już dostarczonego przez Sprzedającego po uzyskaniu pisemnej zgody Sprzedającego, za wyjątkiem sytuacji objętych Gwarancją, Sprzedający może udzielić takiej zgody, jeśli Towar nie był używany i pozostaje w oryginalnym opakowaniu a zwrot/wymiana nastąpi w terminie 7 dni od daty dostarczenia Kupującemu. W razie wyrażenia przez Sprzedającego zgody na dokonanie zwrotu/wymiany Towaru, Sprzedający nie poniesie z tego tytułu żadnych kosztów, w szczególności transport Towaru odbędzie się na koszt Kupującego. Sprzedający dokona kontroli zwróconego/wymienionego Towaru i będzie mógł zdecydować czy: <ol>
<li>zwrócić Kupującemu wartość zwróconego Towaru;</li>
<li>odmówić przyjęcia zwróconego/wymienionego Towaru.</li>
</ol>
</li>
<li>W przypadku dokonania zwrotu/wymiany Towaru, Kupujący ponosi opłatę manipulacyjną za zwrot/wymianę Towaru w wysokości 10 % (dziesięć) ceny Towaru dla elementów standardowych oraz 50 % (pięćdziesiąt) ceny Towaru dla zamówień niestandardowych, nie występujących w katalogu Sprzedającego.</li>
<li>Towar wyprodukowany na specjalne zamówienie Kupującego nie podlega zwrotowi/wymianie.</li>
</ol>
<h2 id="x-zastrze-enie-prawa-w-asno-ci-towaru">X. Zastrzeżenie prawa własności Towaru</h2>
<p>Towar dostarczony na podstawie Umowy pozostaje własnością Sprzedającego do czasu uiszczenia całkowitej zapłaty Ceny. Prawo własności i związane z nim uprawnienie do żądania wydania odpowiednich Towaru, nie wyłącza dochodzenia przez Sprzedającego roszczeń z tytułu poniesionych strat i/lub utraconych korzyści.</p>
<h2 id="xi-tajemnica-handlowa">XI. Tajemnica handlowa</h2>
<ol>
<li>Wszelkie informacje dotyczące działalności Sprzedającego, które nie są publicznie znane, jak również postanowienia Warunków oraz Umowy, mają charakter poufny i stanowią tajemnicę przedsiębiorstwa Sprzedającego w rozumieniu art. 11 ustawy z dnia 16 kwietnia 1993 r. o zwalczaniu nieuczciwej konkurencji (Dz. U. Nr 47, poz. 211, z późn. zm.).</li>
<li>Strony uzgadniają, że nie będą ujawniać poufnych informacji osobom trzecim, ani wykorzystywać ich do celów innych niż wykonanie zobowiązań wynikających z Warunków i Umów. Kupujący podejmie wszelkie uzasadnione kroki, aby uniemożliwić ujawnienie informacji poufnych.</li>
<li>Jeżeli Kupujący zostanie zobowiązany przez bezwzględnie obowiązujące przepisy prawa do ujawnienia jakiejkolwiek informacji dotyczącej działalności Sprzedającego, zawiadomi o tym Sprzedającego i będzie z nim współpracować w celu wyeliminowania lub zmniejszenia do minimum negatywnych skutków ujawnienia takich informacji. Zobowiązanie to wiąże Kupującego przez okres trzech lat od dnia nabycia Towaru przez Kupującego. Po tym okresie informacje poufne będą chronione na podstawie powszechnie obowiązujących przepisów prawa, w tym art. 11 ustawy z 16 kwietnia 1993 r. o zwalczaniu nieuczciwej konkurencji (Dz. U. Nr 47, poz. 211).</li>
<li>Bez pisemnej zgody Sprzedającego Kupujący nie będzie używał nazwy, znaków towarowych ani nazw handlowych Sprzedającego, ani też powoływał się na związki gospodarcze ze Sprzedawcą w jakimkolwiek celu.</li>
</ol>
<h2 id="xii-status-przedsi-biorcy">XII. Status przedsiębiorcy</h2>
<ol>
<li>Press Bar Sp. z o.o. posiada status dużego przedsiębiorcy w rozumieniu art.4 pkt 6 powołanej wyżej ustawy oraz załącznika I do rozporządzenia Komisji (UE) nr 651/2014 z dnia 17 czerwca 2014 roku uznającego niektóre rodzaje pomocy za zgodne z rynkiem wewnętrznym w zastosowaniu art. 107 i art. 108 Traktatu.</li>
</ol>
<h2 id="xiii-postanowienia-ko-cowe">XIII. Postanowienia końcowe</h2>
<ol>
<li>W sprawach nie uregulowanych w Warunkach stosuje się przepisy Kodeksu Cywilnego.</li>
<li>W sytuacji gdy Kupujący będzie miał siedzibę lub miejsce zamieszkania poza Polską w sprawach nie uregulowanych w Warunkach zastosowanie będą miały przepisy prawa polskiego.</li>
<li>Sądem właściwym miejscowo do rozstrzygnięcia wszelkich sporów, powstałych w związku ze sprzedażą Towaru na podstawie Warunków, właściwym rzeczowo będzie sąd powszechny w Bydgoszczy. Ewentualna nieskuteczność poszczególnych postanowień Warunków nie wpływa na skuteczność pozostałych postanowień.</li>
</ol>

    </div>
    </div>
  )
}

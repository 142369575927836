import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectProducts, selectChosenProducts, selectUsers, selectChosenUser, selectChosenUserSearchPrices,
  setChosenUser, getProductsByName, addToChosenProducts, getUsers, addIndividualPrices, getUsersSearchPrices, 
  selectUsersSearchPrices, setChosenUserSearchPrices, getProductsByNameForUser, selectUserPrices, deleteProductIndPrice } from '../../../../Store/Slices/admin/Products/IndPrices';
import { AppDispatch } from '../../../../Store/store';
import { AnimatePresence } from 'framer-motion';
import Confirm from '../../../../Common/Modals/Confirm/Confirm';

export default function ProductIndPrice() {


  const products = useSelector(selectProducts);
  const chosenProducts = useSelector(selectChosenProducts);
  const foundUsers = useSelector(selectUsers);
  const chosenUser = useSelector(selectChosenUser);
  const foundUsersSearchDiscounts = useSelector(selectUsersSearchPrices);
  const chosenUserSearchDiscounts = useSelector(selectChosenUserSearchPrices);
  const userIndPrices = useSelector(selectUserPrices);

  const dispatch = useDispatch<AppDispatch>();
  
  const [pricesValues, setPricesValue] = useState<string[]>([]);
  const [clientSearchDiscounts, setClientSearchDiscounts] = useState('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [chosenPriceToDelete, setChosenPriceToDelete] = useState(-1);
  const [chosenProductToDelete, setChosenProductToDelete] = useState(-1);
  const [client, setClient] = useState('');

  const [search, setSearch] = useState('');

  useEffect(()=>{
    if(products.length!=pricesValues.length){
      for(var i=0; i<chosenProducts.length-pricesValues.length; i++){
        setPricesValue(pricesValues.concat(['']));
      }
    }
  },[chosenProducts])

  return (
    <div>
      <p>
        <input value={search} onChange={(e)=>{
          setSearch(e.target.value);
        }} placeholder='Nazwa lub numer katalogowy produktu'/>
        <button onClick={()=>{
          dispatch(getProductsByName({name: search}))
        }}>Szukaj</button>
      </p>
      <p>
        {products&&products.map(element=>{
          return(
            <p>
              {element.Name}
              <button onClick={()=>{
              dispatch(addToChosenProducts(element))
              }} style={{marginLeft: '30px'}}>dodaj</button>
            </p>
          )
        })}
      </p>
      <p>Wybrane:</p>
      <p>
        {chosenProducts&&chosenProducts.map((element, index)=>{
          return(
            <p>
              {element.Name}<span style={{width:'20px'}}></span>Cena: <input onChange={(e)=>{
                setPricesValue(pricesValues.map((item, i)=>i==index?e.target.value:item))
                //pricesValues[index]=e.target.value;
              }}/>
            </p>
          )
        })}
      </p>
      <br/>
      <p>
        <input value={client} onChange={(e)=>{
          setClient(e.target.value);
        }} placeholder='nazwa lub NIP klienta'/>
        <button onClick={()=>{
          dispatch(getUsers({name: client}));
        }}>Szukaj</button>
      </p>
      <br/>
      <p>{foundUsers&&foundUsers.map((element)=>{
        return(
          <p><button style={{background: (element.Id===chosenUser?'blue':'red')}} onClick={()=>{
            dispatch(setChosenUser(element.Id))
          }}>
            {element.Name}
          </button></p>
        )
      })}</p>
      <br/><br/>
      <p>
        <button onClick={()=>{
          var productsIds:{id: number, price: number}[] = chosenProducts.map((element, index)=>{
            if(pricesValues.length<=index){
              if(pricesValues[index]===''||pricesValues[index]==undefined){
                return {id: -1, price: 0};
              }
            }
            return {id: element.Id, price: parseInt(pricesValues[index])};
          }).filter(item=>item.id!=-1);
          dispatch(addIndividualPrices({userId: chosenUser, products: productsIds}))
        }}>
          Zatwierdź
        </button>
      </p>
      <br/><br/>
      <p>Wyświetl rabaty klienta:</p>
      <p>
        <input value={clientSearchDiscounts} onChange={(e)=>{
          setClientSearchDiscounts(e.target.value);
        }} placeholder='nazwa lub NIP klienta'/>
        <button onClick={()=>{
          dispatch(getUsersSearchPrices({name: clientSearchDiscounts}));
        }}>Szukaj</button>
      </p>
      <p>{foundUsersSearchDiscounts&&foundUsersSearchDiscounts.map((element)=>{
        return(
          <p><button style={{background: (element.Id===chosenUserSearchDiscounts?'blue':'red')}} onClick={()=>{
            dispatch(setChosenUserSearchPrices(element.Id))
          }}>
            {element.Name}
          </button></p>
        )
      })}</p>
      <br/>
      <p>
        <button onClick={()=>{
          var productsIds:{id: number, price: number}[] = chosenProducts.map((element, index)=>{
            if(pricesValues.length<=index){
              if(pricesValues[index]===''||pricesValues[index]==undefined){
                return {id: -1, price: 0};
              }
            }
            return {id: element.Id, price: parseInt(pricesValues[index])};
          }).filter(item=>item.id!=-1);
          dispatch(getProductsByNameForUser({userId: chosenUserSearchDiscounts}))
        }}>
          Zatwierdź
        </button>
      </p>
      <p>
        {
          userIndPrices&&userIndPrices.map(element=>{
            return(
              <p>
                {element.Name}-{element.Price}zł
                <button onClick={()=>{
                  setChosenPriceToDelete(element.Price);
                  setChosenProductToDelete(element.Id);
                  setShowConfirmDelete(true);
                }}>Usuń</button>
              </p>
            )
          })
        }
      </p>
      <AnimatePresence mode="wait">
        {showConfirmDelete && (
          <Confirm
            handleClose={function (): void {
              setShowConfirmDelete(false);
            }}
            handleConfirm={function (): void {
              setShowConfirmDelete(false);
              dispatch(deleteProductIndPrice({userId: chosenUserSearchDiscounts, id: chosenProductToDelete, price: chosenPriceToDelete}))
            }}
            text={"Czy na pewno chcesz usunać ten rabat?"}
          />
        )}
      </AnimatePresence>
      <br/><br/>

    </div>
  )
}

import { motion } from "framer-motion";
import React from "react";
import { dropIn } from "../../../../Animations/Animations";
import ButtonPrimaryToLighter from "../../../../Common/Buttons/ButtonPrimaryToLighter";
import { useSelector } from "react-redux";
import { selectCartPrice } from "../../../../Store/Slices/Cart/Cart";
import {
  selectCreateOrderState,
  selectDiscount,
  selectPriceDetails,
  selectStage,
} from "../../../../Store/Slices/Order/Order";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";
import CreditCardIcon from '@mui/icons-material/CreditCard';

function OrderControls() {
  const priceDetails = useSelector(selectPriceDetails);
  const priceProducts = useSelector(selectCartPrice);
  const createOrderState = useSelector(selectCreateOrderState);
  const discount = useSelector(selectDiscount);

  const stage = useSelector(selectStage);

  return (
    <motion.div
      className="p-2 sticky top-28 flex flex-col justify-between overflow-hidden w-full xl:w-1/4 h-fit bg-neutral-100 rounded-lg shadow-md"
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {createOrderState.isLoading && <LoadingAnimation color="primary" />}
      <div className="p-2 flex flex-col space-y-1">
        <h1 className="text-2xl font-bold">Podsumowanie</h1>
        <div className="flex justify-between items-center">
          <h1 className="text-lg">Produkty:</h1>
          <h1 className="text-lg">{((1-discount/100)*priceProducts).toFixed(2)} PLN</h1>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-lg">Dostawa:</h1>
          <h1 className="text-lg">{priceDetails.shipping.toFixed(2)} PLN</h1>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-lg">VAT:</h1>
          <h1 className="text-lg">{((((1-discount/100)*priceProducts)+priceDetails.shipping) * 0.23).toFixed(2)} PLN</h1>
        </div>
        <hr />
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold">Razem do zapłaty:</h1>
          <h1 className="text-lg font-bold">
            {((((1-discount/100)*priceProducts) + priceDetails.shipping)* 1.23).toFixed(2)} PLN
          </h1>
        </div>
      </div>
      <div className="w-full p-2">
        <ButtonPrimaryToLighter
          text={stage === 3 ? "Zamawiam i płacę" : "Przejdź do płatności"}
          size="large"
          Icon={CreditCardIcon}
          customClasses="w-full"
          form={stage === 3 ? "payment-form" : "order-form"}
          isSubmit={true}
        />
      </div>
    </motion.div>
  );
}

export default OrderControls;

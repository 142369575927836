import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";
import EditNameForm from "../../WishlistControls/ListItem/EditNameForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
  copyListToCart,
  deleteList,
  selectListId,
  selectListName,
} from "../../../../Store/Slices/wishlist/wishlistSlice";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function WishlistInfoButtons() {
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const name = useSelector(selectListName);
  const id = useSelector(selectListId);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div
      className={`flex items-center justify-between flex-wrap text-neutral-50`}
    >
      <div
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700"
        onClick={() => {
          dispatch(copyListToCart(id));
        }}
      >
        <span className="text-sm md:text-base whitespace-nowrap">
          Dodaj do koszyka
        </span>
        <AddShoppingCartIcon />
      </div>
      <div
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700"
        onClick={() => {
          setShowEdit(true);
        }}
      >
        <span className="text-sm md:text-base whitespace-nowrap">
          Zmień nazwę
        </span>
        <EditIcon />
      </div>
      <div
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700"
        onClick={() => {
          setShowDeleteConfirm(true);
        }}
      >
        <span className="text-sm md:text-base whitespace-nowrap">
          Usuń listę
        </span>
        <DeleteIcon />
      </div>
      <AnimatePresence mode="wait">
        {showDeleteConfirm && (
          <Confirm
            handleClose={() => {
              setShowDeleteConfirm(false);
            }}
            handleConfirm={() => {
              dispatch(deleteList(id));
              setShowDeleteConfirm(false);
            }}
            text={"Czy napewno chcesz usunąć listę?"}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {showEdit && (
          <EditNameForm
            setShowEdit={setShowEdit}
            name={name ?? ""}
            id={id ?? -1}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default WishlistInfoButtons;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimaryToLighter from "../../../../../Common/Buttons/ButtonPrimaryToLighter";
import Input from "../../../../../Common/Input/Input";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Store/store";
import { addAddress } from "../../../../../Store/Slices/User/User";
import SaveIcon from "@mui/icons-material/Save";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function AddAddress() {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="w-full items-center">
        <Link to="/profile/addresses" className="flex items-center space-x-1">
          <ChevronLeftIcon />
          <span className="text-xl">Powrót</span>
        </Link>
      </div>
      <form
        id="address-form"
        className="w-full items-center rounded-lg p-3 border-2 "
        onSubmit={(e) => {
          e.preventDefault();
          const addressName = e.currentTarget.Address_name.value;
          const address = e.currentTarget.Address.value;
          const city = e.currentTarget.City.value;
          const zipCode = e.currentTarget.Zip_code.value;
          const country = e.currentTarget.Country.value;
          dispatch(
            addAddress({
              Address_name: addressName,
              Address: address,
              City: city,
              Zip_code: zipCode,
              Country: country,
            })
          );
          navigate("/profile/addresses");
        }}
      >
        <div className="flex flex-col lg:flex-row lg:space-y-0 space-y-3 lg:space-x-3 items-center">
          <Input
            name="Nazwa"
            label="Address_name"
            type={"text"}
            size="sm"
            placeholder="Dom"
            required={true}
          />
          <Input
            name="Ulica i numer domu"
            label="Address"
            type={"text"}
            size="sm"
            placeholder="ul. Przykładowa 1"
            required={true}
          />
          <Input
            name="Miasto"
            label="City"
            type={"text"}
            size="sm"
            placeholder="Warszawa"
            required={true}
          />
          <Input
            name="Kod pocztowy"
            label="Zip_code"
            type={"text"}
            size="sm"
            placeholder="00-000"
            required={true}
          />
          <Input
            name="Kraj"
            label="Country"
            type={"text"}
            size="sm"
            placeholder="Polska"
            required={true}
          />
        </div>
        <ButtonPrimaryToLighter
          text={"Zapisz"}
          size={"medium"}
          isSubmit={true}
          form="address-form"
          Icon={SaveIcon}
          customClasses="w-full mt-2"          
        />
      </form>
    </div>
  );
}

export default AddAddress;

import React from "react";
import Input from "../../../../../../Common/Input/Input";

function Comments() {
  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold">Wpisz uwagi dotyczące zamówienia:</h1>
      <div className="w-full rounded-lg p-3 border-2 flex justify-evenly items-center">
        <Input label={"comments"} type={"textarea"} name={"Uwagi"} required={false} />
      </div>
    </div>
  );
}

export default Comments;

import React from "react";
import { Route, Routes } from "react-router-dom";
import AddAddress from "./AddAddress/AddAddress";
import AddressList from "./AddressList/AddressList";
import EditAddress from "./EditAddress/EditAddress";

function Addresses() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<AddressList />} />
        <Route path="/add" element={<AddAddress />} />
        <Route path="/edit/:id" element={<EditAddress />} />
      </Routes>
    </div>
  );
}

export default Addresses;

import React from "react";
import { Link } from "react-router-dom";
import { ButtonProps } from "../../Models/Button";

function ButtonTransparentToColor({
  text,
  Icon = null,
  link = null,
  onClick,
  size,
  isSubmit = false,
  customClasses = "",
}: ButtonProps) {
  const colorDependentClasses =
    " text-neutral-950 hover:bg-neutral-950 hover:text-neutral-50 border-neutral-950";

  if (link === null) {
    return (
      <button
        className={
          `      
      ${size === "small" && "text-lg p-1"}
      ${size === "medium" && "text-xl p-2"}
      ${size === "large" && "text-2xl p-2"}
      border-2  space-x-2 flex items-center justify-center bg-transparent hover:text-neutral-50 rounded-md font-bold transition-all ` +
          customClasses +
          colorDependentClasses
        }
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
        type={isSubmit ? "submit" : "button"}
      >
        <span>{text}</span>
        {Icon != null && <Icon />}
      </button>
    );
  }

  return (
    <Link
      to={`/${link}`}
      className={
        `      
      ${size === "small" && "text-lg p-1"}
      ${size === "medium" && "text-xl p-2"}
      ${size === "large" && "text-2xl p-2"}
      border-2 space-x-2 flex items-center justify-center bg-transparent hover:text-neutral-50 rounded-md font-bold transition-all ` +
        customClasses +
        colorDependentClasses
      }
    >
      <span>{text}</span>
      {Icon != null && <Icon />}
    </Link>
  );
}

export default ButtonTransparentToColor;

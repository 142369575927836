import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { login, selectLoginState } from "../../../Store/Slices/User/User";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimaryToLighter from "../../Buttons/ButtonPrimaryToLighter";
import Confirm from "../../Modals/Confirm/Confirm";
import { saveCartAsList } from "../../../Store/Slices/Cart/Cart";
import { fadeIn } from "../../../Animations/Animations";
import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";

type Props = {
  closeForm?: () => void;
  redirect?: string;
};

function LoginForm({ closeForm, redirect }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [showCartChoice, setShowCartChoice] = useState(false);

  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectLoginState);

  const cookiesAccept = localStorage.getItem("cookiesAccepted");

  function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const remember = formData.get("remember") as string;
    const rememberMe = remember === "on" ? true : false;
    if (localStorage.getItem("cart")) {
      dispatch(saveCartAsList());
    }
    dispatch(login({ email, password, rememberMe }));
  }

  useEffect(() => {
    if (hasLoaded && closeForm && !showCartChoice) {
      closeForm();
    }
    if (hasLoaded && !showCartChoice) {
      if (redirect === "" || redirect === undefined || redirect == null) {
        navigate("/");
      } else {
        if (redirect.startsWith("/")) {
          navigate(redirect, { replace: true });
        } else {
          navigate("/" + redirect, { replace: true });
        }
      }
    }
  }, [closeForm, hasLoaded, navigate, redirect, showCartChoice]);

  return (
    <div className="relative p-3 overflow-hidden w-full h-full">
      {cookiesAccept !== "true" && (
        <motion.div
          variants={fadeIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute top-0 left-0 p-2 w-full h-full backdrop-blur-md flex items-center justify-center"
        >
          <span className="text-sm sm:text-base font-semibold text-center">
            Musisz zaakceptować pliki cookies aby korzystać z funkcji logowania.{" "}
            <a
              className="underline hover:no-underline"
              href="/polityka-prywatnosci"
            >
              Polityka prywatności
            </a>
          </span>
        </motion.div>
      )}
      {isLoading && <LoadingAnimation color="primary" />}
      {/*<AnimatePresence mode="wait">
        {showCartChoice && (
          <Confirm
            handleClose={() => {
              localStorage.removeItem("cart");
              setShowCartChoice(false);
              if (redirect) {
                navigate(redirect);
              }
            }}
            handleConfirm={() => {
              dispatch(saveCartAsList());
              setShowCartChoice(false);
              if (redirect) {
                navigate(redirect);
              }
            }}
            text={
              "Czy chcesz zapisać koszyk jako listę zakupów przed zalogowaniem?"
            }
          />
        )}
      </AnimatePresence>*/}
      <form
        className="flex flex-col space-y-1 text-lg"
        onSubmit={handleLogin}
        id="login-form"
      >
        <label
          htmlFor="email"
          className="flex items-center space-x-2 font-bold"
        >
          Email <EmailIcon />
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="twoj@mail.pl"
          className="appearance-none block w-full p-2 text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all"
        />
        <label
          htmlFor="password"
          className="flex items-center space-x-2 font-bold"
        >
          Hasło <KeyIcon />
        </label>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="********"
          className="appearance-none block w-full p-2 text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all"
        />
        <div className="flex flex-row justify-between text-base flex-wrap">
          <div className="flex flex-row items-center space-x-2">
            <label htmlFor="remember" className="cursor-pointer">
              Zapamiętaj mnie
            </label>
            <input
              type="checkbox"
              name="remember"
              id="remember"
              className="rounded-md cursor-pointer"
            />
          </div>
          <Link to="/reset-password" className="underline hover:no-underline">
            Zapomniałeś hasła?
          </Link>
        </div>
        <ButtonPrimaryToLighter
          text="Zaloguj się"
          size="small"
          Icon={LoginIcon}
          isSubmit={true}
          form="login-form"
        />
      </form>
      <h1 className="text-sm sm:text-base md:text-lg font-black my-1 font-mono text-center">
        Nie masz jeszcze konta?{" "}
        <Link to="/register" className="underline hover:no-underline">
          Zarejestruj się
        </Link>
      </h1>
      <AnimatePresence>
        {failedLoading && (
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="text-red-500 absolute top-2 right-2 text-sm font-bold"
          >
            {error?.code === "ERR_BAD_REQUEST"
              ? "Invalid email or password"
              : error?.message}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default LoginForm;

import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { acceptUser, getUnAcceptedUsers, getUserDetails, getUsers, selectAdminUserState, selectAdminUsersState, selectUser, selectUsers, updateUser } from '../../../../Store/Slices/admin/Users/Users';
import { AppDispatch } from '../../../../Store/store';
import { UserAdminDisplay } from '../../../../Models/User';
import { OrderModel, OrderType } from '../../../../Models/Order';
import { ShopAddress } from '../../../../Models/Address';
import { CategoryDisc, ProductDisc, ProductIndPrice } from '../../../../Models/IndividualPrices';
import ButtonPrimaryToLighter from '../../../../Common/Buttons/ButtonPrimaryToLighter';
import LoadingAnimation from '../../../../Common/LoadingAnimation/LoadingAnimation';
import ErrorMessage from '../../../../Common/Messages/ErrorMessage/ErrorMessage';
import { AnimatePresence } from 'framer-motion';
import Confirm from '../../../../Common/Modals/Confirm/Confirm';

type Props = {}

function EditUser({}: Props) {

  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  useEffect(() => {
    if(id){
        dispatch(getUserDetails({userId: id}));
    }
  }, [dispatch, id]);

  var [userEditData, setUserEditData] 
  = useState({} as { name: string; surname: string; email: string; phoneNumber: string; userId: string; });

  const user: {user:UserAdminDisplay, orders: OrderModel[], addresses: ShopAddress[],categoriesDiscounts:CategoryDisc[], 
    productsDiscounts: ProductDisc[]} = useSelector(selectUser);
  const userState = useSelector(selectAdminUserState);
  const { isLoading, failedLoading, hasLoaded, error } = useSelector(selectAdminUserState);
  const [confirmEdit, setConfirmEdit] = useState(false);

  return (
    <div>
      {ReactDOM.createPortal(
        <AnimatePresence initial={false} mode="wait">
          {confirmEdit && (
            <Confirm
              handleClose={()=>{
                setConfirmEdit(false);
              }}
              handleConfirm={() => {
                console.log(userEditData.userId);
                dispatch(updateUser(userEditData));
                setConfirmEdit(false);
              }}
              text={"Czy na pewno chcesz usunąć produkt z koszyka?"}
            />
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}
      <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Edytuj uzytkownika: </h2>
      {userState.isLoading && <LoadingAnimation color="primary" type="local" />}
      {userState.failedLoading && (
        <ErrorMessage message={"Bład podczas pobierania"} error={userState.error} />
      )}
      <form
        id='editUserForm'
        onSubmit={(e)=>{
          setConfirmEdit(true);
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          setUserEditData({
            name: formData.get('name')?.toString()??'', 
            surname: formData.get('surname')?.toString()??'', 
            email: formData.get('email')?.toString()??'', 
            phoneNumber: formData.get('phoneNumber')?.toString()??'', 
            userId: id?.toString()??''
          });
      }}>
        <p><span>Imię: </span><input defaultValue={user.user.Name} name="name"/></p>
        <p><span>Nazwisko: </span><input defaultValue={user.user.Surname} name="surname"/></p>
        <p><span>Email: </span><input defaultValue={user.user.Email} name="email"/></p>
        <p><span>Nr telefonu: </span><input defaultValue={user.user.Phone_number} name="phoneNumber"/></p>
        <p><ButtonPrimaryToLighter 
          isSubmit={true} 
          text='Zatwierdź' 
          size='medium' 
          form='editUserForm'/>
        </p>
      </form>
      {(!user.user.Is_client_accepted)&&<p>
        <ButtonPrimaryToLighter text='Zaakceptuj uzytkownika' onClick={()=>{
          dispatch(acceptUser(parseInt(id??'-1')));
        }} size='medium'/>
      </p>}
      <p><p style={{fontSize: '18px', fontWeight: 'bold', paddingTop: '20px', paddingBottom: '10px'}}>Adresy:</p> {user.addresses&&user.addresses.map(element=>{
        return (
        <p>
          {(element as any).Address+', '+(element as any).Zip_code+' '+element.City}
        </p>)
      })}</p>
      <p><p style={{fontSize: '18px', fontWeight: 'bold', paddingTop: '20px', paddingBottom: '10px'}}>Zamówienia:</p> {user.orders&&user.orders.map(element=>{
        return (
        <Link to={`/admin/orders/${element.Id}`}><p>
          {element.Price + "zł - " + element.Creation_date.toString().substring(0,16).replaceAll('T', ' ') + ' ' +(element.Is_paid?'Zapłacone':'Niezapłacone')}
        </p></Link>)
      })}</p>
      <p><p style={{fontSize: '18px', fontWeight: 'bold', paddingTop: '20px', paddingBottom: '10px'}}>Znizki na kategorie (%):</p> {user.categoriesDiscounts&&user.categoriesDiscounts.map(element=>{
        return (
        <p>
          {element.Name}-{element.Discount}%
        </p>)
      })}</p>
      <p><p style={{fontSize: '18px', fontWeight: 'bold', paddingTop: '20px', paddingBottom: '10px'}}>Znizki na produkty (%):</p> {user.productsDiscounts&&user.productsDiscounts.map(element=>{
        return (
        <p>
          {element.Name}-{element.Discount}%
        </p>)
      })}</p>
        
    </div>
    
  )
}

export default EditUser
import { CategoryModel } from "../../../Models/Category";

export const mainCategories: CategoryModel[] = [
  {
    Id: 2,
    Name: "Siłowniki pneumatyczne",
    Description: "Siłowniki pneumatyczne",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/zdjecia2/Zrzut%20ekranu%202024-03-6%20o%2000.40.02.png",
  },
  {
    Id: 11,
    Name: "Zawory i elektozawory pneumatyczne",
    Description: "Zawory i elektozawory pneumatyczne",
    Parent_Category: 1,
    Has_children: 1,
    Image_url:
      "https://pressbarimages.b-cdn.net/zdjecia2/Zrzut%20ekranu%202024-03-6%20o%2000.39.21.png",
  },
  {
    Id: 7,
    Name: "Przygotowanie powietrza",
    Description: "Przygotowanie powietrza",
    Parent_Category: 1,
    Has_children: 1,
    Image_url:
      "https://pressbarimages.b-cdn.net/zdjecia/knocks.png",
  },
  {
    Id: 225,
    Name: "Sprężarki",
    Description: "Sprężarki",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/sprezarka-jpg.webp",
  },
  {
    Id: 226,
    Name: "Osuszacze",
    Description: "Osuszacze",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/osuszacze-jpg.webp",
  },
  {
    Id: 227,
    Name: "Zbiorniki",
    Description: "Zbiorniki",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/zdjecia/ZB270-16VP_12202_0.png.jpeg",
  },
  /*{
    Id: 228,
    Name: "Filtry sprężonego powietrza",
    Description: "Filtry sprężonego powietrza",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/filtry-1-jpg.webp",
  },*/
  {
    Id: 147,
    Name: "Złącza do przewodów i węży",
    Description: "Złącza do przewodów i węży",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/zdjecia2/Zrzut%20ekranu%202024-03-6%20o%2000.41.33.png",
  },
  {
    Id: 177,
    Name: "Armatura gwintowana",
    Description: "Armatura gwintowana",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/kategorie/423062789_1804253570045886_8588959429566494129_n.png",
  },
  {
    Id: 216,
    Name: "Zawory odcinające i napędy pneumatyczne",
    Description: "Zawory odcinające i napędy pneumatyczne",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/Gotowe-34.jpg",
  },
  {
    Id: 229,
    Name: "Przewody pneumatyczne",
    Description: "Przewody pneumatyczne",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/przewody.png",
  },
  {
    Id: 230,
    Name: "Aparatura kontrolno pomiarowa",
    Description: "Aparatura kontrolno pomiarowa",
    Parent_Category: 1,
    Has_children: 1,
    Image_url: "https://pressbarimages.b-cdn.net/DSC_0555-scaled.jpg",
  },
];

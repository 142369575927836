import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/store';
import { getAdminOrderDetails, removeProductFromOrder, selectAdminOrder, setProductQuantity } from '../../../../../Store/Slices/admin/Orders/Orders';
import ProductList from '../../Products/ProductList';
import Confirm from '../../../../../Common/Modals/Confirm/Confirm';
import ChangeQuantity from './Forms/ChangeQuantity';
import AddProduct from './Forms/AddProduct';
import ButtonPrimaryToLighter from '../../../../../Common/Buttons/ButtonPrimaryToLighter';


export default function EditOrder() {

    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const order = useSelector(selectAdminOrder);

    const [openAddProductForm, setOpenAddProductForm] = useState(false);
    const [openRemoveProductForm, setOpenRemoveProductForm] = useState(false);
    const [openChangeQuantityProductForm, setOpenChangeQuantityProductForm] = useState(false);
    const [productToRemove, setProductToRemove] = useState(-1);
    const [productToChangeQuantity, setProductToChangeQuantity] = useState(-1);
    const [newQuantityValue, setNewQuantityValue] = useState(1);

    useEffect(() => {
        if (id) {
          dispatch(getAdminOrderDetails(id));
        }
      }, [id]);


    return (
        <motion.div
        className="my-10 w-full flex flex-col items-center justify-center px-2 sm:px-5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        >
            {order.order&&<div className="w-full mb-10 border-2 p-4">
                <h2 className="text-2xl font-semibold">
                    Zarządzaj zamówieniem nr: {order.order.Order_number}
                </h2>
                <p>
                    <button onClick={()=>{
                        setOpenAddProductForm(true);
                    }}>
                        Dodaj produkt
                    </button>
                </p>

                {order.products&&order.products.map((element) => {
                    return (
                        <p>
                            <ProductList product={element} />
                            <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span style={{fontSize: '20px'}}>Ilość: {element.Quantity}</span>
                                <ButtonPrimaryToLighter size='medium' text='Zmień ilość' onClick={()=>{
                                    setProductToChangeQuantity((element as any).Product_id);
                                    setOpenChangeQuantityProductForm(true);
                                    setNewQuantityValue(element.Quantity??1);
                                }}/>
                                <ButtonPrimaryToLighter size='medium' text='Usuń' onClick={()=>{
                                    setProductToRemove(element.Id);
                                    setOpenRemoveProductForm(true);
                                }}/>
                            </p>
                        </p>
                    );
                })}

            </div>}
            {openAddProductForm&&<AddProduct
                handleClose={() => {
                    setOpenAddProductForm(false);
                }}
                handleConfirm={() => {
                    //dispatch(setOrderAsFinished(id ?? "-1"));
                    setOpenAddProductForm(false);
                }}
                text={"Czy chcesz dodać ten produkt"}
                orderId={order.order.Id}
                userId={order.order.User_id}
            />}
            {openRemoveProductForm&&productToRemove!==-1&&<Confirm
                handleClose={() => {
                    setProductToRemove(-1);
                    setOpenRemoveProductForm(false);
                }}
                handleConfirm={() => {
                    dispatch(removeProductFromOrder({productId: productToRemove, orderId: order.order.Id}));
                    setProductToRemove(-1);
                    setOpenRemoveProductForm(false);
                }}
                text={"Czy chcesz usunąć ten produkt"}
            />}
            {openChangeQuantityProductForm&&productToChangeQuantity!==-1&&<ChangeQuantity
                handleClose={() => {
                    setProductToChangeQuantity(-1);
                }}
                handleConfirm={() => {
                    dispatch(setProductQuantity({productId: productToChangeQuantity, 
                        orderId: id??'-1', quantity: newQuantityValue}));
                    setProductToChangeQuantity(-1);
                }}
                text={"Czy chcesz zmienić ilość dla tego produktu"}
                orderId={order.order.Id}
                product={order.products.filter(element=>element.Id===productToChangeQuantity)[0]}
                newQuantity={newQuantityValue}
                setNewQuantity={setNewQuantityValue}
            />}
        </motion.div>
    )
}

import React, { useState } from "react";
import Filters from "../Filters/Filters";

function CategoriesFilters({admin, mobile}:{admin?:boolean, mobile?: boolean}) {
  // const [showCategories, setShowCategories] = useState(true);
  const [showFilters, setShowFilters] = useState(true);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col items-center">
      <div className="flex items-center justify-evenly w-full">
        {/*<button
          className={`transition-all font-bold text-lg border-b-2 hover:border-primary ${
            showCategories ? "border-primary" : "border-neutral-100"
          }`}
          onClick={() => {
            setShowCategories(true);
            setShowFilters(false);
          }}
        >
          Kategorie
        </button>*/}
        <button
          className={`transition-all font-bold text-lg border-b-2 hover:border-primary ${
            showFilters ? "border-primary" : "border-neutral-100"
          }`}
          onClick={() => {
            //setShowCategories(false);
            //setShowFilters(true);
          }}
        >
          Filtry
        </button>
      </div>
      {/*showCategories && <Categories admin={admin} mobile={mobile}/>*/}
      {showFilters && <Filters mobile={mobile}/>}
    </div>
  );
}

export default CategoriesFilters;

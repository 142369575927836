import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProductDisplay } from "../../Models/Product";
import ProductInfo from "./ProductInfo/ProductInfo";
import ProductControls from "./ProductControls/ProductControls";

type Props = {
  product: ProductDisplay;
  showAddToCart?: boolean;
  showRemoveFromCart?: boolean;
  showCartControls?: boolean;
  isFirstChild?: boolean;
  showRating?: boolean;
  flag?: "none" | "new" | "sale";
  shrink?: boolean;
  grow?: boolean;
  mobile?: boolean;
  productDetails?: boolean;
};

function ProductCard({
  product,
  showAddToCart = true,
  showRemoveFromCart = false,
  showCartControls = false,
  isFirstChild = false,
  showRating = false,
  flag = "none",
  shrink = true,
  grow = true,
  mobile = false,
  productDetails=false,
}: Props) {
  const navigate = useNavigate();
  return (
    <div
      className={`${isFirstChild ? "ml-2 mt-2" : ""} ${
        shrink ? "shrink" : "shrink-0"
      } ${
        grow ? "flex-grow" : "flex-grow-0"
      } shadow-md hover:shadow-lg flex flex-col justify-end snap-center relative z-10 overflow-hidden rounded-lg h-80 w-60 transition-all`}
    >
      <div
        className="w-full h-1/2 z-0 bg-white"
        style={{
          position: "relative",
          backgroundImage: `url(${
            product.Image_url !== ""
              ? product.Image_url
              : "/Images/no_photography_FILL0_wght400_GRAD0_opsz24.png"
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        onClick={
          mobile
            ? () => {
                navigate(
                  `/product/${product.Catalog_name?.replaceAll("/", "*1*")}`
                );
              }
            : undefined
        }
      ></div>
      <Link
        className="w-full h-1/2 p-4 flex flex-col justify-between "
        to={`/product/${product.Catalog_name?.replaceAll("/", "*1*")}`}
      >
        <ProductInfo product={product} />
      </Link>
      <div className="absolute top-0 p-2 justify-between flex items-start w-full z-10">
        {!mobile && !productDetails && product.Id!==-1 && <div className="flex items-center space-x-1 rounded-full bg-white bg-opacity-40 backdrop-blur-sm">
          <h2 className="block whitespace-nowrap text-sm sm:text-base font-bold select-none cursor-default">
            {(product.Stock_quantity ?? 0) > 0 ? "Dostępny" : "Na zamówienie"}
          </h2>
          <div
            className={`w-4 h-4 rounded-full animate-pulse bg-${
              product.Stock_quantity !== undefined
                ? (product.Stock_quantity ?? 0) > 0
                  ? "green"
                  : "orange"
                : "transparent"
            }-600`}
          ></div>
        </div>}
        {!mobile && !productDetails && <ProductControls product={product} />}
      </div>
    </div>
  );
}

export default React.memo(ProductCard, (prevProps, nextProps) => {
  // Zwróć true, jeśli re-render nie jest potrzebny, false w przeciwnym razie
  return prevProps.product.Catalog_name === nextProps.product.Catalog_name;
  // Dodaj inne warunki porównujące propsy, które są ważne dla twojego komponentu
});

import React from "react";
import { Actuator } from "../../../Store/Slices/Actuators/Actuators";
import Description from "../../Product/InfoBelow/Description/Description";
import TechnicalData from "../../Product/InfoBelow/TechnicalData/TechnicalData";

type Props = {
  actuator: Actuator;
};

function ActuatorInfoBelow({ actuator }: Props) {
  return (
    <div className="w-full">
      {actuator.Description !== "" && (
        <Description description={actuator.Description} />
      )}
      {actuator.Technical_data && (
        <TechnicalData technicalData={actuator.Technical_data} />
      )}
    </div>
  );
}

export default ActuatorInfoBelow;

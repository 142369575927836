import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Dispatch } from "redux";
import { useAppDispatch } from "../../../Store/store";
import { ProductCart } from "../../../Models/Product";
import {
  removeFromCart,
  removeProductLocal,
} from "../../../Store/Slices/Cart/Cart";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";
import Confirm from "../Confirm/Confirm";

type Props = {
  children: React.ReactNode;
  product: ProductCart;
};

function AddToCartButton({ children, product }: Props) {
  const dispatch: Dispatch<any> = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full">
      <button
        className="w-full"
        onClick={(e) => {
          if (!showModal) {
            handleShowModal();
          } else {
            handleCloseModal();
          }
        }}
      >
        {children}
      </button>

      {ReactDOM.createPortal(
        <AnimatePresence initial={false} mode="wait">
          {showModal && (
            <Confirm
              handleClose={handleCloseModal}
              handleConfirm={() => {
                if (isLoggedIn) {
                  dispatch(removeFromCart(product.product.Id));
                } else {
                  dispatch(removeProductLocal([product]));
                }
                handleCloseModal();
              }}
              text={"Czy na pewno chcesz usunąć produkt z koszyka?"}
            />
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}
    </div>
  );
}

export default AddToCartButton;

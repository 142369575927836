import React from "react";
import CategoryCard from "./CategoryCard/CategoryCard";
import { mainCategories } from "./CategoriesList";

function Categories() {
  const categories = mainCategories;

  const [width, setWidth] = React.useState(window.innerWidth);
  const [nOfElements, setNOfElements] = React.useState(8); 

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (width > 1024)
  {
    nOfElements === 8 && setNOfElements(10);
  } else {
    nOfElements === 10 && setNOfElements(8);
  }

  return (
    <div className="w-full rounded-lg py-4 relative flex flex-nowrap space-x-2 lg:space-x-0 overflow-x-scroll lg:grid lg:grid-cols-4 lg:grid-rows-2 xl:grid-cols-5 gap-2">
      {categories.slice(0, 10).map((category) => (
        <CategoryCard key={category.Id} category={category} />
      ))}
    </div>
  );
}

export default Categories;

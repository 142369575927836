import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";
import CheckIcon from '@mui/icons-material/Check';

function PressureDropCalculator() {
  const [przeplyw, setPrzeplyw] = useState("");
  const [dlEfektywna, setDlEfektywna] = useState("");
  const [srednica, setSrednica] = useState("");
  const [cisnienie, setCisnienie] = useState("");
  const [result, setResult] = useState("");

  const calculatePressureDrop = () => {
    const cisnAtm = 1.013;
    const spadekCisn =
      (((Math.pow(parseFloat(przeplyw) / 3600, 1.85) *
        parseFloat(dlEfektywna)) /
        Math.pow(parseFloat(srednica) / 1000, 5) /
        (parseFloat(cisnienie) + cisnAtm)) *
        1.6) /
      Math.pow(10, 6);
    setResult(`Wynik: ${spadekCisn.toFixed(1).toString()} bar`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Spadek ciśnienia
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>
            Przepływ pow. (m<sup>3</sup>/h)
          </span>
          <input
            type="number"
            value={przeplyw}
            onChange={(e) => setPrzeplyw(e.target.value)}
            placeholder="Przepływ pow. (m^3/h)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Długość efektywana (m)</span>
          <input
            type="number"
            value={dlEfektywna}
            onChange={(e) => setDlEfektywna(e.target.value)}
            placeholder="Długość efektywna (m)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Średnica rurociągu (mm)</span>
          <input
            type="number"
            value={srednica}
            onChange={(e) => setSrednica(e.target.value)}
            placeholder="Średnica (mm)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie sprężarki (bar)</span>
          <input
            type="number"
            value={cisnienie}
            onChange={(e) => setCisnienie(e.target.value)}
            placeholder="Ciśnienie sprężarki (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculatePressureDrop}
          Icon={CheckIcon}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default PressureDropCalculator;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { AppDispatch } from "../../../Store/store";
import { useParams } from "react-router-dom";
import {
  addToCart,
  changeSkok,
  changeSrednica,
  getCategoryDetails,
  getPrice,
  selectActuatorDetails,
  selectActuatorOrderData,
  selectActuatorState,
  selectAddToCartLocal,
  selectProductLocal,
  setAddToCartLocal,
} from "../../../Store/Slices/Actuators/Actuators";
import Gallery from "../../Product/Gallery/Gallery";
import ActuatorInfoBelow from "./ActuatorInfoBelow";
import LoadingAnimation from "../../../Common/LoadingAnimation/LoadingAnimation";
import DropDownList from "../../../Common/DropDownList/DropDownList";
import Input from "../../../Common/Input/Input";
import ButtonTransparentToColor from "../../../Common/Buttons/ButtonTransparentToColor";
import ErrorMessage from "../../../Common/Messages/ErrorMessage/ErrorMessage";
import AddToCartButtonActuator from "../../../Common/Modals/AddToCart/Actuators/AddToCartActuator";
import { addActuatorLocal } from "../../../Store/Slices/Cart/Cart";
import CheckIcon from "@mui/icons-material/Check";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SellIcon from '@mui/icons-material/Sell';

export default function ActuatorConfig() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const actuatorDetails = useSelector(selectActuatorDetails);
  const actuatorDetailsState = useSelector(selectActuatorState);
  const actuatorDetailsOrder = useSelector(selectActuatorOrderData);
  const [showListDropdown, setShowListDropdown] = useState(false);
  const addToCartLocal = useSelector(selectAddToCartLocal);
  const productLocal = useSelector(selectProductLocal);

  useEffect(() => {
    dispatch(getCategoryDetails(id ?? "-1"));
  }, [id]);

  useEffect(() => {
    if (addToCartLocal) {
      //dispatch(addActuatorLocal({ product: productLocal, quantity: 1 }));
      dispatch(setAddToCartLocal(false));
    }
  }, [addToCartLocal]);

  return (
    <motion.div
      className="relative w-full min-h-96 mb-10 flex flex-col items-center items-top justify-center max-w-[1536px] space-y-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {actuatorDetailsState.isLoading && (
        <LoadingAnimation color="primary" type="global" />
      )}
      {actuatorDetailsState.failedLoading && (
        <div className="w-full flex flex-col items-center rounded-lg shadow-md bg-neutral-100 p-2 space-y-5">
          <ErrorMessage
            message={"Błąd podczas ładowania danych"}
            error={actuatorDetailsState.error}
          />
        </div>
      )}
      {actuatorDetailsState.hasLoaded && (
        <div className="w-full flex flex-col items-center rounded-lg shadow-md bg-neutral-100 p-2 space-y-5">
          <div className="w-full flex flex-col space-y-2">
            <h1 className="text-xl lg:text-4xl font-bold text-neutral-950">
              {actuatorDetails.Name}
            </h1>
            {actuatorDetails?.Images !== undefined &&
              actuatorDetails.Images.length !== 0 && (
                <div className="flex flex-col lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0">
                  <div className="lg:sticky top-32 w-full flex justify-center items-center lg:w-1/2 h-64 sm:h-72 xl:h-80 2xl:h-96">
                    <Gallery images={actuatorDetails.Images} />
                  </div>
                  <div className="flex flex-col w-full lg:w-1/2 space-y-2">
                    <div className="flex flex-col mb-5">
                      <span className="text-2xl font-semibold">
                        Numer katalogowy:{" "}
                        {actuatorDetails.Prefix +
                          (actuatorDetailsOrder?.srednica ?? "") +
                          "X" +
                          (actuatorDetailsOrder?.skok ?? "") +
                          actuatorDetails.Subfix}
                      </span>
                      {actuatorDetailsOrder.Cena !== undefined && (
                        <div className="flex items-center space-x-2">
                          <SellIcon />
                          <span className="font-semibold text-2xl">
                            {actuatorDetailsOrder.Cena.toFixed(2)} PLN
                          </span>
                        </div>
                      )}
                      {actuatorDetailsOrder.Cena !== undefined && (
                        <AddToCartButtonActuator
                          addToCart={addToCart}
                          products={[]}
                          parameters={{
                            srednica: actuatorDetailsOrder?.srednica,
                            skok: actuatorDetailsOrder?.skok,
                            categoryId: parseInt(id ?? "-1"),
                          }}
                        >
                          <div
                            className={`flex items-center justify-center px-1 py-2 lg:px-2 lg:py-4 group bg-neutral-50 hover:bg-primary shadow-md rounded-lg space-x-1 transition-all`}
                          >
                            <span className="group-hover:text-neutral-50 text-xl font-bold">
                              Do koszyka
                            </span>
                            <div className="group-hover:text-neutral-50">
                              <AddShoppingCartIcon />
                            </div>
                          </div>
                        </AddToCartButtonActuator>
                      )}
                    </div>
                    <h1 className="text-2xl font-bold text-neutral-950">
                      Konfigurator
                    </h1>
                    <Input
                      label={"skok"}
                      type={"number"}
                      name={"Wybierz skok"}
                      placeholder="Skok"
                      value={actuatorDetailsOrder?.skok?.toString() || "1"}
                      minValue={1}
                      onChange={(e) => {
                        dispatch(
                          changeSkok(
                            e.target.value !== "" ? e.target.value : "1"
                          )
                        );
                      }}
                    />
                    <div className="relative">
                      <span className="text-xl">Wybierz średnicę</span>
                      <button
                        className={`p-2 appearance-none block w-full text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all`}
                        onClick={() => {
                          setShowListDropdown(!showListDropdown);
                        }}
                      >
                        <span className="text-neutral-700 group-hover:text-neutral-950 text-xl font-bold">
                          Wybierz średnicę {actuatorDetailsOrder.srednica!}
                        </span>
                      </button>

                      {showListDropdown && (
                        <DropDownList
                          customClasses="w-full"
                          options={
                            actuatorDetails?.Srednice?.map((item) => {
                              return {
                                name: item.toString(),
                                value: item.toString(),
                                icon: null,
                              };
                            }) || []
                          }
                          onClick={(item) => {
                            dispatch(changeSrednica(item));
                            setShowListDropdown(false);
                          }}
                          selectedOption={
                            actuatorDetailsOrder?.srednica?.toString() || ""
                          }
                        />
                      )}
                    </div>
                    <ButtonTransparentToColor
                      text={"Zatwierdź"}
                      size={"medium"}
                      Icon={CheckIcon}
                      onClick={() => {
                        if (
                          actuatorDetailsOrder.srednica !== undefined &&
                          actuatorDetailsOrder.skok !== undefined
                        ) {
                          dispatch(
                            getPrice({
                              srednica: actuatorDetailsOrder.srednica,
                              skok: actuatorDetailsOrder.skok,
                              categoryId: id ?? "-1",
                            })
                          );
                        } else {
                          alert("Wybierz średnicę i skok");
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            {/*<ProductData product={{product}} />*/}
          </div>
          <ActuatorInfoBelow actuator={actuatorDetails} />
        </div>
      )}
    </motion.div>
  );
}

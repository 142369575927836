import React from "react";
import ReactDOM from "react-dom";
import AddToCart from "../AddToCart";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Dispatch } from "redux";
import { useAppDispatch } from "../../../../Store/store";
import { ProductCart } from "../../../../Models/Product";
import { addActuatorLocal, addProductsLocal } from "../../../../Store/Slices/Cart/Cart";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../Store/Slices/User/User";
import { CartAddProduct, productCartToCartAddProduct } from "../../../../Models/Cart";
import { AxiosResponse } from "axios";
import { AsyncThunk } from "@reduxjs/toolkit";

type Props = {
  children: React.ReactNode;
  products: ProductCart[];
  addToCart: AsyncThunk<AxiosResponse<any, any>, any, any>,
  parameters?:{srednica: number, skok: number, categoryId: number}
};

function AddToCartButtonActuator({ children, products, addToCart, parameters }: Props) {
  const dispatch: Dispatch<any> = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleShowModal = () => {
    //dispatch(addToCart(parameters!=undefined?parameters:productCartToCartAddProduct(products)));

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full">
      <button
        className="w-full"
        onClick={(e) => {
          if (!showModal) {
            handleShowModal();
          } else {
            handleCloseModal();
          }
        }}
      >
        {children}
      </button>

      {ReactDOM.createPortal(
        <AnimatePresence initial={false} mode="wait">
          {showModal && (
            <div className="fixed z-50 top-0 left-0 w-full h-full flex justify-center items-center">
              <AddToCart
                handleClose={handleCloseModal}
                products={products}
                type={isLoggedIn ? "server" : "local"}
              />
            </div>
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}
    </div>
  );
}

export default AddToCartButtonActuator;

import React from "react";
import { Link } from "react-router-dom";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const options = [
  {
    name: "O nas",
    link: "/about",
    icon: <InfoOutlinedIcon style={{ scale: "1.1" }} />,
  },
  {
    name: "Usługi",
    link: "/uslugi",
    icon: <BuildOutlinedIcon style={{ scale: "1.1" }} />,
  },
  {
    name: "Wiedza",
    link: "/wiedza",
    icon: <CalculateOutlinedIcon style={{ scale: "1.1" }} />,
  },
  {
    name: "Do pobrania",
    link: "/downloads",
    icon: <FileDownloadOutlinedIcon style={{ scale: "1.1" }} />,
  },
  {
    name: "Kontakt",
    link: "/kontakt",
    icon: <ContactSupportOutlinedIcon style={{ scale: "1.1" }} />,
  },
];

function Links() {
  return (
    <div className="w-full flex items-center justify-start sm:justify-center space-x-2 overflow-x-scroll">
      {options.map((option) => (
        <Link
          key={option.name}
          to={option.link}
          className="w-[128px] sm:w-full relative flex flex-col items-center justify-center cursor-pointer p-2 rounded-lg hover:bg-neutral-100"
        >
          {option.icon}
          <span className="whitespace-nowrap">{option.name}</span>
        </Link>
      ))}
    </div>
  );
}

export default Links;

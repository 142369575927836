import React from 'react'
import Backdrop from '../../Backdrop/Backdrop';
import { motion } from 'framer-motion';
import { dropIn } from '../../../Animations/Animations';

type Props = {
  handleClose: () => void;
  text: string;
};

function Informational({handleClose, text}: Props) {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="text-neutral-950 bg-neutral-50 rounded-lg shadow-lg p-4 space-y-4 flex flex-col items-center w-[90%] sm:w-fit sm:max-w-[640px] bg-opacity-80"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1 className="text-3xl font-bold">{text}</h1>
        <div className="flex justify-end space-x-4">          
          <button
            onClick={() => {
              handleClose();
            }}
            className="bg-white text-green-500 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-green-500 hover:text-white transition-all"
          >
            <span className="text-xl">Ok</span>
          </button>
        </div>
      </motion.div>
    </Backdrop>
  );
}

export default Informational
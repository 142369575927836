export const dropIn = {
  hidden: {
    opacity: 0,
    y: "-100vh",
  },
  visible: {
    opacity: 1,
    y: "0",
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 35,
      stiffness: 500,
    },
  },
  exit: {
    opacity: 0,
    y: "100vh",
  },
};

export const dropDown = {
  hidden: {
    opacity: 0,
    y: "0",
  },
  visible: {
    opacity: 1,
    y: "0",
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 35,
      stiffness: 500,
    },
  },
  exit: {
    opacity: 0,
    y: "100vh",
  },
};

export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.1 },
  },
  exit: {
    opacity: 0,
  },
};

export const slideFromRight = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: { duration: 0.3 },
  },
  exit: {
    x: "100vw",
  },
};

export const slideFromLeft = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: 0,
    transition: { duration: 0.3 },
  },
  exit: {
    x: "-100vw",
  },
};

export const slideFromBottom = {
  hidden: {    
    y: "100vh",
  },
  visible: {
    y: 0,
    transition: { duration: 0.3 },
  },
  exit: {
    y: "100vh",
  },
};

import React from "react";
import CategoriesFiltersButton from "../../../../Common/CategoriesFilters/CategoriesFiltersButton/CategoriesFiltersButton";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

function FiltersButton() {
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <CategoriesFiltersButton customStyle={windowWidth > 1024 ? "hidden" : ""}>
      <div
        className={`w-full relative flex flex-col items-center justify-center cursor-pointer p-2 rounded-lg hover:bg-neutral-100`}
      >
        <TuneOutlinedIcon style={{scale: '1.1'}}/>
        <span>Filtry</span>
      </div>
    </CategoriesFiltersButton>
  );
}

export default FiltersButton;

import React, { Component } from "react";
import WishlistInfoButtons from "./WishlistInfoButtons/WishlistInfoButtons";

type Props = {
  name: string;
};

type State = {};

class WishlistInfo extends Component<Props, State> {
  state = {};

  render() {
    return (
      <div className="z-10 xl:sticky xl:top-32 flex flex-wrap justify-between w-full rounded-lg p-2 bg-red-500 bg-opacity-100 shadow-xl text-neutral-50">
        <h1 className="text-xl md:text-2xl font-bold">
          {this.props.name ? this.props.name : "Nie wybrano listy"}
        </h1>
        <WishlistInfoButtons />
      </div>
    );
  }
}

export default WishlistInfo;

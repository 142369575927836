import { useEffect, useState } from "react";
import Data from "../../Pages/Product/InfoBelow/TechnicalData/Data/Data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { CategoryDescription as CategoryDescriptionType, getCategoriesDescriptions, selectCategoryDescription, selectCategoryDescriptionState, selectCurrentCategoryId } from "../../Store/Slices/Categories/Categories";
import { TechnicalDataModel } from "../../Models/Product";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import Backdrop from "../../Common/Backdrop/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import CategoryHtmlDescTest from "./CategoryHtmlDescTest";
import ButtonPrimaryToLighter from "../Buttons/ButtonPrimaryToLighter";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

function CategoryDescription({}:{}){
    
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const categoryDescription = useSelector(selectCategoryDescription);
    const categoryDescriptionState = useSelector(selectCategoryDescriptionState);
    const currentCategoryId = useSelector(selectCurrentCategoryId);
    const [openDescription, setOpenDescription] = useState(false);
    const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }


    useEffect(()=>{
        dispatch(getCategoriesDescriptions(currentCategoryId));
    }, [currentCategoryId]);
    
    return (
        <div style={{width:'100%'}}>
            {/*ReactDOM.createPortal(
                <AnimatePresence mode="wait">
                {categoryDescription?.Category_id!==undefined&&openDescription && (
                    <Backdrop onClick={() => setOpenDescription(false)}>
                        <motion.button
                            className="absolute top-8 left-8 flex items-center justify-center rounded-full h-14 w-14 bg-neutral-100 shadow-md"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setOpenDescription(false)}
                        >
                            <CloseIcon />
                        </motion.button>
                        {categoryDescription?.Category_id!==undefined?<div className="items-center max-w-[1536px] relative py-2 overflow-x-hidden h-full overflow-y-scroll w-full inline"
                        style={{display: 'flex', flexDirection: 'column'}}>
                            {openDescription&&<div style={{
                                position: 'fixed',
                                top: '10%',
                                left: '10%',
                                width: '80%',
                                height: '80%',
                                padding: '20px',
                                backgroundColor: 'white',
                                zIndex: 10,
                                overflow: 'auto',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                                }}>
                                <button onClick={()=>{setOpenDescription(false)}} style={{ float: 'right', padding: '5px 20px' }}>Close</button>
                                <CategoryHtmlDescTest/>
                            </div>}
                        </div>:<div>
                    </div>}
                    </Backdrop>
                    )}
                </AnimatePresence>,
                document.body
            )*/
            openDescription&&ReactDOM.createPortal(
                <AnimatePresence mode="wait">
                {categoryDescription?.Category_id!==undefined&&categoryDescription.PDF_url!==undefined&&openDescription && (
                    <Backdrop onClick={() => setOpenDescription(false)}>
                        <motion.button
                            className="absolute top-8 left-8 flex items-center justify-center rounded-full h-14 w-14 bg-neutral-100 shadow-md"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => setOpenDescription(false)}
                        >
                            <CloseIcon />
                        </motion.button>
                        <div style={{width: '60%'}}>
                            <embed
                                src={categoryDescription.PDF_url}
                                style={{ width: "100%", height: "100vh", border: "none" }}
                                title="PDF Viewer"
                            />
                        </div>
                    </Backdrop>
                    )}
                </AnimatePresence>,
                document.body
            )}

            {categoryDescription?.Category_id!==undefined&&<div style={{width: '100%'}}>
                <div className="w-full flex flex-col xl:flex-row items-start space-y-2 xl:space-y-0 xl:space-x-2"
                    style={{height: open?'300px':'100px', zIndex: '100', overflow: 'hidden'}}>
                    <div className="top-32 w-full flex justify-center items-center xl:w-1/3" style={{height: '100%'}}>
                        <div className="grid grid-cols-1 gap-4" style={{width: '60%'}}>
                        {
                            categoryDescription!==null&&(categoryDescription?.Image_url??[]).map((desc, index)=>{
                                    return(
                                        <Link
                                            to={`/producer/${categoryDescription.Producer[index]??''}`}
                                            className="w flex items-center justify-end"
                                        >
                                            <img src={desc??''}/>
                                        </Link>
                                    );
                                })
                            }        
                        </div>
                    </div>
                    <div className="z-40 w-full xl:w-2/3 flex flex-col sticky top-32">
                        {<table className="w-full table-container">
                            {categoryDescription?.Technical_data!==undefined&&(categoryDescription!=={} as CategoryDescriptionType)&&<tbody>
                                {(categoryDescription?.Technical_data as unknown as TechnicalDataModel[]).map((object, index) => (
                                <tr key={index} className="border-b border-neutral-300">
                                    <td className="p-2">
                                        {<Data object={object} />}
                                    </td>
                                    <td className="p-2">
                                    <span className="text-neutral-700 text-start text-base">
                                        {Object.values(object)}
                                    </span>
                                    </td>
                                </tr>
                                ))}
                            </tbody>}
                        </table>}
                    </div>
                </div>
            </div>}
            
            {categoryDescriptionState.isLoading&&<LoadingAnimation color="primary" />}

            {categoryDescription?.Category_id!==undefined&&<div style={{width: '100%', textAlign: 'center', marginTop: '30px', zIndex: '1000', display: 'flex'}}>
                <ButtonPrimaryToLighter onClick={()=>{
                    setOpen(!open);
                }} text={open?'Zwiń':'Rozwiń'} size="medium" customClasses="w-full"/>
                {categoryDescription.PDF_url!==undefined&&<ButtonPrimaryToLighter onClick={()=>{
                    setOpenDescription(true)
                }} text={'Pokaż opis'} size="medium" customClasses="w-full"/>}
            </div>}
                    
        </div>
        
    )

}

export default CategoryDescription;

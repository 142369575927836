import { motion } from "framer-motion";
import React from "react";
import Links from "../../Common/Links/Links";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

function Contact() {
  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full space-y-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <Links />
      </div>
      <div className="w-full my-10 bg-primary-darker">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
            Kontakt
          </h1>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center max-w-[1536px] text-xl"
      >
        <div className="w-full lg:space-x-5 flex flex-col lg:flex-row lg:space-y-0 space-y-5 items-stretch justify-evenly">
          <div className="flex flex-col space-y-4 items-center">
            <h1 className="text-3xl font-semibold"> Główna siedziba firmy</h1>
            <h2 className="text-3xl font-semibold">Press Bar Sp. z o. o.</h2>
            <p className="flex space-x-2">
              <LocationOnIcon />
              <span>ul. Główna 14, 42-280 Częstochowa</span>
            </p>
            <p className="flex space-x-2">
              <ScheduleIcon />
              <span>Godziny otwarcia: Pn. – Pt.: 8:00 – 16:00</span>
            </p>
          </div>
          <div className="w-1 h-auto bg-primary"></div>
          <div className=" flex flex-col space-y-4 items-center">
            <h1 className="text-3xl font-semibold"> Kontakt</h1>

            <p className="flex items-center space-x-2">
              <EmailIcon />
              <a href="mailto:biuro@pressbar.pl">biuro@pressbar.pl</a>
            </p>
            <p className="flex items-center space-x-2">
              <PhoneIcon />
              <a href="tel:+48506162825">+48 506 162 825</a>
            </p>
          </div>
          <div className="w-1 h-auto bg-primary"></div>
          <div className=" flex flex-col space-y-4 items-center">
            <h1 className="text-3xl font-semibold"> Dane do przelewu</h1>
            <p>Bank PKO BP</p>
            <p>44 1020 1664 0000 3502 0690 1260</p>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-lg sm:text-2xl max-w-[1024px] space-y-5 font-semibold">
          <p className="flex space-x-2">
            <span>
              Celem naszej działalności jest kompleksowe zaopatrzenie przemysłu
              w elementy i układy pneumatyki siłowej.
            </span>
          </p>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-md sm:text-lg max-w-[1024px] space-y-5">
          <p>
            Możemy zaproponować Państwu nowoczesne rozwiązania nie tylko w
            zakresie pneumatyki siłowej ale również w wielu dziedzinach
            technicznych związanych z utrzymaniem ruchu.
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Contact;

import React from 'react'
import { motion } from "framer-motion";
import Gallery from '../Product/Gallery/Gallery';


export default function Instalacje() {
  
    const images = ['https://pressbarimages.b-cdn.net/uslugi/20231107_173641.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231123_095200.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231206_122435.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231220_102905.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231220_102922.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231220_102933.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231220_102950.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240123_133757.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240201_191605.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240314_101729.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240314_101805.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240320_165840.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_155721.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_164212.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_170208.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_170217.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240528_193135.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240606_145636.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240606_145652.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240606_145803.jpg'];

    return (
        <motion.div
            className="flex flex-col justify-center items-center w-full space-y-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{padding:'15px'}}
        >
            <div className="w-full my-10 bg-primary-darker">
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
                >
                <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
                    Instalacje pneumatyczne
                </h1>
                </motion.div>
            </div>

            <header style={{marginBottom: '20px'}}>
                <h1 style={{color: '#008391', fontSize: '22px', fontWeight: 'bold'}}>Nasze Usługi Instalacyjne</h1>
                <p style={{margin: '10px 0'}}>Profesjonalne projektowanie i montaż instalacji.</p>
            </header>

            <section>
                <h2 style={{color: '#008391', marginTop: '10px', fontSize: '19px', fontWeight: 'bold'}}>O Firmie</h2>
                <p style={{margin: '10px 0'}}>Specjalizujemy się w projektowaniu i kompleksowym wykonawstwem instalacji sprężonego powietrza oraz innych gazów i cieczy technicznych i przemysłowych, takich jak azot, gaz ziemny, glikol, woda lodowa, woda bytowa, woda technologiczna w pełnym zakresie średnic.</p>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Specjalistyczne Systemy Transportowe</h2>
                <p style={{margin: '10px 0'}}>Nasza firma zajmuje się także montażem specjalistycznych układów do transportu płatków śniadaniowych, orzechów i innych kruchych produktów spożywczych. Dla jednej z największych firm spożywczych wykonaliśmy kompleksowy montaż rurowego przenośnika dyskowego Cablevey.</p>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Dokumentacja Projektowa</h2>
                <p style={{margin: '10px 0'}}>Na życzenie klienta przygotowujemy kompleksową dokumentację projektową, w tym projekty techniczne, wykonawcze oraz warsztatowe, wszystko w oparciu o wcześniej przygotowane Oceny Ryzyka oraz Instrukcje Bezpiecznego Wykonywania Robót.</p>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Materiały i Technologie</h2>
                <p style={{margin: '10px 0'}}>Oferujemy montaż instalacji z różnych materiałów oraz w różnych technologiach, w zależności od środowiska pracy oraz wytycznych klienta:</p>
                <ul>
                    <li>Stalowe: zaprasowywane, spawane, skręcane</li>
                    <li>Nierdzewne: zaprasowywane, spawane</li>
                    <li>Z tworzyw sztucznych PP, PE, PVC: zgrzewane elektrooporowo i doczołowo, polifuzja termiczna, klejone</li>
                </ul>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Doświadczenie i Dystrybucja</h2>
                <p style={{margin: '10px 0'}}>Od 20 lat dostarczamy systemy sprężonego powietrza oraz zajmujemy się ich serwisem. Jesteśmy autoryzowanym dystrybutorem marek takich jak Atlas Copco i AirPol.</p>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Kontakt</h2>
                <p style={{margin: '10px 0'}}>Jeśli szukasz profesjonalnej i sprawdzonej firmy, która zajmie się wykonaniem instalacji na Twoim zakładzie, zapraszamy do kontaktu z nami.</p>
            </section>

            <div className="w-full h-96" id="gallery">
                <Gallery
                images={images.map((image: any) => ({
                    Image_url: image,
                    Product_group_id: 0,
                    Order_number: 0,
                }))}
                />
            </div>

        </motion.div>
    )
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginFormNav from "./LoginFormNav/LoginFormNav";
import { AnimatePresence } from "framer-motion";
import { selectUser, selectIsLoggedIn } from "../../../Store/Slices/User/User";
import { UserIdRoleAccepted } from "../../../Models/User";
import { selectNumberOfProducts } from "../../../Store/Slices/Cart/Cart";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LoginIcon from "@mui/icons-material/Login";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

function NavBarIcons() {
  const cartQuantity: number = useSelector(selectNumberOfProducts);
  const isLoggedin = useSelector(selectIsLoggedIn);
  const user: UserIdRoleAccepted | null = useSelector(selectUser);

  const [showLogin, setShowLogin] = useState(false);
  const [clickOutside, setClickOutside] = useState(false);
  const [showWishlistInfo, setShowWishlistInfo] = useState(false);

  const [windowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const closeLoginFormNav = () => {
      if (showLogin) {
        setShowLogin(false);
      }
    };

    if (clickOutside) {
      document.addEventListener("click", closeLoginFormNav);
    } else {
      document.removeEventListener("click", closeLoginFormNav);
    }
    return () => {
      document.removeEventListener("click", closeLoginFormNav);
    };
  }, [clickOutside, showLogin, isLoggedin]);

  return (
    <div className="flex text-3xl justify-between items-center space-x-1">
      {/* {<Link
        to="/silowniki"
        className="flex items-center hover:bg-neutral-100 p-3 cursor-pointer rounded-lg relative"
      >
        <SettingsOutlinedIcon
          fontSize={windowWidth > 1024 ? "large" : "medium"}
        />
      </Link>} */}

      <div className="relative w-full h-full flex items-center justify-center">
        {isLoggedin ? (
          <Link
            to={`/${user?.Role === "admin" ? "admin" : "profile"}`}
            className="flex items-center w-full h-full hover:text-primary hover:bg-neutral-100 p-3 cursor-pointer rounded-lg relative"
          >
            <PersonOutlineOutlinedIcon
              fontSize={windowWidth > 1024 ? "large" : "medium"}
            />
          </Link>
        ) : (
          <div
            className={`flex items-center hover:bg-neutral-100 hover:text-primary p-3 cursor-pointer rounded-lg relative ${
              showLogin ? "rounded-b-none bg-neutral-100" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setShowLogin(true);
              setClickOutside(true);
            }}
          >
            <div style={{display: 'flex', padding: '5px'}}>
              <span style={{fontSize: '15px', fontWeight: 'bold', height: '100%'}}>
                Zaloguj się
              </span>
              <LoginIcon fontSize={windowWidth > 1024 ? "large" : "medium"} />
            </div>
          </div>
        )}

        <AnimatePresence initial={false} mode="wait">
          {showLogin && (
            <LoginFormNav
              closeForm={() => {
                setShowLogin(false);
                setClickOutside(false);
              }}
            />
          )}
        </AnimatePresence>
      </div>
      <Link
        to="/wishlist"
        className={`${
          isLoggedin
            ? "hover:text-red-600 cursor-pointer "
            : "hover:text-neutral-600 cursor-not-allowed "
        } flex items-center relative hover:bg-neutral-100 p-3 rounded-lg overflow-visible`}
        onMouseEnter={() => {
          if (!isLoggedin) {
            setShowWishlistInfo(true);
          }
        }}
        onMouseLeave={() => {
          setShowWishlistInfo(false);
        }}
      >
        <FavoriteBorderIcon
          fontSize={windowWidth > 1024 ? "large" : "medium"}
        />
        {showWishlistInfo && !isLoggedin && (
          <div className="absolute top-full z-30 w-auto text-neutral-950 right-0 bg-neutral-50 p-2 rounded-lg shadow-md">
            <span className="text-sm whitespace-nowrap">
              Zaloguj się, aby korzystać z listy życzeń
            </span>
          </div>
        )}
      </Link>
      <Link
        to="/cart"
        className="hover:text-primary flex items-center justify-center hover:bg-neutral-100 p-3 cursor-pointer rounded-lg relative"
      >
        <ShoppingCartOutlinedIcon
          fontSize={windowWidth > 1024 ? "large" : "medium"}
        />
        <span className="absolute top-0 right-0 text-xs text-neutral-50 bg-green-600 rounded-full w-5 h-5 flex justify-center items-center">
          {cartQuantity < 10 ? cartQuantity : "9+"}
        </span>
      </Link>
    </div>
  );
}

export default NavBarIcons;

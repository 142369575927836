import { motion } from "framer-motion";
import React, { useEffect } from "react";
import LoginForm from "../../Common/Forms/LoginForm/LoginForm";
import { useParams } from "react-router-dom";

function Login() {
  useEffect(() => {
    document.title = "Logowanie - Press Bar Pneumatyka";
  }, []);

  const { redirect } = useParams();

  return (
    <motion.div
      className="flex flex-col items-center w-full px-2 sm:px-5 space-y-10 mb-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col items-center space-y-4 w-full max-w-[1024px] my-10">
        <h1 className="text-4xl font-bold text-center">Logowanie</h1>
        <LoginForm redirect={redirect} />
      </div>
    </motion.div>
  );
}

export default Login;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddresses,
  selectAddresses,
  selectFetchAddressesState,
} from "../../../../../Store/Slices/User/User";
import { AppDispatch } from "../../../../../Store/store";
import LoadingAnimation from "../../../../../Common/LoadingAnimation/LoadingAnimation";
import InfoMessage from "../../../../../Common/Messages/InfoMessage/InfoMessage";
import ErrorMessage from "../../../../../Common/Messages/ErrorMessage/ErrorMessage";
import AddressCard from "./AddressCard/AddressCard";
import ButtonNeutral50 from "../../../../../Common/Buttons/ButtonNeutral50";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

function AddressList() {
  const dispatch = useDispatch<AppDispatch>();

  const addresses = useSelector(selectAddresses);
  const state = useSelector(selectFetchAddressesState);

  useEffect(() => {
    dispatch(fetchAddresses());
  }, [dispatch]);

  return (
    <div>
      <h1 className="text-2xl font-semibold">Zapisane adresy</h1>
      <div className="relative w-full rounded-lg p-3 border-2 space-y-2 items-center justify-center">
        {state.hasLoaded &&
          addresses.length > 0 &&
          addresses.map((address) => {
            return <AddressCard key={address.Id} address={address} />;
          })}
        {state.isLoading && <LoadingAnimation type="local" color="primary" />}
        {state.isLoading && <div className="w-full h-32"></div>}
        {state.failedLoading && (
          <ErrorMessage
            error={state.error}
            message={"Błąd ładowania adresów"}
            size="small"
          />
        )}
        {state.hasLoaded && addresses.length === 0 && (
          <InfoMessage
            Icon={InfoIcon}
            message="Nie masz zapisanych adresów"
            size="small"
          />
        )}
      </div>
      <div className="w-full mt-2 flex justify-end">
        <ButtonNeutral50
          text={"Dodaj adres"}
          size={"medium"}
          Icon={AddIcon}
          link={"add"}
        />
      </div>
    </div>
  );
}

export default AddressList;

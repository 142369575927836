import { motion } from "framer-motion";
import React from "react";
import Links from "../../Common/Links/Links";
import StoreIcon from "@mui/icons-material/Store";
import BuildIcon from "@mui/icons-material/Build";

function Services() {
  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full space-y-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <Links />
      </div>
      <div className="w-full my-10 bg-primary-darker">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
            Usługi
          </h1>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-lg sm:text-2xl max-w-[1024px] space-y-5 font-semibold">
          <p className="flex space-x-2">
            <StoreIcon />
            <span>
              Sprzedaż, dostawa i serwis urządzeń pneumatycznych oraz montaż
              instalacji sprężonego powietrza.
            </span>
          </p>
          <p className="flex space-x-2">
            <BuildIcon />
            <span>
              Budowa nowych sprężarkowni oraz układów dystrybucji sprężonego
              powietrza.
            </span>
          </p>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="w-full lg:space-x-5 flex flex-col lg:flex-row lg:space-y-0 space-y-5 items-stretch">
          <div className="text-lg sm:text-xl w-full space-y-5 rounded-lg shadow-md p-5">
            <h1 className="text-2xl font-semibold">Sprężarki</h1>
            <ul className="ml-5 text-base list-disc list-outside">
              <li>Sprężarki śrubowe marki Airpol</li>
              <ul className="list-disc list-inside">
                <li>olejowe</li>
                <li>bezolejowe</li>
                <li>spiralne</li>
              </ul>
              <li>Sprężarki tłokowe marki Airpol</li>
              <ul className="list-disc list-inside">
                <li>olejowe</li>
                <li>bezolejowe</li>
              </ul>
              <li>Sprężarki śrubowe marki Gudepol</li>
              <ul className="list-disc list-inside">
                <li>olejowe</li>
              </ul>
              <li>Sprężarki tłokowe marki Gudepol</li>
            </ul>
          </div>
          <div className="text-lg sm:text-xl w-full space-y-5 rounded-lg shadow-md p-5">
            <h1 className="text-2xl font-semibold">Usługi</h1>
            <ul className="ml-5 text-base list-disc list-outside">
              <li>
                Projektowanie oraz montaż instalacji pneumatycznych oraz gazów
                technicznych
              </li>
              <li>Dobór urządzeń</li>
              <li>Audyty</li>
              <li>Serwis sprężarek śrubowych</li>
              <li>Osuszacze powietrza</li>
              <li>Zbiorniki</li>
              <li>Generatory azotu</li>
            </ul>
          </div>
          <div className="text-lg sm:text-xl w-full space-y-5 rounded-lg shadow-md p-5">
            <h1 className="text-2xl font-semibold">Pneumatyka</h1>
            <ul className="ml-5 text-base list-disc list-outside">
              <li>
                Siłowniki pneumatyczne standardowe oraz na zamówienie klienta
              </li>
              <li>Siłowniki profilowe</li>
              <li>Siłowniki ISO</li>
              <li>Siłowniki kompaktowe</li>
              <li>Regeneracja siłowników</li>
              <li>Zawory pneumatyczne</li>
              <li>Zawory sterujące przepływem pow.</li>
              <li>Zawory odcinające</li>
              <li>Elektrozawory</li>
              <li>Złącza pneumatyczne</li>
              <li>Przygotowanie powietrza</li>
              <ul className="list-disc list-inside">
                <li>Filtry</li>
                <li>Reduktory</li>
                <li>Filtroreduktory</li>
                <li>Smarownice</li>
              </ul>
              <li>Filtry spreżonego powietrza</li>
              <li>Przewody pneumatyczne</li>
              <li>Aparatura kontrolno – pomiarowa</li>
            </ul>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-lg sm:text-2xl max-w-[1024px] space-y-5 font-semibold">
          <p className="flex space-x-2">
            <span>
              Stawiamy sobie za główny cel zastosowanie urządzeń w taki sposób
              aby wszystkie dobrane przez nas maszyny mogły zareagować w
              odpowiednim czasie produkując powietrze zużywając do tego celu
              możliwie jak najmiej energii elektrycznej.
            </span>
          </p>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1024px]"
      >
        <img
          src="https://pressbar.pl/wp-content/uploads/2023/03/instalacjawzor2-jpg.webp"
          height={"auto"}
          width={"100%"}
          alt=""
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-md sm:text-lg max-w-[1024px] space-y-5">
          <p>
            Bazując na wieloletnim doświadczeniu w budowaniu tego typu układów
            przyjmujemy zasadę każdorazowego przeprowadzania badań aktualnych
            poborów powietrza, wysokości ciśnienia oraz parametrów jakościowych
            takich jak punkt rosy i temp.
          </p>
          <p>
            Najważniejszym elementem układu jest sprężarka. Niezależnie od tego
            jakiego typu jest to urządzenie (śrubowa, łopatkowa czy też tłokowa)
            to bezwzględnie należy zadbać o to aby była możliwe jak najbardziej
            niezawodna, energooszczędna i miała jak najmniej negatywny wpływ na
            otaczające ją środowisko a miejsce jej montażu było tak przygotowane
            aby w łatwy sposób wykonać obsługę serwisową.
          </p>
          <p>
            Koszty zakupu i serwisu zmieniają się łącznie w zakresie 15-30%
            łącznych kosztów, natomiast dominująca wartością stanowiącą 70-90%
            to koszty energii. Wniosek: kupując urządzenie a ściślej dokonując
            wyboru i wydając niewielką część swoich łącznych kosztów produkcji
            powietrza, kształtuje się główną część swoich przyszłych wydatków.
            Dlatego właściwy dobór urządzeń, analiza energooszczędności systemów
            produkcji i zużycia sprężonego powietrza ma kluczowe znaczenie w tej
            dziedzinie.
          </p>
          <p>
            Dlatego też dzisiaj, kiedy ceny energii są tak wysokie dobór i
            wykonanie tego typu układów warto powierzyć filmie dającej
            gwarancję, że wszystkie czynniki podczas projektowania sprężarkowni
            oraz układu dystrybucji powietrza zostały wzięte pod uwagę.
          </p>
          <p>
            Jesteśmy autoryzowanym przedstawiciel firmy Airpol Sp. z o.o.
            największego polskiego producenta sprężarek śrubowych od ponad 60
            lat. Oferujemy pełną obsługę w zakresie od projektu sprężarkowni, po
            montaż i pełną obsługą serwisową podczas całego okresu eksploatacji
            urządzeń. Projekty realizujemy w oparciu o standardowe produkty, a
            także wyroby, które są dostosowane do indywidualnych potrzeb i
            oczekiwań klienta.
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Services;

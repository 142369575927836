import { motion } from "framer-motion";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import AddOffer from "./AddOffer";
import AllOffers from "./AllOffers";


export default function Offers() {
  

  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="text-3xl font-bold mt-10">Oferty</h2>
      <div className="flex items-center w-full justify-evenly my-8">
        {/*<Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="category-discount"
        >
          Rabaty dla klienta na kategorie
        </Link>
        <Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="product-discount"
        >
          Rabaty dla klienta na produkt
        </Link>
        <Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="product-price"
        >
          Cena indywidualna
        </Link>*/}
        <Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="add"
        >
          Dodaj ofertę
        </Link>
      </div>
      <Routes>
        <Route path="add" element={<AddOffer />} />
        <Route path="/" element={<AllOffers />} />
      </Routes>
    </motion.div>
  );
}

import React, { useEffect } from "react";
import { motion } from "framer-motion";
import RegisterInformational from "./RegisterInformational/RegisterInformational";
import RegisterBox from "./RegisterBox/RegisterBox";
import { Route, Routes } from "react-router-dom";
import ButtonPrimaryToLighter from "../../Common/Buttons/ButtonPrimaryToLighter";
import { dropIn } from "../../Animations/Animations";
import LoginIcon from "@mui/icons-material/Login";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Register() {
  useEffect(() => {
    document.title = "Rejestracja - Press Bar Pneumatyka";
  }, []);

  return (
    <motion.div
      className="flex flex-col items-center w-full px-2 sm:px-5 space-y-10 mb-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex flex-col lg:flex-row items-start space-y-10 lg:space-x-10 lg:space-y-0 w-full max-w-[1536px]">
              <RegisterBox />
              <RegisterInformational />
            </div>
          }
        />
        <Route
          path="/success"
          element={
            <motion.div
              variants={dropIn}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="w-full max-w-[1536px] px-2 sm:px-5 my-20 flex flex-col items-center justify-center space-y-6"
            >
              <h1 className="text-xl sm:text-5xl font-semibold flex items-center space-x-3">
                <span>Konto utworzone</span>
                <CheckCircleIcon />
              </h1>
              <p className="text-sm sm:text-base font-black my-1 text-center">
                Na podany adres email został wysłany link aktywacyjny. Kliknij w
                link, aby aktywować konto.
              </p>
              <ButtonPrimaryToLighter
                text={"Przejdź do logowania"}
                size={"medium"}
                customClasses="w-full"
                Icon={LoginIcon}
                link={"/login"}
              />
            </motion.div>
          }
        />
      </Routes>
    </motion.div>
  );
}

export default Register;

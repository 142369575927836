import React from "react";
import { Address } from "../../../../../../../../Models/Address";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../../Store/store";
import {
  selectChosenAddressId,
  setAddressId,
  setOrderAddress,
} from "../../../../../../../../Store/Slices/Order/Order";
import HomeIcon from "@mui/icons-material/Home";
import SignpostIcon from "@mui/icons-material/Signpost";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FlagIcon from "@mui/icons-material/Flag";
import FactoryIcon from "@mui/icons-material/Factory";

type Props = {
  address: Address;
  onAddressSelect: (id: number) => void;
};

function AddressList({ address, onAddressSelect }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const chosenAddressId = useSelector(selectChosenAddressId);

  return (
    <div
      className={`${
        address.Id === chosenAddressId ? "border-2 border-primary" : ""
      } relative p-2 rounded-lg shadow-md hover:shadow-lg w-full overflow-hidden flex space-x-5 items-center cursor-pointer transition-all`}
      onClick={() => {
        onAddressSelect(address.Id);
        dispatch(setAddressId(address.Id));
        dispatch(
          setOrderAddress({
            address: address.Address,
            city: address.City,
            zipCode: address.Zip_code,
          })
        );
      }}
    >
      <div className="absolute h-full right-0 w-[50%] bg-gradient-to-r from-transparent via-neutral-100 to-neutral-100"></div>
      <input type='radio' checked={address.Id === chosenAddressId}/>
      <h1 className="text-xl font-semibold flex items-center space-x-1 whitespace-nowrap">
        <HomeIcon />
        <span>{address.Address_name}</span>
      </h1>
      <p className="text-base flex items-center space-x-2 whitespace-nowrap">
        <div className="flex items-center space-x-1 font-semibold">
          <SignpostIcon />
          <span>Ulica: </span>
        </div>
        <span>{address.Address}</span>
      </p>
      <p className="text-base flex items-center space-x-2 whitespace-nowrap">
        <div className="flex items-center space-x-1 font-semibold">
          <LocationCityIcon />
          <span>Miasto: </span>
        </div>
        <span>
          {address.Zip_code}, {address.City}
        </span>
      </p>
      <p className="text-base flex items-center space-x-2 whitespace-nowrap">
        <div className="flex items-center space-x-1 font-semibold">
          <FlagIcon />
          <span>Państwo: </span>
        </div>
        <span>{address.Country}</span>
      </p>
      <p className="text-base flex items-center space-x-2 whitespace-nowrap ">
        <div className="flex items-center space-x-1 font-semibold">
          <FactoryIcon />
          <span>Firma: </span>
        </div>
        <span>{address.Company_name}</span>
      </p>
    </div>
  );
}

export default AddressList;

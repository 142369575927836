import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Article, ArticleLandingPage } from "../../../Models/Article";
import { StateType } from "../../../Models/State";
import axios from "axios";
import { server } from "../../../server";
import { ArticleType } from "../../../Pages/MarkdownArticle/MarkdownArticle";

type articlesSliceState = {
  articles: {
    articlesList: ArticleLandingPage[];
    state: StateType;
  };
  article: {
    article: ArticleType;
    state: StateType;
  };
};

export const fetchLandingPageArticles = createAsyncThunk(
  "products/fetchLandingPageArticles",
  async () => {
    try {
      const response = await axios.get(`${server}/api/Articles/articles`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchArticle = createAsyncThunk(
  "products/fetchArticle",
  async (id: string) => {
    try {
      const response = await axios.get(`${server}/api/Articles/article/${id}`);
      return response.data as ArticleType;
    } catch (error) {
      throw error;
    }
  }
);

const articlesSlice = createSlice({
  name: "Articles",
  initialState: {
    articles: {
      articlesList: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    article: {
      article: {},
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
  } as unknown as articlesSliceState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchLandingPageArticles.pending, (state, action) => {
      state.articles.state.isLoading = true;
      state.articles.state.failedLoading = false;
      state.articles.state.hasLoaded = false;
      state.articles.state.error = null;
    });
    builder.addCase(fetchLandingPageArticles.fulfilled, (state, action) => {
      state.articles.state.isLoading = false;
      state.articles.state.failedLoading = false;
      state.articles.state.hasLoaded = true;
      state.articles.articlesList = action.payload;
    });
    builder.addCase(fetchLandingPageArticles.rejected, (state, action) => {
      state.articles.state.isLoading = false;
      state.articles.state.failedLoading = true;
      state.articles.state.hasLoaded = false;
      state.articles.state.error = action.error;
    });
    builder.addCase(fetchArticle.pending, (state, action) => {
      state.article.state.isLoading = true;
      state.article.state.failedLoading = false;
      state.article.state.hasLoaded = false;
      state.article.state.error = null;
    });
    builder.addCase(fetchArticle.fulfilled, (state, action) => {
      state.article.state.isLoading = false;
      state.article.state.failedLoading = false;
      state.article.state.hasLoaded = true;
      state.article.article = action.payload;
    });
    builder.addCase(fetchArticle.rejected, (state, action) => {
      state.article.state.isLoading = false;
      state.article.state.failedLoading = true;
      state.article.state.hasLoaded = false;
      state.article.state.error = action.error;
    });
  },
});

export const selectLandingPageArticles = (state: {
  articles: articlesSliceState;
}): ArticleLandingPage[] => state.articles.articles.articlesList;

export const selectLandingPageArticlesState = (state: {
  articles: articlesSliceState;
}): StateType => state.articles.articles.state;

export const selectArticle = (state: {
  articles: articlesSliceState;
}): ArticleType => state.articles.article.article;

export const selectArticleState = (state: {
  articles: articlesSliceState;
}): StateType => state.articles.article.state;

export default articlesSlice.reducer;

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Parameter } from "../../Models/Parameter";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../Store/store";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import Filter from "./Filter/Filter";
import {
  fetchParameters,
  getCountsForFilters,
  removeAllParameterChoices,
  selectChosenCategoryId,
  selectParameterChoices,
  selectParameters,
  selectParametersCounts,
  selectParametersState,
  selectProducers,
  selectProducersState,
} from "../../Store/Slices/Parameters/Parameters";
import PriceFilter from "./Filter/PriceFilter/PriceFilter";
import { selectCurrentCategoryId } from "../../Store/Slices/Categories/Categories";
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'


function Filters({ mobile }: { mobile?: boolean }) {

  const dispatch = useDispatch<AppDispatch>();

  const { isLoading, hasLoaded, failedLoading, error } = useSelector(
    selectParametersState
  );

  const chosenParameters = useSelector(selectParameterChoices);

  const [producersOpen, setProducersOpen] = useState(false);

  const filters = useSelector(selectParameters);

  const producers = useSelector(selectProducers)
  const producersState = useSelector(selectProducersState)

  const filtersCounts = useSelector(selectParametersCounts);

  const chosenCategoryId = useSelector(selectChosenCategoryId);

  const [searchParams, setSearchParams] = useSearchParams({
    filters: [],
    producers: [],
  });

  const currentCategoryId = useSelector(selectCurrentCategoryId);

  useEffect(() => {
    if (currentCategoryId !== chosenCategoryId) {
      dispatch(fetchParameters(currentCategoryId));
    }
  }, [currentCategoryId]);

  useEffect(() => {
    var parameters = [];
    var choices = [];
    for (const element of chosenParameters) {
      for (const element2 of element.choicesIds) {
        choices.push(element2);
      }
      if (element.choicesIds.length !== 0) {
        parameters.push(element.parameterId);
      }
    }
    dispatch(
      getCountsForFilters({
        Category_id: parseInt(currentCategoryId.toString()),
        parameters: parameters,
        choices: choices,
      })
    );
  }, [chosenParameters, currentCategoryId]);

  return (
    <div
      className={`${
        mobile ? "h-[80%]" : ""
      } relative px-1 py-2 overflow-x-hidden overflow-y-scroll w-full`}
    >
      {isLoading && <LoadingAnimation color="primary" />}
      {isLoading && <div className="w-full h-40"></div>}
      {failedLoading && (
        <div className="text-xl font-bold text-neutral-700">
          {error?.message}
        </div>
      )}
      {hasLoaded && filters.length > 0 && (
        <div className="flex justify-end">
          <button
            className="text-sm font-bold text-neutral-700 hover:text-neutral-800"
            onClick={() => {
              if (!mobile) {
                setSearchParams((prev) => {
                  dispatch(removeAllParameterChoices());
                  prev.delete("filters");
                  prev.delete("price_min");
                  prev.delete("price_max");
                  prev.delete("skok");
                  prev.delete("wydajnosc");
                  return prev;
                });
              }
            }}
          >
            Wyczyść filtry
          </button>
        </div>
      )}
      <motion.div
        className="flex flex-col"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.3 }}
        key={"filters"}
      >
        {hasLoaded && <PriceFilter key={"price_filter"} />}
        {producersState.hasLoaded &&
          producers.length > 0 &&
          <div>
            <div
              className="rounded-lg bg-neutral-100 hover:bg-neutral-200 p-1 flex space-x-1 items-center text-base cursor-pointer"
              onClick={() => {
                setProducersOpen(!producersOpen)
              }}
            >
              {"Producenci"}
              {<div>{producersOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>}
            </div>
            <AnimatePresence>
              {
                producersState.hasLoaded&&producersOpen&&producers.map((option, index)=>{
                  return(
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      className="overflow-hidden"
                    >
                      <div
                        className="w-full cursor-pointer flex flex-row items-center"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          name={option.Name ? option.Name : "undefined"}
                          id={option.Id.toString()}
                          className="mr-2"
                          checked={
                            JSON.parse(searchParams.get('producers')??'[]')?.includes(option.Id) || false
                          }
                          onChange={(e) => {
                            var checkedProducers = JSON.parse(searchParams.get('producers')??'[]') as number[];
                            if(!checkedProducers.includes(option.Id)){
                              checkedProducers.push(option.Id)
                              setSearchParams((prev) => {
                                prev.set("producers", JSON.stringify(checkedProducers));
                                return prev;
                              });
                            }
                            else{
                              checkedProducers=checkedProducers.filter(temp=>temp!==option.Id);
                              setSearchParams((prev) => {
                                prev.set("producers", JSON.stringify(checkedProducers));
                                return prev;
                              });
                            }
                          }}
                        />
                        <label
                          className="cursor-pointer w-full"
                          htmlFor={option.Id?.toString()}
                        >
                          {option.Name}
                        </label>
                        
                      </div>
                    </motion.div>
                  )
                })
              }
            </AnimatePresence>
          </div>  
        }
        {hasLoaded &&
          filters.length > 0 &&
          filters.map((filter: Parameter, id: number) => {
            return (
              <Filter
                key={id}
                filter={filter}
                FiltersCounts={filtersCounts}
                mobile={mobile}
              />
            );
          })}
      </motion.div>
      {hasLoaded && filters.length === 0 && (
        <div className="text-xl font-bold text-neutral-700">
          Dla tej kategorii nie ma żadnych dodatkowych filtrów.
        </div>
      )}
    </div>
  );
}

export default Filters;

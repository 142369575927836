// PrivateRoute.tsx
import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUser, selectIsLoggedIn } from "../../Store/Slices/User/User";

interface Props {
  admin?: boolean;
  children: ReactElement;
}

const PrivateRoute: React.FC<Props> = ({ admin = false, children }) => {
  const isLoggedin = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (user === null || user === undefined) {
      navigate(`/login${location.pathname}`, { replace: true });
    }
    if (admin && user?.Role !== "admin") {
      navigate(`/`, { replace: true });
    }
  }, [isLoggedin, navigate, user]);

  return children;
};

export default PrivateRoute;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
  clearCart,
  copyCartToNewList,
  removeAllProductsLocal,
  saveCartAsList,
} from "../../../../Store/Slices/Cart/Cart";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";
import { AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";
import { selectIsLoggedIn } from "../../../../Store/Slices/User/User";
import Informational from "../../../../Common/Modals/Informational/Informational";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";

type Props = {
  type: "mobile" | "desktop";
};

function CartInfoButtons({ type }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [showConfirmRemove, setShowConfirmRemove] = React.useState(false);
  const [showConfirmSave, setShowConfirmSave] = React.useState(false);

  return (
    <div
      className={`${
        type === "mobile" ? "flex sm:hidden flex-wrap" : "hidden sm:flex"
      } items-center justify-between text-neutral-50`}
    >
      <button
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-primary-darker whitespace-nowrap"
        onClick={() => {
          setShowConfirmRemove(true);
        }}
      >
        <span className="text-sm md:text-base">Usuń wszystko</span>
        <RemoveShoppingCartIcon />
      </button>
      {ReactDOM.createPortal(
        <AnimatePresence initial={false} mode="wait">
          {showConfirmRemove && (
            <Confirm
              handleClose={() => {
                setShowConfirmRemove(false);
              }}
              handleConfirm={() => {
                if (isLoggedIn) {
                  dispatch(clearCart());
                } else {
                  dispatch(removeAllProductsLocal());
                }
                setShowConfirmRemove(false);
              }}
              text="Czy na pewno chcesz usunąć wszystkie produkty z koszyka?"
            />
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}

      <button
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-primary-darker whitespace-nowrap"
        onClick={() => {
          setShowConfirmSave(true);
        }}
      >
        <span className="text-sm md:text-base">Zapisz jako listę</span>
        <FavoriteIcon />
      </button>
      {ReactDOM.createPortal(
        <AnimatePresence initial={false} mode="wait">
          {showConfirmSave && isLoggedIn && (
            <Confirm
              handleClose={() => {
                setShowConfirmSave(false);
              }}
              handleConfirm={() => {
                dispatch(copyCartToNewList());
                setShowConfirmSave(false);
                // window.location.reload();
              }}
              text="Czy na pewno chcesz zapisać produkty z koszyka jako listę?"
            />
          )}
          {showConfirmSave && !isLoggedIn && (
            <Informational
              handleClose={() => {
                setShowConfirmSave(false);
              }}
              text="Aby zapisać produkty z koszyka jako listę musisz być zalogowany!"
            />
          )}
        </AnimatePresence>,
        document.getElementById("root")!
      )}
    </div>
  );
}

export default CartInfoButtons;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import Slider from "react-slider";
import {
  selectMaxminprice,
  setPrice,
} from "../../../../Store/Slices/Parameters/Parameters";
import { AppDispatch } from "../../../../Store/store";

function PriceFilter() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const maxminprice = useSelector(selectMaxminprice);

  const [values, setValues] = React.useState<number[]>([
    parseInt(searchParams.get("price_min") || maxminprice.min_price),
    parseInt(searchParams.get("price_max") || maxminprice.max_price),
  ]);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between">
        <p className="text-sm text-neutral-500 ">{values[0]}</p>
        <p className="text-sm text-neutral-500 ">{values[1]}</p>
      </div>
      <div className="p-2">
        <Slider
          className="slider bg-neutral-200 w-full rounded-full h-1"
          thumbClassName="thumb bg-primary w-4 h-4 rounded-full shadow-lg cursor-pointer top-[50%] transform translate-y-[-50%] hover:scale-110 transition-transform  hover:shadow-xl focus:outline-none"
          trackClassName="track"
          min={parseInt(maxminprice.min_price)}
          max={parseInt(maxminprice.max_price)}
          value={values}
          onChange={(values) => {
            setValues(values);
          }}
          onAfterChange={(values) => {
            if (location.pathname.startsWith("/search")) {
              setSearchParams((prev) => {
                prev.set("price_min", values[0].toString());
                prev.set("price_max", values[1].toString());
                return prev;
              });
            }
            dispatch(
              setPrice({
                min_price: values[0],
                max_price: values[1],
              })
            );
          }}
        />
      </div>
    </div>
  );
}

export default PriceFilter;

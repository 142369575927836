import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getUnAcceptedUsers, getUsers, selectAdminUsersState, selectUsers } from '../../../../Store/Slices/admin/Users/Users';
import { AppDispatch } from '../../../../Store/store';
import { UserAdminDisplay } from '../../../../Models/User';
import LoadingAnimation from '../../../../Common/LoadingAnimation/LoadingAnimation';
import ErrorMessage from '../../../../Common/Messages/ErrorMessage/ErrorMessage';

type Props = {}

function Users({}: Props) {

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUsers({}));
  }, [dispatch]);

  const users: UserAdminDisplay[] = useSelector(selectUsers);
  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectAdminUsersState);

  return (
    <div>
      <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Uzytkownicy: </h2>
      {isLoading && <LoadingAnimation color="primary" type="local" />}
      {failedLoading && (
        <ErrorMessage message={"Bład podczas pobierania"} error={error} />
      )}
      <p>
        {
          users.map(element=>{
            return <p style={{padding: '6px', fontSize: '16px'}}>
              <Link to={"/admin/users/"+element.Id}>
                <button style={{backgroundColor: element.Is_client_accepted?'transparent':'red'}}>
                  {element.Name + ' ' + element.Surname + ' - ' + element.Email
                  + (element.CompanyName!==''&&element.CompanyName!==null?` - ${element.CompanyName}`:'')}{element.Is_client_accepted?'':' - Niezaakceptowany'}
                </button>
              </Link>
            </p>
          })
        }
      </p>
    </div>
  )
}

export default Users;


import { SerializedError } from "@reduxjs/toolkit";

export type StateType = {
  isLoading: boolean;
  failedLoading: boolean;
  hasLoaded: boolean;
  error: SerializedError | null;
};

export const initialState: StateType = {
  isLoading: false,
  failedLoading: false,
  hasLoaded: false,
  error: null,
};

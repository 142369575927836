import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { useNavigate } from "react-router";
import LoadingAnimation from "../../../Common/LoadingAnimation/LoadingAnimation";
import Input from "../../../Common/Input/Input";
import ButtonPrimaryToLighter from "../../../Common/Buttons/ButtonPrimaryToLighter";
import { register, selectRegisterState } from "../../../Store/Slices/User/User";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";

export function isEmailValid(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function checkPasswordStrength(
  password: string,
  confirmPassword: string
): boolean {
  const minLength = 8;

  const upperCaseRegex = /[A-Z]/;
  const lowerCaseRegex = /[a-z]/;
  const digitsRegex = /[0-9]/;
  const specialCharsRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>?-]/;

  if (password.length < minLength) {
    return false;
  }

  if (!upperCaseRegex.test(password)) {
    return false;
  }

  if (!lowerCaseRegex.test(password)) {
    return false;
  }

  if (!digitsRegex.test(password)) {
    return false;
  }

  if (!specialCharsRegex.test(password)) {
    return false;
  }

  if (password !== confirmPassword) {
    return false;
  }

  return true;
}

function RegisterForm() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectRegisterState);

  useEffect(() => {
    if (hasLoaded) {
      navigate("success");
    }
  }, [hasLoaded, navigate]);

  function handleRegister(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email") as string;
    if (!isEmailValid(email)) {
      alert("Email jest niepoprawny");
      return;
    }
    const password = formData.get("password") as string;
    const confirmPassword = formData.get("confirm-password") as string;
    if (!checkPasswordStrength(password, confirmPassword)) {
      alert("Hasło jest niepoprawne");
      return;
    }
    const firstName = formData.get("name") as string;
    const lastName = formData.get("surname") as string;
    dispatch(
      register({
        email,
        password,
        Name: firstName,
        Surname: lastName,
        Phone_number: formData.get("phone_number") as string,
        CompanyRegister: formData.get("register_as_company") === "tak",
        CompanyName: formData.get("company_name") as string,
        CompanyAddress: formData.get("company_address") as string,
        CompanyCity: formData.get("company_city") as string,
        CompanyPostalCode: formData.get("company_postal_code") as string,
        CompanyNIP: formData.get("nip") as string,
      })
    );
  }

  const [showRegisterAsCompany, setShowRegisterAsCompany] = useState(false);

  return (
    <div className="flex flex-col">
      <form
        className="relative flex flex-col space-y-2"
        onSubmit={handleRegister}
        id="register-form"
      >
        {isLoading && <LoadingAnimation color="primary" />}
        {failedLoading && <p className="text-red-500">{error?.message}</p>}
        <Input
          label="name"
          type="text"
          name="Imię"
          placeholder="Jan"
          required={true}
        />
        <Input
          label="surname"
          type="text"
          name="Nazwisko"
          placeholder="Kowalski"
          required={true}
        />
        <Input
          label="email"
          type="email"
          name="Email"
          placeholder="jan@kowalski.com "
        />
        <Input
          label="phone_number"
          type="tel"
          name="Numer telefonu"
          placeholder="123456789"
        />
        <div className="flex flex-row items-center space-x-2">
          <input
            type="radio"
            name="register_as_company"
            id={"tak"}
            required={true}
            value={"tak"}
            className="w-5 h-5"
            onClick={() => {
              setShowRegisterAsCompany(true);
            }}
          />
          <label
            className="flex items-center space-x-1 cursor-pointer"
            htmlFor="tak"
          >
            <BusinessIcon />
            <span className="text-sm sm:text-xl font-semibold whitespace-nowrap">
              Zarejestruj jako firma
            </span>
          </label>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <input
            type="radio"
            name="register_as_company"
            id={"nie"}
            value={"nie"}
            required={true}
            className="w-5 h-5"
            onClick={() => {
              setShowRegisterAsCompany(false);
            }}
          />
          <label
            className="flex items-center space-x-1 cursor-pointer"
            htmlFor="nie"
          >
            <PersonIcon />
            <span className="text-sm sm:text-xl font-semibold whitespace-nowrap">
              Zarejestruj jako osoba prywatna
            </span>
          </label>
        </div>
        {showRegisterAsCompany && (
          <div className="w-full border-2 rounded-lg p-2">
            <h2 className="text-xl font-semibold">Dane firmy</h2>
            <Input
              label="company_name"
              type="text"
              name="Nazwa firmy"
              placeholder="Firma"
              required={true}
            />
            <Input
              label="company_address"
              type="text"
              name="Adres firmy"
              placeholder="ul. Klonowa 1"
              required={true}
            />
            <Input
              label="company_city"
              type="text"
              name="Miasto"
              placeholder="Warszawa"
              required={true}
            />
            <Input
              label="company_postal_code"
              type="text"
              name="Kod pocztowy"
              placeholder="00-000"
              required={true}
            />
            <Input
              label="nip"
              type="text"
              name="NIP"
              placeholder="1234567890"
              required={true}
            />
          </div>
        )}
        <Input
          label="password"
          type="password"
          name="Hasło"
          placeholder="********"
          required={true}
        />
        <Input
          label="confirm-password"
          type="password"
          name="Potwierdź hasło"
          placeholder="********"
          required={true}
        />

        <div className="flex flex-col justify-between space-y-1">
          <div className="flex flex-row items-center space-x-2">
            <input
              type="checkbox"
              name="terms"
              id="terms"
              className="rounded-md"
              required={true}
            />
            <label htmlFor="terms">
              * Przeczytałem i akceptuję{" "}
              <a href="/regulamin" className="underline hover:no-underline font-bold">
                Regulamin
              </a>
            </label>
          </div>
          {/* <div className="flex flex-row items-center space-x-2">
            <input
              type="checkbox"
              name="newsletter"
              id="newsletter"
              className="rounded-md"
            />
            <label htmlFor="newsletter">Zasubskrybuj newsletter</label>
          </div> */}
        </div>
        <ButtonPrimaryToLighter
          text="Załóż konto"
          size="medium"
          Icon={PersonAddIcon}
          isSubmit={true}
          form="register-form"
        />
      </form>
    </div>
  );
}

export default RegisterForm;

import React from "react";
import Map from "../Map/Map";
import CompanyInfo from "./CompanyInfo/CompanyInfo";

function Footer() {
  return (
    <footer className="mt-auto w-full text-lg overflow-hidden flex flex-col items-center">
      <div className="relative overflow-hidden w-full h-10 bg-primary" key={0}>
        <div className="absolute rotate-0 bg-neutral-50 w-full h-10 -top-5"></div>
      </div>
      <div className="relative overflow-hidden w-full h-10 bg-primary" key={1}>
        <div className="absolute rotate-0 bg-neutral-50 w-full h-10 -bottom-5"></div>
      </div>
      <div className="w-full max-w-[1536px] py-5 px-2 sm:px-5 space-y-5 flex flex-col items-center">
        <Map />
        <CompanyInfo />
        <div className="w-full flex flex-col items-center space-y-10">
          <div className="w-full flex flex-col items-center text-xs sm:text-base space-y-2">
            <h2 className="text-2xl -xl:text-3xl font-bold">
              Press Bar Sp. z o.o.
            </h2>
            <p>NIP: 573-29-40-254 REGON: 523960502</p>
            <p>Numer konta: PKO 44 1020 1664 0000 3502 0690 1260</p>
            <p>
              Sąd Rejonowy w Częstochowie XVII Wydział Gospodarczy KRS
              0001007705
            </p>
            <p>Nr BDO: 000602964</p>
            <p>Kapitał zakładowy: 50.000,00 zł.</p>
          </div>
          <div className="w-full flex flex-col-reverse items-center sm:flex-row justify-evenly text-sm sm:text-base">
            <p>© 2024 Press Bar Sp. z o. o. Wszelkie prawa zastrzeżone.</p>
            <a href="/polityka-prywatnosci" className="underline">Polityka prywatności</a>
            <a href="/warunki-handlowe" className="underline">Ogólne warunki handlowe</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

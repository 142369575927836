import { motion } from "framer-motion";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import CategoryDiscount from "./CategoryDiscount";
import ProductDiscount from "./ProductDiscount";
import ProductIndPrice from "./ProductIndPrice";
import CompanyDiscount from "./CompanyDiscount";

export default function Prices() {
  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="text-3xl font-bold mt-10">Ceny indywidualne</h2>
      <div className="flex items-center w-full justify-evenly my-8">
        {/*<Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="category-discount"
        >
          Rabaty dla klienta na kategorie
        </Link>
        <Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="product-discount"
        >
          Rabaty dla klienta na produkt
        </Link>
        <Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="product-price"
        >
          Cena indywidualna
        </Link>*/}
        <Link
          className="hover:bg-neutral-200 p-2 rounded-lg font-semibold border-2"
          to="company-discount"
        >
          Rabaty dla firm
        </Link>
      </div>
      <Routes>
        <Route path="category-discount" element={<CategoryDiscount />} />
        <Route path="product-discount" element={<ProductDiscount />} />
        <Route path="product-price" element={<ProductIndPrice />} />
        <Route path="company-discount" element={<CompanyDiscount />} />
      </Routes>
    </motion.div>
  );
}

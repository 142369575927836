import React from "react";
import { SerializedError } from "@reduxjs/toolkit";
import ErrorIcon from "@mui/icons-material/Error";

type Props = {
  error?: SerializedError | null;
  message: string;
  size?: "small" | "medium" | "large";
};

function ErrorMessage({ error, message, size = "medium" }: Props) {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center space-y-2 p-2">
      <ErrorIcon fontSize="large" />
      <h1
        className={`${size === "small" ? "text-2xl" : ""} ${
          size === "medium" ? "text-3xl" : ""
        } ${
          size === "large" ? "text-4xl" : ""
        } font-bold text-neutral-800 text-center`}
      >
        {message}
      </h1>
      <p
        className={`${size === "small" ? "text-sm" : ""} ${
          size === "medium" ? "text-lg" : ""
        } ${size === "large" ? "text-xl" : ""} text-neutral-600 text-center`}
      >
        {error?.message || "Unknown error"}
      </p>
    </div>
  );
}

export default ErrorMessage;

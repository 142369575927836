import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { DeliveryType, OrderType, PaymentType } from "../../../Models/Order";
import { StateType, initialState } from "../../../Models/State";
import { set } from "lodash";

type orderSliceState = {
  stage: number;
  inSync: boolean;
  orderId: string;
  createdOrder: boolean;
  chosenAddressId: number | null;
  paymentUrl: string | null;
  discount: number;
  discount_code:string;
  priceDetails: {
    shipping: number;
    payment: number;
  };
  shippingDetails: {
    deliveryTypes: DeliveryType[];
    chosenDeliveryType: DeliveryType | null;
  };
  deliveryTypesState: StateType;
  createOrderState: StateType;
  fetchPaymentTypesState: StateType;
  fetchAddressState: StateType;
  fetchPaymentURLState: StateType;
  paymentTypes: PaymentType[];
  orderData: OrderType;
  deliveryTimes: number;
  deliveryTimesState: StateType;
  priceFirstTime: number,
  priceSecondTime: number,
  chosenDeliveryTime: number,
};

const initialSliceState: orderSliceState = {
  stage: 1,
  createdOrder: false,
  chosenAddressId: null,
  paymentUrl: null,
  inSync: false,
  orderId: "",
  discount_code: '',
  discount: 0,
  priceDetails: {
    shipping: 0,
    payment: 0,
  },
  shippingDetails: {
    deliveryTypes: [],
    chosenDeliveryType: null,
  },
  paymentTypes: [],
  deliveryTypesState: initialState,
  createOrderState: initialState,
  fetchAddressState: initialState,
  fetchPaymentTypesState: initialState,
  fetchPaymentURLState: initialState,
  orderData: {
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    products: [],
    delivery_type: -1,
    pay_type: -1,
    CompanyName: "",
    CompanyAddress: "",
    CompanyCity: "",
    CompanyPostalCode: "",
    CompanyNIP: "",
    chosenDeliveryTime: 1
  },
  deliveryTimes: 1,
  deliveryTimesState: initialState,
  priceFirstTime: 0,
  priceSecondTime: 0,
  chosenDeliveryTime: 1
};

export const getDiscount = createAsyncThunk(
  'order/getDiscount',
  async(code: string)=>{
    try{
      const discount = (await axios.get<{discount: number}>(`${server}/api/Products/discount?code=${code}`,
      {withCredentials: true})).data.discount;
      return {discount, code};
    }
    catch(e){
      throw e;
    }
  }
)

export const fetchPAymentUrl = createAsyncThunk(
  "order/fetchPaymentUrl",
  async function (orderId: string) {
    try {
      const response = await axios.get(
        `${server}/api/Orders/payment-url/${orderId}`,
        {
          withCredentials: true,
        }
      );      
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPaymentTypes = createAsyncThunk(
  "order/fetchPaymentTypes",
  async function (price: number) {
    try {
      const response = await axios.get(`${server}/api/Orders/payments`, {
        params: {
          price: price,
        },
        withCredentials: true,
      });
      return response.data as PaymentType[];
    } catch (error) {
      throw error;
    }
  }
);

export const getDeliveryTime = createAsyncThunk(
  'order/getDeliveryTime',
  async function ({online}:{online: boolean}){
    if(online){
      const result = await axios.get<{deliveries: number, priceFirstTime:number, priceSecondTime: number}>
      (`${server}/api/Carts/cart/delivery-time`,
      {withCredentials: true});
      return result.data;
    }
    else{
      return {
        deliveries:1,
        priceFirstTime:-1,
        priceSecondTime:-1
      };
    }
  }
)

export const createOrderLogged = createAsyncThunk(
  "order/createOrderLogged",
  async function ({
    name,
    surname,
    email,
    phoneNumber,
    address,
    city,
    zipCode,
    comments,
    delivery_type,
    discount_code,
    products,
    pay_type,
    CompanyAddress,
    CompanyName,
    CompanyCity,
    CompanyNIP,
    CompanyPostalCode,
    chosenDeliveryTime
  }: OrderType) {
    try {
      const response = await axios.post(
        `${server}/api/Orders/add`,
        {
          Name: name + " " + surname,
          Email: email,
          Phone_number: phoneNumber,
          Address: address,
          City: city,
          Zip_code: zipCode,
          Comments: comments,
          Delivery_type: delivery_type,
          Discount_code: discount_code,
          products: products,
          Pay_type: pay_type,
          CompanyName: CompanyName,
          CompanyAddress: CompanyAddress,
          CompanyCity: CompanyCity,
          CompanyPostalCode: CompanyPostalCode,
          CompanyNIP: CompanyNIP,
          chosenDeliveryTime: chosenDeliveryTime
        },
        { withCredentials: true }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const createOrderNotLogged = createAsyncThunk(
  "order/createOrderNotLogged",
  async function ({
    name,
    surname,
    email,
    phoneNumber,
    address,
    city,
    zipCode,
    comments,
    delivery_type,
    discount_code,
    products,
    pay_type,
    CompanyAddress,
    CompanyName,
    CompanyCity,
    CompanyNIP,
    CompanyPostalCode,
  }: OrderType) {
    try {
      const response = await axios.post(`${server}/api/Orders/add/notlogged`, {
        name: name + " " + surname,
        email: email,
        phoneNumber: phoneNumber,
        address: address,
        city: city,
        zipCode: zipCode,
        comments: comments,
        delivery_type: delivery_type,
        discount_code: discount_code,
        products: products,
        pay_type: pay_type,
        CompanyName: CompanyName,
        CompanyAddress: CompanyAddress,
        CompanyCity: CompanyCity,
        CompanyPostalCode: CompanyPostalCode,
        CompanyNIP: CompanyNIP
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDeliveryTypes = createAsyncThunk(
  "order/fetchDeliveryTypes",
  async function () {
    try {
      const response = await axios.get(`${server}/api/Orders/deliveries`, {
        withCredentials: true,
      });
      return response.data as DeliveryType[];
    } catch (error) {
      throw error;
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: initialSliceState,
  reducers: {
    initializeOrder: (state) => {
      state = initialSliceState;
    },
    setDeliveryType: (
      state,
      action: { payload: { deliveryType: number; price: number } }
    ) => {
      state.shippingDetails.chosenDeliveryType =
        state.shippingDetails.deliveryTypes.find(
          (deliveryType) => deliveryType.Id === action.payload.deliveryType
        ) ?? null;
      state.priceDetails.shipping =
        parseFloat(state.shippingDetails.chosenDeliveryType?.Price || "0") ?? 0;
      if (
        state.shippingDetails.chosenDeliveryType?.Id === 1 &&
        action.payload.price >= 500
      ) {
        state.priceDetails.shipping = 0;
      }
    },
    setStage: (state, action) => {
      if (state.inSync === false) {
        if (action.payload < 1) {
          state.stage = 1;
        } else {
          state.stage = action.payload;
        }
      }
    },
    setAddressId: (state, action: { payload: number }) => {
      state.chosenAddressId = action.payload;
    },
    setOrderDataStageTwo: (state, action: { payload: OrderType }) => {
      state.orderData = action.payload;
      state.stage = 3;
    },
    setOrderPayTypeStageThree: (state, action: { payload: number }) => {
      state.orderData.pay_type = action.payload;
    },
    setOrderAddress: (
      state,
      action: { payload: { address: string; zipCode: string; city: string } }
    ) => {
      state.orderData.address = action.payload.address;
      state.orderData.city = action.payload.city;
      state.orderData.zipCode = action.payload.zipCode;
    },
    setPersonalInformation: (
      state,
      action: {
        payload: {
          name: string;
          surname: string;
          email: string;
          phoneNumber: string;
        };
      }
    ) => {
      state.orderData.name = action.payload.name ?? "";
      state.orderData.surname = action.payload.surname ?? "";
      state.orderData.phoneNumber = action.payload.phoneNumber;
      state.orderData.email = action.payload.email;
    },
    setCompanyInfo: (
      state,
      action: {
        payload: {
          companyName: string;
          companyAddress: string;
          companyCity: string;
          companyPostalCode: string;
          companyNIP: string;
        };
      }
    ) => {
      state.orderData.CompanyName = action.payload.companyName;
      state.orderData.CompanyAddress = action.payload.companyAddress;
      state.orderData.CompanyCity = action.payload.companyCity;
      state.orderData.CompanyPostalCode = action.payload.companyPostalCode;
      state.orderData.CompanyNIP = action.payload.companyNIP;
    },
    setPersInfName: (state, action: { payload: string }) => {
      state.orderData.name = action.payload;
    },
    setPersInfSurname: (state, action: { payload: string }) => {
      state.orderData.surname = action.payload;
    },
    setPersInfEmail: (state, action: { payload: string }) => {
      state.orderData.email = action.payload;
    },
    setPersInfPhoneNumber: (state, action: { payload: string }) => {
      state.orderData.phoneNumber = action.payload;
    },
    setChosenDeliveryTime: (state, action: {payload: number})=>{
      state.chosenDeliveryTime=action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeliveryTypes.pending, (state, action) => {
      state.deliveryTypesState.isLoading = true;
      state.deliveryTypesState.hasLoaded = false;
      state.deliveryTypesState.failedLoading = false;
      state.deliveryTypesState.error = null;
    });
    builder.addCase(fetchDeliveryTypes.fulfilled, (state, action) => {
      state.deliveryTypesState.isLoading = false;
      state.deliveryTypesState.hasLoaded = true;
      state.deliveryTypesState.failedLoading = false;
      state.deliveryTypesState.error = null;
      state.shippingDetails.deliveryTypes = action.payload;
    });
    builder.addCase(fetchDeliveryTypes.rejected, (state, action) => {
      state.deliveryTypesState.isLoading = false;
      state.deliveryTypesState.hasLoaded = false;
      state.deliveryTypesState.failedLoading = true;
      state.deliveryTypesState.error = action.error;
    });
    builder.addCase(createOrderNotLogged.pending, (state, action) => {
      state.createOrderState.isLoading = true;
      state.createOrderState.hasLoaded = false;
      state.createOrderState.failedLoading = false;
      state.createOrderState.error = null;
    });
    builder.addCase(createOrderNotLogged.fulfilled, (state, action) => {
      state.createOrderState.isLoading = false;
      state.createOrderState.hasLoaded = true;
      state.createOrderState.failedLoading = false;
      state.createOrderState.error = null;
      state.inSync = true;
      state.createdOrder = true;
      state.orderId = action.payload.data.orderId;
      state.paymentUrl = action.payload.data;
      state.discount=0;
      state.discount_code='';
    });
    builder.addCase(createOrderNotLogged.rejected, (state, action) => {
      state.createOrderState.isLoading = false;
      state.createOrderState.hasLoaded = false;
      state.createOrderState.failedLoading = true;
      state.createOrderState.error = action.error;
    });
    builder.addCase(createOrderLogged.pending, (state, action) => {
      state.createOrderState.isLoading = true;
      state.createOrderState.hasLoaded = false;
      state.createOrderState.failedLoading = false;
      state.createOrderState.error = null;
    });
    builder.addCase(createOrderLogged.fulfilled, (state, action) => {
      state.createOrderState.isLoading = false;
      state.createOrderState.hasLoaded = true;
      state.createOrderState.failedLoading = false;
      state.createOrderState.error = null;
      state.inSync = true;
      state.createdOrder = true;
      state.orderId = action.payload.data.orderId;
      state.paymentUrl = action.payload.data;
      state.discount=0;
      state.discount_code='';
    });
    builder.addCase(createOrderLogged.rejected, (state, action) => {
      state.createOrderState.isLoading = false;
      state.createOrderState.hasLoaded = false;
      state.createOrderState.failedLoading = true;
      state.createOrderState.error = action.error;
    });
    builder.addCase(fetchPaymentTypes.pending, (state, action) => {
      state.fetchPaymentTypesState.isLoading = true;
      state.fetchPaymentTypesState.hasLoaded = false;
      state.fetchPaymentTypesState.failedLoading = false;
      state.fetchPaymentTypesState.error = null;
    });
    builder.addCase(fetchPaymentTypes.fulfilled, (state, action) => {
      state.fetchPaymentTypesState.isLoading = false;
      state.fetchPaymentTypesState.hasLoaded = true;
      state.fetchPaymentTypesState.failedLoading = false;
      state.fetchPaymentTypesState.error = null;
      state.paymentTypes = action.payload;
    });
    builder.addCase(fetchPaymentTypes.rejected, (state, action) => {
      state.fetchPaymentTypesState.isLoading = false;
      state.fetchPaymentTypesState.hasLoaded = false;
      state.fetchPaymentTypesState.failedLoading = true;
      state.fetchPaymentTypesState.error = action.error;
    });
    builder.addCase(fetchPAymentUrl.pending, (state, action) => {
      state.fetchPaymentURLState.isLoading = true;
      state.fetchPaymentURLState.hasLoaded = false;
      state.fetchPaymentURLState.failedLoading = false;
      state.createOrderState.error = null;
    });
    builder.addCase(fetchPAymentUrl.fulfilled, (state, action) => {
      state.fetchPaymentURLState.isLoading = false;
      state.fetchPaymentURLState.hasLoaded = true;
      state.fetchPaymentURLState.failedLoading = false;
      state.fetchPaymentURLState.error = null;
      state.paymentUrl = action.payload[0].Url;
    });
    builder.addCase(fetchPAymentUrl.rejected, (state, action) => {
      state.fetchPaymentURLState.isLoading = false;
      state.fetchPaymentURLState.hasLoaded = false;
      state.fetchPaymentURLState.failedLoading = true;
      state.createOrderState.error = action.error;
    });
    builder.addCase(getDiscount.pending, (state, action) => {
      //state.fetchPaymentURLState.isLoading = true;
      //state.fetchPaymentURLState.hasLoaded = false;
      //state.fetchPaymentURLState.failedLoading = false;
      //state.createOrderState.error = null;
    });
    builder.addCase(getDiscount.fulfilled, (state, action) => {
      //state.fetchPaymentURLState.isLoading = false;
      //state.fetchPaymentURLState.hasLoaded = true;
      //state.fetchPaymentURLState.failedLoading = false;
      //state.fetchPaymentURLState.error = null;
      state.discount = action.payload.discount;
      state.discount_code = action.payload.code;
    });
    builder.addCase(getDiscount.rejected, (state, action) => {
      //state.fetchPaymentURLState.isLoading = false;
      //state.fetchPaymentURLState.hasLoaded = false;
      //state.fetchPaymentURLState.failedLoading = true;
      //state.createOrderState.error = action.error;
    });
    builder.addCase(getDeliveryTime.pending, (state, action) => {
      state.deliveryTimesState.isLoading = true;
      state.deliveryTimesState.hasLoaded = false;
      state.deliveryTimesState.failedLoading = false;
      state.deliveryTimesState.error = null;
    });
    builder.addCase(getDeliveryTime.fulfilled, (state, action) => {
      state.deliveryTimesState.isLoading = false;
      state.deliveryTimesState.hasLoaded = true;
      state.deliveryTimesState.failedLoading = false;
      state.deliveryTimesState.error = null;
      if(action.payload.deliveries==-1){
        state.deliveryTimes=1;
        state.priceFirstTime=0;
        state.priceSecondTime=0;
      }
      else{
        state.deliveryTimes=action.payload.deliveries;
        state.priceFirstTime=action.payload.priceFirstTime;
        state.priceSecondTime=action.payload.priceSecondTime;
      }
    });
    builder.addCase(getDeliveryTime.rejected, (state, action) => {
      state.deliveryTimesState.isLoading = false;
      state.deliveryTimesState.hasLoaded = false;
      state.deliveryTimesState.failedLoading = true;
      state.deliveryTimesState.error = action.error;
    });
  },
});

export const selectPriceDetails = (state: { order: orderSliceState }) => {
  return state.order.priceDetails;
};

export const selectStage = (state: { order: orderSliceState }): number => {
  return state.order.stage;
};

export const selectDeliveryTypes = (state: {
  order: orderSliceState;
}): DeliveryType[] => {
  return state.order.shippingDetails.deliveryTypes;
};

export const selectDeliveryTypesState = (state: {
  order: orderSliceState;
}): StateType => {
  return state.order.deliveryTypesState;
};

export const selectChosenDeliveryType = (state: {
  order: orderSliceState;
}): DeliveryType | null => {
  return state.order.shippingDetails.chosenDeliveryType;
};

export const selectCreateOrderState = (state: {
  order: orderSliceState;
}): StateType => {
  return state.order.createOrderState;
};

export const selectChosenAddressId = (state: {
  order: orderSliceState;
}): number | null => {
  return state.order.chosenAddressId;
};

export const selectPaymentTypes = (state: {
  order: orderSliceState;
}): PaymentType[] => {
  return state.order.paymentTypes;
};

export const selectFetchPaymentTypesState = (state: {
  order: orderSliceState;
}): StateType => {
  return state.order.fetchPaymentTypesState;
};

export const selectOrderData = (state: {
  order: orderSliceState;
}): OrderType => {
  return state.order.orderData;
};

export const selectOrderAddress = (state: {
  order: orderSliceState;
}): { address: string; city: string; zipCode: string } => {
  return {
    address: state.order.orderData.address ?? "",
    city: state.order.orderData.city ?? "",
    zipCode: state.order.orderData.zipCode ?? "",
  };
};

export const selectPersonalInformation = (state: {
  order: orderSliceState;
}): { name: string; surname: string; email: string; phoneNumber: string } => {
  return {
    name: state.order.orderData.name,
    surname: state.order.orderData.surname,
    email: state.order.orderData.email,
    phoneNumber: state.order.orderData.phoneNumber,
  };
};

export const selectCompanyInfo = (state: {
  order: orderSliceState;
}): {
  companyName: string;
  companyAddress: string;
  companyCity: string;
  companyPostalCode: string;
  companyNIP: string;
} => {
  return {
    companyName: state.order.orderData.CompanyName,
    companyAddress: state.order.orderData.CompanyAddress,
    companyCity: state.order.orderData.CompanyCity,
    companyPostalCode: state.order.orderData.CompanyPostalCode,
    companyNIP: state.order.orderData.CompanyNIP,
  };
};

export const selectOrderId = (state: { order: orderSliceState }): string => {
  return state.order.orderId;
};

export const hasCreatedOrder = (state: { order: orderSliceState }): boolean => {
  return state.order.createdOrder;
};

export const selectPaymentURL = (state: { order: orderSliceState }): string => {
  return state.order.paymentUrl ?? "";
};

export const selcetFetchPaymentURLState = (state: {
  order: orderSliceState;
}): StateType => {
  return state.order.fetchPaymentURLState;
};

export const selectDiscount = (state:{
  order: orderSliceState;
}):number=>{
  return state.order.discount;
}

export const selectDiscountCode = (state:{
  order: orderSliceState;
}):string=>{
  return state.order.discount_code;
}

export const selectDeliveryTimesState =(state:{
  order: orderSliceState;
}):StateType=>{
  return state.order.deliveryTimesState;
}

export const selectDeliveryTimes = (state:{
  order: orderSliceState;
}):{deliveries: number, priceFirstDelivery: number, priceSecondDelivery: number}=>{
  return {deliveries: state.order.deliveryTimes, priceFirstDelivery: state.order.priceFirstTime,
  priceSecondDelivery: state.order.priceSecondTime};
}

export const selectChosenDeliveryTime = (state:{
  order: orderSliceState
}):number=>{
  return state.order.chosenDeliveryTime;
}

export const {
  setDeliveryType,
  setStage,
  setAddressId,
  setOrderDataStageTwo,
  setOrderPayTypeStageThree,
  setOrderAddress,
  setPersonalInformation,
  setPersInfName,
  setPersInfSurname,
  setPersInfEmail,
  setPersInfPhoneNumber,
  initializeOrder,
  setCompanyInfo,
  setChosenDeliveryTime
} = orderSlice.actions;

export default orderSlice.reducer;

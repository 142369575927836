import React, { useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { CategoryModel } from "../../../Models/Category";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  category: CategoryModel;
  setCurrentCategoryId: ActionCreatorWithPayload<any, any>;
  admin?: boolean;
  mobile?: boolean;
};

function CategoryList({
  category,
  setCurrentCategoryId,
  admin,
  mobile,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const [, setSearchParams] = useSearchParams({
    category: "-1",
  });

  const [showImage, setShowImage] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    setShowImage(true);
  };

  const handleMouseLeave = () => {
    setShowImage(false);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (tooltipRef.current) {
      const { clientX, clientY } = event;
      const tooltipWidth = tooltipRef.current.clientWidth;
      const tooltipHeight = tooltipRef.current.clientHeight;

      setTooltipPosition({
        x: clientX - tooltipWidth / 2,
        y: clientY - tooltipHeight - 10,
      });
    }
  };

  return (
    <div
      className="group flex flex-col items-center justify-start min-h-40 p-1 cursor-pointer"
      onClick={() => {
        dispatch(setCurrentCategoryId(category.Id));

        if (
          location.pathname.startsWith("/search") ||
          (admin && location.pathname.startsWith("/admin/products"))
        ) {
          if (!mobile) {
            setSearchParams((prev) => {
              prev.set("category", category.Id.toString());
              return prev;
            });
          }
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <div className="h-35 w-25 bg-center bg-cover shadow-sm bg-neutral-100 bg-opacity-40 backdrop-blur-md">
        <div
          ref={tooltipRef}
          style={{
            zIndex: 9999,
            backgroundImage: `url(${category.Image_url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "white",
            width: "10rem",
            height: "8rem",
          }}
          className="w-40 h-40 group-hover:shadow-lg bg-opacity-0 backdrop-blur-md transition-all"
        ></div>
      </div>
      <span className="text-center w-44 mt-2 flex-grow font-bold">
        {category.Name}
      </span>
      {category.Has_children ? false && <ChevronRightIcon /> : <span></span>}
    </div>
  );
}

export default CategoryList;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";
import ListItem from "./ListItem/ListItem";
import ButtonNeutral50 from "../../../Common/Buttons/ButtonNeutral50";
import { WishlistName } from "../../../Models/Wishlist";
import { AnimatePresence } from "framer-motion";
import AddNewListForm from "./AddNewListForm";
import AddIcon from "@mui/icons-material/Add";

function WishlistControls({ lists }: { lists: WishlistName[] }) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [showEdit, setShowEdit] = useState(false);

  return (
    <div className="sticky top-28 flex flex-col justify-between overflow-hidden w-full xl:w-1/4 h-fit bg-neutral-100 rounded-lg shadow-md">
      <div className="w-full p-2">
        <div className="w-full rounded-t-lg p-2 flex justify-between">
          <div className="">
            <h2 className="text-lg font-bold">Twoje listy ({lists.length})</h2>
          </div>
        </div>
        <div className="w-full rounded-b-lg p-1 flex flex-col space-y-2">
          <div className="max-h-44 overflow-y-scroll">
            {lists.map((element) => {
              return (
                <ListItem
                  name={element.Name}
                  key={element.Id}
                  id={element.Id}
                />
              );
            })}
          </div>
          <div className="flex items-center justify-end">
            <ButtonNeutral50
              text={"Utwórz listę"}
              size={"small"}
              Icon={AddIcon}
              onClick={() => {
                setShowEdit(true);
              }}
            />
          </div>
        </div>
      </div>
      {!isLoggedIn && (
        <div className="w-full h-full border-t-2 p-2">
          <h1 className="text-base font-bold">
            Zaloguj się aby zapisać swoją listę życzń oraz tworzyć nowe listy.
          </h1>
        </div>
      )}
      <AnimatePresence mode="wait">
        {showEdit && <AddNewListForm setShowEdit={setShowEdit} />}
      </AnimatePresence>
    </div>
  );
}

export default WishlistControls;

import { on } from "events";
import React from "react";

type Props = {
  label: string;
  type: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  style?: string;
  value?: string | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  minValue?: number;
  maxValue?: number;
  size?: "sm" | "md" | "lg";
};

function Input({
  label,
  type,
  name,
  placeholder = "",
  required = false,
  style = "",
  value = undefined,
  onChange = undefined,
  minValue,
  maxValue,
  size = "md",
}: Props) {
  if (type === "password") {
    return (
      <div className="w-full">
        <label
          className="text-xl text-neutral-950 whitespace-nowrap"
          htmlFor={label}
        >
          {name}
        </label>
        <input
          id={label}
          type="password"
          name={label}
          required={required}
          placeholder={placeholder}
          pattern="^(?=.*([A-Z].*[a-z].*[\d+_%@!$*~-]|\d.*[a-z+_%@!$*~-]|[+_%@!$*~-].*[a-z\d])|[a-z].*([A-Z].*[\d+_%@!$*~-]|\d.*[A-Z+_%@!$*~-]|[+_%@!$*~-].*[A-Z\d])|\d.*([A-Z].*[a-z+_%@!$*~-]|[a-z].*[A-Z+_%@!$*~-]|[+_%@!$*~-].*[A-Za-z])|[+_%@!$*~-].*([A-Z].*[a-z\d]|[a-z].*[A-Z\d]|\d.*[A-Za-z])))(?!.*{{escapeRegExp(username)}})[\w+_%@!$*~]+$"
          minLength={8}
          className={
            `${size === "sm" ? "p-2" : ""} ${size === "md" ? "p-3" : ""} ${
              size === "lg" ? "p-4" : ""
            } appearance-none block w-full text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all ` +
            style
          }
        />
        <p className="text-sm text-neutral-950">
          Hasło musi zawierać minimum 8 znaków, jedną dużą literę, jedną małą
          literę, jedną cyfrę oraz jeden znak specjalny.
        </p>
      </div>
    );
  } else if (type === "textarea") {
    return (
      <div className="w-full">
        <label
          className="text-xl text-neutral-950 whitespace-nowrap"
          htmlFor={label}
        >
          {name}
        </label>
        <textarea
          id={label}
          name={label}
          required={required}
          placeholder={placeholder}
          maxLength={400}
          className={
            `${size === "sm" ? "p-2" : ""} ${size === "md" ? "p-3" : ""} ${
              size === "lg" ? "p-4" : ""
            } appearance-none block w-full text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all ` +
            style
          }
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <label
        className="text-xl text-neutral-950 whitespace-nowrap"
        htmlFor={label}
      >
        {name}
      </label>
      <input
        id={label}
        type={type}
        name={label}
        required={required}
        placeholder={placeholder}
        value={value!}
        onChange={onChange!}
        min={minValue!}
        max={maxValue!}
        className={
          `${size === "sm" ? "p-2" : ""} ${size === "md" ? "p-3" : ""} ${
            size === "lg" ? "p-4" : ""
          } appearance-none block w-full text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all ` +
          style
        }
      />
    </div>
  );
}

export default Input;

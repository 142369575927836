import { motion } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import ActuatorsCategories from "./Categories/ActuatorsCategories";
import ActuatorConfig from "./Actuator/ActuatorConfig";

export default function Actuators() {
  return (
    <motion.div
      className="flex flex-col px-2 sm:px-5 justify-center items-center w-full max-w-[1536px] space-y-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Routes>
        <Route path="/" element={<ActuatorsCategories />} />
        <Route path="/:id" element={<ActuatorConfig />} />
      </Routes>
    </motion.div>
  );
}

import React, { useEffect, useState } from "react";
import SearchBar from "../../SearchBar/SearchBar";
import { addPaymentToCompany, deleteCompanyPayment, fetchCompaniesNip, fetchCompanyPayments, fetchPayments, selectCompanies, selectCompanyPayments, selectPayments } from "../../../../Store/Slices/admin/Products/IndDiscounts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import { fetchPaymentTypes } from "../../../../Store/Slices/Order/Order";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";

type Props = {};

function Filters({}: Props) {

  const dispatch = useDispatch<AppDispatch>();
  const companies = useSelector(selectCompanies);
  const [chosenCompany, setChosenCompany] = useState(-1);

  const payments = useSelector(selectPayments)
  const companyPayments = useSelector(selectCompanyPayments)

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [paymentToDelete, setPaymentToDelete] = useState(-1);

  useEffect(()=>{
    dispatch(fetchCompanyPayments(chosenCompany));
    dispatch(fetchPayments());
  }, [chosenCompany])

  return <div>
    {deleteConfirm&&<Confirm
    handleClose={()=>{
      setDeleteConfirm(false);
    }}
    handleConfirm={()=>{
      dispatch(deleteCompanyPayment({companyId: chosenCompany, paymentId: paymentToDelete}));
      setDeleteConfirm(false);
    }}
    text="Czy potwierdzasz usunięcie tej opcji płatności dla klienta"
    />}
    <p>Płatności:</p>
    <form
          onSubmit={(e) => {
            e.preventDefault();
            const nip = (
              document.getElementById("company_nip") as HTMLInputElement
            ).value;
            dispatch(
              fetchCompaniesNip({
                nip: nip,
              })
            );
          }}
          className="flex"
        >
          <SearchBar placeholder="NIP firmy" id="company_nip" />
          <button className="border-2 p-2" type="submit">
            Szukaj
          </button>
        </form>
        <div className="w-full flex flex-col max-h-96 overflow-scroll">
          {companies &&
            companies.map((company) => (
              <button
                key={company.Id}
                className={`p-2 ${
                  company.Id === chosenCompany
                    ? "bg-primary text-white"
                    : "hover:bg-primary hover:text-white"
                }`}
                onClick={() => setChosenCompany(company.Id)}
              >
                {company.Name}
              </button>
            ))}
        </div>
        <div>
          {chosenCompany!==-1&&companyPayments&&companyPayments.map(element=>{
            return(
              <p>
                {element.Name} <button onClick={()=>{
                  setPaymentToDelete(element.Payment_id);
                  setDeleteConfirm(true);
                }}>Usuń</button>
              </p>
            )
          })}
        </div>
        <p>Nie dodawaj płatności, która juz jest dodana, nie dubluj tych wartości</p>
        <div>
          {
            chosenCompany!==-1&&payments&&payments.map(element=>{
              return(
                <p>{element.Name} <button onClick={()=>{
                  dispatch(addPaymentToCompany({companyId: chosenCompany, paymentId: element.Id, name: element.Name}));
                }}>Dodaj</button></p>
              )
            })
          }
        </div>

  </div>;
}

export default Filters;

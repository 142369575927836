import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectProducts } from "../../../../../../Store/Slices/Cart/Cart";
import ProductList from "../../../../../../Common/ProductList/ProductList";
import { useNavigate } from "react-router-dom";

function Products() {
  const products = useSelector(selectProducts);

  const navigate = useNavigate();

  useEffect(() => {
    if (products.length === 0) {
      navigate("/cart");
    }
  });

  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold">Produkty:</h1>
      <div className="w-full flex flex-col max-h-40 overflow-y-scroll space-y-1 pb-2">
        {products.map((product) => (
          <ProductList
            key={product.product.Id}
            product={product.product}
            productQuantity={product.quantity}
            showAddToCart={false}
            showAddToList={false}
            showMoreInfo={false}
            showPriceQuantity={true}
            showFullNameOnHover={false}
            showFullName={true}
          />
        ))}
      </div>
    </div>
  );
}

export default Products;

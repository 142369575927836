import React from "react";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ButtonPrimaryToLighter from "../../../Common/Buttons/ButtonPrimaryToLighter";
import LoginIcon from "@mui/icons-material/Login";

function KonfiguratorLinki() {
  return (
    <div className="w-full rounded-lg overflow-hidden">
      {/* {<Link
        className="w-full flex flex-col items-center group cursor-pointer hover:brightness-110 bg-neutral-50"
        to={"/silowniki"}
      >
        <div className="flex p-4 justify-center items-center space-x-2">
          <h2 className="text-2xl font-semibold font-display">Konfigurator</h2>
          <SettingsOutlinedIcon />
        </div>
        <p className="text-base text-center">Znajdź swój siłownik</p>
      </Link>} */}
      <div className="flex flex-col divide-y-2 p-2">
        <div className="w-full">
          <a
            className="font-semibold hover:bg-neutral-100 rounded-lg p-2 w-full flex items-center space-x-2"
            href={"/article"}
          >
            <LaunchIcon color="secondary" />
            <span className="text-base">Artykuły</span>
          </a>
        </div>
        <div className="w-full">
          <a
            className="font-semibold hover:bg-neutral-100 rounded-lg p-2 w-full flex items-center space-x-2"
            href={"/uslugi/instalacje"}
          >
            <LaunchIcon color="secondary" />
            <span className="text-base">Instalacje pneumatyczne</span>
          </a>
        </div>
        <div className="w-full">
          <a
            className="font-semibold hover:bg-neutral-100 rounded-lg p-2 w-full flex items-center space-x-2"
            href={"/uslugi/sprezarkownie"}
          >
            <LaunchIcon color="secondary" />
            <span className="text-base">Sprężarkownie</span>
          </a>
        </div>
        <div className="w-full">
          <a
            className="font-semibold hover:bg-neutral-100 rounded-lg p-2 w-full flex items-center space-x-2"
            href={"/uslugi/utrzymanie_ruchu"}
          >
            <LaunchIcon color="secondary" />
            <span className="text-base">Utrzymanie ruchu</span>
          </a>
        </div>
      </div>
      <div className="w-full flex flex-col space-y-2 items-center group">
        <ButtonPrimaryToLighter
          text={"Zarejestruj się"}
          size={"medium"}
          customClasses="w-full"
          Icon={LoginIcon}
          link={"/register"}
        />
        <p className="text-sm text-center">
          Zarejestruj się i korzystaj z własnych warunków handlowych
        </p>
      </div>
    </div>
  );
}

export default KonfiguratorLinki;

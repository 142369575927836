import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import "path-browserify";
import { server } from "../../../server";
import Cookies from "js-cookie";
import {
  TokenType,
  UserIdRoleAccepted,
  UserLoginResponse,
  UserRegisterResponse,
} from "../../../Models/User";
import { StateType, initialState } from "../../../Models/State";
import jwtDecode from "jwt-decode";
import { Address, AddressRequest } from "../../../Models/Address";
import { OrderData } from "../../../Models/Order";


type usersSliceState = {
  loginState: StateType;
  registerState: StateType;
  confirmState: StateType;
  addresses: AddressRequest[];
  orders: OrderData[];
  order: OrderData;
  fetchAddressesState: StateType;
  deleteAddressState: StateType;
  addAddressState: StateType;
  fetchUserOrdersState: StateType;
  fetchOrderDetailsState: StateType;
  resetPasswordState: StateType;
  company: Company;
  companyState: StateType;
  coworkers: User[];
};

type RegisterData = {
  email: string;
  password: string;
  Name: string;
  Surname: string;
  Phone_number: string;
  CompanyRegister: boolean;
  CompanyName?: string;
  CompanyAddress?: string;
  CompanyPostalCode?: string;
  CompanyCity?: string;
  CompanyNIP?: string;
};

export type Company = {
  company: {
    Name: string;
    Address: string;
    PostalCode: string;
    City: string;
    NIP: string;
    Manager_id: number;
  };
  workers: User[];
};
export type User = {
  Id: number;
  Email: string;
  Name: string;
  Surname: string;
};

export const resetPasswordGetToken = createAsyncThunk(
  "user/resetPassword",
  async function (email: string) {
    try {
      const response = await axios.post(
        `${server}/api/Users/reset-password/request/${email}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async function ({ password, token }: { password: string; token: string }) {
    try {
      const response = await axios.post(`${server}/api/Users/reset-password`, {
        password,
        token,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchOrderDetails = createAsyncThunk(
  "order/fetchOrderDetails",
  async function (orderId: string) {
    try {
      const response = await axios.get(`${server}/api/Orders/get/${orderId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserOrders = createAsyncThunk(
  "order/fetchUserOrders",
  async function () {
    try {
      const response = await axios.get(`${server}/api/Orders/user/get`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAddresses = createAsyncThunk(
  "user/fetchAddresses",
  async function () {
    try {
      const response = await axios.get(`${server}/api/Users/addresses`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "user/deleteAddress",
  async function (addressId: string) {
    try {
      const response = await axios.delete(
        `${server}/api/Users/address/${addressId}`,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addAddress = createAsyncThunk(
  "user/addAddress",
  async function (address: AddressRequest) {
    try {
      await axios.post(
        `${server}/api/Users/address`,
        {
          address: address,
        },
        {
          withCredentials: true,
        }
      );
      return address;
    } catch (error) {
      throw error;
    }
  }
);

export const editAddress = createAsyncThunk(
  "user/editAddress",
  async function ({
    addressID,
    address,
  }: {
    addressID: string;
    address: AddressRequest;
  }) {
    try {
      const response = await axios.post(
        `${server}/api/Users/address/edit/${addressID}`,
        {
          address: address,
        },
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async ({
    email,
    password,
    Name,
    Surname,
    Phone_number,
    CompanyRegister,
    CompanyName,
    CompanyAddress,
    CompanyPostalCode,
    CompanyCity,
    CompanyNIP,
  }: RegisterData) => {
    try {
      const response = await axios.post<UserRegisterResponse>(
        `${server}/api/Users/register`,
        {
          email,
          password,
          Name,
          Surname,
          Phone_number,
          CompanyRegister,
          CompanyName,
          CompanyAddress,
          CompanyPostalCode,
          CompanyCity,
          CompanyNIP,
        }
      );
      return response.data as UserRegisterResponse;
    } catch (error) {
      throw error;
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async ({
    email,
    password,
    rememberMe,
  }: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) => {
    try {
      const response = await axios.post<UserLoginResponse>(
        `${server}/api/Users/login`,
        {
          email,
          password,
          rememberMe,
        }
      );

      if(localStorage.getItem('cart')){
        const products = JSON.parse(
          localStorage.getItem("cart") || "null"
        ).products;
        const productList = [];
        for (const element of products) {
          productList.push({
            Product_id: element.product.Id,
            Catalog_name: element.product.Catalog_name,
            Quantity: element.quantity,
          });
        }
        /*await axios.post(
          `${server}/api/Carts/copy/cart`,
          {
            products: productList,
            userId: response.data.token
          }
        );*/
        localStorage.removeItem("cart");
      }
      
      return response.data as UserLoginResponse;
    } catch (error) {
      throw error;
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "user/confirm",
  async (token: string) => {
    try {
      const response = await axios.post(
        `${server}/api/Users/confirm/${token}`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCompanyDetails = createAsyncThunk(
  "user/getCompanyDetails",
  async () => {
    try {
      const response = await axios.get(`${server}/api/Users/company/-1`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCoworkers = createAsyncThunk("users/getCoworkers", async () => {
  try {
    const response = await axios.get(`${server}/api/Users/coworkers`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
});

export const addCoworkerToCompany = createAsyncThunk(
  "users/addCoworkerToCompany",
  async (user: number) => {
    try {
      await axios.post(
        `${server}/api/Users/company/add-user`,
        { user: user },
        { withCredentials: true }
      );
      return user;
    } catch (e) {
      throw e;
    }
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: {
    loginState: initialState,
    registerState: initialState,
    confirmState: initialState,
    fetchAddressesState: initialState,
    deleteAddressState: initialState,
    addAddressState: initialState,
    fetchUserOrdersState: initialState,
    fetchOrderDetailsState: initialState,
    resetPasswordState: initialState,
    company: {
      workers: [],
      company: {
        Name: "",
        Address: "",
        City: "",
        PostalCode: "",
        NIP: "",
        Manager_id: -1,
      },
    },
    companyState: initialState,
    coworkers: [],
  } as unknown as usersSliceState,
  reducers: {
    logout(state) {
      state.loginState.isLoading = false;
      state.loginState.failedLoading = false;
      state.loginState.hasLoaded = false;
      state.loginState.error = null;
      Cookies.remove("token");
      Cookies.remove("user");
    },
    resetAddAddressState(state) {
      state.addAddressState = initialState;
    },
    setOrderAsPaidDisplay(state, action){
      state.order.order.Is_paid=action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(login.pending, (state, action) => {
      state.loginState.isLoading = true;
      state.loginState.failedLoading = false;
      state.loginState.hasLoaded = false;
      state.loginState.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginState.isLoading = false;
      state.loginState.failedLoading = false;
      state.loginState.hasLoaded = true;
      state.loginState.error = null;
      const decodedToken: TokenType = jwtDecode(action.payload.token);
      Cookies.set("token", action.payload.token, {
        expires: new Date(decodedToken.exp * 1000),
      });
      Cookies.set("user", JSON.stringify(action.payload.user), {
        expires: new Date(decodedToken.exp * 1000),
      });
      Cookies.set("company", JSON.stringify(action.payload.company), {
        expires: new Date(decodedToken.exp * 1000),
      });
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginState.isLoading = false;
      state.loginState.failedLoading = true;
      state.loginState.hasLoaded = false;
      state.loginState.error = action.error;
    });
    builder.addCase(register.pending, (state, action) => {
      state.registerState.isLoading = true;
      state.registerState.failedLoading = false;
      state.registerState.hasLoaded = false;
      state.registerState.error = null;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.registerState.isLoading = false;
      state.registerState.failedLoading = false;
      state.registerState.hasLoaded = true;
      state.registerState.error = null;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.registerState.isLoading = false;
      state.registerState.failedLoading = true;
      state.registerState.hasLoaded = false;
      state.registerState.error = action.error;
    });
    builder.addCase(confirmEmail.pending, (state, action) => {
      state.confirmState.isLoading = true;
      state.confirmState.failedLoading = false;
      state.confirmState.hasLoaded = false;
      state.confirmState.error = null;
    });
    builder.addCase(confirmEmail.fulfilled, (state, action) => {
      state.confirmState.isLoading = false;
      state.confirmState.failedLoading = false;
      state.confirmState.hasLoaded = true;
      state.confirmState.error = null;
    });
    builder.addCase(confirmEmail.rejected, (state, action) => {
      state.confirmState.isLoading = false;
      state.confirmState.failedLoading = true;
      state.confirmState.hasLoaded = false;
      state.confirmState.error = action.error;
    });
    builder.addCase(fetchAddresses.pending, (state, action) => {
      state.fetchAddressesState.isLoading = true;
      state.fetchAddressesState.failedLoading = false;
      state.fetchAddressesState.hasLoaded = false;
      state.fetchAddressesState.error = null;
    });
    builder.addCase(fetchAddresses.fulfilled, (state, action) => {
      state.fetchAddressesState.isLoading = false;
      state.fetchAddressesState.failedLoading = false;
      state.fetchAddressesState.hasLoaded = true;
      state.fetchAddressesState.error = null;
      state.addresses = action.payload;
    });
    builder.addCase(fetchAddresses.rejected, (state, action) => {
      state.fetchAddressesState.isLoading = false;
      state.fetchAddressesState.failedLoading = true;
      state.fetchAddressesState.hasLoaded = false;
      state.fetchAddressesState.error = action.error;
    });
    builder.addCase(deleteAddress.pending, (state, action) => {
      state.deleteAddressState.isLoading = true;
      state.deleteAddressState.failedLoading = false;
      state.deleteAddressState.hasLoaded = false;
      state.deleteAddressState.error = null;
    });
    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      state.deleteAddressState.isLoading = false;
      state.deleteAddressState.failedLoading = false;
      state.deleteAddressState.hasLoaded = true;
      state.deleteAddressState.error = null;
    });
    builder.addCase(deleteAddress.rejected, (state, action) => {
      state.deleteAddressState.isLoading = false;
      state.deleteAddressState.failedLoading = true;
      state.deleteAddressState.hasLoaded = false;
      state.deleteAddressState.error = action.error;
    });
    builder.addCase(addAddress.pending, (state, action) => {
      state.addAddressState.isLoading = true;
      state.addAddressState.failedLoading = false;
      state.addAddressState.hasLoaded = false;
      state.addAddressState.error = null;
    });
    builder.addCase(addAddress.fulfilled, (state, action) => {
      state.addAddressState.isLoading = false;
      state.addAddressState.failedLoading = false;
      state.addAddressState.hasLoaded = true;
      state.addAddressState.error = null;
      var addresses: AddressRequest[] = [];
      for (const element of state.addresses) {
        addresses.push(element);
      }
      addresses.push({
        Address_name: action.payload.Address_name,
        Address: action.payload.Address,
        City: action.payload.City,
        Country: action.payload.Country,
        Zip_code: action.payload.Zip_code,
        //Id: state.addresses
      });
      state.addresses = addresses;
    });
    builder.addCase(addAddress.rejected, (state, action) => {
      state.addAddressState.isLoading = false;
      state.addAddressState.failedLoading = true;
      state.addAddressState.hasLoaded = false;
      state.addAddressState.error = action.error;
    });
    builder.addCase(fetchUserOrders.pending, (state, action) => {
      state.fetchUserOrdersState.isLoading = true;
      state.fetchUserOrdersState.failedLoading = false;
      state.fetchUserOrdersState.hasLoaded = false;
      state.fetchUserOrdersState.error = null;
    });
    builder.addCase(fetchUserOrders.fulfilled, (state, action) => {
      state.fetchUserOrdersState.isLoading = false;
      state.fetchUserOrdersState.failedLoading = false;
      state.fetchUserOrdersState.hasLoaded = true;
      state.fetchUserOrdersState.error = null;
      state.orders = action.payload;
    });
    builder.addCase(fetchUserOrders.rejected, (state, action) => {
      state.fetchUserOrdersState.isLoading = false;
      state.fetchUserOrdersState.failedLoading = true;
      state.fetchUserOrdersState.hasLoaded = false;
      state.fetchUserOrdersState.error = action.error;
    });
    builder.addCase(fetchOrderDetails.pending, (state, action) => {
      state.fetchOrderDetailsState.isLoading = true;
      state.fetchOrderDetailsState.failedLoading = false;
      state.fetchOrderDetailsState.hasLoaded = false;
      state.fetchOrderDetailsState.error = null;
    });
    builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
      state.fetchOrderDetailsState.isLoading = false;
      state.fetchOrderDetailsState.failedLoading = false;
      state.fetchOrderDetailsState.hasLoaded = true;
      state.fetchOrderDetailsState.error = null;
      state.order = action.payload;
    });
    builder.addCase(fetchOrderDetails.rejected, (state, action) => {
      state.fetchOrderDetailsState.isLoading = false;
      state.fetchOrderDetailsState.failedLoading = true;
      state.fetchOrderDetailsState.hasLoaded = false;
      state.fetchOrderDetailsState.error = action.error;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.resetPasswordState.isLoading = true;
      state.resetPasswordState.failedLoading = false;
      state.resetPasswordState.hasLoaded = false;
      state.resetPasswordState.error = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPasswordState.isLoading = false;
      state.resetPasswordState.failedLoading = false;
      state.resetPasswordState.hasLoaded = true;
      state.resetPasswordState.error = null;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordState.isLoading = false;
      state.resetPasswordState.failedLoading = true;
      state.resetPasswordState.hasLoaded = false;
      state.resetPasswordState.error = action.error;
    });
    builder.addCase(getCompanyDetails.pending, (state, action) => {
      state.companyState.isLoading = true;
      state.companyState.failedLoading = false;
      state.companyState.hasLoaded = false;
      state.companyState.error = null;
    });
    builder.addCase(getCompanyDetails.fulfilled, (state, action) => {
      state.companyState.isLoading = false;
      state.companyState.failedLoading = false;
      state.companyState.hasLoaded = true;
      state.companyState.error = null;
      state.company = action.payload;
    });
    builder.addCase(getCompanyDetails.rejected, (state, action) => {
      state.companyState.isLoading = false;
      state.companyState.failedLoading = true;
      state.companyState.hasLoaded = false;
      state.companyState.error = action.error;
    });
    builder.addCase(getCoworkers.pending, (state, action) => {
      state.companyState.isLoading = true;
      state.companyState.failedLoading = false;
      state.companyState.hasLoaded = false;
      state.companyState.error = null;
    });
    builder.addCase(getCoworkers.fulfilled, (state, action) => {
      state.companyState.isLoading = false;
      state.companyState.failedLoading = false;
      state.companyState.hasLoaded = true;
      state.companyState.error = null;
      state.coworkers = action.payload;
    });
    builder.addCase(getCoworkers.rejected, (state, action) => {
      state.companyState.isLoading = false;
      state.companyState.failedLoading = true;
      state.companyState.hasLoaded = false;
      state.companyState.error = action.error;
    });
    builder.addCase(addCoworkerToCompany.pending, (state, action) => {
      state.companyState.isLoading = true;
      state.companyState.failedLoading = false;
      state.companyState.hasLoaded = false;
      state.companyState.error = null;
    });
    builder.addCase(addCoworkerToCompany.fulfilled, (state, action) => {
      state.companyState.isLoading = false;
      state.companyState.failedLoading = false;
      state.companyState.hasLoaded = true;
      state.companyState.error = null;
      state.coworkers = state.coworkers.filter((element) => {
        if (element.Id !== action.payload) {
          return true;
        }
        return false;
      });
    });
    builder.addCase(addCoworkerToCompany.rejected, (state, action) => {
      state.companyState.isLoading = false;
      state.companyState.failedLoading = true;
      state.companyState.hasLoaded = false;
      state.companyState.error = action.error;
    });
  },
});

export const selectUser = (state: any): UserIdRoleAccepted | null => {
  const user = Cookies.get("user");
  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
};

export const selectToken = (state: any): string | null => {
  const token = Cookies.get("token");
  if (token) {
    return token;
  } else {
    return null;
  }
};

export const selectIsLoggedIn = (state: any): boolean => {
  const user = Cookies.get("user");
  const token = Cookies.get("token");
  if (user && token) {
    return true;
  } else {
    return false;
  }
};

export const selectAddresses = (state: any): Address[] => {
  return state.user.addresses;
};

export const selectOrders = (state: any): OrderData[] => {
  return state.user.orders;
};

export const selectOrder = (state: any): OrderData => {
  return state.user.order;
};

export const selectLoginState = (state: any): StateType => {
  return state.user.loginState;
};

export const selectRegisterState = (state: any): StateType => {
  return state.user.registerState;
};

export const selectConfirmState = (state: any): StateType => {
  return state.user.confirmState;
};

export const selectFetchAddressesState = (state: any): StateType => {
  return state.user.fetchAddressesState;
};

export const selectDeleteAddressState = (state: any): StateType => {
  return state.user.deleteAddressState;
};

export const selectAddAddressState = (state: any): StateType => {
  return state.user.addAddressState;
};

export const selectFetchUserOrdersState = (state: any): StateType => {
  return state.user.fetchUserOrdersState;
};

export const selectFetchOrderDetailsState = (state: any): StateType => {
  return state.user.fetchOrderDetailsState;
};

export const selectResetPasswordState = (state: any): StateType => {
  return state.user.resetPasswordState;
};

export const selectCompanyData = (state: any): Company => {
  return state.user.company;
};

export const selectCompanyDataState = (state: any): StateType => {
  return state.user.companyState;
};

export const selectCoworkers = (state: any): User[] => {
  return state.user.coworkers;
};

export const { logout, resetAddAddressState, setOrderAsPaidDisplay } = UserSlice.actions;

export default UserSlice.reducer;

import React, { Dispatch } from "react";
import AddToCartButton from "../../../../Common/Modals/AddToCart/AddToCartButton";
import { ProductDisplay } from "../../../../Models/Product";
import { useAppDispatch } from "../../../../Store/store";
import { addToCart } from "../../../../Store/Slices/Cart/Cart";
import { changeCartAddQuantity } from "../../../../Store/Slices/Products/Products";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {addToCart as addToCartActuator} from "../../../../Store/Slices/Actuators/Actuators";

type Props = {
  product: ProductDisplay;
};

function AddToCartBlock({ product }: Props) {
  const dispatch: Dispatch<any> = useAppDispatch();
  const [numberOfItems, setNumberOfItems] = React.useState(1);

  return (
    <div className="w-full flex items-center justify-end 2xl:items-center xl:items-end flex-row xl:flex-col 2xl:flex-row space-x-2 xl:space-x-0 xl:space-y-2 2xl:space-y-0 2xl:space-x-2">
      <div className="w-fit h-full flex items-center space-x-1">
        <button
          className="flex items-center justify-center rounded-lg p-1 sm:p-2 cursor-pointer hover:bg-neutral-50"
          onClick={() => {
            if (numberOfItems > 1) {
              setNumberOfItems(numberOfItems - 1);
              dispatch(changeCartAddQuantity(numberOfItems - 1));
            }
          }}
        >
          <RemoveIcon />
        </button>
        <input
          type="text"
          className="text-center w-10 bg-neutral-100"
          value={numberOfItems}
          onChange={(e) => {
            setNumberOfItems(
              parseInt(e.target.value === "" ? "0" : e.target.value)
            );
            dispatch(
              changeCartAddQuantity(
                parseInt(e.target.value === "" ? "0" : e.target.value)
              )
            );
          }}
        />
        <button
          className="flex items-center justify-center rounded-lg p-1 sm:p-2 cursor-pointer hover:bg-neutral-50"
          onClick={() => {
            setNumberOfItems(numberOfItems + 1);
            dispatch(changeCartAddQuantity(numberOfItems + 1));
          }}
        >
          <AddIcon />
        </button>
      </div>
      <div className="w-full h-full flex items-center">
        {product.Availability ? (
          <AddToCartButton
            addToCart={product.Id!==-1?addToCart:addToCartActuator}
            products={[
              {
                product: product,
                quantity: numberOfItems,
              },
            ]}
          >
            <div
              className={`w-full flex items-center justify-center px-1 py-1 sm:py-2 xl:px-2 xl:py-4 group bg-neutral-50 hover:bg-primary shadow-md rounded-lg space-x-1 transition-all`}
            >
              <span className="group-hover:text-neutral-50 text-sm sm:text-xl font-bold">
                Do koszyka
              </span>
              <div className="flex items-center justify-center group-hover:text-neutral-50 text-sm sm:text-xl">
                <AddShoppingCartIcon />
              </div>
            </div>
          </AddToCartButton>
        ) : (
          <div
            className={`w-full cursor-not-allowed flex items-center justify-center px-1 py-2 xl:px-2 xl:py-4 group bg-neutral-50 hover:bg-opacity-50 shadow-md rounded-lg space-x-1 transition-all`}
          >
            <span className="text-xl font-bold">Do koszyka</span>
            <AddShoppingCartIcon />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddToCartBlock;

import React from 'react'

type Props = {}

function ArticlesModule({}: Props) {
  return (
    <div>ArticlesModule</div>
  )
}

export default ArticlesModule
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateType } from "../../../Models/State";
import axios from "axios";
import { server } from "../../../server";
import { ProductDisplay, TechnicalDataModel } from "../../../Models/Product";
import { act } from "react-dom/test-utils";
import { CartAddProduct } from "../../../Models/Cart";

export type ActuatorCategory = {
  Id: number;
  Name: string;
  Description: string;
  Technical_data: string;
  Image_url: string;
};

export type Actuator = {
  Id: number;
  Name: string;
  Description: string;
  Technical_data: TechnicalDataModel[];
  Images: [];
  Srednice: number[];
  Max_skok: number;
  Prefix: string;
  Subfix: string;
};

export type ActuatorOrderData = {
  srednica: number;
  skok: number;
  Cena: number;
};

type ActuatorsSliceState = {
  categories: {
    categoriesList: ActuatorCategory[];
    state: StateType;
  };
  addToCartLocal: boolean,
  productLocal: ProductDisplay,
  actuator: {
    actuator: Actuator;
    data: ActuatorOrderData;
    state: StateType;
  };
};

export const getCategories = createAsyncThunk(
  "actuators/categories",
  async () => {
    try {
      const response = await axios.get(`${server}/api/Configurator/categories`);
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getCategoryDetails = createAsyncThunk(
  "actuators/getCategoryDetails",
  async (id: string) => {
    try {
      const response = await axios.get(
        `${server}/api/Configurator/category/${id}`
      );
      return response.data as Actuator;
    } catch (e) {
      throw e;
    }
  }
);

export const changeSrednica = createAsyncThunk(
  "actuators/changeSrednica",
  (srednica: string) => {
    return parseInt(srednica);
  }
);

export const changeSkok = createAsyncThunk(
  "actuators/changeSkok",
  (skok: string) => {
    return parseInt(skok);
  }
);

export const getPrice = createAsyncThunk(
  "actuators/getPrice",
  async ({
    srednica,
    skok,
    categoryId,
  }: {
    srednica: number;
    skok: number;
    categoryId: string;
  }) => {
    try {
      const response = await axios.get(
        `${server}/api/Configurator/price?srednica=${srednica}&skok=${skok}&categoryId=${categoryId}`,
        {withCredentials: true}
      );
      return response.data.price;
    } catch (e) {
      throw e;
    }
  }
);

export const addToCart = createAsyncThunk(
  "actuators/addToCart",
  async (products: {product:ProductDisplay, quantity: number}[]) => {
    try {
      const response = await axios.post(
        `${server}/api/Configurator/cart/add`,
        {product: products[0]},
        {withCredentials: true}
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

const actuatorsReducer = createSlice({
  name: "Actuators",
  initialState: {
    categories: {
      categoriesList: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    addToCartLocal: false,
    productLocal: {},
    actuator: {
      actuator: {},
      data: {
        skok: 100,
      },
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
  } as unknown as ActuatorsSliceState,
  reducers: {
    setAddToCartLocal: (state, action)=>{
      state.addToCartLocal=action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getCategories.pending, (state, action) => {
      state.categories.state.isLoading = true;
      state.categories.state.failedLoading = false;
      state.categories.state.hasLoaded = false;
      state.categories.state.error = null;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories.state.isLoading = false;
      state.categories.state.failedLoading = false;
      state.categories.state.hasLoaded = true;
      state.categories.categoriesList = action.payload;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.categories.state.isLoading = false;
      state.categories.state.failedLoading = true;
      state.categories.state.hasLoaded = false;
      state.categories.state.error = action.error;
    });
    builder.addCase(getCategoryDetails.pending, (state, action) => {
      state.actuator.state.isLoading = true;
      state.actuator.state.failedLoading = false;
      state.actuator.state.hasLoaded = false;
      state.actuator.state.error = null;
    });
    builder.addCase(getCategoryDetails.fulfilled, (state, action) => {
      state.actuator.state.isLoading = false;
      state.actuator.state.failedLoading = false;
      state.actuator.state.hasLoaded = true;
      state.actuator.actuator = action.payload;
    });
    builder.addCase(getCategoryDetails.rejected, (state, action) => {
      state.actuator.state.isLoading = false;
      state.actuator.state.failedLoading = true;
      state.actuator.state.hasLoaded = false;
      state.actuator.state.error = action.error;
    });
    builder.addCase(changeSrednica.fulfilled, (state, action) => {
      state.actuator.data.srednica = action.payload;
    });
    builder.addCase(changeSkok.fulfilled, (state, action) => {
      state.actuator.data.skok = action.payload;
    });
    builder.addCase(getPrice.pending, (state, action) => {
      state.actuator.state.isLoading = true;
      state.actuator.state.failedLoading = false;
      state.actuator.state.hasLoaded = false;
      state.actuator.state.error = null;
    });
    builder.addCase(getPrice.fulfilled, (state, action) => {
      state.actuator.state.isLoading = false;
      state.actuator.state.failedLoading = false;
      state.actuator.state.hasLoaded = true;      
      state.actuator.data.Cena = action.payload;
    });
    builder.addCase(getPrice.rejected, (state, action) => {
      state.actuator.state.isLoading = false;
      state.actuator.state.failedLoading = true;
      state.actuator.state.hasLoaded = false;
      state.actuator.state.error = action.error;
    });
    builder.addCase(addToCart.pending, (state, action) => {
      state.actuator.state.isLoading = true;
      state.actuator.state.failedLoading = false;
      state.actuator.state.hasLoaded = false;
      state.actuator.state.error = null;
    });
    builder.addCase(addToCart.fulfilled, (state, action) => {
      state.actuator.state.isLoading = false;
      state.actuator.state.failedLoading = false;
      state.actuator.state.hasLoaded = true;
      if(action.payload?.product!=undefined){
        state.productLocal = action.payload.product;
        state.addToCartLocal=true;
      }
      
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.actuator.state.isLoading = false;
      state.actuator.state.failedLoading = true;
      state.actuator.state.hasLoaded = false;
      state.actuator.state.error = action.error;
    });
  },
});

export const selectCategories = (state: {
  actuators: ActuatorsSliceState;
}): ActuatorCategory[] => state.actuators.categories.categoriesList;

export const selectActuatorsCategoriesState = (state: {
  actuators: ActuatorsSliceState;
}): StateType => state.actuators.categories.state;

export const selectActuatorDetails = (state: {
  actuators: ActuatorsSliceState;
}): Actuator => state.actuators.actuator.actuator;

export const selectActuatorState = (state: {
  actuators: ActuatorsSliceState;
}): StateType => state.actuators.actuator.state;

export const selectActuatorOrderData = (state: {
  actuators: ActuatorsSliceState;
}): ActuatorOrderData => state.actuators.actuator.data;

export const selectAddToCartLocal = (state: {
  actuators: ActuatorsSliceState;
}): boolean => state.actuators.addToCartLocal;

export const selectProductLocal = (state: {
  actuators: ActuatorsSliceState;
}): ProductDisplay => state.actuators.productLocal;

export const {setAddToCartLocal} = actuatorsReducer.actions;

export default actuatorsReducer.reducer;

import React, { useState } from 'react'
import Backdrop from '../../../../../../Common/Backdrop/Backdrop';
import {motion} from 'framer-motion'
import { dropIn } from "../../../../../../Animations/Animations";
import { OrderModel } from '../../../../../../Models/Order';
import { ProductDisplay, ProductModel } from '../../../../../../Models/Product';
import Input from '../../../../../../Common/Input/Input';


type Props = {
    handleClose: () => void;
    handleConfirm: () => void;
    text: string;
    orderId: number;
    product: ProductDisplay;
    newQuantity: number;
    setNewQuantity: any;
};

export default function ChangeQuantity({ handleClose, handleConfirm, text, orderId, product, newQuantity, setNewQuantity }: Props) {
    
    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="z-50 text-neutral-950 bg-neutral-50 rounded-lg shadow-lg p-4 space-y-4 flex flex-col items-center w-[90%] sm:w-fit sm:max-w-[640px] bg-opacity-80"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                
                <h1 className="text-3xl font-bold">{text}</h1>
                
                <input value={(newQuantity).toString()} onChange={(e)=>{
                    setNewQuantity(parseInt(e.target.value));
                }} type='textarea' name='Nowa ilość' className={
                    `"p-3 appearance-none block w-full text-base text-neutral-950 border-2 border-neutral-950 
                    active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none 
                    focus:ring-1 focus:ring-neutral-950 transition-all `
                  }/>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={() => {
                            handleConfirm();
                        }}
                        className="bg-white text-red-500 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-red-500 hover:text-white transition-all"
                    >
                        <span className="text-xl">Tak</span>
                    </button>
                    <button
                        onClick={() => {
                            handleClose();
                        }}
                        className="bg-white text-green-500 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-green-500 hover:text-white transition-all"
                    >
                        <span className="text-xl">Nie</span>
                    </button>
                </div>
            </motion.div>
        </Backdrop>
    )
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchOrderDetails,
  selectFetchOrderDetailsState,
  selectOrder,
} from "../../Store/Slices/User/User";
import { AppDispatch } from "../../Store/store";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { motion } from "framer-motion";
import ErrorMessage from "../Messages/ErrorMessage/ErrorMessage";
import {
  fetchPAymentUrl,
  selcetFetchPaymentURLState,
  selectPaymentURL,
} from "../../Store/Slices/Order/Order";

type Props = {
  isSummary?: boolean;
};

function OrderPage({ isSummary = false }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const { id } = useParams();

  const order = useSelector(selectOrder);
  const state = useSelector(selectFetchOrderDetailsState);

  function onClick() {
    dispatch(fetchPAymentUrl(order.order.Id.toString()));
  }

  const paymentURL = useSelector(selectPaymentURL);
  const paymentURLState = useSelector(selcetFetchPaymentURLState);

  useEffect(() => {
    if (id) {
      dispatch(fetchOrderDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (paymentURLState.hasLoaded) {
      window.location.href = paymentURL;
    }
  }, [paymentURLState.hasLoaded]);

  const formatDate = (dateString: string) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString("pl-PL", options as any);
  };

  return (
    <div className="relative">
      {paymentURLState.isLoading && (
        <LoadingAnimation color="primary" size="small" type="global" />
      )}
      {state.isLoading && <LoadingAnimation type="local" color="primary" />}
      {state.failedLoading && (
        <ErrorMessage
          error={state.error}
          message="Błąd podczas ładowania zamówienia."
        />
      )}
      {state.hasLoaded && (
        <div className="flex flex-col items-start w-full space-y-4 p-2">
          {!order.order.Is_paid &&
            isSummary &&
            order.order.Pay_type === "1" && (
              <div className="w-full flex flex-col items-center justify-center">
                <h3 className="text-xl font-semibold">
                  Jak tylko zamówienie zostanie zrealizowane, otrzymasz
                  powiadomienie o możliwości odbioru na adres mailowy.
                </h3>
                <h3 className="text-base font-semibold mt-4">
                  W razie pytań prosimy o kontakt mailowy:{" "}
                  <a
                    href={`mailto:biuro@pressbar.pl`}
                    className="text-primary-500 underline"
                  >
                    biuro@pressbar.pl{" "}
                  </a>
                  lub telefoniczny:{" "}
                  <a
                    href={`tel:+48 506 162 825`}
                    className="text-primary-500 underline"
                  >
                    +48 506 162 825
                  </a>
                </h3>
              </div>
            )}
          {!order.order.Is_paid &&
            isSummary &&
            order.order.Pay_type === "2" && (
              <motion.button
                className="relative group w-full flex items-center justify-center p-4 font-semibold border-2 border-neutral-950"
                onClick={onClick}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>Przejdź do płatności</span>
              </motion.button>
            )}
          {!order.order.Is_paid &&
            isSummary &&
            order.order.Pay_type === "3" && (
              <div className="w-full flex flex-col items-center justify-center">
                <h3 className="text-xl font-semibold">
                  {order.order.PaymentName}
                </h3>
                <h2 className="text-xl font-semibold">Dane do przelewu:</h2>
                <p className="text-center">
                  Numer konta: PKO 44 1020 1664 0000 3502 0690 1260
                </p>
                <p className="text-center">Kwota: {order.order.Price} PLN</p>
              </div>
            )}
          <div className="flex flex-col sm:flex-row items-start sm:justify-between w-full sm:items-center">
            <div className="flex flex-col">
              <h1 className="text-2xl sm:text-3xl font-bold">
                Zamówienie #{order.order.Order_number}
              </h1>
              <p>
                Status zamówienia:{" "}
                {order.order.Is_paid === 1 ? (
                  <span className="text-lg font-semibold text-green-600">
                    Opłacone
                  </span>
                ) : (
                  <span className="text-lg font-semibold text-red-600">
                    Nieopłacone
                  </span>
                )}
              </p>
            </div>
            <div className="flex space-x-2 items-center">
              {!order.order.Is_paid &&
                !isSummary &&
                order.order.Pay_type === "2" && (
                  <motion.button
                    className="group flex items-center p-4 font-semibold border-2 border-neutral-950"
                    onClick={onClick}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Przejdź do płatności
                  </motion.button>
                )}
            </div>
          </div>
          <div className="flex items-center justify-normal flex-wrap">
            <div className="w-auto mr-8">
              <p className="font-semibold">Data zamówienia:</p>
              <p>{formatDate(order.order.Creation_date)}</p>
            </div>
            <div className="w-auto mr-8">
              <p className="font-semibold">Metoda płatności:</p>
              <p>{order.order.PaymentName}</p>
            </div>
            <div className="w-auto mr-8">
              <p className="font-semibold">Metoda dostawy:</p>
              <p>{order.order.DeliveryName}</p>
            </div>
          </div>
          <div className="flex items-center justify-normal flex-wrap">
            <div className="w-auto mr-8">
              <p className="font-semibold">Nazwa firmy:</p>
              <p>{order.order.Company_name??''}</p>
            </div>
            <div className="w-auto mr-8">
              <p className="font-semibold">NIP:</p>
              <p>{order.order.NIP??''}</p>
            </div>
            <div className="w-auto mr-8">
              <p className="font-semibold">Adres firmy:</p>
              <p>{(order.order.CompanyAddress??'')+', '+(order.order.CompanyPostalCode??'')+' '
              +(order.order.CompanyCity??'')}</p>
            </div>
          </div >

          <div>
            <h2 className="text-xl font-bold mb-5">Zamówione produkty</h2>
            <div className="flex justify-start overflow-x-scroll flex-shrink-0 flex-wrap">
              {order.products.map((product, id) => (
                <Link to={`/product/${product.Catalog_name}`}>
                  <div
                    key={id}
                    className="flex flex-col space-y-2 w-40 mr-2 mb-2"
                  >
                    <img
                      className="w-40 h-40 object-contain bg-white  "
                      src={product.Image_url}
                      alt={product.Name}
                    />
                    <p className="text-start font-semibold">
                      {product.Catalog_name}{" "}
                    </p>
                    <p className="whitespace-nowrap">
                      netto {product.Amount} PLN x{product.Quantity}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-xl font-bold">Koszt zamówienia</h2>
            <div className="w-full flex justify-between">
              <p>Wartość produktów z dostawą:</p>
              <p className="whitespace-nowrap">{order.order.Price} PLN</p>
            </div>
            {/*<div className="w-full flex justify-between">
              <p>Dostawa:</p>
              <p>{0} PLN</p>
              </div>*/}
            <div className="w-full flex justify-between border-b-black border-b">
              <p>VAT:</p>
              <p className="whitespace-nowrap">
                {(parseFloat(order.order.Price) * 0.23).toFixed(2)} PLN
              </p>
            </div>
            <div className="w-full font-semibold flex justify-between">
              <p>Suma:</p>
              <p>{(parseFloat(order.order.Price) * 1.23).toFixed(2)} PLN</p>
            </div>
          </div>
          {order.order.Address !== "" && (
            <div>
              <h2 className="text-xl font-bold">Adres dostawy</h2>
              <p>{order.order.Address}</p>
              <p>{order.order.City}</p>
              <p>{order.order.Zip_code}</p>
            </div>
          )}
          {!order.order.Is_paid &&
            !isSummary &&
            order.order.Pay_type === "3" && (
              <div className="w-full flex flex-col items-center justify-center">
                <h3 className="text-xl font-semibold">
                  Prosimy o opłatę w ciągu 7 dni od daty złożenia zamówienia.
                </h3>
                <h2 className="text-xl font-semibold">Dane do przelewu:</h2>
                <p className="text-center">
                  Numer konta: PKO 44 1020 1664 0000 3502 0690 1260
                </p>
                <p className="text-center">
                  Tytuł przelewu: {order.order.Order_number}
                </p>
                <p className="text-center">Kwota: {order.order.Price} PLN</p>
                <h3 className="text-base font-semibold mt-4">
                  Prosimy o przesłanie potwierdzenia przelewu na adres mailowy:{" "}
                  <a
                    href={`mailto:biuro@pressbar.pl`}
                    className="text-primary-500"
                  >
                    biuro@pressbar.pl
                  </a>
                </h3>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default OrderPage;

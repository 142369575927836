import React from "react";
import Input from "../../../../../../Common/Input/Input";
import Cookies from "js-cookie";

function CompanyInfo() {
  const company = JSON.parse(Cookies.get("company") ?? "{}") as {
    Name: string;
    Address: string;
    City: string;
    PostalCode: string;
    NIP: string;
  };

  const [companyInfo, setCompanyInfo] = React.useState({
    Name: company.Name ? company.Name : "",
    Address: company.Address ? company.Address : "",
    City: company.City ? company.City : "",
    PostalCode: company.PostalCode ? company.PostalCode : "",
    NIP: company.NIP ? company.NIP : "",
  });

  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold">Wypełnij dane do faktury:</h1>
      <div className="w-full rounded-lg p-3 border-2 flex items-center flex-wrap">
        <Input
          label="company_name"
          type="text"
          name="Nazwa firmy"
          placeholder="Firma"
          required={true}
          value={companyInfo.Name}
          onChange={(e) => {
            setCompanyInfo({ ...companyInfo, Name: e.target.value });
          }}
        />
        <Input
          label="company_address"
          type="text"
          name="Adres firmy"
          placeholder="ul. Klonowa 1"
          required={true}
          value={companyInfo.Address}
          onChange={(e) => {
            setCompanyInfo({ ...companyInfo, Address: e.target.value });
          }}
        />
        <Input
          label="company_city"
          type="text"
          name="Miasto"
          placeholder="Warszawa"
          required={true}
          value={companyInfo.City}
          onChange={(e) => {
            setCompanyInfo({ ...companyInfo, City: e.target.value });
          }}
        />
        <Input
          label="company_postal_code"
          type="text"
          name="Kod pocztowy"
          placeholder="00-000"
          required={true}
          value={companyInfo.PostalCode}
          onChange={(e) => {
            setCompanyInfo({ ...companyInfo, PostalCode: e.target.value });
          }}
        />
        <Input
          label="nip"
          type="text"
          name="NIP"
          placeholder="1234567890"
          required={true}
          value={companyInfo.NIP}
          onChange={(e) => {
            setCompanyInfo({ ...companyInfo, NIP: e.target.value });
          }}
        />
      </div>
    </div>
  );
}

export default CompanyInfo;

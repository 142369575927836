import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import WishlistControls from "../../../Wishlist/WishlistControls/WishlistControls";
import WishlistInfo from "../../../Wishlist/WishlistInfo/WishlistInfo";
import { UserIdRoleAccepted } from "../../../../Models/User";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
  selectIsLoggedIn,
  selectUser,
} from "../../../../Store/Slices/User/User";
import {
  changeDeliveryTime,
  changeProductDiscount,
  changeProductQuantity,
  changeProductSpecialPrice,
  createOffer,
  getLists,
  getProductsToList,
  offerProduct,
  removeProductFromList,
  selectListId,
  selectListName,
  selectLists,
  selectOfferUrl,
  selectProducts,
} from "../../../../Store/Slices/wishlist/wishlistSlice";
import ButtonPrimaryToLighter from "../../../../Common/Buttons/ButtonPrimaryToLighter";
import ProductList from "../Products/ProductList";
import {
  addNewExtraProductsWithImage,
  getUsers,
  removeFromExtraProducts,
  selectChosenUser,
  selectClientAddress,
  selectClientCity,
  selectClientDataWaznosci,
  selectClientDataZlecenia,
  selectClientEmail,
  selectClientNIP,
  selectClientName,
  selectClientPlatnosc,
  selectClientTel,
  selectClientTworca,
  selectClientTworcaId,
  selectClientZamawiajacy,
  selectExtraProducts,
  selectOfferState,
  selectSearchUsers,
  setChosenUser,
  setClientAddress,
  setClientCity,
  setClientEmail,
  setClientNIP,
  setClientName,
  setClientTel,
  setClientZamawiajacy,
  setDataWaznosci,
  setDataZlecenia,
  setPlatnosc,
  setTworca,
  setTworcaId,
} from "../../../../Store/Slices/admin/Offers/Offers";
import HomeIcon from "@mui/icons-material/Home";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import SearchBar from "../../SearchBar/SearchBar";
import { fetchCompaniesNip, selectCompanies } from "../../../../Store/Slices/admin/Products/IndDiscounts";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom";

export default function AddOffer() {

    const products = useSelector(selectProducts);
  const lists = useSelector(selectLists);
  const selectedList = useSelector(selectListId);
  const selectedName = useSelector(selectListName);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch<AppDispatch>();
  const user: UserIdRoleAccepted | null = useSelector(selectUser);
  const [userNameToSearch, setUserNameToSearch] = useState("");
  const searchUsers = useSelector(selectSearchUsers);
  const chosenUser = useSelector(selectChosenUser);
  const stateUsers = useSelector(selectOfferState);

  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);

  const companies = useSelector(selectCompanies);
  const [chosenCompany, setChosenCompany] = useState(-1);

  const [productName, setProductName] = useState("");
  const [productCatalogName, setProductCatalogName] = useState("");
  const [productDeliveryTime, setProductDeliveryTime] = useState("");
  const [productQuantity, setProductQuantity] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productDiscount, setProductDiscount] = useState(0);

  const [addNewExtraProduct, setAddNewExtraProduct] = useState(false);

  const extraProducts = useSelector(selectExtraProducts);

  const clientName = useSelector(selectClientName);
  const clientNIP = useSelector(selectClientNIP);
  const clientAddress = useSelector(selectClientAddress);
  const clientCity = useSelector(selectClientCity);
  const clientZamawiajacy = useSelector(selectClientZamawiajacy);
  const clientTel = useSelector(selectClientTel);
  const clientEmail = useSelector(selectClientEmail);
  const dataZlecenia = useSelector(selectClientDataZlecenia);
  const dataWaznosci = useSelector(selectClientDataWaznosci);
  const platnosc = useSelector(selectClientPlatnosc);
  const tworca = useSelector(selectClientTworca);
  const tworcaId = useSelector(selectClientTworcaId);

  const tworcy = [
    {id: 1, name: 'Paweł Mermer', mobile: "506 162 825", email: "mermer@pressbar.pl"},
    {id: 2, name: 'Piotr Gniadzik', mobile: "697 231 212", email: "gniadzik@pressbar.pl"},
    {id: 3, name: 'Jakub Machera', mobile: "667 055 766", email: "machera@pressbar.pl"},
    {id: 4, name: 'Bartłomiej Chowaniec', mobile: "693 508 324", email: "chowaniec@pressbar.pl"},
    {id: 5, name: 'Anna Filipczyk', mobile: "601 864 122", email: "filipczyk@pressbar.com.pl"}
  ]

  const offerUrl = useSelector(selectOfferUrl);

  useEffect(() => {
    dispatch(getLists());
  }, [isLoggedIn]);

  useEffect(() => {
    if (selectedList != -1) {
      dispatch(getProductsToList(selectedList));
    }
  }, [selectedList]);

  const [file, setFile] = useState<File | null>(null);

  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col xl:flex-row space-y-4 xl:space-x-4 xl:space-y-0 w-full max-w-[1536px]">
        <div className="flex flex-col w-full xl:w-3/4 min-h-96 items-center bg-neutral-100 rounded-lg shadow-md p-2 space-y-2">
          <div className="h-96 overflow-scroll xl:h-fit xl:max-h-full space-y-2 w-full">
            {products.length === 0 && (
              <div className="flex flex-col items-center justify-center w-full h-full space-y-4">
                <HeartBrokenIcon />
                <h1 className="text-4xl font-bold text-center">
                  Ta lista jest pusta
                </h1>
                <ButtonPrimaryToLighter
                  link={""}
                  text="Strona główna"
                  size="large"
                  Icon={HomeIcon}
                />
              </div>
            )}
            {products.map((product) =>
            {
              if(product.Delivery_time===undefined){
                if((product.Stock_quantity??0)<(product.Quantity??0)){
                  
                }
              }
              return (
                <p >
                  <ProductList
                    key={product.Id}
                    product={product}
                    productQuantity={product.Quantity}
                    showCartControls={true}
                  />
                  <div>Stan magazynowy: {product.Stock_quantity??0}</div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <span>Rabat (%)</span>
                      <input
                        placeholder="rabat"
                        className="p-1 border-2 border-primary"
                        style={{display: 'flex', marginRight: '10px'}}
                        value={(product.Discount ?? 0.0)}
                        onChange={(e) => {
                          if(e.target.value===''){
                            dispatch(
                              changeProductDiscount({
                                id: product.Id,
                                discount: parseFloat('0.0'),
                              })
                            );
                          }
                          else{
                            dispatch(
                              changeProductDiscount({
                                id: product.Id,
                                discount: parseFloat(e.target.value),
                              })
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <span>Cena (PLN)</span>
                      <input
                        placeholder="cena"
                        className="p-1 border-2 border-primary"
                        style={{display: 'flex', marginRight: '10px'}}
                        value={((product.SpecialPrice ?? product.Price)).toFixed(2)}
                        onChange={(e) => {
                          if(e.target.value===''){
                            dispatch(
                              changeProductSpecialPrice({
                                id: product.Id,
                                price: parseFloat('0.0'),
                              })
                            );
                          }
                          else{
                            dispatch(
                              changeProductSpecialPrice({
                                id: product.Id,
                                price: parseFloat(e.target.value),
                              })
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <span>Ilość</span>
                      <input
                        style={{display: 'flex', marginRight: '10px'}}
                        placeholder="Ilość"
                        className="p-1 border-2 border-primary"
                        value={(product.Quantity ?? 0).toFixed(0)}
                        onChange={(e) => {
                          if(e.target.value===''){
                            dispatch(
                              changeProductQuantity({
                                id: product.Id,
                                quantity: parseFloat('0.0'),
                              })
                            );
                          }
                          else{
                            dispatch(
                              changeProductQuantity({
                                id: product.Id,
                                quantity: parseFloat(e.target.value),
                              })
                            );
                          }
                          
                        }}
                      />
                    </div>
                    <div>
                      <span>Czas dostawy</span>
                      <input
                        style={{display: 'flex', marginRight: '5px'}}
                        placeholder="Czas dostawy"
                        className="p-1 border-2 border-primary"
                        value={product.Delivery_time??''}
                        onChange={(e) => {
                          dispatch(
                            changeDeliveryTime({
                              id: product.Id,
                              deliveryTime: e.target.value
                            })
                          );
                        }}
                      />
                    </div>
                    <div>
                      <span>Wartość (PLN)</span>
                      <span style={{display: 'flex'}}>{((product.SpecialPrice ?? product.Price)*(product.Quantity??0)).toFixed(2)}</span>
                    </div>
                    <button
                      className="p-2 : hover:bg-primary hover:text-white"
                      onClick={() => {
                        dispatch(
                          removeProductFromList({
                            productId: product.Id,
                            listId: selectedList,
                          })
                        );
                      }}
                    >
                      Usun z listy
                    </button>
                  </div>
                  
                </p>
              )
            })}
            <div>
            <p>
              <button
                className="p-2 : hover:bg-primary hover:text-white"
                onClick={() => {
                  setAddNewExtraProduct(true);
                }}
              >
                Dodaj inny produkt
              </button>
            </p>
            <br/>

            {addNewExtraProduct && (
              <p>
                <input
                  type="file"
                  className="p-2"
                  onChange={(event) => {
                    if (
                      event.target.files &&
                      event.target.files.length > 0
                    ) {
                      setFile(event.target.files[0]);
                    }
                  }}
                />
                <br/>
                <div>
                  <span>Nazwa</span>
                  <input
                    className="w-full p-2 border-2 border-primary"
                    placeholder="Nazwa"
                    value={productName}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <span>Numer katalogowy</span>
                  <input
                    className="w-full p-2 border-2 border-primary"
                    placeholder="Numer katalogowy"
                    value={productCatalogName}
                    onChange={(e) => {
                      setProductCatalogName(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <span>Czas dostawy</span>
                  <input
                    className="w-full p-2 border-2 border-primary"
                    placeholder="Czas dostawy"
                    value={productDeliveryTime}
                    onChange={(e) => {
                      setProductDeliveryTime(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <span>Ilość</span>
                  <input
                    className="w-full p-2 border-2 border-primary"
                    placeholder="Ilość"
                    value={productQuantity}
                    onChange={(e) => {
                      if(e.target.value===''){
                        setProductQuantity(parseFloat('0.0'));
                      }
                      else{
                        setProductQuantity(parseFloat(e.target.value));
                      }
                      
                    }}
                  />
                </div>
                <div>
                  <span>Rabat (%)</span>
                  <input
                    className="w-full p-2 border-2 border-primary"
                    placeholder="Rabat (%)"
                    value={productDiscount}
                    onChange={(e) => {
                      setProductDiscount(parseFloat(e.target.value));
                    }}
                  />
                </div>
                <div>
                  <span>Cena netto po rabacie (PLN)</span>
                  <input
                    className="w-full p-2 border-2 border-primary"
                    placeholder="Cena (PLN)"
                    value={productPrice}
                    onChange={(e) => {
                      setProductPrice(parseFloat(e.target.value));
                    }}
                  />
                </div>
                <p>{productPrice*productQuantity}</p>
                
                <p>
                  <button
                    onClick={() => {
                        dispatch(
                          addNewExtraProductsWithImage({
                            name: productName,
                            catalogName: productCatalogName,
                            deliveryTime: productDeliveryTime,
                            quantity: productQuantity,
                            price: productPrice,
                            discount: productDiscount,
                            image: file,
                          })
                        );
                    }}
                  >
                    Zatwierdź
                  </button>
                </p>
              </p>
            )}
            <p>
              {extraProducts &&
                extraProducts.map((element) => {
                  return (
                    <p style={{display: 'flex'}}>
                      <div style={{marginRight: '20px'}}>
                        <span>Nazwa</span>
                        <span style={{display: 'flex'}}>{element.Name}</span>
                      </div>
                      <div style={{marginRight: '20px'}}>
                        <span>Numer katalogowy</span>
                        <span style={{display: 'flex'}}>{element.Catalog_name}</span>
                      </div>
                      <div style={{marginRight: '20px'}}>
                        <span>Czas dostawy</span>
                        <span style={{display: 'flex'}}>{element.Delivery_time}</span>
                      </div>
                      <div style={{marginRight: '20px'}}>
                        <span>Ilość</span>
                        <span style={{display: 'flex'}}>{element.Quantity}</span>
                      </div>
                      <div style={{marginRight: '20px'}}>
                        <span>Cena netto po rabacie</span>
                        <span style={{display: 'flex'}}>{element.Price}</span>
                      </div>
                      <div>
                        <button
                          className="p-2 : hover:bg-primary hover:text-white"
                          onClick={()=>{
                            dispatch(removeFromExtraProducts(element))
                          }}>
                          Usuń
                        </button>
                      </div>
                    </p>
                  );
                })}
            </p>
            </div>
            <div style={{display: 'flex'}}>
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const nip = (
                      document.getElementById("company_nip") as HTMLInputElement
                    ).value;
                    dispatch(
                      fetchCompaniesNip({
                        nip: nip,
                      })
                    );
                  }}
                  className="flex"
                >
                  <SearchBar placeholder="NIP firmy" id="company_nip" />
                  <button className="border-2 p-2" type="submit">
                    Szukaj
                  </button>
                </form>
                <div className="w-full flex flex-col max-h-96 overflow-scroll">
                  {companies &&
                    companies.map((company) => (
                      <button
                        key={company.Id}
                        className={`p-2 ${
                          company.Id === chosenCompany
                            ? "bg-primary text-white"
                            : "hover:bg-primary hover:text-white"
                        }`}
                        onClick={() => {
                          setChosenCompany(company.Id);
                          dispatch(setClientEmail(company.Email));
                          dispatch(setClientTel(company.Phone_number));
                        }}
                      >
                        {company.Name}
                      </button>
                    ))}
                </div>
              </div>
              <div>{user?.Role == "admin" && (
                <div>
                  <p>
                    <button onClick={()=>{
                      setChosenCompany(-1)
                    }}>Podaj dane innego klienta</button>
                  </p>
                  {chosenCompany==-1&&<div>
                    <p>
                      <span>Nazwa</span>
                      <input
                        placeholder="Nazwa"
                        style={{marginBottom: '5px'}}
                        className="w-full p-2 border-2 border-primary"
                        value={clientName}
                        onChange={(e) => {
                          dispatch(setClientName(e.target.value));
                        }}
                      />
                    </p>
                    <p>
                      <span>NIP</span>
                      <input
                        placeholder="NIP"
                        style={{marginBottom: '5px'}}
                        className="w-full p-2 border-2 border-primary"
                        value={clientNIP}
                        onChange={(e) => {
                          dispatch(setClientNIP(e.target.value));
                        }}
                      />
                    </p>
                    <p>
                      <span>Ulica i numer</span>
                      <input
                        placeholder="Ulica i numer"
                        style={{marginBottom: '5px'}}
                        className="w-full p-2 border-2 border-primary"
                        value={clientAddress}
                        onChange={(e) => {
                          dispatch(setClientAddress(e.target.value));
                        }}
                      />
                    </p>
                    <p>
                      <span>Kod i miasto</span>
                      <input
                        placeholder="Kod i miasto"
                        style={{marginBottom: '5px'}}
                        className="w-full p-2 border-2 border-primary"
                        value={clientCity}
                        onChange={(e) => {
                          dispatch(setClientCity(e.target.value));
                        }}
                      />
                    </p>
                  </div>}
                  <p>
                    <span>Osoba zamawiająca</span>
                    <input
                      placeholder="Osoba zamawiająca"
                      style={{marginBottom: '5px'}}
                      className="w-full p-2 border-2 border-primary"
                      value={clientZamawiajacy}
                      onChange={(e) => {
                        dispatch(setClientZamawiajacy(e.target.value));
                      }}
                    />
                  </p>
                  <p>
                    <span>Numer telefonu zamawiającego</span>
                    <input
                      placeholder="Numer telefonu zamawiającego"
                      style={{marginBottom: '5px'}}
                      className="w-full p-2 border-2 border-primary"
                      value={clientTel}
                      onChange={(e) => {
                        dispatch(setClientTel(e.target.value));
                      }}
                    />
                  </p>
                  <p>
                    <span>Email zamawiającego</span>
                    <input
                      placeholder="Email zamawiającego"
                      style={{marginBottom: '5px'}}
                      className="w-full p-2 border-2 border-primary"
                      value={clientEmail}
                      onChange={(e) => {
                        dispatch(setClientEmail(e.target.value));
                      }}
                    />
                  </p>
                  <p>
                    <span>Termin realizacji: </span>
                    <input
                      placeholder="Termin realizacji"
                      type="date"
                      value={dataZlecenia}
                      defaultValue={dataZlecenia}
                      onChange={(e) => {
                        dispatch(setDataZlecenia(e.target.value));
                      }}
                    />
                  </p>
                  <p>
                    <span>Data wazności: </span>
                    <input
                      placeholder="Termin wazności"
                      type="date"
                      value={dataWaznosci}
                      defaultValue={dataWaznosci}
                      onChange={(e) => {
                        dispatch(setDataWaznosci(e.target.value));
                      }}
                    />
                  </p>
                  <p>
                    <span>Forma płatności: </span>
                    <input
                      placeholder="Forma płatności"
                      className="w-full p-2 border-2 border-primary"
                      value={platnosc}
                      onChange={(e) => {
                        dispatch(setPlatnosc(e.target.value));
                      }}
                    />
                  </p>
                  <p>
                    <span>Autor: </span>
                    <div className="w-full flex flex-col max-h-96 overflow-scroll">
                      {tworcy.map((element) => (
                      <button
                        key={element.id}
                        className={`p-2 ${
                          element.id === tworcaId
                            ? "bg-primary text-white"
                            : "hover:bg-primary hover:text-white"
                        }`}
                        onClick={() => dispatch(setTworcaId(element.id))}
                      >
                        {element.name}
                      </button>
                    ))}
                </div>
                  </p>
                </div>
              )}
              </div>
            </div>
            
          </div>
          <button
            style={{fontSize: '25px', fontWeight: 'bold'}}
            className="p-3 : hover:bg-primary hover:text-white"
            onClick={()=>{
                const chosenCompanyData=chosenCompany==-1?undefined:companies.filter(element=>element.Id===chosenCompany)[0];
                var productsList:offerProduct[] = [];
                var totalAmount=0.0;
                for(var element of products){
                productsList.push({
                    Id: element.Id,
                    Name: element.Name,
                    Catalog_name: element.Catalog_name??'',
                    Delivery_time: element.Delivery_time??'',
                    Price: (element.SpecialPrice ?? element.Price),
                    Discount: element.Discount??0.0,
                    Image_url: element.Image_url??'',
                    DisplayDiscount: element.Discount!==undefined&&element.Discount!==0,
                    Quantity: element.Quantity??0
                });
                totalAmount+=element.SpecialPrice ?? element.Price;
                }
                for(var element2 of extraProducts){
                productsList.push({
                    Id: -1,
                    Name: element2.Name,
                    Catalog_name: element2.Catalog_name??'',
                    Delivery_time: element2.Delivery_time??'',
                    Price: (element2.Price)/(element2.Quantity??0),
                    Discount: element2.Discount??0.0,
                    Image_url: element2.Image_url??'',
                    DisplayDiscount: element2.Discount!==undefined&&element2.Discount!==0,
                    Quantity: element2.Quantity??0
                });
                totalAmount+=element2.Price;
                }
                dispatch(createOffer({products:productsList, data:{
                Company_id: chosenCompanyData!==undefined?chosenCompanyData.Id:-1,
                Name: chosenCompanyData!==undefined?chosenCompanyData.Name:clientName,
                NIP: chosenCompanyData!==undefined?chosenCompanyData.NIP:clientNIP,
                Address: chosenCompanyData!==undefined?chosenCompanyData.Address:clientAddress,
                City: chosenCompanyData!==undefined?chosenCompanyData.City:clientCity,
                Zamawiajacy: clientZamawiajacy,
                DataZlecenia: (new Date(dataZlecenia.toString())).toISOString().substring(0, 10),
                DataWaznosci: (new Date(dataWaznosci.toString())).toISOString().substring(0, 10),
                Tworca: tworcy.filter(element=>element.id===tworcaId)[0].name,
                TworcaPhone: tworcy.filter(element=>element.id===tworcaId)[0].mobile,
                TworcaEmail: tworcy.filter(element=>element.id===tworcaId)[0].email,
                Platnosc: platnosc,
                Email: clientEmail,
                Tel: clientTel,
                }}));
                navigate('../');
            }}
          >Generuj oferte</button>
          
        </div>
        <WishlistControls lists={lists} />
      </div>
    </motion.div>
  )
}

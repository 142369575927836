import React from "react";
import { useNavigate } from "react-router-dom";
import { CategoryModel } from "../../../../Models/Category";
import { StateType } from "../../../../Models/State";
import { ProductDisplay } from "../../../../Models/Product";
import ProductList from "../../../ProductList/ProductList";

type Props = {
  suggestedCategories: CategoryModel[];
  categoriesState: StateType;
  suggestedProducts: ProductDisplay[];
  suggestedProductsState: StateType;
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>;
};

function Suggested({
  suggestedCategories,
  categoriesState,
  suggestedProducts,
  suggestedProductsState,
  setDropDown,
}: Props) {
  const navigate = useNavigate();

  return (
    <div className="w-[96vw] md:w-[80vw] lg:w-[70vw] flex flex-col lg:flex-row items-start justify-between p-2 absolute top-full z-50 left-[50%] translate-x-[-50%] bg-neutral-100 bg-opacity-80 backdrop-blur-md rounded-lg shadow-md">
      <div className="p-1 w-full xl:w-1/2 border-r-0 border-b-2 lg:border-r-2 lg:border-b-0 space-y-1">
        <span className="text-lg font-semibold border-b-2 border-primary">
          Proponowane produkty
        </span>
        <div className="flex flex-col space-y-2 max-h-32 pb-2 overflow-y-scroll lg:max-h-none">
          {suggestedProducts.map((product) => (
            <ProductList
              product={product}
              key={product.Id}
              showAddToCart={false}
              showAddToList={false}
              showMoreInfo={false}
              showFullNameOnHover={false}
              pictureSize="small"
            />
          ))}
        </div>
      </div>
      <div className="p-1 w-full xl:w-1/2 space-y-1">
        <span className="text-lg font-semibold border-b-2 border-primary">
          Proponowane kategorie
        </span>
        <div className="flex flex-col space-y-2 max-h-32 overflow-y-scroll lg:max-h-none">
          {suggestedCategories.map((category) => (
            <div
              key={category.Id}
              className="w-full p-2 rounded-lg hover:bg-neutral-200 flex flex-col"
            >
              <button
                className="w-full text-left"
                onClick={() => {
                  setDropDown(false);
                  navigate(`/search?category=${category.Id}`);
                }}
              >
                {category.Name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Suggested;

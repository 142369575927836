import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Product from "./Pages/Product/Product";
import Login from "./Pages/Login/Login";
import Profile from "./Pages/Profile/Profile";
import PrivateRoute from "./Common/PrivateRoute/PrivateRoute";
import ScrollUp from "./Common/ScrollUp/ScrollUp";
import Footer from "./Common/Footer/Footer";
import Admin from "./Pages/Admin/Admin";
import NavBarComputer from "./Common/NavBar/NavBar";
import LandingPage from "./Pages/LandingPage/LandingPage";
import NotFound from "./Pages/NotFound/NotFound";
import Cart from "./Pages/Cart/Cart";
import Search from "./Pages/Search/Search";
import Wishlist from "./Pages/Wishlist/Wishlist";
import Register from "./Pages/Register/Register";
import CookiesInformational from "./Common/CookiesInformational/CookiesInformational";
import Order from "./Pages/Order/Order";
import RegisterConfirm from "./Pages/Register/RegisterConfirm";
import Actuators from "./Pages/Actuators/Actuators";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Calculators from "./Pages/Calculators/Calculators";
import Downloads from "./Pages/Downloads/Downloads";
import Contact from "./Pages/Contact/Contact";
import lodash from "lodash";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./Store/store";
import {
  changeProductQuantityCart,
  changeUpdateQuantitiesState,
} from "./Store/Slices/Cart/Cart";
import { CartAddProduct } from "./Models/Cart";
import Summary from "./Pages/Order/Summary/Summary";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import OrderSuccess from "./Pages/Order/Success/OrderSuccess";
import OrderCancel from "./Pages/Order/Cancel/OrderCancel";
import Article from "./Pages/MarkdownArticle/MarkdownArticle";
import Regulamin from "./Pages/Regulamin/Regulamin";
import PolitykaPrywatnosci from "./Pages/PolitykaPrywatnosci/PolitykaPrywatnosci";
import WarunkiHandlowe from "./Pages/Regulamin/WarunkiHandlowe";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, purple } from "@mui/material/colors";
import Producer from "./Pages/Producer/Producer";
import Instalacje from "./Pages/About/Instalacje";
import UtrzymanieRuchu from "./Pages/About/UtrzymanieRuchu";
import Articles from "./Pages/MarkdownArticle/Articles";
import Sprezarkownie from "./Pages/About/Sprezarkownie";

const theme = createTheme({
  palette: {
    primary: {
      main: "#09090b",
    },
    secondary: {
      main: "#008391",
    },
    error: {
      main: "#dc2626",
    },
    success: {
      main: "#16a34a",
    },
  },
});

function App() {
  const location = useLocation();

  useEffect(() => {
    if(!location.pathname.startsWith('/search')){
      window.scrollTo(0, 0);
    }
  }, [location]);

  const dispatch = useDispatch<AppDispatch>();

  var [products, setProducts] = useState<CartAddProduct[]>([]);

  const debounceDispatch = lodash.debounce(
    (dispatch, products: CartAddProduct[]) => {
      if (products.length !== 0) {
        dispatch(changeProductQuantityCart(products));
      }
    },
    1400
  );

  const updateQuantity = useCallback(
    ({
      newQuantity,
      product,
    }: {
      newQuantity: string;
      product: { Id: number };
    }) => {
      var productsTemp: CartAddProduct[] = [];
      setProducts((currentProducts) => {
        const productIndex = currentProducts.findIndex(
          (p) => p.Product_id === product.Id
        );

        let updatedProducts = [...currentProducts];

        if (productIndex >= 0) {
          updatedProducts[productIndex] = {
            ...updatedProducts[productIndex],
            Quantity: parseInt(newQuantity),
          };
        } else {
          updatedProducts.push({
            Product_id: product.Id,
            Quantity: parseInt(newQuantity),
          });
        }
        productsTemp = updatedProducts;
        return updatedProducts;
      });

      if (productsTemp.length !== 0) {
        dispatch(changeUpdateQuantitiesState(true));
      } else {
        dispatch(changeUpdateQuantitiesState(false));
      }

      debounceDispatch(dispatch, productsTemp);
    },
    []
  );

  const isAdminPage = location.pathname.startsWith("/admin");
  const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");

  return (
    <ThemeProvider theme={theme}>
      <div id="App">
        <div className="font-body w-full min-h-screen flex flex-col items-center justify-center bg-neutral-50">
          {isAdminPage ? null : <NavBarComputer />}
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/search/" element={<Search />} />
            <Route path="/product/:id" element={<Product />} />
            <Route
              path="/cart"
              element={<Cart updateQuantity={updateQuantity} />}
            />
            <Route path="/login/:redirect?" element={<Login />} />
            <Route path="/register/*" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route path="/uslugi" element={<Services />} />
            <Route path='/uslugi/instalacje' element={<Instalacje/>}/>
            <Route path='/uslugi/utrzymanie_ruchu' element={<UtrzymanieRuchu/>}/>
            <Route path='/uslugi/sprezarkownie' element={<Sprezarkownie/>}/>
            <Route path="/wiedza" element={<Calculators />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/warunki-handlowe" element={<WarunkiHandlowe />} />
            <Route path="/silowniki/*" element={<Actuators />} />
            <Route
              path="/admin/*"
              element={
                <PrivateRoute admin={true}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route path="/reset-password/*" element={<ResetPassword />} />
            <Route
              path="polityka-prywatnosci"
              element={<PolitykaPrywatnosci />}
            />
            <Route path="regulamin" element={<Regulamin />} />
            <Route
              path="/wishlist"
              element={
                <PrivateRoute>
                  <Wishlist />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/*"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/order" element={<Order />} />
            <Route path="/order/summary/:id" element={<Summary />} />
            <Route path="/order/success/:orderId" element={<OrderSuccess />} />
            <Route path="/order/cancel/:orderId" element={<OrderCancel />} />
            <Route path="/confirmation/:token" element={<RegisterConfirm />} />
            <Route path='/article' element={<Articles/>}/>
            <Route path="/article/:id" element={<Article />} />
            <Route path="/producer/:id" element={<Producer />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />

          <ScrollUp />
          {hasAcceptedCookies ? null : <CookiesInformational />}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect } from "react";
import { marked } from "marked";
import "./MarkdownArticle.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import {
  fetchArticle,
  selectArticle,
  selectArticleState,
} from "../../Store/Slices/Articles/Article";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../Common/Messages/ErrorMessage/ErrorMessage";
import Gallery from "../Product/Gallery/Gallery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export type ArticleType = {
  Category_id: number;
  Id: number;
  Title: string;
  Description: string;
  Images: string[];
  Created_date: string;
};

const Article: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const article = useSelector(selectArticle);
  const articleState = useSelector(selectArticleState);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchArticle(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    document.title = "Artykuł - Press Bar Pneumatyka";
    if (articleState.hasLoaded) {
      document.title = `${article.Title} - Press Bar Pneumatyka`;
    }
  }, [articleState.hasLoaded]);

  useEffect(() => {
    if (articleState.hasLoaded) {
      const updateMarkdownContent = async () => {
        const parsedContent = await marked.parse(article.Description);
        document.getElementById("markdown_content")!.innerHTML = parsedContent;
      };

      updateMarkdownContent();
    }
  }, [article, articleState.hasLoaded]);

  const formatDate = (dateString: string) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString("pl-PL", options as any);
  };

  return (
    <div className="w-full max-w-[1280px] p-2 flex flex-col">
      {articleState.isLoading && (
        <LoadingAnimation type="global" color="primary" />
      )}
      {articleState.failedLoading && (
        <ErrorMessage
          message="Nie udało się załadować artykułu"
          error={articleState.error}
        />
      )}
      {articleState.hasLoaded && (
        <div id="top_info" className="w-full flex justify-between items-center">
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold">{article.Title}</h1>
            <p className="text-base">{formatDate(article.Created_date)}</p>
            <p className="text-sm">
              Czas czytania: około{" "}
              {Math.round(article.Description.length / 238)} minut
            </p>
          </div>
          <div className="flex flex-col">
            <button
              className="flex items-center space-x-2 group"
              onClick={() => {
                const element = document.getElementById("gallery");
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <ExpandMoreIcon />
              <span className="group-hover:underline">
                Zobacz galerie zdjęć
              </span>
            </button>
          </div>
        </div>
      )}
      <div
        id="markdown_content"
        className="w-full flex flex-col items-center"
      ></div>
      {articleState.hasLoaded &&
        article.Images !== undefined &&
        article.Images !== null && (
          <div className="w-full h-96" id="gallery">
            <Gallery
              images={article.Images?.map((image: any) => ({
                Image_url: image,
                Product_group_id: 0,
                Order_number: 0,
              }))}
            />
          </div>
        )}
    </div>
  );
};

export default Article;

import React from "react";
import { OrderData } from "../../../../../Models/Order";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  orderData: OrderData;
};

function OrderItem({ orderData }: Props) {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  const [productsLimit, setProductsLimit] = React.useState(
    innerWidth > 640 ? 3 : 1
  );

  React.useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      setProductsLimit(innerWidth > 640 ? 3 : 1);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="border-b-2 flex flex-col items-start w-full space-y-2 p-2">
      <div className="flex sm:flex-row flex-col sm:justify-between w-full sm:items-center items-start">
        <div className="flex flex-col">
          <h2 className="text-lg sm:text-xl font-bold">
            Zamówienie #{orderData.order.Order_number}
          </h2>
          <p>
            Status zamówienia:{" "}
            {orderData.order.Is_paid ? (
              <span className="text-lg font-semibold text-green-600">
                Opłacone
              </span>
            ) : (
              <span className="text-lg font-semibold text-red-600">
                Nieopłacone
              </span>
            )}
          </p>
        </div>
        <h1 className="text-xl sm:text-2xl font-bold">
          <span className="font-normal text-base underline">netto</span>{" "}
          {orderData.order.Price} PLN
        </h1>
      </div>
      <div className="flex flex-row w-full items-start space-x-2 overflow-x-scroll">
        {orderData.products.slice(0, productsLimit).map((product, id) => (
          <div key={id} className="w-20 sm:w-36">
            <img
              className="w-full h-32 sm:h-40 object-contain bg-white"
              src={product.Image_url}
              alt={product.Name}
            />
            <p className="text-center text-xs sm:text-base">
              {product.Catalog_name}
            </p>
          </div>
        ))}
        {orderData.products.length > productsLimit && (
          <div className="w-20 h-32 sm:w-36 sm:h-40 bg-neutral-200 flex justify-center items-center select-none">
            <p className="text-2xl sm:text-3xl font-bold text-neutral-500">
              +{orderData.products.length - productsLimit}
            </p>
          </div>
        )}
      </div>
      <div className="w-full flex justify-end">
        <Link className="group flex items-center" to={`${orderData.order.Id}`}>
          <span className="group-hover:underline text-xl decoration-primary font-semibold">
            Szczegóły
          </span>
          <ChevronRightIcon />
        </Link>
      </div>
    </div>
  );
}

export default OrderItem;

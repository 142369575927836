import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductDisplay } from "../../../../Models/Product";
import { StateType } from "../../../../Models/State";
import { UserAdminDisplay } from "../../../../Models/User";
import axios from "axios";
import { server } from "../../../../server";



type adminProductsSliceState = {
    searchProducts: ProductDisplay[],
    searchUsers: UserAdminDisplay[],
    chosenUser: number,
    chosenUserSearchPrices: number;
    chosenProducts: ProductDisplay[],
    searchUsersSearchPrices: UserAdminDisplay[],
    searchProductsSearchPrices: {Id: number, Name: string, Price: number}[],
    selectChosenUserDiscounts: {Id: number, Name: string, Price: number}[];
    chosenProductToDelete: number;
    state: StateType
};

export const getUsers=createAsyncThunk(
    'indPricesProducts/getUsers',
    async({name}:{name:string})=>{
      try{
        const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
        {withCredentials: true});
        return result.data;
      }
      catch(error){
        throw error;
      }
    }
);

export const getProductsByName = createAsyncThunk(
    'indPricesProducts/getProductsByName',
    async({name}:{name:string})=>{
        try{
            const response = await axios.get(`${server}/api/IndPrices/products/search/${name.replaceAll('/', '*1*')}`,
            {withCredentials: true});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
) 

export const getProductsByNameForUser = createAsyncThunk(
    'indPricesProducts/getProductsByNameForUser',
    async({userId}:{userId: number})=>{
        try{
            const response = await axios.get(`${server}/api/IndPrices/products/user/prices/get/${userId}}`,
            {withCredentials: true});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
) 

export const getUsersSearchPrices=createAsyncThunk(
    'indPricesProducts/getUsersSearchPrices',
    async({name}:{name:string})=>{
      try{
        const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
        {withCredentials: true});
        return result.data;
      }
      catch(error){
        throw error;
      }
    }
);

export const addIndividualPrices = createAsyncThunk(
    'indPricesProducts/addIndividualPrices',
    async({products, userId}:{products: {id: number, price: number}[], userId: number})=>{
        try{
            const result = await axios.post(`${server}/api/IndPrices/product/user/price/add`,
            {products: products, userId},
            {withCredentials: true});
            return true
        }
        catch(e){
            throw e;
        }
    }
)


export const deleteProductIndPrice = createAsyncThunk(
    'indPricesProducts/deleteProductPrice',
    async({id, userId, price}:{id: number, userId:number, price:number})=>{
        try{
            const response = await axios.post(`${server}/api/IndPrices/products/price/delete`,
            {userId, id, price},
            {withCredentials: true});
            return {id, price};
        }
        catch(e){
            throw e;
        }
    }
)

const indPricesProductsSlice = createSlice({
    name: 'indPricesProducts',
    initialState:{
        searchProducts: [],
        searchUsers: [],
        chosenUser: -1,
        chosenUserSearchDiscount:-1, 
        chosenProducts: [],
        searchUsersSearchPrices: [],
        searchProductsSearchPrices: [],
        chosenProductToDelete: -1,
        state: {
            isLoading: false,
            failedLoading: false,
            hasLoaded: false,
            error: null,
        }
    } as unknown as adminProductsSliceState,
    reducers: {
        addToChosenProducts: (state, action)=>{
            state.chosenProducts=[action.payload].concat(state.chosenProducts)??[];
        },
        clearChosenProducts: (state)=>{
          state.chosenProducts=[];
        },
        setChosenUser: (state, action)=>{
            state.chosenUser = action.payload;
        },
        setChosenProductToDelete: (state, action)=>{
            state.chosenProductToDelete = action.payload;
        },
        setChosenUserSearchPrices: (state, action)=>{
            state.chosenUserSearchPrices = action.payload;
        }
    },
    extraReducers: {
        [getUsers.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchUsers =  action.payload;
          },
          [getUsers.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getUsers.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getProductsByName.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProducts =  action.payload;
          },
          [getProductsByName.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getProductsByName.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getUsersSearchPrices.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchUsersSearchPrices =  action.payload;
          },
          [getUsersSearchPrices.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getUsersSearchPrices.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [deleteProductIndPrice.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProductsSearchPrices = state.searchProductsSearchPrices.filter(element=>{
              if(element.Price!=action.payload.price || element.Id!=action.payload.id){
                return true;
              }
              return false;
            });
          },
          [deleteProductIndPrice.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [deleteProductIndPrice.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [addIndividualPrices.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProductsSearchPrices = [];
          },
          [addIndividualPrices.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [addIndividualPrices.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getProductsByNameForUser.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProductsSearchPrices = action.payload;
          },
          [getProductsByNameForUser.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getProductsByNameForUser.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          
    }
})

export const selectProducts = (state: {
    indPricesProducts: adminProductsSliceState;
  }): ProductDisplay[] => {
    return state.indPricesProducts.searchProducts;
  };

  export const selectChosenProducts = (state: {
    indPricesProducts: adminProductsSliceState;
  }): ProductDisplay[] => {
    return state.indPricesProducts.chosenProducts;
  };

  export const selectUsers = (state: {
    indPricesProducts: adminProductsSliceState;
  }): UserAdminDisplay[] => {
    return state.indPricesProducts.searchUsers;
  };

  export const selectChosenUser = (state: {
    indPricesProducts: adminProductsSliceState;
  }): number => {
    return state.indPricesProducts.chosenUser;
  };

  export const selectChosenUserSearchPrices = (state: {
    indPricesProducts: adminProductsSliceState;
  }): number => {
    return state.indPricesProducts.chosenUserSearchPrices;
  };

  export const selectUsersSearchPrices = (state: {
    indPricesProducts: adminProductsSliceState;
  }): UserAdminDisplay[] => {
    return state.indPricesProducts.searchUsersSearchPrices;
  };

  export const selectUserPrices = (state: {
    indPricesProducts: adminProductsSliceState;
  }): {Id: number, Name: string, Price: number}[] => {
    return state.indPricesProducts.searchProductsSearchPrices;
  };

export const {addToChosenProducts, clearChosenProducts, setChosenUserSearchPrices,
    setChosenProductToDelete, setChosenUser} = indPricesProductsSlice.actions


export default indPricesProductsSlice.reducer;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../SearchBar/SearchBar";
import {
  addIndividualDiscountToProduct,
  addProductDiscount,
  delteDiscount,
  fetchCompaniesNip,
  getCompanyDiscounts,
  getProductsByName,
  removeProductDiscount,
  selectCompanies,
  selectCompanyDiscounts,
  selectDeleteState,
  selectProductDiscountState,
  selectProducts,
  selectProductsDiscounts,
} from "../../../../Store/Slices/admin/Products/IndDiscounts";
import { AppDispatch } from "../../../../Store/store";
import {
  addCategoryDiscount,
  addIndividualDiscountCategory,
  getCategories,
  removeCategoryDiscount,
  selectAllCategories,
  selectAllCategoriesMap,
  selectCategories,
  selectCategoriesDiscounts,
  selectCurrentCategoryId,
  selectParentCategory,
  selectUSerState,
  setCurrentCategoryId,
} from "../../../../Store/Slices/admin/Categories/Categories";
import { CategoryModel } from "../../../../Models/Category";
import Category from "../../../../Common/Categories/Category/Category";
import _ from "lodash";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../../../Common/Messages/ErrorMessage/ErrorMessage";
import InfoMessage from "../../../../Common/Messages/InfoMessage/InfoMessage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function CompanyDiscountManager() {
  const companies = useSelector(selectCompanies);
  const dispatch = useDispatch<AppDispatch>();

  const [chosenCompany, setChosenCompany] = useState(-1);
  const products = useSelector(selectProducts);
  const productDiscounts = useSelector(selectProductsDiscounts);
  const categoriesDiscounts = useSelector(selectCategoriesDiscounts);
  const categories = useSelector(selectCategories);
  let parentCategory: CategoryModel = useSelector(selectParentCategory);
  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const allCategoriesMap = useSelector(selectAllCategoriesMap);
  const allCategories = useSelector(selectAllCategories);
  const currentCategory = useSelector(selectParentCategory);
  const companyDiscounts = useSelector(selectCompanyDiscounts);
  const productDiscountState = useSelector(selectProductDiscountState);
  const categoryDiscountState = useSelector(selectUSerState);
  const deleteState = useSelector(selectDeleteState);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(setCurrentCategoryId(1));
  }, [dispatch]);

  useEffect(() => {
    if (chosenCompany !== -1) dispatch(getCompanyDiscounts(chosenCompany));
  }, [chosenCompany]);

  useEffect(() => {
    dispatch(
      getCategories({
        id: currentCategoryId,
        allCategoriesMap,
        currentCategory:
          allCategories.filter((element) => {
            if (element.Id.toString() == currentCategoryId) {
              return true;
            }
            return false;
          })[0] ?? currentCategory,
      })
    );
  }, [currentCategoryId]);

  useEffect(() => {
    if (deleteState.hasLoaded) {
      dispatch(getCompanyDiscounts(chosenCompany));
    }
  }, [chosenCompany, deleteState, dispatch]);

  return (
    <div className="w-full flex space-x-6">
      <div className="relative flex w-1/3 flex-col space-y-2">
        {deleteState.isLoading && <LoadingAnimation color="primary" />}
        {deleteState.failedLoading && (
          <ErrorMessage message="Wystąpił błąd" error={deleteState.error} />
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const nip = (
              document.getElementById("company_nip") as HTMLInputElement
            ).value;
            dispatch(
              fetchCompaniesNip({
                nip: nip,
              })
            );
          }}
          className="flex"
        >
          <SearchBar placeholder="NIP firmy" id="company_nip" />
          <button className="border-2 p-2" type="submit">
            Szukaj
          </button>
        </form>
        <div className="w-full flex flex-col max-h-96 overflow-scroll">
          {companies &&
            companies.map((company) => (
              <button
                key={company.Id}
                className={`p-2 ${
                  company.Id === chosenCompany
                    ? "bg-primary text-white"
                    : "hover:bg-primary hover:text-white"
                }`}
                onClick={() => setChosenCompany(company.Id)}
              >
                {company.Name}
              </button>
            ))}
        </div>
        <h2 className="font font-semibold underline text-xl">
          Znizki na kategorie:
        </h2>
        <div>
          {companyDiscounts &&
            companyDiscounts.categories.map((element) => {
              return (
                <div className="w-full flex justify-between items-center max-h-96 overflow-scroll space-y-1">
                  <span>{element.Name}</span>
                  <div className="flex space-x-1 items-center">
                    <span className="font-semibold underline">
                      {element.Discount}%
                    </span>
                    {(
                      <button
                        className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                        onClick={() => {
                          dispatch(
                            delteDiscount({
                              companyId: chosenCompany,
                              id: element.Id,
                              category: true,
                              discount: element.Discount,
                            })
                          );
                        }}
                      >
                        Usuń
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <h2 className="font font-semibold underline text-xl">
          Znizki na produkty:
        </h2>
        <div>
          {companyDiscounts &&
            companyDiscounts.products.map((element) => {
              return (
                <div className="w-full flex justify-between items-center max-h-96 overflow-scroll space-y-1">
                  <span>{element.Name}</span>
                  <div className="flex space-x-1 items-center">
                    <span className="font-semibold underline">
                      {element.Discount}%
                    </span>
                    {false && (
                      <button
                        className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                        onClick={() => {
                          dispatch(
                            delteDiscount({
                              companyId: chosenCompany,
                              id: element.Id,
                              category: false,
                              discount: element.Discount,
                            })
                          );
                        }}
                      >
                        Usuń
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {chosenCompany !== -1 && (
        <div className="relative w-1/3 flex flex-col space-y-1">
          {productDiscountState.isLoading && (
            <LoadingAnimation color="primary" />
          )}
          {productDiscountState.failedLoading && (
            <ErrorMessage
              message="Wystąpił błąd podczas ładowania produktów"
              error={productDiscountState.error}
            />
          )}
          {productDiscountState.hasLoaded && (
            <InfoMessage
              message="Produkty zostały załadowane"
              Icon={CheckCircleIcon}
            />
          )}
          <div className="w-full">
            <span className="font-bold underline">Wybrane produkty</span>
            <div className="w-full max-h-96 overflow-scroll">
              {productDiscounts &&
                productDiscounts.map((element) => {
                  return (
                    <div className="w-full flex justify-between items-center">
                      <span>{element.product.Name}</span>
                      <div className="flex items-center space-x-1">
                        <span className="underline font-semibold">
                          {element.discount}%
                        </span>
                        <button
                          disabled={chosenCompany === -1}
                          onClick={() => {
                            dispatch(removeProductDiscount(element));
                          }}
                        >
                          usuń
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
              
          <div className="w-full">
            <form
              className="flex"
              onSubmit={(e) => {
                e.preventDefault();
                const name = (
                  document.getElementById("name") as HTMLInputElement
                ).value;
                dispatch(getProductsByName({ name: name }));
              }}
            >
              <SearchBar
                disable={chosenCompany === -1}
                placeholder="Nazwa lub numer katalogowy produktu"
                id="name"
              />
              <button
                className="border-2 p-2"
                type="submit"
                disabled={chosenCompany === -1}
              >
                Szukaj
              </button>
            </form>
          </div>
          <div className="w-full max-h-96 overflow-scroll space-y-2 flex flex-col">
            {products &&
              products.map((element) => {
                return (
                  <div className="w-full flex justify-between">
                    <span>{element.Name}</span>
                    <div className="flex">
                      <input
                        disabled={chosenCompany === -1}
                        defaultValue="1"
                        type="number"
                        min={1}
                        max={50}
                        id={`discount_${element.Id}`}
                      />
                      <button
                        disabled={chosenCompany === -1}
                        onClick={() => {
                          const discount = (
                            document.getElementById(
                              `discount_${element.Id}`
                            ) as HTMLInputElement
                          ).value;
                          if (discount) {
                            let isOnList = false;
                            for (const elementTemp of productDiscounts) {
                              if (elementTemp.product.Id === element.Id) {
                                isOnList = true;
                                break;
                              }
                            }
                            try {
                              if (
                                !isOnList &&
                                parseFloat(discount) > 0 &&
                                parseFloat(discount) <= 50
                              ) {
                                dispatch(
                                  addProductDiscount({
                                    product: element,
                                    discount: discount,
                                  })
                                );
                              }
                            } catch (e) {}
                          }
                        }}
                        className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                      >
                        dodaj
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="w-full max-h-96 overflow-scroll space-y-2"></div>
          <button
            disabled={chosenCompany === -1}
            className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
            onClick={() => {
              if (productDiscounts.length > 0) {
                let discounts = [];
                for (const element of productDiscounts) {
                  discounts.push({
                    Product_id: element.product.Id,
                    Discount: element.discount,
                  });
                }
                dispatch(
                  addIndividualDiscountToProduct({
                    companyId: chosenCompany,
                    discounts: discounts,
                  })
                );
              }
            }}
          >
            Zatwierdź
          </button>
        </div>
      )}
      {chosenCompany !== -1 && (
        <div className="relative w-1/3 flex flex-col space-y-2">
          {categoryDiscountState.isLoading && (
            <LoadingAnimation color="primary" />
          )}
          {categoryDiscountState.failedLoading && (
            <ErrorMessage
              message="Wystąpił błąd"
              error={productDiscountState.error}
            />
          )}
          {categoryDiscountState.hasLoaded && (
            <InfoMessage
              message="Rabaty zostały załadowane"
              Icon={CheckCircleIcon}
            />
          )}
          {chosenCompany !== -1 && (
            <div className="w-full">
              <span className="font-bold underline">Wybrane kategorie</span>
              <div className="w-full max-h-96 overflow-scroll">
                {categoriesDiscounts &&
                  categoriesDiscounts.map((element) => {
                    return (
                      <div className="w-full flex justify-between items-center">
                        <span>{element.category}</span>
                        <div className="flex items-center space-x-1">
                          <span className="underline font-semibold">
                            {element.discount}%
                          </span>
                          <button
                            disabled={chosenCompany === -1}
                            onClick={() => {
                              dispatch(removeCategoryDiscount(element));
                            }}
                          >
                            usuń
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {parentCategory !== undefined && parentCategory?.Id !== 1 && (
            <div
              className="py-1 flex items-center cursor-pointer"
              onClick={() => {
                dispatch(setCurrentCategoryId(parentCategory.Parent_Category));
              }}
            >
              <ChevronLeftIcon />
              <span className="text-lg font-bold">{parentCategory.Name}</span>
            </div>
          )}
          {categories && (
            <div className="w-full max-h-100 overflow-scroll flex flex-col space-y-1">
              <div className="flex justify-between items-center">
                    <span className="relative rounded-lg bg-neutral-100 hover:bg-neutral-200 p-1 flex space-x-1 items-center text-base cursor-pointer">
                      Wszystkie produkty</span>
                    <input
                      defaultValue="0"
                      disabled={chosenCompany === -1}
                      type="number"
                      min={1}
                      max={50}
                      id={`category_dicsount_1`}
                      ref={inputRef}
                    />
                    <span>%</span>
                    <button
                      disabled={chosenCompany === -1}
                      onClick={() => {
                        const discount = (
                          document.getElementById(
                            `category_dicsount_1`
                          ) as HTMLInputElement
                        ).value;
                        try {
                          if (
                            discount &&
                            parseFloat(discount) > 0 &&
                            parseFloat(discount) <= 50
                          ) {
                            dispatch(
                              addCategoryDiscount({
                                category: parentCategory.Name,
                                category_id: parentCategory.Id,
                                discount: discount,
                              })
                            );
                            if(inputRef.current!==null){
                              inputRef.current.value='0'
                            }
                          }
                        } catch (e) {}
                      }}
                      className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                    >
                      dodaj
                    </button>
                </div>
              {categories.map((category) =>{
                var filteredCategories = companyDiscounts.categories.filter(element=>{
                  if(element.Id===category.Id){
                    return true;
                  }
                  return false;
                });
                if(filteredCategories.length===0){
                  filteredCategories=companyDiscounts.categories.filter(element=>{
                    if(element.Id===category.Parent_Category){
                      return true;
                    }
                    return false;
                  })
                  if(filteredCategories.length===0){
                    filteredCategories=companyDiscounts.categories.filter(element=>{
                      if(element.Id===(categories.filter(element2=>{
                        if(element2.Id===category.Parent_Category){
                          return true
                        }
                        else{
                          return false
                        }})[0]?.Id??'-1')){
                        return true;
                      }
                      return false;
                    })
                  }
                }
                var value = filteredCategories[0]?.Discount.toString()??'0';
                return(
                  <div className="flex justify-between items-center">
                    <Category
                      key={category.Id}
                      category={category}
                      setCurrentCategoryId={setCurrentCategoryId}
                    />
                    <div className="flex">
                      <input
                        defaultValue={value}
                        key={value}
                        //value={value}
                        disabled={chosenCompany === -1}
                        type="number"
                        min={1}
                        max={50}
                        id={`category_dicsount_${category.Id}`}
                      />
                      <span>%</span>
                      <button
                        disabled={chosenCompany === -1}
                        onClick={() => {
                          const discount = (
                            document.getElementById(
                              `category_dicsount_${category.Id}`
                            ) as HTMLInputElement
                          ).value;
                          try {
                            if (
                              discount &&
                              parseFloat(discount) > 0 &&
                              parseFloat(discount) <= 50
                            ) {
                              dispatch(
                                addCategoryDiscount({
                                  category: category.Name,
                                  category_id: category.Id,
                                  discount: discount,
                                })
                              );
                            }
                          } catch (e) {}
                        }}
                        className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
                      >
                        dodaj
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <button
            disabled={chosenCompany === -1}
            className="border-2 p-2 hover:border-primary hover:bg-primary hover:text-white"
            onClick={() => {
              dispatch(
                addIndividualDiscountCategory({
                  discounts: categoriesDiscounts,
                  companyId: chosenCompany,
                })
              );
            }}
          >
            Zatwierdź
          </button>
        </div>
      )}
    </div>
  );
}

import { motion } from "framer-motion";
import React from "react";
import Links from "../../Common/Links/Links";
import CalculatorsModule from "./CalculatorsModule/CalculatorsModule";
import ArticlesModule from "./ArticlesModule/ArticlesModule";

function Calculators() {
  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full space-y-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <Links />
      </div>
      <div className="w-full my-10 bg-primary-darker">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
            Kalkulatory sprężonego powietrza
          </h1>
        </motion.div>
      </div>
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <CalculatorsModule />
      </div>
      <div className="w-full my-10 bg-primary-darker">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
            Artykuły
          </h1>
        </motion.div>
      </div>
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <ArticlesModule />
      </div>
    </motion.div>
  );
}

export default Calculators;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  Parameter,
  ParameterChoice,
  ParameterResponse,
} from "../../../Models/Parameter";
import { Option } from "../../../Models/Option";
import { server } from "../../../server";
import { StateType, initialState } from "../../../Models/State";
import { Producer } from "../../../Models/Producer";

type parametersState = {
  state: StateType;
  parameters: Parameter[];
  currentCategoryId: string;
  parameterChoices: ParameterChoice[];
  maxminprice: {
    max_price: string;
    min_price: string;
  };
  currentPrice: {
    max_price: string;
    min_price: string;
  };
  producers: Producer[],
  producersState: StateType,
  count: {Choice_id: number, Count: number}[];
};


export const fetchParameters = createAsyncThunk(
  "parameters/fetchParameters",
  async (id: string) => {
    try {
      const response = await axios.get<ParameterResponse>(
        `${server}/api/Parameters/${id}`
      );
      const parametersWithOptionArray: Parameter[] =
        response.data.parameters.map((parameter) => {
          return {
            ...parameter,
            Predefined_choices: parameter.Predefined_choices as Option[],
          };
        });  
      return {
        parameters: parametersWithOptionArray,
        maxminprice: response.data.maxminprice,
        categoryId: id
      };
    } catch (error) {
      throw error;
    }
  }
);

export const getCountsForFilters = createAsyncThunk(
  'parameters/getCountsForFilters',
  async({Category_id, parameters, choices}:{Category_id: number, parameters: number[], choices: number[]})=>{
    try{
      
      const response = await axios.post<{Count:number, Choice_id: number}[]>(`${server}/api/Parameters/count`,
      {parameters: parameters, Category_id: Category_id, choices: choices});

      return response.data;
    }
    catch(e){
      throw e;
    }
  }
)

export const fetchProducers = createAsyncThunk(
  'parameters/fetchProducers',
  async({
    searchQuery = "",
    category = "1",
    filters = [],
    price_min = "0",
    price_max = "100000",
    skok=''
  }:
  {
    searchQuery:string,
    category:string,
    filters?:{ parameterId: string; choicesIds: string[] }[],
    price_min:string,
    price_max:string,
    skok:string
  })=>{
    try{
      const result = await axios.get(`${server}/api/Producers/products`,
        {
          params: {
            filters: filters,
            category: category,
            searchQuery: searchQuery?.replaceAll("/", "*1*"),
            price_min: price_min,
            price_max: price_max,
            skok: skok
          }
        }
      );
      return result.data.producers;
    }
    catch(e){
      throw e;
    }
  }
)

const parametersSlice = createSlice({
  name: "parameters",
  initialState: {
    state: {
      isLoading: false,
      failedLoading: false,
      hasLoaded: false,
      error: null,
    },
    parameters: [],
    parameterChoices: [],
    currentCategoryId: '-1',
    maxminprice: {
      max_price: "10000",
      min_price: "0",
    },
    currentPrice: {
      max_price: "10000",
      min_price: "0",
    },
    count: [],
    producers: [],
    producersState: initialState
  } as parametersState,
  reducers: {
    addParameterChoice: (state, action): void => {
      const { parameterId, choiceId } = action.payload;
      const parameterChoice = state.parameterChoices.find(
        (parameterChoice) => parameterChoice.parameterId === parameterId
      );

      if (parameterChoice) {
        parameterChoice.choicesIds.push(choiceId);
      } else {
        state.parameterChoices.push({
          parameterId,
          choicesIds: [choiceId],
        });
      }
    },
    removeParameterChoice: (state, action): void => {
      const { parameterId, choiceId } = action.payload;
      const parameterChoice = state.parameterChoices.find(
        (parameterChoice) => parameterChoice.parameterId === parameterId
      );

      if (parameterChoice) {
        const index = parameterChoice.choicesIds.indexOf(choiceId);
        parameterChoice.choicesIds.splice(index, 1);
      }
    },
    removeAllParameterChoices: (state): void => {
      state.parameterChoices = [];
    },
    setPrice: (state, action): void => {
      state.currentPrice = action.payload;
    },
  },
  extraReducers: {
    [fetchParameters.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.currentCategoryId=action.payload.categoryId;
      const parameters = state.parameters;
      const parametersIds = parameters.map((parameter) => parameter.Id);
      const newParameters = action.payload.parameters as Parameter[];
      const newParametersIds = newParameters.map((parameter) => parameter.Id);
      for (const parameterId of parametersIds) {
        if (!newParametersIds.includes(parameterId)) {
          const parameterChoiceIndex = state.parameterChoices.findIndex(
            (parameterChoice) => parameterChoice.parameterId === parameterId
          );
          state.parameterChoices.splice(parameterChoiceIndex, 1);
        }
      }
      state.parameters = newParameters;
      state.maxminprice = action.payload.maxminprice;
    },
    [fetchParameters.pending.type]: (state) => {
      state.state.hasLoaded = false;
      state.state.isLoading = true;
      state.state.failedLoading = false;
    },
    [fetchParameters.rejected.type]: (state, action) => {
      state.state.hasLoaded = false;
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.error = action.error;
    },
    [getCountsForFilters.fulfilled.type]: (state, action) => {
      /*state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;*/
      state.count = action.payload;
    },
    [getCountsForFilters.pending.type]: (state) => {
      /*state.state.hasLoaded = false;
      state.state.isLoading = true;
      state.state.failedLoading = false;*/
    },
    [getCountsForFilters.rejected.type]: (state, action) => {
      state.state.hasLoaded = false;
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.error = action.error;
    },
    [fetchProducers.pending.type]: (state) => {
      state.producersState.hasLoaded = false;
      state.producersState.isLoading = true;
      state.producersState.failedLoading = false;
      state.producersState.error = null;
    },
    [fetchProducers.fulfilled.type]: (state, action) => {
      state.producersState.hasLoaded = true;
      state.producersState.isLoading = false;
      state.producersState.failedLoading = false;
      state.producers = action.payload;
    },
    [fetchProducers.rejected.type]: (state, action) => {
      state.producersState.hasLoaded = false;
      state.producersState.isLoading = false;
      state.producersState.failedLoading = true;
      state.producersState.error = action.error;
    },
  },
});

export const selectParameters = (state: {
  parameters: parametersState;
}): Parameter[] => {
  return state.parameters.parameters;
};

export const selectParameterChoices = (state: {
  parameters: parametersState;
}): ParameterChoice[] => {
  return state.parameters.parameterChoices;
};

export const selectChoicesForParameter = (
  state: { parameters: parametersState },
  parameterId: number
): ParameterChoice => {
  const parameterChoices = state.parameters.parameterChoices.find(
    (parameterChoice: ParameterChoice) =>
      parameterChoice.parameterId === parameterId
  ) as ParameterChoice;
  return parameterChoices;
};

export const selectParametersState = (state: {
  parameters: parametersState;
}): StateType => {
  return state.parameters.state;
};

export const selectMaxminprice = (state: {
  parameters: parametersState;
}): { max_price: string; min_price: string } => {
  return state.parameters.maxminprice;
};

export const selectCurrentPrice = (state: {
  parameters: parametersState;
}): { max_price: string; min_price: string } => {
  return state.parameters.currentPrice;
};

export const selectParametersCounts = (state:{
  parameters: parametersState;
}):{Count: number, Choice_id: number}[]=>{
  return state.parameters.count;
}

export const selectChosenCategoryId = (state:{
  parameters: parametersState;
}):string =>{
  return state.parameters.currentCategoryId;
}

export const selectProducers = (state:{
  parameters: parametersState
}):Producer[]=>{
  return state.parameters.producers;
}

export const selectProducersState = (state:{
  parameters: parametersState
}):StateType=>{
  return state.parameters.producersState;
}


export const {
  addParameterChoice,
  removeParameterChoice,
  removeAllParameterChoices,
  setPrice,
} = parametersSlice.actions;

export default parametersSlice.reducer;

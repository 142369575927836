import React, { useEffect } from "react";
import CartInfoButtons from "./CartInfoButtons/CartInfoButtons";
import { useSelector } from "react-redux";
import {
  selectCartPrice,
  selectProducts,
} from "../../../Store/Slices/Cart/Cart";

function CartInfo() {
  const products = useSelector(selectProducts);
  const price = useSelector(selectCartPrice);

  useEffect(() => {}, [price, products]);

  return (
    <div className="xl:sticky xl:top-28 flex flex-col w-full rounded-lg p-2 bg-primary bg-opacity-100 shadow-xl text-neutral-50 z-20">
      <div className="flex justify-between items-center w-full flex-wrap">
        <h1 className="text-xl md:text-2xl font-bold whitespace-nowrap">
          Twój koszyk ({products.length})
        </h1>
        <CartInfoButtons type="desktop" />
        <h1 className="text-xl md:text-2xl font-bold whitespace-nowrap">
          Razem: {price.toFixed(2)} PLN
        </h1>
      </div>
      <CartInfoButtons type="mobile" />
    </div>
  );
}

export default CartInfo;

import { motion } from "framer-motion";
import React from "react";
import Links from "../../Common/Links/Links";
import ButtonNeutral50 from "../../Common/Buttons/ButtonNeutral50";
import DownloadIcon from '@mui/icons-material/Download';

type Element = {
  name: string;
  link: string;
  imgSrc: string;
};

const elements: Element[] = [
  {
    name: "Katalog produktów",
    link: "https://pressbarimages.b-cdn.net/Przeglad-wyrobow-PressBar.pdf",
    imgSrc:
      "https://pressbarimages.b-cdn.net/kontakt-uslugi/Zrzut-ekranu-2023-05-23-o-09.07.16.png",
  },
];

function Downloads() {
  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full space-y-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <Links />
      </div>
      <div className="w-full my-10 bg-primary-darker">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
            Do pobrania
          </h1>
        </motion.div>
      </div>
      <div className="w-full px-2 lg:px-5 flex flex-col justify-center items-center text-justify max-w-[1536px]">
        <div className="flex flex-col w-full items-start">
          {elements.map((element, index) => (
            <div
              key={element.name}
              className="flex items-center justify-center space-x-4"
            >
              <div className="flex flex-col items-start justify-start">
                <h1 className="text-xl font-semibold">{element.name}</h1>
                <img src={element.imgSrc} alt={element.name} width={200} />
              </div>
              <div>
                <ButtonNeutral50
                  text={"Pobierz"}
                  size={"large"}
                  Icon={DownloadIcon}
                  onClick={() => window.open(element.link, "_blank")}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}

export default Downloads;

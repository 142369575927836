import React from "react";
import AirflowCalculator from "./AirflowCalculator/AirflowCalculator";
import DiameterCalculator from "./DiameterCalculator/DiameterCalculator";
import PressureDropCalculator from "./PressureDropCalculator/PressureDropCalculator";
import AirConsumptionCalculator from "./AirConsumptionCalculator/AirConsumptionCalculator";
import PushingForceCalculator from "./PushingForceCalculator/PushingForceCalculator";
import DiameterCalculatorSecond from "./DiamterCalculatorSecond/DiameterCalculatorSecond";
import PressureCalculator from "./PressureCalculator/PressureCalculator";
import PullingForceCalculator from "./PullingForceCalculator/PullingForceCalculator";

function CalculatorsModule() {
  return (
    <div className="w-full flex flex-col items-center space-y-6">
      <AirflowCalculator />
      <DiameterCalculator />
      <PressureDropCalculator />
      <AirConsumptionCalculator />
      <PushingForceCalculator />
      <DiameterCalculatorSecond />
      <PressureCalculator />
      <PullingForceCalculator />
    </div>
  );
}

export default CalculatorsModule;

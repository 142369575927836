import React from "react";
import { ProductDisplay } from "../../../Models/Product";
import OrderInfo from "./OrderInfo/OrderInfo";
import AddToCartBlock from "./AddToCartBlock/AddToCartBlock";
import PriceInfo from "./PriceInfo/PriceInfo";
import DropDownList from "../../../Common/DropDownList/DropDownList";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToList,
  getLists,
  selectAddToList,
  selectLists,
} from "../../../Store/Slices/wishlist/wishlistSlice";
import { AppDispatch } from "../../../Store/store";
import NotifyWhenAvailable from "./NotifyWhenAvailable/NotifyWhenAvailable";
import FavoriteIcon from "@mui/icons-material/Favorite";

type Props = {
  product: ProductDisplay;
  deliveryTime: string;
};

function ProductControls({ product, deliveryTime }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const [showListDropdown, setShowListDropdown] = React.useState(false);
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  const lists = useSelector(selectLists);
  const addToListStatus = useSelector(selectAddToList);

  /*React.useEffect(() => {
    dispatch(getLists());
  }, [dispatch]);*/

  return (
    <div className="fixed bottom-0 xl:sticky z-40 xl:top-32 w-full xl:w-1/4 h-fit xl:pl-0 xl:pr-5">
      <div className="drop-shadow-lg xl:drop-shadow-none p-1 xl:p-2 xl:pb-2 xl:top-28 h-fit bg-neutral-100 bg-opacity-80 xl:bg-opacity-100 backdrop-blur-lg rounded-lg xl:shadow-md flex flex-col  sm:space-y-4 items-center justify-between">
        <div className="w-full justify-between">
          {innerWidth > 1024 && (
            <div className="relative">
              <button
                className={`w-full group flex items-center justify-end space-x-1 transition-all`}
                onClick={() => {
                  setShowListDropdown(true);
                }}
              >
                <span className="text-neutral-700 group-hover:text-neutral-950 text-xl font-bold">
                  {addToListStatus.id !== product.Id
                    ? "Dodaj do listy"
                    : addToListStatus.name}
                </span>
                <div className="text-neutral-700 group-hover:text-neutral-950">
                  <FavoriteIcon />
                </div>
              </button>
              {showListDropdown && (
                <DropDownList
                  options={lists.map((list) => {
                    return {
                      name: list.Name,
                      value: list.Id.toString(),
                      icon: null,
                    };
                  })}
                  selectedOption=""
                  customClasses="right-0"
                  onClick={(value) => {
                    dispatch(
                      addProductToList({
                        listId: parseInt(value),
                        products: [
                          {
                            Product_id: product.Id,
                            Quantity: 1,
                          },
                        ],
                      })
                    );
                    setShowListDropdown(false);
                  }}
                />
              )}
            </div>
          )}
        </div>
        <OrderInfo
          isAvailable={product.Availability ? true : false}
          deliveryTime={deliveryTime}
        />
        <PriceInfo product={product} />

        {product.Availability === 0 && <NotifyWhenAvailable />}
        {product.Availability === 1 && (
          <div className="w-full flex items-center">
            <AddToCartBlock product={product} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductControls;

import React, { useEffect, useState } from "react";
import ProductList from "../../../Common/ProductList/ProductList";
import ProductListAdmin from "../../Admin/AdminPages/Products/ProductList";
import ProductCard from "../../../Common/ProductCard/ProductCard";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PageControls from "./PageControls/PageControls";
import {
  fetchProducts,
  fetchProductsCount,
  fetchProductsParams,
  selectNumberOfProducts,
  selectProducts,
  selectProductsState,
} from "../../../Store/Slices/Products/Products";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import LoadingAnimation from "../../../Common/LoadingAnimation/LoadingAnimation";
import CategoriesFilters from "../../../Common/CategoriesFilters/CategoriesFilters";
import Legend from "./Legend/Legend";
import { selectParentCategory } from "../../../Store/Slices/Categories/Categories";
import ButtonTransparentToColor from "../../../Common/Buttons/ButtonTransparentToColor";
import ErrorIcon from "@mui/icons-material/Error";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { fetchProducers } from "../../../Store/Slices/Parameters/Parameters";

type Params = {
  admin?: boolean;
};

export const silowniki = [
  "6",
  "2",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "5",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "276",
  "277",
  "278",
  "279",
  "280",
  "282",
  "283",
  "284",
  "285",
  "287",
  "288",
  "289",
  "322",
  "323",
  "324",
  "325",
  "326",
  "327",
  "266",
  "264",
  "265",
  "334",
  "335",
  "336",
  "337",
  "338",
];

function SearchResults({ admin }: Params) {
  const dispatch = useDispatch<AppDispatch>();

  const [searchParams, setSearchParams] = useSearchParams({
    display: window.innerWidth > 768 ? "list" : "grid",
  });

  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    document.title = "Szukaj - Press Bar Pneumatyka";
    if (searchParams.get("searchQuery")) {
      document.title = `"${
        searchParams.get("searchQuery") ?? "Szukaj"
      }" - Press Bar Pneumatyka`;
    } else {
      document.title = `${
        currentCategory?.Name ?? "Szukaj"
      } - Press Bar Pneumatyka`;
    }
    const fetch: fetchProductsParams = {
      searchQuery: searchParams.get("searchQuery") || "",
      category: searchParams.get("category") || "1",
      limit: searchParams.get("limit") || "20",
      sort: searchParams.get("sort") || "default",
      filters:
        decodeURIComponent(searchParams.get("filters") || "") !== ""
          ? JSON.parse(decodeURIComponent(searchParams.get("filters") || ""))
          : [],
      producers:
        decodeURIComponent(searchParams.get("producers") || "") !== ""
          ? JSON.parse(decodeURIComponent(searchParams.get("producers") || ""))
          : [],
      page: searchParams.get("page") || "1",
      price_min: searchParams.get("price_min") || "0",
      price_max: searchParams.get("price_max") || "1000000",
      skok: searchParams.get("skok") || "",
      wydajnosc: searchParams.get("wydajnosc") || "",
    };

    dispatch(fetchProducts(fetch));
    dispatch(fetchProductsCount(fetch));
    dispatch(fetchProducers({searchQuery: searchParams.get("searchQuery") || "",
      category: searchParams.get("category") || "1",
      filters:
        decodeURIComponent(searchParams.get("filters") || "") !== ""
          ? JSON.parse(decodeURIComponent(searchParams.get("filters") || ""))
          : [],
      price_min: searchParams.get("price_min") || "0",
      price_max: searchParams.get("price_max") || "1000000",
      skok: searchParams.get("skok") || "",})
    );
  }, [
    searchParams.get("searchQuery"),
    searchParams.get("category"),
    searchParams.get("limit"),
    searchParams.get("sort"),
    searchParams.get("filters"),
    searchParams.get("page"),
    searchParams.get("price_min"),
    searchParams.get("price_max"),
    searchParams.get("skok"),
    searchParams.get("producers"),
    searchParams.get("wydajnosc"),
  ]);

  const currentCategory = useSelector(selectParentCategory);

  const products = useSelector(selectProducts);
  const productsState = useSelector(selectProductsState);

  const totalElements = useSelector(selectNumberOfProducts);

  const nagivate = useNavigate();
  const location = useLocation();

  const [locationHistory, setLocationHistory] = useState<string[]>([]);

  useEffect(() => {
    setLocationHistory((prev) => {
      return [...prev, location.search];
    });
  }, [location.search]);

  useEffect(()=>{
    const maxPage = Math.ceil(totalElements / parseInt(searchParams.get("limit") || "20")) || 1;
    if(parseInt(searchParams.get("page")??'1')>maxPage){
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, [totalElements]);

  return (
    <div className="mb-10 w-full flex flex-col items-center">
      <div className="flex flex-col lg:flex-row justify-center items-top lg:space-x-4 w-full max-w-[1536px]">
        <div className="hidden p-2 lg:flex h-[70vh] flex-col items-center sticky top-32 shrink-0 w-1/4 bg-neutral-100 rounded-lg shadow-md">
          <CategoriesFilters admin={admin} />
        </div>

        <div
          className={`w-full lg:w-3/4 relative shrink-1 rounded-lg flex flex-col items-center`}
        >
          {productsState.isLoading && <LoadingAnimation color="primary" />}
          {false&&silowniki.includes(searchParams.get("category") || "") && (
            <div className="w-full p-2 flex items-center flex-wrap">
              <ButtonTransparentToColor
                link={"silowniki"}
                text={"Konfigurator"}
                size={"small"}
                customClasses="mr-2"
              />
              <p className="text-sm sm:text-base text-neutral-600">
                Przejdź do konfiguratora siłowników
              </p>
            </div>
          )}
          <div className="w-full flex flex-col-reverse sm:flex-row-reverse items-center justify-between">
            {locationHistory !== undefined && (
              <div className="p-2 w-full sm:w-auto">
                <button
                  className="whitespace-nowrap flex items-center space-x-2 group font-semibold"
                  onClick={() => {
                    setLocationHistory((prev) => {
                      prev.pop();
                      return prev;
                    });
                    if (locationHistory[locationHistory.length - 2]) {
                      nagivate(locationHistory[locationHistory.length - 2]);
                    } else {
                      nagivate("/");
                    }
                  }}
                >
                  <div className="text-base">
                    {locationHistory.length > 1 ? (
                      <ArrowBackIcon />
                    ) : (
                      <HomeIcon />
                    )}
                  </div>
                  <span className="group-hover:underline text-base">
                    {locationHistory.length > 1
                      ? "Cofnij"
                      : "Powrót do strony głownej"}
                  </span>
                </button>
              </div>
            )}
            <div className="w-full p-2">
              {searchParams.get("searchQuery") !== null && searchParams.get("searchQuery")!==''? (
                <div className="flex items-center space-x-1">
                  <h1 className="text-xl sm:text-3xl font-bold text-neutral-800">
                    Wyniki dla "{searchParams.get("searchQuery")}"
                  </h1>
                  <button
                    onClick={() => {
                      setSearchParams((prev) => {
                        prev.set("searchQuery", "");
                        return prev;
                      });
                    }}
                    className="text-2xl text-neutral-600 hover:text-neutral-800 transition-colors duration-200 ease-in-out"
                  >
                    <CloseIcon />
                  </button>
                </div>
              ) : (
                <h1 className="text-xl sm:text-3xl font-bold text-neutral-800">
                  {currentCategory?.Name}
                </h1>
              )}
              <span className="text-lg sm:text-2xl text-neutral-600">
                {productsState.hasLoaded &&
                  `${totalElements} ${
                    totalElements === 1
                      ? "produkt"
                      : totalElements.toString().endsWith("2") ||
                        totalElements.toString().endsWith("3") ||
                        totalElements.toString().endsWith("4")
                      ? "produkty"
                      : "produktów"
                  }`}
              </span>
            </div>
          </div>
          {false&&<Legend />}
          <PageControls />
          {productsState.failedLoading && (
            <div className="h-full w-full flex flex-col justify-center items-center space-y-2 p-2">
              <ErrorIcon />
              <h1 className="text-3xl font-bold text-neutral-800">
                Błąd ładowania produktów
              </h1>
              <p className="text-lg text-neutral-600">
                {productsState.error?.message || "Unknown error"}
              </p>
            </div>
          )}
          {productsState.hasLoaded && products.length === 0 && (
            <div className="h-full w-full flex flex-col justify-center items-center space-y-2 p-2">
              <SearchIcon />
              <h1 className="text-3xl font-bold text-neutral-800">
                Nie znaleziono produktów
              </h1>
              <p className="text-lg text-neutral-600">
                Spróbuj zmienić kryteria wyszukiwania.
              </p>
            </div>
          )}
          {searchParams.get("display") === "list" &&
            productsState.hasLoaded && (
              <div className="w-full flex flex-col items-center space-y-2 p-2">
                {products.map((product) =>
                  admin ? (
                    <ProductListAdmin key={product.Id} product={product} />
                  ) : (
                    <ProductList key={product.Id} product={product} />
                  )
                )}
              </div>
            )}
          {searchParams.get("display") === "grid" &&
            productsState.hasLoaded && (
              <div className="flex flex-wrap space-x-2 space-y-2 items-center justify-center p-2">
                {products.map((product, index) => (
                  <ProductCard
                    key={product.Id}
                    product={product}
                    isFirstChild={index === 0}
                    mobile={windowWidth < 760}
                  />
                ))}
              </div>
            )}
          <PageControls />
        </div>
      </div>
    </div>
  );
}

export default SearchResults;

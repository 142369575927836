import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import {
  fetchProduct,
  fetchProductMoreDetails,
  fetchSimilarProducts,
  getProductsParametersChoices,  
  selectDeliveryTime,
  selectProduct,
  selectProductDescriptionState,
  selectProductState,
} from "../../Store/Slices/Products/Products";
import { ProductModel, productToDisplay } from "../../Models/Product";
import Gallery from "./Gallery/Gallery";
import LoadingAnimation from "../../Common/LoadingAnimation/LoadingAnimation";
import { motion } from "framer-motion";
import ProductControls from "./ProductControls/ProductControls";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import InfoBelow from "./InfoBelow/InfoBelow";
import { StateType } from "../../Models/State";
import SimiliarProducts from "./SimiliarProducts/SimiliarProducts";
import ProductData from "./ProductData/ProductData";
import { Link } from "react-router-dom";
import ProductParameters from "./ProductParameters/ProductParameters";
import { Helmet } from "react-helmet";

function Product() {
  const dispatch = useDispatch<AppDispatch>();
  const productTimeDelivery = useSelector(selectDeliveryTime);
  const product: ProductModel = useSelector(selectProduct);
  const ProductState: StateType = useSelector(selectProductState);
  const productDescription = useSelector(selectProductDescriptionState);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchProduct(id));
      dispatch(fetchProductMoreDetails(id));
      dispatch(fetchSimilarProducts({id, mobile: window.innerWidth<760}));
      dispatch(getProductsParametersChoices(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    document.title = "Produkt - Press Bar Pneumatyka";
    if (ProductState.hasLoaded) {
      document.title = `${product.details?.Name} - Press Bar Pneumatyka`;
    }
  }, [ProductState.hasLoaded]);
  return (
    <motion.div
      className="relative w-full min-h-96 flex flex-col items-center items-top justify-center max-w-[1536px] space-y-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {ProductState.hasLoaded&&product.details.Name &&(<Helmet>
        <title>{product.details.Name??''} ({product.details.Catalog_name??''})</title>
        {product.technical_data!==undefined&&<meta name="description" content={`${product.technical_data[0]!==undefined&&product.technical_data[0]!==null?(Object.keys(product.technical_data[0]))+'-'??'':''}${product.technical_data[0]!==undefined&&product.technical_data[0]!==null?Object.values(product.technical_data[0]??{})??'':''},
            ${product.technical_data[1]!==undefined&&product.technical_data[1]!==null?(Object.keys(product.technical_data[1]))+'-'??'':''}${product.technical_data[1]!==undefined&&product.technical_data[1]!==null?(Object.values(product.technical_data[1]))??'':''},
            ${product.technical_data[2]!==undefined&&product.technical_data[2]!==null?(Object.keys(product.technical_data[2]))+'-'??'':''}${product.technical_data[2]!==undefined&&product.technical_data[2]!==null?(Object.values(product.technical_data[2]))??'':''},
            ${product.technical_data[3]!==undefined&&product.technical_data[3]!==null?(Object.keys(product.technical_data[3]))+'-'??'':''}${product.technical_data[3]!==undefined&&product.technical_data[3]!==null?(Object.values(product.technical_data[3]))??'':''},
            ${product.technical_data[4]!==undefined&&product.technical_data[4]!==null?(Object.keys(product.technical_data[4]))+'-'??'':''}${product.technical_data[4]!==undefined&&product.technical_data[4]!==null?(Object.values(product.technical_data[4]))??'':''},
            ${product.technical_data[5]!==undefined&&product.technical_data[5]!==null?(Object.keys(product.technical_data[5]))+'-'??'':''}${product.technical_data[5]!==undefined&&product.technical_data[5]!==null?(Object.values(product.technical_data[5]))??'':''},`} />}
        {product.technical_data!==undefined&&<meta name="keywords" content={`Sklep pneumatyka, pneumatyka, ${product.details.Catalog_name??''}, ${product.details.Producer??''}, ${product.details.Name??''},
        ${product.breadcrumbs!==undefined?product.breadcrumbs[product.breadcrumbs?.length-1].NAME:''}, ${product.technical_data[0]!==undefined&&product.technical_data[0]!==null?(Object.keys(product.technical_data[0]))+'-'??'':''}${product.technical_data[0]!==undefined&&product.technical_data[0]!==null?Object.values(product.technical_data[0]??{})??'':''},

        ${product.technical_data[1]!==undefined&&product.technical_data[1]!==null?(Object.keys(product.technical_data[1]))+'-'??'':''}${product.technical_data[1]!==undefined&&product.technical_data[1]!==null?(Object.values(product.technical_data[1]))??'':''},
        ${product.technical_data[2]!==undefined&&product.technical_data[2]!==null?(Object.keys(product.technical_data[2]))+'-'??'':''}${product.technical_data[2]!==undefined&&product.technical_data[2]!==null?(Object.values(product.technical_data[2]))??'':''},
        ${product.technical_data[3]!==undefined&&product.technical_data[3]!==null?(Object.keys(product.technical_data[3]))+'-'??'':''}${product.technical_data[3]!==undefined&&product.technical_data[3]!==null?(Object.values(product.technical_data[3]))??'':''},
        ${product.technical_data[4]!==undefined&&product.technical_data[4]!==null?(Object.keys(product.technical_data[4]))+'-'??'':''}${product.technical_data[4]!==undefined&&product.technical_data[4]!==null?(Object.values(product.technical_data[4]))??'':''},
        ${product.technical_data[5]!==undefined&&product.technical_data[5]!==null?(Object.keys(product.technical_data[5]))+'-'??'':''}${product.technical_data[5]!==undefined&&product.technical_data[5]!==null?(Object.values(product.technical_data[5]))??'':''},
        ${product.technical_data[6]!==undefined&&product.technical_data[6]!==null?(Object.keys(product.technical_data[6]))+'-'??'':''}${product.technical_data[6]!==undefined&&product.technical_data[6]!==null?(Object.values(product.technical_data[6]))??'':''},
        ${product.technical_data[7]!==undefined&&product.technical_data[7]!==null?(Object.keys(product.technical_data[7]))+'-'??'':''}${product.technical_data[7]!==undefined&&product.technical_data[7]!==null?(Object.values(product.technical_data[7]))??'':''}`}/>}
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="copyright" content="2024, Press Bar Sp. z o. o. "/>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": product.details.Name??'',
            "image": product.details.Image_url??'',
            "description": product.technical_data!==undefined?`${product.details.Catalog_name??''}, ${product.details.Producer??''}, ${product.details.Name??''},
            ${product.technical_data[0]!==undefined&&product.technical_data[0]!==null?(Object.keys(product.technical_data[0]))+'-'??'':''}${product.technical_data[0]!==undefined&&product.technical_data[0]!==null?Object.values(product.technical_data[0]??{})??'':''},
            ${product.technical_data[1]!==undefined&&product.technical_data[1]!==null?(Object.keys(product.technical_data[1]))+'-'??'':''}${product.technical_data[1]!==undefined&&product.technical_data[1]!==null?(Object.values(product.technical_data[1]))??'':''},
            ${product.technical_data[2]!==undefined&&product.technical_data[2]!==null?(Object.keys(product.technical_data[2]))+'-'??'':''}${product.technical_data[2]!==undefined&&product.technical_data[2]!==null?(Object.values(product.technical_data[2]))??'':''},
            ${product.technical_data[3]!==undefined&&product.technical_data[3]!==null?(Object.keys(product.technical_data[3]))+'-'??'':''}${product.technical_data[3]!==undefined&&product.technical_data[3]!==null?(Object.values(product.technical_data[3]))??'':''},
            ${product.technical_data[4]!==undefined&&product.technical_data[4]!==null?(Object.keys(product.technical_data[4]))+'-'??'':''}${product.technical_data[4]!==undefined&&product.technical_data[4]!==null?(Object.values(product.technical_data[4]))??'':''},
            ${product.technical_data[5]!==undefined&&product.technical_data[5]!==null?(Object.keys(product.technical_data[5]))+'-'??'':''}${product.technical_data[5]!==undefined&&product.technical_data[5]!==null?(Object.values(product.technical_data[5]))??'':''},
            `:'',
            "sku": product.details.Catalog_name??'',
            "mpn": product.details.Catalog_name??'',
            "brand": {
              "@type": "Brand",
              "name": product.details.Producer??''
            },
            "offers": {
              "@type": "Offer",
              "url": 'https://pressbar.pl/product/'+product.details.Catalog_name??'',
              "priceCurrency": 'PLN',
              "price": product.details.Price??0,
              "priceValidUntil": '2024-12-31',
              "itemCondition": "http://schema.org/NewCondition",
              "availability": "http://schema.org/InStock",
              "seller": {
                "@type": "Organization",
                "name": 'Press Bar'
              }
            }
          })}
        </script>
      </Helmet>)}
      {ProductState.hasLoaded && (
        <Breadcrumbs breadcrumbs={product.breadcrumbs} />
      )}
      {ProductState.isLoading && (
        <LoadingAnimation color="primary" type="global" />
      )}
      {ProductState.hasLoaded && (
        <div className="space-y-4 xl:space-y-0 flex flex-col xl:flex-row justify-center items-top xl:space-x-4 w-full mb-10">
          <div className="w-full xl:w-3/4 h-fit px-2 sm:px-5 xl:pl-5 xl:pr-0">
            <div className="relative flex flex-col items-top justify-center space-y-1 xl:space-y-4 rounded-lg  p-2">
              <div className="w-full flex flex-row justify-between items-center">
                <h1 className="text-xl xl:text-4xl w-2/3 font-bold text-neutral-950">
                  {product.details.Name??''}
                </h1>
                <Link
                  to={`/producer/${product.details.Producer??''}`}
                  className="w-1/3 flex items-center justify-end"
                >
                  <img
                    src={product.details.ProducerLogo??''}
                    alt={product.details.Producer??''}
                    className="max-h-32 w-full object-contain object-right"
                  />
                </Link>
              </div>
              <div className="w-full flex flex-col xl:flex-row items-start space-y-2 xl:space-y-0 xl:space-x-2">
                {product?.images !== undefined &&
                  product.images.length !== 0 && (
                    <div className="xl:sticky top-32 w-full flex justify-center items-center h-64 sm:h-72 xl:h-80 2xl:h-96 xl:w-1/2">
                      <Gallery images={product.images} />
                    </div>
                  )}
                <div className="z-40 w-full xl:w-1/2 flex flex-col sticky top-32">
                  <ProductData product={product} />
                  <ProductParameters product={product} />
                </div>
              </div>
              <p>{product&&``}</p>
              <InfoBelow product={product} />
              {productDescription && (
                <div dangerouslySetInnerHTML={{ __html: productDescription }} />
              )}
            </div>
          </div>
          <ProductControls
            product={productToDisplay(product)}
            deliveryTime={productTimeDelivery}
          />
        </div>
      )}
      <SimiliarProducts />
    </motion.div>
  );
}

export default Product;

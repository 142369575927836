import React, { useState, useEffect, useRef } from "react";
import ShowDetailsButton from "../../../../Common/Buttons/ShowDetailsButton";
import DescriptionIcon from "@mui/icons-material/Description";

type Props = {
  description: string;
};

function Description({ description }: Props) {
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkHeight = () => {
      if (descriptionRef.current) {
        const height = descriptionRef.current.getBoundingClientRect().height;
        setIsButtonVisible(height > 156);
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);

  return (
    <div className="w-full">
      <div className="flex items-center space-x-1">
        <h2 className="text-3xl font-bold text-neutral-950">Opis produktu</h2>
        <DescriptionIcon color="secondary" />
      </div>
      <div
        ref={descriptionRef}
        className={`${
          showDetails ? "max-h-fit" : "max-h-44"
        } relative w-full overflow-hidden description-container`}
      >
        <p className="text-lg p-2">{description}</p>
        {isButtonVisible && (
          <ShowDetailsButton
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        )}
      </div>
    </div>
  );
}

export default Description;

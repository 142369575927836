import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateType } from "../../../../Models/State";
import axios from "axios";
import { server } from "../../../../server";
import { CategoryModel } from "../../../../Models/Category";
import { act } from "react-dom/test-utils";


type adminAddProductSliceState = {
    producers: {Id: number, Name: string}[],
    chosenProducer: number,
    producersState: StateType,
    currentCategoryId: string;
    parentCategory: CategoryModel[],
    categories: CategoryModel[],
    categoriesAllMap: Map<number, CategoryModel[]>,
    categoriesAll: CategoryModel[],
    chosenCategory: {Id: number, Name: string},
    categoriesState: StateType,
    images: string[],
    chosenImage: string,
    technicalData: {name: string, value: string}[],
};

export const getCategories=createAsyncThunk(
    'adminCategories/fetchAll',
    async ({id, allCategoriesMap, currentCategory}:{id: string, allCategoriesMap: Map<number, CategoryModel[]>, currentCategory: CategoryModel}) => {
        try {
          if(allCategoriesMap.has(parseInt(id))){
            return {categories: [currentCategory].concat(allCategoriesMap.get(parseInt(id))??[]), concat: false}
          }
          const response = await axios.get<CategoryModel[]>(
            `${server}/api/Categories/${id}/children`
          );
          
          return {categories: response.data, concat: true};
        } catch (error) {
          throw error;
        }
    }
);

export const getProducers = createAsyncThunk(
    'addProduct/getProducers',
    async()=>{
        try{
            const response = await axios.get(`${server}/api/Producers/get`);
            return response.data;
        }
        catch(e){
            throw e;
        }
    }
)

export const addProduct = createAsyncThunk(
    'addProduct/addProduct',
    async({name, catalogName, price, producerId, categoryId, images, imageUrl, availableToBuy, technicalData}:
        {name:string, catalogName:string, price: number,producerId:number, categoryId:number,
        images:string[], imageUrl:string, availableToBuy: boolean, technicalData: {name:string, value:string}[]})=>{
            try{
                var technicalDataString='{'; 
                Object.values(technicalData).forEach((element, index)=>{
                    technicalDataString+='\"'+element.name+'\":\"'+element.value+"\", ";
                });
                technicalDataString = technicalDataString.substring(0, technicalDataString.length-2);
                technicalDataString+="}";
                const response = await axios.post(`${server}/api/Products/admin/add`,
                    {name, catalogName, producerId, price, categoryId, imageUrl, images, availableToBuy, 
                        technicalData: technicalDataString},
                    {withCredentials: true});
                return {};
            }
            catch(e){
                throw e;
            }
    }
)

const addProductSlice = createSlice({
    name: 'addProduct',
    initialState:{
        producers:[],
        chosenProducer: -1,
        producersState: {
            isLoading: false,
            failedLoading: false,
            hasLoaded: false,
            error: null,
        },
        currentCategoryId: "1",
        parentCategory: [],
        categories: [],
        categoriesAllMap: new Map(),
        categoriesAll: [],
        chosenCategory: {},
        categoriesState: {
            isLoading: false,
            failedLoading: false,
            hasLoaded: false,
            error: null,
        },
        images: [],
        chosenImage: '',
        technicalData: []
    } as unknown as adminAddProductSliceState,
    reducers:{
        setChosenProducer:(state, action)=>{
            state.chosenProducer = action.payload
        },
        setCurrentCategoryId: (state, action) => {
            state.currentCategoryId = action.payload;
        },
        setChosenCategory: (state, action)=>{
            state.chosenCategory = action.payload;
        },
        addImageToImages: (state, action)=>{
            state.images = state.images.concat([action.payload]);
        },
        setChosenImage: (state, action)=>{
            state.chosenImage = action.payload;
        },
        addToTechnicalData: (state, action)=>{
            state.technicalData=state.technicalData.concat([action.payload]);
        }
    },
    extraReducers: {
        [getProducers.fulfilled.type]: (state, action)=>{
            state.producersState.hasLoaded = true;
            state.producersState.isLoading = false;
            state.producersState.failedLoading = false;
            state.producers = action.payload;
        },
        [getProducers.pending.type]: (state) => {
            state.producersState.isLoading = true;
            state.producersState.failedLoading = false;
            state.producersState.hasLoaded = false;
        },
        [getProducers.rejected.type]: (state, action) => {
            state.producersState.isLoading = false;
            state.producersState.failedLoading = true;
            state.producersState.hasLoaded = false;
            state.producersState.error = action.error;
        },
        [getCategories.fulfilled.type]: (state, action) => {
            state.categoriesState.hasLoaded = true;
            state.categoriesState.isLoading = false;
            state.categoriesState.failedLoading = false;
            state.parentCategory=[action.payload.categories[0]];
            var category = -1;
            var categories:CategoryModel[] = [];
      
            if(action.payload.concat){
              state.categoriesAll=state.categoriesAll.concat(action.payload.categories);
              ((action.payload.categories as CategoryModel[]).slice(1)).forEach(element=>{
                if(element.Parent_Category!=category){
                  state.categoriesAllMap.set(category, categories);
                  categories=[];
                  category=element.Parent_Category;
                }
                categories.push(element);
              })
              if(((action.payload.categories as CategoryModel[]).slice(1)).length==0){
                category=state.parentCategory[0].Id;
              }
              state.categoriesAllMap.set(category, categories);
            }
            
            state.categories=state.categoriesAllMap.get((action.payload.categories as CategoryModel[])[0].Id)??[];
            state.currentCategoryId = (action.payload.categories as CategoryModel[])[0].Id.toString();
          },
          [getCategories.pending.type]: (state) => {
            state.categoriesState.isLoading = true;
            state.categoriesState.failedLoading = false;
            state.categoriesState.hasLoaded = false;
          },
          [getCategories.rejected.type]: (state, action) => {
            state.categoriesState.isLoading = false;
            state.categoriesState.failedLoading = true;
            state.categoriesState.hasLoaded = false;
            state.categoriesState.error = action.error;
        },
        [addProduct.fulfilled.type]: (state, action)=>{
            state.producersState.hasLoaded = true;
            state.producersState.isLoading = false;
            state.producersState.failedLoading = false;
            state.producers = [];
            state.chosenCategory={Id: -1, Name: ''};
            state.chosenImage='';
            state.chosenProducer=-1;
            state.images=[];

        },
        [addProduct.pending.type]: (state) => {
            state.producersState.isLoading = true;
            state.producersState.failedLoading = false;
            state.producersState.hasLoaded = false;
        },
        [addProduct.rejected.type]: (state, action) => {
            state.producersState.isLoading = false;
            state.producersState.failedLoading = true;
            state.producersState.hasLoaded = false;
            state.producersState.error = action.error;
        },
    }
})


export const selectProducers = (state: {
    addProduct: adminAddProductSliceState;
}): {Id: number, Name: string}[]=>{
    return state.addProduct.producers
}

export const selectChosenProducer = (state: {
    addProduct: adminAddProductSliceState;
}): number=>{
    return state.addProduct.chosenProducer
}

export const selectChosenCategory = (state: {
    addProduct: adminAddProductSliceState;
}): {Id: number, Name: string}=>{
    return state.addProduct.chosenCategory
}

export const selectParentCategory = (state: {
    addProduct: adminAddProductSliceState;
}): CategoryModel => {
    return state.addProduct.parentCategory[0];
};
  
export const selectCurrentCategoryId = (state: {
    addProduct: adminAddProductSliceState;
}): string => {
    return state.addProduct.currentCategoryId;
};
  
export const selectAllCategoriesMap = (state: {
    addProduct: adminAddProductSliceState;
}): Map<number, CategoryModel[]> => {
    return state.addProduct.categoriesAllMap;
};
  
export const selectAllCategories = (state: {
    addProduct: adminAddProductSliceState;
}): CategoryModel[] => {
    return state.addProduct.categoriesAll;
};
export const selectCategories = (state: {
    addProduct: adminAddProductSliceState;
}): CategoryModel[] => {
    return state.addProduct.categories;
};

export const selectImages = (state: {
    addProduct: adminAddProductSliceState;
}): string[] =>{
    return state.addProduct.images;
};

export const selectChosenImage = (state: {
    addProduct: adminAddProductSliceState;
}): string =>{
    return state.addProduct.chosenImage;
};

export const selectTechnicalData = (state: {
    addProduct: adminAddProductSliceState;
}): {name: string, value: string}[]=>{
    return state.addProduct.technicalData;
}


export const {setChosenProducer, setCurrentCategoryId, setChosenCategory,
    addImageToImages, setChosenImage, addToTechnicalData} = addProductSlice.actions;

export default addProductSlice.reducer;
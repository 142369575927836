import React from "react";
import Input from "../../../../../../Common/Input/Input";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../Store/store";
import {
  setPersInfEmail,
  setPersInfName,
  setPersInfSurname,
  setPersInfPhoneNumber,
} from "../../../../../../Store/Slices/Order/Order";

type Props = {
  personalInformation: {
    name: string | undefined;
    surname: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;
  };
};

function PersonalData({ personalInformation }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold">Uzupełnij dane osobowe:</h1>
      <div className="w-full rounded-lg p-3 border-2 flex flex-col lg:flex-row lg:space-y-0 space-y-3 lg:space-x-3 items-center">
        <Input
          label={"name"}
          type={"text"}
          name={"Imię"}
          required={true}
          placeholder="Jan"
          value={
            personalInformation?.name !== undefined
              ? personalInformation?.name
              : ""
          }
          onChange={(e) => {
            if (personalInformation !== undefined) {
              dispatch(setPersInfName(e.target.value));
            }
          }}
          size="sm"
        />
        <Input
          label={"surname"}
          type={"text"}
          name={"Nazwisko"}
          required={true}
          placeholder="Kowalski"
          size="sm"
          value={
            personalInformation?.surname !== undefined
              ? personalInformation?.surname
              : ""
          }
          onChange={(e) => {
            if (personalInformation !== undefined) {
              dispatch(setPersInfSurname(e.target.value));
            }
          }}
        />
        <Input
          label={"email"}
          type={"email"}
          name={"Email"}
          required={true}
          value={
            personalInformation?.email !== undefined
              ? personalInformation?.email
              : ""
          }
          onChange={(e) => {
            if (personalInformation !== undefined) {
              dispatch(setPersInfEmail(e.target.value));
            }
          }}
          placeholder="jan@kowalski.pl"
          size="sm"
        />
        <Input
          label={"phoneNumber"}
          type={"tel"}
          name={"Numer Telefonu"}
          required={true}
          onChange={(e) => {
            if (personalInformation !== undefined) {
              dispatch(setPersInfPhoneNumber(e.target.value));
            }
          }}
          value={
            personalInformation?.phoneNumber !== undefined
              ? personalInformation?.phoneNumber
              : ""
          }
          placeholder="500500500"
          size="sm"
        />
      </div>
    </div>
  );
}

export default PersonalData;

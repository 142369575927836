import React from "react";
import OrderInfo from "./OrderInfo/OrderInfo";
import OrderControls from "./OrderControls/OrderControls";
import { useSelector } from "react-redux";
import { selectStage } from "../../../Store/Slices/Order/Order";
import TopInfo from "./TopInfo/TopInfo";

function OrderProcess() {
  const stage = useSelector(selectStage);

  return (
    <div className="flex flex-col space-y-4 w-full max-w-[1536px]">
      <TopInfo />      
      <div className="flex flex-col xl:flex-row xl:items-top space-y-4 xl:space-x-4 xl:space-y-0">
        <div className={`${stage === 1 ? "w-full" : "w-full xl:w-3/4"} `}>
          <OrderInfo stage={stage} />
        </div>
        {stage !== 1 && <OrderControls />}
      </div>
    </div>
  );
}

export default OrderProcess;

import { ProductCart, ProductDisplay } from "./Product";

export type CartAddProduct = {
  Product_id: number;
  Quantity: number;
};

export type CartAddProducts = {
  Product: ProductDisplay;
  Quantity: number;
};

export const productCartToCartAddProduct = (
  products: CartAddProducts[]
): CartAddProduct[] => {
  const result = [] as CartAddProduct[];
  for (const product of products) {
    result.push({
      Product_id: product.Product.Id,
      Quantity: product.Quantity,
    });
  }
  return result;
};

export const productCarts = (
  products: ProductCart[]
): CartAddProducts[] => {
  const result = [] as CartAddProducts[];
  for (const product of products) {
    result.push({
      Product: product.product,
      Quantity: product.quantity,
    });
  }
  return result;
};

export const getLowestPrice = (product: ProductCart): number => {
  if (
    product.product.Price_Individual !== null &&
    product.product.Price_Individual !== undefined
  ) {
    return product.product.Price_Individual;
  } else if (
    (product.product.Price_Discount !== null &&
      product.product.Price_Discount !== undefined) ||
    (product.product.Category_Discount !== null &&
      product.product.Category_Discount !== undefined)
  ) {
    return Math.min(
      product.product.Price_Discount || Infinity,
      product.product.Price || Infinity
    );
  } else {
    return product.product.Price;
  }
};

import React, { useEffect } from "react";
import { TechnicalDataModel } from "../../../../../Models/Product";
import HelpIcon from "@mui/icons-material/Help";

type Props = {
  object: TechnicalDataModel;
};

function Data({ object }: Props) {
  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <div className="text-lg font-bold w-auto flex justify-start">
      <div className="relative flex space-x-2 items-center">
        <div
          className="hidden cursor-default"
          onMouseEnter={() => setShowDetails(true)}
          onMouseLeave={() => setShowDetails(false)}
        >
          <HelpIcon />
        </div>
        <span className="text-base">{[Object.keys(object)]}</span>
        {false && showDetails && (
          <div className="absolute -top-12 w-fit whitespace-nowrap rounded-lg shadow-md bg-neutral-100 bg-opacity-80 backdrop-blur-md p-2">
            <span className="text-base">{[Object.keys(object)]}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Data;

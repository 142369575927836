import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import wishlistReducer from "./Slices/wishlist/wishlistSlice";
import productsReducer from "./Slices/Products/Products";
import categoriesReduer from "./Slices/Categories/Categories";
import userReducer from "./Slices/User/User";
import parameterReducer from "./Slices/Parameters/Parameters";
import cartReducer from "./Slices/Cart/Cart";
import adminOrdersReducer from "./Slices/admin/Orders/Orders";
import adminUsersReducer from "./Slices/admin/Users/Users";
import adminCategoriesReducer from "./Slices/admin/Categories/Categories";
import orderReducer from "./Slices/Order/Order";
import articlesReducer from "./Slices/Articles/Article";
import actuatorsReducer from "./Slices/Actuators/Actuators";
import indDiscountsProductsReducer from "./Slices/admin/Products/IndDiscounts";
import indPricesProductsReducer from "./Slices/admin/Products/IndPrices";
import addProductReducer from "./Slices/admin/Products/AddProduct";
import offersReducer from "./Slices/admin/Offers/Offers";

import { enableMapSet } from "immer";

enableMapSet();

export const store = configureStore({
  reducer: {
    wishlist: wishlistReducer,
    products: productsReducer,
    categories: categoriesReduer,
    user: userReducer,
    parameters: parameterReducer,
    cart: cartReducer,
    adminOrders: adminOrdersReducer,
    adminUsers: adminUsersReducer,
    adminCategories: adminCategoriesReducer,
    order: orderReducer,
    articles: articlesReducer,
    actuators: actuatorsReducer,
    indDiscountsProducts: indDiscountsProductsReducer,
    indPricesProducts: indPricesProductsReducer,
    addProduct: addProductReducer,
    offers: offersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

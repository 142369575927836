import React from "react";
import { Address } from "../../../../../../Models/Address";
import ButtonGrey from "../../../../../../Common/Buttons/ButtonGrey";
import Confirm from "../../../../../../Common/Modals/Confirm/Confirm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Store/store";
import {
  deleteAddress,
  selectDeleteAddressState,
} from "../../../../../../Store/Slices/User/User";
import LoadingAnimation from "../../../../../../Common/LoadingAnimation/LoadingAnimation";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import SignpostIcon from "@mui/icons-material/Signpost";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FlagIcon from "@mui/icons-material/Flag";

type Props = {
  address: Address;
};

function AddressCard({ address }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const delteState = useSelector(selectDeleteAddressState);

  const [showConfirmDelete, setShowConfirmDelete] =
    React.useState<boolean>(false);

  return (
    <div
      key={address.Id}
      className="relative rounded-lg p-3 w-full flex flex-col shadow-lg bg-neutral-100"
    >
      {delteState.isLoading && (
        <LoadingAnimation color="primary" type="local" />
      )}
      {delteState.failedLoading && (
        <div className="abolute top-0 left-0 p-1">
          <p className="text-red-500">{delteState.error?.message}</p>
        </div>
      )}
      {showConfirmDelete && (
        <Confirm
          text={"Czy na pewno chcesz usunąć ten adres?"}
          handleClose={() => {
            setShowConfirmDelete(false);
          }}
          handleConfirm={() => {
            dispatch(deleteAddress(address.Id.toString()));
            setShowConfirmDelete(false);
            window.location.reload();
          }}
        />
      )}
      <div className="w-full flex items-center justify-between mb-2">
        <h1 className="text-xl font-semibold flex items-center space-x-1">
          <HomeIcon />
          <span>{address.Address_name}</span>
        </h1>
        <div className="flex space-x-1 items-center">
          <ButtonGrey
            text={""}
            size={"small"}
            Icon={EditIcon}
            onClick={() => {
              navigate(`/profile/addresses/edit/${address.Id}`);
            }}
          />
          <ButtonGrey
            text={""}
            size={"small"}
            Icon={DeleteIcon}
            onClick={() => setShowConfirmDelete(true)}
          />
        </div>
      </div>

      <div className="text-base flex items-center space-x-2">
        <div className="flex items-center space-x-1 font-semibold">
          <SignpostIcon />
          <span>Ulica: </span>
        </div>
        <span>{address.Address}</span>
      </div>
      <div className="text-base flex items-center space-x-2">
        <div className="flex items-center space-x-1 font-semibold">
          <LocationCityIcon />
          <span>Miasto: </span>
        </div>
        <span>
          {address.Zip_code}, {address.City}
        </span>
      </div>
      <div className="text-base flex items-center space-x-2">
        <div className="flex items-center space-x-1 font-semibold">
          <FlagIcon />
          <span>Państwo: </span>
        </div>
        <span>{address.Country}</span>
      </div>
    </div>
  );
}

export default AddressCard;

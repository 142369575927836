import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence } from "framer-motion";
import { AppDispatch } from "../../Store/store";
import {
  fetchCategoriesWithChildren,
  selectAllCategories,
  selectAllCategoriesMap,
  selectCategories,
  selectCategoriesState,
  selectCurrentCategoryId,
  selectParentCategory,
  setCurrentCategoryId,
} from "../../Store/Slices/Categories/Categories";
import { useLocation, useSearchParams } from "react-router-dom";
import { CategoryModel } from "../../Models/Category";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { removeAllParameterChoices } from "../../Store/Slices/Parameters/Parameters";
import CategoryList from "./Category/Category";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function CategoriesList({
  admin,
  mobile,
}: {
  admin?: boolean;
  mobile?: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();

  const [searchParams, setSearchParams] = useSearchParams({
    category: "1",
  });
  const location = useLocation();

  let parentCategory: CategoryModel = useSelector(selectParentCategory);
  const { isLoading, failedLoading, hasLoaded, error } = useSelector(
    selectCategoriesState
  );

  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const allCategoriesMap = useSelector(selectAllCategoriesMap);
  const allCategories = useSelector(selectAllCategories);
  const currentCategory = useSelector(selectParentCategory);
  const categories = useSelector(selectCategories);

  useEffect(() => {
    var currentCategoryTemp;
    for (const element of allCategories) {
      if (element.Id.toString() === currentCategoryId.toString()) {
        currentCategoryTemp = element;
        break;
      }
    }
    dispatch(
      fetchCategoriesWithChildren({
        id: currentCategoryId,
        allCategoriesMap,
        currentCategory: currentCategoryTemp ?? currentCategory,
      })
    );
    if (!mobile) {
      if (
        [
          "1",
          "2",
          "7",
          "11",
          "147",
          "177",
          "216",
          "225",
          "226",
          "227",
          "228",
          "229",
          "230",
        ].includes(currentCategoryId)
      ) {
        setSearchParams((prev) => {
          dispatch(removeAllParameterChoices());
          prev.delete("filters");
          prev.delete("price_min");
          prev.delete("price_max");
          return prev;
        });
      }
    }
    //dispatch(fetchCategoriesHierarchy(currentCategoryId));
  }, [currentCategoryId, dispatch, mobile]);

  useEffect(() => {
    const newCategoryId = searchParams.get("category");
    if (newCategoryId !== currentCategoryId.toString() && !mobile) {
      if (newCategoryId === undefined || newCategoryId === null) {
        dispatch(setCurrentCategoryId(1));
      } else {
        dispatch(setCurrentCategoryId(searchParams.get("category")));
      }
    }
  }, [searchParams.get("category"), dispatch, mobile, currentCategoryId, searchParams]);

  return (
    <div className="items-center max-w-[1536px] relative py-2 overflow-x-hidden h-full overflow-y-scroll w-full inline">
      {isLoading && <LoadingAnimation color="primary" />}
      {isLoading && <div className="w-full h-32"></div>}
      {parentCategory !== undefined && parentCategory?.Id !== 1 && (
        <div
          className="py-1 flex items-center cursor-pointer"
          onClick={() => {
            dispatch(setCurrentCategoryId(parentCategory.Parent_Category));
            if (
              (location.pathname.startsWith("/search") ||
                (admin && location.pathname.startsWith("/admin/products"))) &&
              !mobile
            ) {
              setSearchParams((prev) => {
                if(parentCategory.Parent_Category===1){
                  prev.delete('skok');
                  prev.delete('wydajnosc');
                }
                prev.set("category", parentCategory.Parent_Category.toString());
                return prev;
              });
            }
          }}
        >
          <ChevronLeftIcon />
          <span className="text-lg font-bold">{parentCategory.Name}</span>
        </div>
      )}
      {failedLoading && (
        <div className="text-xl font-bold text-red-500">{error?.message}</div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {hasLoaded && (
          <div className="w-auto flex shrink-0 space-x-2 overflow-x-scroll flex-nowrap py-3 snap-start snap-mandatory snap-always">
            {categories.map((category) => (
              <CategoryList
                key={category.Id}
                category={category}
                setCurrentCategoryId={setCurrentCategoryId}
                admin={admin}
                mobile={mobile}
              />
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
/*
{/*<motion.div
            className="flex flex-col space-y-1"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
        ></AnimatePresence>

          </motion.div>*/

export default CategoriesList;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductDisplay } from "../../../../Models/Product";
import { StateType, initialState } from "../../../../Models/State";
import { UserAdminDisplay } from "../../../../Models/User";
import axios from "axios";
import { server } from "../../../../server";
import { add, initial, remove } from "lodash";

type adminProductsSliceState = {
  searchProducts: ProductDisplay[];
  searchUsers: UserAdminDisplay[];
  productDiscounts: {
    product: ProductDisplay;
    discount: number;
  }[];
  chosenUser: number;
  companies: {
    Address: string;
    City: string;
    Id: number;
    Manager_id: number;
    NIP: string;
    Name: string;
    PostalCode: string;
    Email: string;
    Phone_number: string;
  }[];
  chosenUserSearchDiscount: number;
  chosenProducts: ProductDisplay[];
  searchUsersSearchDiscounts: UserAdminDisplay[];
  searchProductsSearchDiscounts: {
    Id: number;
    Name: string;
    Discount: number;
  }[];
  selectChosenUserDiscounts: { Id: number; Name: string; Discount: number }[];
  chosenProductToDelete: number;
  companyDiscounts: {
    categories: { Id: number; Name: string; Discount: number }[];
    products: { Id: number; Name: string; Discount: number }[];
  };
  companyPayments:{
    Payment_id: number, Name: string
  }[],
  payments:{
    Id: number, Name: string
  }[],
  productDiscountState: StateType;
  categoryDiscountState: StateType;
  delteDiscountState: StateType;
  state: StateType;

};

export const getUsers = createAsyncThunk(
  "indDiscountsProducts/getUsers",
  async ({ name }: { name: string }) => {
    try {
      const result = await axios.get<UserAdminDisplay[]>(
        `${server}/api/Users/admin/find/${name}`,
        { withCredentials: true }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCompaniesNip = createAsyncThunk(
  "indDiscountsProducts/getCompanies",
  async ({ nip }: { nip: string }) => {
    try {
      const result = await axios.get(
        `${server}/api/Users/companys?nip=${nip}`,
        { withCredentials: true }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProductsByName = createAsyncThunk(
  "indDiscountsProducts/getProductsByName",
  async ({ name }: { name: string }) => {
    try {
      const response = await axios.get(
        `${server}/api/IndPrices/products/search/${name.replaceAll(
          "/",
          "*1*"
        )}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProductsByNameForUser = createAsyncThunk(
  "indDiscountsProducts/getProductsByNameForUser",
  async ({ name, userId }: { name: string; userId: number }) => {
    try {
      const response = await axios.get(
        `${server}/api/IndPrices/products/discounts?userId=${userId}&name=${name.replaceAll(
          "/",
          "*1*"
        )}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUsersSearchDiscounts = createAsyncThunk(
  "indDiscountsProducts/getUsersSearchDiscounts",
  async ({ name }: { name: string }) => {
    try {
      const result = await axios.get<UserAdminDisplay[]>(
        `${server}/api/Users/admin/find/${name}`,
        { withCredentials: true }
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addCompanyDiscountsToCategories = createAsyncThunk(
  "indDiscountsProducts/addCompanyDiscountsToCategories",
  async ({
    discounts,
    companyId,
  }: {
    discounts: {
      Category_id: number;
      Discount: number;
    }[];
    companyId: number;
  }) => {
    try {
      const result = await axios.post(
        `${server}/api/IndPrices/category/user/discount/add`,
        { discounts, companyId },
        { withCredentials: true }
      );
      return true;
    } catch (e) {
      throw e;
    }
  }
);

export const addCompanyDiscountsToProducts = createAsyncThunk(
  "indDiscountsProducts/addCompanyDiscountsToProducts",
  async ({
    discounts,
    companyId,
  }: {
    discounts: {
      Product_id: number;
      Discount: number;
    }[];
    companyId: number;
  }) => {
    try {
      const result = await axios.post(
        `${server}/api/IndPrices/product/user/discount/add`,
        { discounts, companyId },
        { withCredentials: true }
      );
      return true;
    } catch (e) {
      throw e;
    }
  }
);

export const addIndividualDiscountToProduct = createAsyncThunk(
  "indDiscountsProducts/addIndDiscProduct",
  async ({
    discounts,
    companyId,
  }: {
    companyId: number;
    discounts: { Product_id: number; Discount: number }[];
  }) => {
    try {
      const result = await axios.post(
        `${server}/api/IndPrices/product/user/discount/add`,
        { discounts: discounts, companyId: companyId },
        { withCredentials: true }
      );
      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

export const delteDiscount = createAsyncThunk(
  "indDiscountsProducts/deleteDiscount",
  async ({
    id,
    companyId,
    category,
    discount,
  }: {
    id: number;
    companyId: number;
    category: boolean;
    discount: number;
  }) => {
    try {
      const result = await axios.post(
        `${server}/api/Users/company/remove-discount`,
        { id, companyId, category, discount },
        { withCredentials: true }
      );
      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

export const deleteProductDiscount = createAsyncThunk(
  "indDiscountsProducts/deleteProductDiscount",
  async ({
    id,
    userId,
    discount,
  }: {
    id: number;
    userId: number;
    discount: number;
  }) => {
    try {
      const response = await axios.post(
        `${server}/api/IndPrices/products/delete`,
        { userId, id, discount },
        { withCredentials: true }
      );
      return { id, discount };
    } catch (e) {
      throw e;
    }
  }
);

export const getUserDiscounts = createAsyncThunk(
  "indDiscountsProducts/getUserDiscounts",
  async ({ userId }: { userId: number }) => {
    try {
      const result = await axios.get<UserAdminDisplay[]>(
        `${server}/api/IndPrices/products/user/get/${userId}`,
        { withCredentials: true }
      );
      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getCompanyDiscounts = createAsyncThunk(
  "indDiscounts/getCompanysDiscount",
  async (companyId: number) => {
    try {
      const result = await axios.get<{
        categories: { Id: number; Name: string; Discount: number }[];
        products: { Id: number; Name: string; Discount: number }[];
      }>(`${server}/api/Users/company/discounts/${companyId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

export const fetchCompanyPayments = createAsyncThunk(
  'indDiscounts/fetchCompanyPayments',
  async(companyId: number)=>{
    try{
      const result = await axios.get<{Payment_id: number, Name: string}[]>(
        `${server}/api/Users/admin/company/payments/${companyId}`,
        {withCredentials: true}
      );
      return result.data;
    }
    catch(e){
      throw e;
    }
  }
)

export const fetchPayments = createAsyncThunk(
  'indDiscounts/fetchPayments',
  async()=>{
    try{
      const result = await axios.get<{Id: number, Name: string}[]>(
        `${server}/api/Users/payments`,
        {withCredentials: true}
      );
      return result.data;
    }
    catch(e){
      throw e;
    }
  }
)

export const addPaymentToCompany = createAsyncThunk(
  'indDiscounts/addPaymentToCompany',
  async({companyId, paymentId, name}:{companyId: number, paymentId: number, name: string})=>{
    try{
      if(companyId===-1){
        throw new Error('zly user');
      }
      const result = await axios.post(`${server}/api/Users/company/add-payment`,
      {companyId: companyId, paymentId: paymentId},
      {withCredentials: true});
      return {companyId, paymentId, name};
    }
    catch(e){
      throw e;
    }
  }
)

export const deleteCompanyPayment = createAsyncThunk(
  'indDiscounts/deleteCompanyPayment',
  async({companyId, paymentId}:{companyId: number, paymentId: number})=>{
    try{
      await axios.post(`${server}/api/Users/admin/company/remove-payment`,
      {companyId: companyId, paymentId: paymentId},
      {withCredentials: true});

      return paymentId;
    }
    catch(e){
      throw e;
    }
  }
)

const indDiscountsProductsSlice = createSlice({
  name: "indDiscountsProducts",
  initialState: {
    searchProducts: [],
    searchUsers: [],
    productDiscounts: [],
    chosenUser: -1,
    chosenUserSearchDiscount: -1,
    chosenProducts: [],
    searchUsersSearchDiscounts: [],
    searchProductsSearchDiscounts: [],
    chosenProductToDelete: -1,
    companyDiscounts: { products: [], categories: [] },
    state: {
      isLoading: false,
      failedLoading: false,
      hasLoaded: false,
      error: null,
    },
    productDiscountState: initialState,
    categoryDiscountState: initialState,
    delteDiscountState: initialState,
    payments: [],
    companyPayments: []
  } as unknown as adminProductsSliceState,
  reducers: {
    addToChosenProducts: (state, action) => {
      state.chosenProducts =
        [action.payload].concat(state.chosenProducts) ?? [];
    },
    clearChosenProducts: (state) => {
      state.chosenProducts = [];
    },
    setChosenUser: (state, action) => {
      state.chosenUser = action.payload;
    },
    setChosenProductToDelete: (state, action) => {
      state.chosenProductToDelete = action.payload;
    },
    setChosenUserSearchDiscounts: (state, action) => {
      state.chosenUserSearchDiscount = action.payload;
    },
    addProductDiscount: (state, action) => {
      state.productDiscounts.push(action.payload);
    },
    removeProductDiscount: (state, action) => {
      remove(state.productDiscounts, action.payload);
    },
  },
  extraReducers: {
    [getUsers.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.searchUsers = action.payload;
    },
    [getUsers.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [getUsers.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [getProductsByName.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.searchProducts = action.payload;
    },
    [getProductsByName.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [getProductsByName.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [getUsersSearchDiscounts.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.searchUsersSearchDiscounts = action.payload;
    },
    [getUsersSearchDiscounts.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [getUsersSearchDiscounts.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [deleteProductDiscount.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.searchProductsSearchDiscounts =
        state.searchProductsSearchDiscounts.filter((element) => {
          if (
            element.Discount != action.payload.discount ||
            element.Id != action.payload.id
          ) {
            return true;
          }
          return false;
        });
    },
    [deleteProductDiscount.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [deleteProductDiscount.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [getUserDiscounts.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.searchProductsSearchDiscounts = action.payload;
    },
    [getUserDiscounts.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [getUserDiscounts.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [fetchCompaniesNip.fulfilled.type]: (state, action) => {
      state.companies = action.payload;
    },
    [addIndividualDiscountToProduct.fulfilled.type]: (state, action) => {
      state.productDiscountState.hasLoaded = true;
      state.productDiscountState.isLoading = false;
      state.productDiscountState.failedLoading = false;
      state.productDiscounts = [];
    },
    [addIndividualDiscountToProduct.pending.type]: (state) => {
      state.productDiscountState.isLoading = true;
      state.productDiscountState.failedLoading = false;
      state.productDiscountState.hasLoaded = false;
    },
    [addIndividualDiscountToProduct.rejected.type]: (state, action) => {
      state.productDiscountState.isLoading = false;
      state.productDiscountState.failedLoading = true;
      state.productDiscountState.hasLoaded = false;
      state.productDiscountState.error = action.error;
      state.state.error = action.error;
    },
    [getCompanyDiscounts.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.companyDiscounts = action.payload;
    },
    [getCompanyDiscounts.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [getCompanyDiscounts.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [delteDiscount.fulfilled.type]: (state, action) => {
      state.delteDiscountState.hasLoaded = true;
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = false;
      state.productDiscounts = [];
    },
    [delteDiscount.pending.type]: (state) => {
      state.delteDiscountState.isLoading = true;
      state.delteDiscountState.failedLoading = false;
      state.delteDiscountState.hasLoaded = false;
    },
    [delteDiscount.rejected.type]: (state, action) => {
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = true;
      state.delteDiscountState.hasLoaded = false;
      state.delteDiscountState.error = action.error;
      state.state.error = action.error;
    },
    [fetchCompanyPayments.fulfilled.type]: (state, action) => {
      state.delteDiscountState.hasLoaded = true;
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = false;
      state.companyPayments = action.payload;
    },
    [fetchCompanyPayments.pending.type]: (state) => {
      state.delteDiscountState.isLoading = true;
      state.delteDiscountState.failedLoading = false;
      state.delteDiscountState.hasLoaded = false;
    },
    [fetchCompanyPayments.rejected.type]: (state, action) => {
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = true;
      state.delteDiscountState.hasLoaded = false;
      state.delteDiscountState.error = action.error;
      state.state.error = action.error;
    },
    [fetchPayments.fulfilled.type]: (state, action) => {
      state.delteDiscountState.hasLoaded = true;
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = false;
      state.payments = action.payload;
    },
    [fetchPayments.pending.type]: (state) => {
      state.delteDiscountState.isLoading = true;
      state.delteDiscountState.failedLoading = false;
      state.delteDiscountState.hasLoaded = false;
    },
    [fetchPayments.rejected.type]: (state, action) => {
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = true;
      state.delteDiscountState.hasLoaded = false;
      state.delteDiscountState.error = action.error;
      state.state.error = action.error;
    },
    [addPaymentToCompany.fulfilled.type]: (state, action) => {
      state.delteDiscountState.hasLoaded = true;
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = false;
      state.companyPayments = [...state.companyPayments, {Payment_id: action.payload.paymentId, Name: action.payload.name}];
    },
    [addPaymentToCompany.pending.type]: (state) => {
      state.delteDiscountState.isLoading = true;
      state.delteDiscountState.failedLoading = false;
      state.delteDiscountState.hasLoaded = false;
    },
    [addPaymentToCompany.rejected.type]: (state, action) => {
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = true;
      state.delteDiscountState.hasLoaded = false;
      state.delteDiscountState.error = action.error;
      state.state.error = action.error;
    },
    [deleteCompanyPayment.fulfilled.type]: (state, action) => {
      state.delteDiscountState.hasLoaded = true;
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = false;
      state.companyPayments = state.companyPayments.filter(element=>{
        if(element.Payment_id===action.payload){
          return false;
        }
        return true;
      });
    },
    [deleteCompanyPayment.pending.type]: (state) => {
      state.delteDiscountState.isLoading = true;
      state.delteDiscountState.failedLoading = false;
      state.delteDiscountState.hasLoaded = false;
    },
    [deleteCompanyPayment.rejected.type]: (state, action) => {
      state.delteDiscountState.isLoading = false;
      state.delteDiscountState.failedLoading = true;
      state.delteDiscountState.hasLoaded = false;
      state.delteDiscountState.error = action.error;
      state.state.error = action.error;
    },
  },
});

export const selectProducts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): ProductDisplay[] => {
  return state.indDiscountsProducts.searchProducts;
};

export const selectChosenProducts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): ProductDisplay[] => {
  return state.indDiscountsProducts.chosenProducts;
};

export const selectUsers = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): UserAdminDisplay[] => {
  return state.indDiscountsProducts.searchUsers;
};

export const selectChosenUser = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): number => {
  return state.indDiscountsProducts.chosenUser;
};

export const selectChosenUserSearchDiscounts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): number => {
  return state.indDiscountsProducts.chosenUserSearchDiscount;
};

export const selectUsersSearchDiscounts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): UserAdminDisplay[] => {
  return state.indDiscountsProducts.searchUsersSearchDiscounts;
};

export const selectProductsDiscounts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): { product: ProductDisplay; discount: number }[] => {
  return state.indDiscountsProducts.productDiscounts;
};

export const selectUserDiscounts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): { Id: number; Name: string; Discount: number }[] => {
  return state.indDiscountsProducts.searchProductsSearchDiscounts;
};

export const selectCompanyDiscounts = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): {
  categories: { Id: number; Name: string; Discount: number }[];
  products: { Id: number; Name: string; Discount: number }[];
} => {
  return state.indDiscountsProducts.companyDiscounts;
};

export const selectCompanies = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): {
  Address: string;
  City: string;
  Id: number;
  Manager_id: number;
  NIP: string;
  Name: string;
  PostalCode: string;
  Email: string;
  Phone_number: string;
}[] => {
  return state.indDiscountsProducts.companies;
};

export const selectProductDiscountState = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): StateType => {
  return state.indDiscountsProducts.productDiscountState;
};

export const selectDeleteState = (state: {
  indDiscountsProducts: adminProductsSliceState;
}): StateType => {
  return state.indDiscountsProducts.delteDiscountState;
};

export const selectCompanyPayments = (state:{
  indDiscountsProducts: adminProductsSliceState;
}):{Payment_id: number, Name: string;}[]=>{
  return state.indDiscountsProducts.companyPayments
}

export const selectPayments = (state:{
  indDiscountsProducts: adminProductsSliceState;
}):{Id: number, Name: string;}[]=>{
  return state.indDiscountsProducts.payments
}

export const {
  addToChosenProducts,
  clearChosenProducts,
  setChosenUserSearchDiscounts,
  setChosenProductToDelete,
  setChosenUser,
  addProductDiscount,
  removeProductDiscount,
} = indDiscountsProductsSlice.actions;

export default indDiscountsProductsSlice.reducer;

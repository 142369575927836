import React, { useState } from 'react'
import { ProductDisplay } from '../../../../../../Models/Product';
import Backdrop from '../../../../../../Common/Backdrop/Backdrop';
import {motion} from 'framer-motion'
import { dropIn } from "../../../../../../Animations/Animations";
import Input from '../../../../../../Common/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../Store/store';
import { addToProductsToAdd, fetchProductByCatalogNumber, selectEditingOrdersAddedProduct, selectProductsToAdd } from '../../../../../../Store/Slices/admin/Orders/Orders';


type Props = {
    handleClose: () => void;
    handleConfirm: () => void;
    text: string;
    orderId: number;
    userId: number;
};

export default function AddProduct({ handleClose, handleConfirm, text, orderId, userId }: Props) {

    const [catalogNumber, setCatalogNumber] = useState('');
    const [quantity, setQuantity] = useState(1);

    const dispatch = useDispatch<AppDispatch>();
    const product = useSelector(selectEditingOrdersAddedProduct);
    const productsToAdd = useSelector(selectProductsToAdd);

    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="z-50 text-neutral-950 bg-neutral-50 rounded-lg shadow-lg p-4 space-y-4 flex flex-col items-center w-[90%] sm:w-fit sm:max-w-[640px] bg-opacity-80"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >

                <h1 className="text-3xl font-bold">{text}</h1>

                <p>
                    <input value={catalogNumber} onChange={(e)=>{
                        setCatalogNumber(e.target.value)
                    }} placeholder='Numer katalogowy' type='textarea' name='Numer katalogowy'
                    style={{
                        textAlign: "center",
                        fontSize: "15px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "3px solid #008391",
                      }}/>
                </p>

                <p>
                    <button onClick={()=>{
                        dispatch(fetchProductByCatalogNumber({catalogNumber: catalogNumber, userId: userId}));
                    }}>
                        Szukaj
                    </button>
                </p>

                <p>
                    {
                        product&&(<div>
                            <p>{product.Name}</p>
                            <p>{product.Catalog_name}</p>
                        </div>)
                    }
                </p>

                <p>
                    <span>Ilość: </span>
                    <input value={catalogNumber} onChange={(e)=>{
                        try{
                                setQuantity(parseInt(e.target.value));
                        }
                        catch(e){console.log(e)}
                    }} placeholder='Ilość' type='textarea' name='Ilość'
                    style={{
                        textAlign: "center",
                        fontSize: "15px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "3px solid #008391",
                      }}/>
                </p>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={() => {
                            if(product.Catalog_name!==undefined){
                                handleConfirm();
                                dispatch(addToProductsToAdd(product));
                            }
                        }}
                        className="bg-white text-red-500 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-red-500 hover:text-white transition-all"
                    >
                        <span className="text-xl">Tak</span>
                    </button>
                    <button
                        onClick={() => {
                            handleClose();
                        }}
                        className="bg-white text-green-500 font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-green-500 hover:text-white transition-all"
                    >
                        <span className="text-xl">Nie</span>
                    </button>
                </div>
            </motion.div>
        </Backdrop>
    )
}

import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLandingPageArticles, selectLandingPageArticles, selectLandingPageArticlesState } from '../../Store/Slices/Articles/Article';
import { AppDispatch } from '../../Store/store';
import { ArticleLandingPage } from '../../Models/Article';
import ButtonPrimaryToLighter from '../../Common/Buttons/ButtonPrimaryToLighter';
import { Link } from 'react-router-dom';
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ArchitectureOutlineIcon from '@mui/icons-material/ArchitectureOutlined';


const ProjectCard = ({project, width}:{project: ArticleLandingPage, width: number}) => (
    <div style={{ marginBottom: '20px', marginTop: '20px', marginLeft: '1%', marginRight: '1%',
    border: '1px solid #008391', 
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    width: width<600?'100%':(width<1000?'30%':'22%') }}>
        <h2 style={{
            height: '2.6em',
            paddingBottom:'50px',
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            fontWeight: 'bold', 
            textAlign: 'center'
        }}>
            {project.Title}
        </h2>
        <div style={{
            height: '100%',
            width: '100%',
            marginBottom: '10px',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' }}>

            <img src={project.Image_url} alt={project.Title} style={{ width: '100%' }} />
        </div>
      <ButtonPrimaryToLighter text='Zobacz więcej' size='small' link={`/article/${project.Id}`}/>
    </div>
);

const options = [
    {
      name: "O nas",
      link: "/about",
      icon: <InfoOutlinedIcon style={{ scale: "1.1" }} />,
    },
    {
      name: "Usługi",
      link: "/uslugi",
      icon: <BuildOutlinedIcon style={{ scale: "1.1" }} />,
    },
    {
      name: "Instalacje",
      link: "/uslugi/instalacje",
      icon: <ArchitectureOutlineIcon style={{ scale: "1.1" }} />,
    },
    {
      name: "Utrzymanie ruchu",
      link: "/uslugi/utrzymanie_ruchu",
      icon: <WorkOutlineIcon style={{ scale: "1.1" }} />,
    },
    {
      name: "Sprężarkownie",
      link: "/uslugi/sprezarkownie",
      icon: <EngineeringOutlinedIcon style={{ scale: "1.1" }} />,
    },
];

export default function Articles() {

    const landingPageArticles = useSelector(selectLandingPageArticles);
    const landingPageArticlesState = useSelector(selectLandingPageArticlesState);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchLandingPageArticles());
    }, []);

    return (
        <motion.div
        className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full space-y-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        >
            <header style={{padding: '20px', textAlign: 'center', color: '#008391', fontSize: '25px', fontWeight: 'bold'}}>
                <h1>Realizowane Projekty</h1>
                {/* Miejsce na dodatkowe przyciski lub banery */}
            </header>
            <div className="w-full flex items-center justify-start sm:justify-center space-x-2 overflow-x-scroll">
                {options.map((option) => (
                    <Link
                    key={option.name}
                    to={option.link}
                    className="w-[128px] sm:w-full relative flex flex-col items-center justify-center cursor-pointer p-2 rounded-lg hover:bg-neutral-100"
                    >
                    {option.icon}
                    <span className="whitespace-nowrap">{option.name}</span>
                    </Link>
                ))}
            </div>
            {
                landingPageArticlesState.hasLoaded&&<div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    {landingPageArticles.map(project => <ProjectCard key={project.Id}  project={project} width={width}/>)}
                </div>
            }
        </motion.div>
    )
}

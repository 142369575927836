import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";

type Props = {
  Icon?:
    | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
      })
    | null;
  message: string;
  size?: "small" | "medium" | "large";
};

function InfoMessage({ Icon, message, size = "medium" }: Props) {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center space-y-2 p-2">
      {Icon && <Icon fontSize="large" />}
      <h1
        className={`${size === "small" ? "text-2xl" : ""} ${
          size === "medium" ? "text-3xl" : ""
        } ${size === "large" ? "text-4xl" : ""} font-bold text-neutral-800`}
      >
        {message}
      </h1>
    </div>
  );
}

export default InfoMessage;

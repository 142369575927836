import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../../Common/PrivateRoute/PrivateRoute";
import ProfileControls from "./ProfileControls/ProfileControls";
import { motion } from "framer-motion";
import Orders from "./Pages/Orders/Orders";
import Addresses from "./Pages/Addresses/Addresses";
import PersonalData from "./Pages/PersonalData/PersonalData";
import Company from "./Pages/Company/Company";
import Cookies from "js-cookie";

function Profile() {
  useEffect(() => {
    document.title = "Profil - Press Bar Pneumatyka";
  }, []);

  const bookmarks =
    Cookies.get("company") !== undefined && Cookies.get("company") !== "{}"
      ? [
          {
            id: 3,
            name: "Dane osobowe",
            icon: "person",
            link: "personal-data",
            Element: <PersonalData />,
          },
          {
            id: 1,
            name: "Zamówienia",
            icon: "receipt_long",
            link: "orders",
            Element: <Orders />,
          },
          {
            id: 2,
            name: "Adresy",
            icon: "import_contacts",
            link: "addresses",
            Element: <Addresses />,
          },
          {
            id: 4,
            name: "Firma",
            icon: "Business",
            link: "company",
            Element: <Company />,
          },
          // {
          //   id: 5,
          //   name: "Metody płatności",
          //   icon: "credit_card",
          //   link: "payment-methods",
          //   Element: <PaymentMethods />,
          // },
        ]
      : [
          {
            id: 3,
            name: "Dane osobowe",
            icon: "person",
            link: "personal-data",
            Element: <PersonalData />,
          },
          {
            id: 1,
            name: "Zamówienia",
            icon: "receipt_long",
            link: "orders",
            Element: <Orders />,
          },
          {
            id: 2,
            name: "Adresy",
            icon: "import_contacts",
            link: "addresses",
            Element: <Addresses />,
          },
        ];

  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full px-2 sm:px-5 z-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="space-y-4 lg:space-y-0 flex flex-col lg:flex-row justify-center items-top lg:space-x-4 mb-10 w-full max-w-[1536px]">
        <ProfileControls bookmarks={bookmarks} />
        <div className="w-full lg:w-3/4 h-fit p-2 sm:p-5 bg-neutral-100 rounded-lg shadow-md">
          <Routes>
            <Route
              path="/"
              element={<PrivateRoute>{bookmarks[0].Element}</PrivateRoute>}
            />
            {bookmarks.map((bookmark) => {
              return (
                <Route
                  key={bookmark.id}
                  path={bookmark.link + "/*"}
                  element={<PrivateRoute>{bookmark.Element}</PrivateRoute>}
                />
              );
            })}
          </Routes>
        </div>
      </div>
    </motion.div>
  );
}

export default Profile;

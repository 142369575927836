import { motion } from "framer-motion";
import React, { useEffect } from "react";
import WishlistControls from "./WishlistControls/WishlistControls";
import WishlistInfo from "./WishlistInfo/WishlistInfo";
import ProductList from "../../Common/ProductList/ProductList";
import { useDispatch, useSelector } from "react-redux";
import {
  getLists,
  getProductsToList,
  removeProductFromList,
  selectListId,
  selectListName,
  selectLists,
  selectProducts,
} from "../../Store/Slices/wishlist/wishlistSlice";
import { AppDispatch } from "../../Store/store";
import { selectIsLoggedIn } from "../../Store/Slices/User/User";
import ButtonNeutral50 from "../../Common/Buttons/ButtonNeutral50";
import HomeIcon from "@mui/icons-material/Home";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";

function Wishlist() {
  const products = useSelector(selectProducts);
  const lists = useSelector(selectLists);
  const selectedList = useSelector(selectListId);
  const selectedName = useSelector(selectListName);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getLists());
  }, [isLoggedIn]);

  useEffect(() => {
    if (selectedList != -1) {
      dispatch(getProductsToList(selectedList));
    }
  }, [selectedList]);

  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full px-2 sm:px-5 z-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col xl:flex-row space-y-4 xl:space-x-4 xl:space-y-0 w-full max-w-[1536px]">
        <div className="flex flex-col w-full xl:w-3/4 min-h-96 items-center bg-neutral-100 rounded-lg shadow-md p-2 space-y-2">
          <WishlistInfo name={selectedName} />
          <div className="h-96  overflow-scroll space-y-2 w-full">
            {products.length === 0 && (
              <div className="flex flex-col items-center justify-center w-full h-full space-y-4">
                <HeartBrokenIcon />
                <h1 className="text-4xl font-bold text-center">
                  Ta lista jest pusta
                </h1>
                <ButtonNeutral50
                  link={"/"}
                  text="Strona główna"
                  size="medium"
                  Icon={HomeIcon}
                />
              </div>
            )}
            {products.map((product) => (
              <ProductList
                key={product.Id}
                product={product}
                productQuantity={product.Quantity}
                showCartControls={true}
                showRemoveFromList={true}
                removeFunction={() => {
                  dispatch(
                    removeProductFromList({
                      productId: product.Id,
                      listId: selectedList,
                    })
                  );
                }}
              />
            ))}
          </div>
        </div>
        <WishlistControls lists={lists} />
      </div>
    </motion.div>
  );
}

export default Wishlist;

import React from 'react'
import { motion } from "framer-motion";
import Gallery from '../Product/Gallery/Gallery';


export default function UtrzymanieRuchu() {

    const images = [
        'https://pressbarimages.b-cdn.net/uslugi/20231030_141935.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231031_130412.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231114_102351.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231123_095200.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231206_122435.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20231220_102922.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240105_163422.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240115_212704.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240123_133757.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240124_115218.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240307_103603.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240311_091523.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240314_101729.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240315_172851.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240320_165523.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240325_133805.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240325_144627.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240325_145335.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_164212.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_170208.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240327_170217.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240417_180759.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240528_193135.jpg',
        'https://pressbarimages.b-cdn.net/uslugi/20240606_145652.jpg'
    ];

    return (
        <motion.div
            className="flex flex-col justify-center items-center w-full space-y-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{padding:'15px'}}
        >
            <div className="w-full my-10 bg-primary-darker">
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
                >
                <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
                    Utrzymanie ruchu
                </h1>
                </motion.div>
            </div>
            <header style={{marginBottom: '20px'}}>
                <h1 style={{color: '#008391', fontSize: '22px', fontWeight: 'bold'}}>Nasze Usługi Instalacyjne</h1>
                <p style={{margin: '10px 0'}}>Profesjonalne serwisowanie i konserwacja maszyn przemysłowych.</p>
            </header>

            <section>
                <h2 style={{color: '#008391', marginTop: '10px', fontSize: '19px', fontWeight: 'bold'}}>O Naszym Zespole</h2>
                <p style={{margin: '10px 0'}}>Firma Press Bar posiada w swoim zespole grupy wysoko wykfalifikowanych pracowników takich jak automatycy, elektrycy, mechanicy oraz serwisanci, pracujących na dużych zakładach przemysłowych, m.in. Elektrociepłownie, Huty Szkła.</p>
                <p style={{margin: '10px 0'}}>Zapewniamy ciągłość pracy parku maszynowego oraz instalacji technicznych i technologicznych bez konieczności tworzenia w strukturze firmy rozbudowanego działu Utrzymania Ruchu.</p>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Zakres Naszych Usług</h2>
                <ul>
                    <li>Serwisowanie i naprawa instalacji pneumatyki oraz instalacji sprężonego powietrza</li>
                    <li>Budowa nowych instalacji pneumatyki i sprężonego powietrza</li>
                    <li>Serwisowanie układów chłodzenia glikolu i wody lodowej</li>
                    <li>Serwisowanie i przebudowa instalacji gazów technicznych</li>
                    <li>Serwisowanie systemu oczyszczania spalin w tym czyszczenie Elektrofiltra</li>
                    <li>Specjalistyczne prace czyszczące kotły parowe i wodne, wymienniki ciepła, kanały spalin</li>
                    <li>Serwisowanie sprężarek śrubowych oraz osuszaczy ziębniczych i adsorpcyjnych z neutralizacją kondensatu</li>
                    <li>Usuwanie usterek i awarii, konserwacja, remonty oraz modernizacja w zakresie elektryki i automatyki</li>
                </ul>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Specjalistyczne Usługi Konserwacji i Remontów</h2>
                <p style={{margin: '10px 0'}}>Realizujemy również remonty i konserwacje planowane maszyn, usuwanie awarii, przywracanie pełnej sprawności urządzeń produkcyjnych, zmiany funkcjonalności linii produkcyjnych, wymianę elementów linii, przebudowę systemów zasilania i sterowania, remonty przyłączy, wymianę linii kablowych, rozdzielnic i transformatorów oraz remonty maszyn elektrycznych.</p>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Materiały i Technologie</h2>
                <p style={{margin: '10px 0'}}>Oferujemy montaż instalacji z różnych materiałów oraz w różnych technologiach, w zależności od środowiska pracy oraz wytycznych klienta:</p>
                <ul>
                    <li>Stalowe: zaprasowywane, spawane, skręcane</li>
                    <li>Nierdzewne: zaprasowywane, spawane</li>
                    <li>Z tworzyw sztucznych PP, PE, PVC: zgrzewane elektrooporowo i doczołowo, polifuzja termiczna, klejone</li>
                </ul>
            </section>

            <section>
                <h2 style={{color: '#008391', marginTop: '20px', fontSize: '19px', fontWeight: 'bold'}}>Kontakt</h2>
                <p style={{margin: '10px 0'}}>Jeśli szukasz profesjonalnej i sprawdzonej firmy w zakresie wymienionym powyżej, zapraszamy do kontaktu z nami.</p>
            </section>

            <div className="w-full h-96" id="gallery">
                <Gallery
                images={images.map((image: any) => ({
                    Image_url: image,
                    Product_group_id: 0,
                    Order_number: 0,
                }))}
                />
            </div>

        </motion.div>
    )
}

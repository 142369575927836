import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../../server";
import { StateType } from "../../../../Models/State";
import { UserAdminDisplay } from "../../../../Models/User";
import { OrderModel, OrderType } from "../../../../Models/Order";
import { Address } from "cluster";
import { ShopAddress } from "../../../../Models/Address";
import {
  CategoryDisc,
  ProductDisc,
} from "../../../../Models/IndividualPrices";

type adminUsersSliceState = {
  users: {
    usersList: UserAdminDisplay[];
    state: StateType;
  };
  user: {
    user: UserAdminDisplay;
    orders: OrderModel[];
    addresses: ShopAddress[];
    categoriesDiscounts: CategoryDisc[];
    productsDiscounts: ProductDisc[];
    state: StateType;
    updateState: StateType;
  };
};

export const getUnAcceptedUsers = createAsyncThunk(
  "adminUsers/fetchUnaccepted",
  async () => {
    try {
      const response = await axios.get<UserAdminDisplay[]>(
        `${server}/api/Users/admin/unaccepted`,
        { withCredentials: true }
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getUsers = createAsyncThunk(
  "adminUsers/fetchAll",
  async ({ name = "", page = "1" }: { name?: string; page?: string }) => {
    try {
      const response = await axios.get<UserAdminDisplay[]>(
        `${server}/api/Users/admin/`,
        {
          params: {
            name: name,
            page: page,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "adminUsers/fetch/user",
  async ({ userId }: { userId: string }) => {
    try {
      const response = await axios.get<{
        user: UserAdminDisplay;
        orders: OrderModel[];
        addresses: ShopAddress[];
        categoriesDiscounts: CategoryDisc[];
        productsDiscounts: ProductDisc[];
      }>(`${server}/api/Users/admin/${userId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const updateUser = createAsyncThunk(
  "adminUsers/edit/user",
  async ({
    name,
    surname,
    email,
    phoneNumber,
    userId,
  }: {
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    userId: string;
  }) => {
    try {
      const response = await axios.post(
        `${server}/api/Users/admin/edit/${userId}`,
        {
          name,
          surname,
          email,
          phoneNumber,
        },
        {
          withCredentials: true,
        }
      );
      return {
        name,
        surname,
        email,
        phoneNumber,
        userId
      };
    } catch (e) {
      throw e;
    }
  }
);

export const acceptUser = createAsyncThunk(
  'adminUsers/acceptUser',
  async(userId: number)=>{
    try{
    
      const result = await axios.post(`${server}/api/Users/admin/accept-user`, 
        {userId}, 
        {withCredentials: true}
      );

      return userId;
    
    }
    catch(e){
      throw e;
    }
  }
)

const adminUsersSlice = createSlice({
  name: "adminUsers",
  initialState: {
    users: {
      usersList: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    user: {
      user: {},
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
      updateState: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
  } as unknown as adminUsersSliceState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUnAcceptedUsers.pending, (state, action) => {
      state.users.state.isLoading = true;
      state.users.state.failedLoading = false;
      state.users.state.hasLoaded = false;
      state.users.state.error = null;
    });
    builder.addCase(getUnAcceptedUsers.fulfilled, (state, action) => {
      state.users.state.isLoading = false;
      state.users.state.failedLoading = false;
      state.users.state.hasLoaded = true;
      state.users.usersList = action.payload;
    });
    builder.addCase(getUnAcceptedUsers.rejected, (state, action) => {
      state.users.state.isLoading = false;
      state.users.state.failedLoading = true;
      state.users.state.hasLoaded = false;
      state.users.state.error = action.error;
    });
    builder.addCase(getUsers.pending, (state, action) => {
      state.users.state.isLoading = true;
      state.users.state.failedLoading = false;
      state.users.state.hasLoaded = false;
      state.users.state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users.state.isLoading = false;
      state.users.state.failedLoading = false;
      state.users.state.hasLoaded = true;
      console.log(action.payload);
      state.users.usersList = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.users.state.isLoading = false;
      state.users.state.failedLoading = true;
      state.users.state.hasLoaded = false;
      state.users.state.error = action.error;
    });
    builder.addCase(getUserDetails.pending, (state, action) => {
      state.users.state.isLoading = true;
      state.users.state.failedLoading = false;
      state.users.state.hasLoaded = false;
      state.users.state.error = null;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.users.state.isLoading = false;
      state.users.state.failedLoading = false;
      state.users.state.hasLoaded = true;
      state.user.user = action.payload.user;
      state.user.orders = action.payload.orders;
      state.user.addresses = action.payload.addresses;
      state.user.categoriesDiscounts = action.payload.categoriesDiscounts;
      state.user.productsDiscounts = action.payload.productsDiscounts;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.users.state.isLoading = false;
      state.users.state.failedLoading = true;
      state.users.state.hasLoaded = false;
      state.users.state.error = action.error;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.user.updateState.isLoading = true;
      state.user.updateState.hasLoaded = false;
      state.user.updateState.failedLoading = false;
      state.user.updateState.error = null;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.user.updateState.isLoading = false;
      state.user.updateState.hasLoaded = false;
      state.user.updateState.failedLoading = true;
      state.user.updateState.error = action.error;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user.updateState.isLoading = false;
      state.user.updateState.hasLoaded = true;
      state.user.updateState.failedLoading = false;
      state.user.updateState.error = null;
      let updatedUsers = [];
      for(var element of state.users.usersList){
        if(element.Id.toString()===action.payload.userId){
          element.Name=action.payload.name;
          element.Surname=action.payload.surname;
          element.Phone_number=action.payload.phoneNumber;
          element.Email=action.payload.email;
        }
        updatedUsers.push(element);
      }
    });
    builder.addCase(acceptUser.fulfilled, (state, action) => {
      state.user.updateState.isLoading = false;
      state.user.updateState.hasLoaded = true;
      state.user.updateState.failedLoading = false;
      state.user.updateState.error = null;
      state.user.user.Is_client_accepted=true;
    });
    
  },
});

export const selectUsers = (state: {
  adminUsers: adminUsersSliceState;
}): UserAdminDisplay[] => {
  return state.adminUsers.users.usersList;
};

export const selectUser = (state: {
  adminUsers: adminUsersSliceState;
}): {
  user: UserAdminDisplay;
  orders: OrderModel[];
  addresses: ShopAddress[];
  categoriesDiscounts: CategoryDisc[];
  productsDiscounts: ProductDisc[];
} => {
  return state.adminUsers.user;
};

export const selectAdminUsersState = (state: {
  adminUsers: adminUsersSliceState;
}): StateType => {
  return state.adminUsers.users.state;
};

export const selectAdminUserState = (state: {
  adminUsers: adminUsersSliceState;
}): StateType => {
  return state.adminUsers.user.state;
};

export const selectAdminEditUserState = (state: {
  adminUsers: adminUsersSliceState;
}): StateType => {
  return state.adminUsers.user.updateState;
};

export default adminUsersSlice.reducer;

import { marked } from "marked";
import React, { useEffect } from "react";

function Regulamin() {
  useEffect(() => {
    document.title = "Regulamin - Press Bar Pneumatyka";
  }, []);

  return (
    <div className="w-full max-w-[1280px] text-left p-2 flex flex-col">
      <h1 className="text-3xl sm:text-4xl text-center md:text-6xl my-10 font-bold">
        Regulamin
      </h1>
      <div
        id="markdown_content"
        className="w-full flex flex-col items-center"
      >
<p><strong>Regulamin sklepu internetowego pressbar.pl określający zasady sprzedaży prowadzonej na odległość</strong></p>
<p><strong>§ 1. Definicje</strong></p>
<ol>
<li>Sklep - sklep internetowy „Press Bar Pneumatyka&quot; prowadzony przez Sprzedawcę pod adresem <a href="http://www.pressbar.pl/">pressbar.pl</a>;</li>
<li>Sprzedający - Press Bar spółka z ograniczoną odpowiedzialnością wpisana do Krajowego Rejestru Sądowego (KRS 0001007705; REGON 523960502) z siedzibą przy ul. Głównej 14, 42-280 Częstochowa.</li>
<li>Kupujący -- osoba fizyczna, która posiada pełną zdolność do czynności prawnych, osoba fizyczna prowadząca działalność gospodarczą, osoba prawna lub jednostka organizacyjna nie będącą osobą prawną, której przepisy szczególne przyznają zdolność prawną, zawierająca Umowę sprzedaży</li>
<li>Konsument -- osoba fizyczna  posiadająca pełną zdolność do czynności prawnych,  zawierająca umowę sprzedaży w celu niezwiązanym bezpośrednio z jej działalnością gospodarczą lub zawodową; </li>
<li>Przedsiębiorca na prawach konsumenta- osoba fizyczna posiadająca pełną zdolność do czynności prawnych,  zawierająca ze Sprzedającym umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie ma ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej;</li>
<li>Przedsiębiorca -- osoba fizyczna, osoba prawna, jednostka organizacyjna nie posiadająca osobowości prawnej  prowadząca w własnym imieniu działalność gospodarczą lub zawodową, dokonująca zakupów za pośrednictwem Sklepu w celu związanym bezpośrednio z jej działalnością gospodarczą lub zawodową;  </li>
<li>Konto - nieodpłatna funkcja Sklepu (usługa) pozwalająca Kupującemu na założenie indywidualnego konta użytkownika w Sklepie;</li>
<li>Regulamin - niniejszy regulamin określający zasady i warunki sprzedaży towarów na odległość;</li>
<li>Zamówienie - oferta zawarcia umowy sprzedaży towaru widocznego w Sklepie w rozumieniu Kodeksu cywilnego, składana Sprzedawcy przez Kupującego za pośrednictwem Sklepu, zawierająca co najmniej: oznaczenie towaru oraz jego cenę;</li>
<li>Umowa sprzedaży -- zawarta w wyniku Zamówienia między Kupującym a Sprzedawcą, umowa sprzedaży w rozumieniu Kodeksu Cywilnego;</li>
<li>Towar -- produkt/produkty prezentowane w Sklepie, będące rzeczami ruchomymi przeznaczonymi do sprzedaży;</li>
<li>Dni robocze - dni od poniedziałku do piątku za wyjątkiem dni ustawowo wolnych od pracy;</li>
<li>Operator płatniczy - podmiot świadczący usługi płatnicze w ramach Sklepu na rzecz Kupującego/Kupujących;</li>
<li>Ustawa  - ustawa z dnia 30 maja 2014 r. o prawach konsumenta.</li>
</ol>
<p><strong>§ 2. Dane Kontaktowe</strong></p>
<ol>
<li>Adres pocztowy: Press Bar Sp. z o. o, ul. Główna 14, 42-280 Częstochowa.</li>
<li>Adres e-mail: biuro@pressbar.pl</li>
<li>Numer telefonu: +48 506 162 825</li>
</ol>
<p><strong>§ 3. Złożenie zamówienia</strong></p>
<ol>
<li>Towar w Sklepie  nie jest ofertą w rozumieniu przepisów kodeksu cywilnego, a jedynie zaproszeniem do zawarcia umowy. Zawarcie umowy wymaga potwierdzenia przez Sklep.</li>
<li>Kupujący składa Sprzedawcy Zamówienie wybierając jeden z poniższych sposobów:</li>
</ol>
<p>a)     poprzez wybór opcji „loguję się&quot; po uprzednim założeniu Konta w Sklepie na zasadach określonych w § 12 Regulaminu.</p>
<p>b)    poprzez wybór opcji „zamawiam jako gość&quot; bez rejestracji</p>
<ol>
<li>W celu złożenia Zamówienia Kupujący dodaje właściwe towary do koszyka w Sklepie, a także, jeśli ma to zastosowanie, określa ich parametry i ilość. Dodanie towaru do koszyka nie oznacza złożenia Zamówienia.  </li>
<li>W kolejnym kroku Kupujący wybiera metodę dostarczenia Zamówienia oraz sposób jego płatności.</li>
<li>W podsumowaniu Zamówienia Kupujący sprawdza jego prawidłowość (w tym weryfikuje prawidłowość podanych danych osobowych) i klika przycisk „Zamawiam i płacę&quot;. </li>
<li>Warunkiem złożenia zamówienia jest potwierdzenie przez Kupującego zapoznania się z niniejszym regulaminem poprzez zaznaczenie odpowiedniego pola w formularzu Zamówienia. </li>
<li>Następnie Kupujący otrzymuje automatyczną wiadomość e-mail ze Sklepu, potwierdzającą otrzymanie Zamówienia. Sprzedający weryfikuje stan magazynowy i przygotowuje Zamówienie do wysyłki lub informuje Kupującego o braku możliwości zrealizowania Zamówienia. </li>
<li>Zawarcie umowy sprzedaży towaru następuje wraz z otrzymaniem przez Kupującego wiadomości na adres e-mail przypisany do Konta lub podany przez Kupującego w formularzu transakcyjnym, potwierdzającej przyjęcie Zamówienia przez Sprzedającego.  </li>
<li>Przyjęcie Zamówienia przez Sprzedającego dokonane z zastrzeżeniem zmiany lub z uzupełnieniem jego treści poczytuje się za nową ofertę i dopiero po jej przyjęciu przez Kupującego następuje zawarcie umowy sprzedaży.</li>
</ol>
<p><strong>§ 4. Koszt zamówienia</strong></p>
<ol>
<li>Ceny podane w Sklepie stanowią ceny netto, do których należy doliczyć właściwy, wskazany w Sklepie, podatek VAT.</li>
<li>Na całkowitą cenę Zamówienia składa się cena za towar oraz koszt dostawy towaru widoczne w Sklepie podczas składania zamówienia oraz w odpowiedniej zakładce w Sklepie, z zastrzeżeniem ust. 3.</li>
<li><p>W przypadku, gdy Zamówienie będzie obejmować towary sprowadzane spoza Polski na specjalne zamówienie Kupującego dostawa takich towarów może się wiązać z dodatkowymi kosztami, o których Kupujący zostanie poinformowany.</p>
</li>
<li><p>Sprzedawca przewiduje możliwość organizowania czasowych promocji w Sklepie m.in. w zakresie wprowadzenia darmowej dostawy dla zamówień przekraczających określoną kwotę. Szczegóły dotyczące aktualnych w danym momencie promocji będą się znajdować w Sklepie.            </p>
</li>
</ol>
<p><strong>§ 5. Płatności -- sposoby i terminy</strong></p>
<ol>
<li>Kupujący podczas składania Zamówienia wybiera jeden z poniższych sposobów zapłaty:</li>
</ol>
<p>1)    płatność z góry tj. :</p>
<p>a)     płatność elektroniczna lub kartą płatniczą-- następuje przekierowanie do zewnętrznego serwisu płatniczego Przelewy24 i należy postępować zgodnie ze wskazówkami tego operatora płatniczego; płatności tej należy dokonać bezpośrednio po złożeniu zamówienia;</p>
<p>b)    przelew tradycyjny  - płatność na rachunek wskazany przez Sprzedającego, płatności należy dokonać w terminie 3 dni od złożenia Zamówienia;</p>
<p>2)    płatność za pobraniem -- zapłata następuje gotówką przy odbiorze towaru ;</p>
<ol>
<li><p>W przypadku wyboru płatności opisanej w ust. 1 pkt. 1 ppkt. a, Zamówienie nie zostanie zrealizowane, gdy płatność nie zostanie dokonana w czasie 30 minut od złożenia Zamówienia. Istnieje możliwość ponownego wygenerowania linku do płatności, po skontaktowaniu się ze Sprzedawcą przez adres email: <a href="&#x6d;&#97;&#x69;&#108;&#x74;&#x6f;&#x3a;&#x62;&#105;&#x75;&#114;&#x6f;&#x40;&#x70;&#114;&#x65;&#x73;&#115;&#x62;&#x61;&#x72;&#46;&#112;&#108;">&#x62;&#105;&#x75;&#114;&#x6f;&#x40;&#x70;&#114;&#x65;&#x73;&#115;&#x62;&#x61;&#x72;&#46;&#112;&#108;</a>              </p>
</li>
<li><p>Potwierdzeniem dokonania przez Kupującego zapłaty ceny i kosztów dostawy Zamówienia jest otrzymanie przez Sprzedającego wiadomości o wpływie środków pieniężnych w odpowiedniej wysokości na konto Operatora płatniczego.</p>
</li>
<li><p>W poszczególnych przypadkach istnieje możliwość skorzystania z odroczonego terminu płatności -- Kupujący zainteresowany takim rozwiązaniem powinien skontaktować się ze Sprzedawcą.</p>
</li>
<li>W przypadku wyboru przez Kupującego metody płatności  opisanej w ust. 1 pkt. 1 ppkt. b Zamówienie nie zostanie zrealizowane, gdy zapłata ceny i kosztów dostawy Zamówienia  nie zostanie dokonana we wskazanym trzydniowym terminie. Za realizację płatności uważa się uznanie rachunku bankowego Sprzedającego.  </li>
<li>Kupujący dokonując zakupów w Sklepie akceptuje stosowanie faktur elektronicznych przez Sprzedawcę. Faktury wysyłane droga elektroniczną zostaną przekazane na adres e-mail Kupującego przypisany do Konta. Kupujący ma prawo wycofać swoją akceptację.</li>
</ol>
<p><strong>§ 6. Realizacja zamówienia</strong></p>
<ol>
<li>Zakupione w Sklepie towary dostarczane są wyłącznie na terytorium Rzeczypospolitej Polskiej, za pośrednictwem paczkomatów InPost, Firmy Kurierskiej, Poczty Polskiej.</li>
<li>Szczegóły dotyczące metod i cen dostawy dostępne są każdorazowo podczas składania Zamówienia oraz w zakładce pod nazwą „Płatność i Dostawa&quot; znajdującej się  na stronie  Sklepu .</li>
<li><p>Kupujący obowiązany jest zbadać przesyłkę w czasie i w sposób przyjęty przy przesyłkach tego rodzaju, a w przypadku stwierdzenia, że w czasie przewozu nastąpił ubytek lub uszkodzenie rzeczy, obowiązany jest dokonać wszelkich czynności niezbędnych do ustalenia odpowiedzialności przewoźnika.</p>
</li>
<li><p>Kupujący ma możliwość osobistego odebrania zakupionego towaru w Siedzibie Sprzedawcy w Dni robocze od poniedziałku do piątku w godzinach 8.00-16.00 po otrzymaniu informacji, że towar jest gotowy do odbioru </p>
</li>
<li><p>Czas potrzebny na skompletowanie i wysłanie zamówienia wynosi pomiędzy 2-3 dni robocze, licząc od dnia przyjęcia Zamówienia przez Sprzedającego. W uzasadnionych przypadkach czas ten może jednak ulec wydłużeniu, wówczas Kupujący zostanie poinformowany o  powyższym. Całkowity termin realizacji Zamówienia nie powinien przekroczyć  30 dni od momentu jego przyjęcia przez Sprzedającego.  </p>
</li>
<li><p>W przypadku, gdy Kupujący jako sposób dostawy towaru wybrał odbiór osobisty, termin wysłania towaru oznacza moment, w którym towar będzie gotowy do odbioru w Siedzibie Sprzedawcy.</p>
</li>
<li>W przypadku gdy na Zamówienie składa się kilka produktów, co do zasady termin realizacji całego zamówienia odpowiada terminowi realizacji dla produktu o najdłuższym czasie realizacji, jednak na podstawie indywidualnych ustaleń między Stronami, Sprzedawca może zrealizować gotową część Zamówienia wcześniej.</li>
<li>W przypadku wystąpienia sytuacji, o której mowa w § 5 ust. 4 towary mogą zostać wysłane i dostarczone oddzielnie, we właściwych dla siebie terminach.</li>
<li>W przypadku, gdy Kupujący zdecydował się na płatność z góry za Zamówienie, rozpoczęcie realizacji Zamówienia nastąpi po otrzymaniu przez Sprzedającego płatności za zakupiony towar.</li>
</ol>
<p><strong>§ 7. Reklamacje</strong></p>
<ol>
<li><p>W przypadku, gdy Towar jest niezgodny z umową, Kupującemu będącemu Konsumentem albo Przedsiębiorcą na prawach konsumenta, przysługują uprawnienia przewidziane w rozdziale 5A ustawy. Jeśli Kupujący jest Przedsiębiorcą, Sprzedający ponosi odpowiedzialność na zasadach określonych w art. 556 i następnych Kodeksu cywilnego. </p>
</li>
<li><p>Konsumentowi albo Przedsiębiorcy na prawach konsumenta w przypadku  niezgodności Towaru z umową, przysługuje roszczenie o doprowadzenie zgodności Towaru z umową i w tym celu może żądać naprawy lub wymiany Towaru.</p>
</li>
<li><p>Jeżeli doprowadzenie do zgodności towaru z umową w sposób wybrany przez Konsumenta albo Przedsiębiorcy na prawach konsumenta jest niemożliwe albo wymagałoby nadmiernych kosztów dla Sprzedającego, może on:</p>
</li>
</ol>
<p>1)    dokonać wymiany, gdy konsument żąda naprawy lub</p>
<p>2)    dokonać naprawy, gdy konsument żąda wymiany.</p>
<ol>
<li>Konsumentowi albo Przedsiębiorcy na prawach konsumenta przysługuje także żądanie obniżenia ceny lub odstąpienia od umowy jeżeli:</li>
</ol>
<p>1)    Sprzedający odmówił doprowadzenia towaru do zgodności z umową;</p>
<p>2)    Sprzedający nie doprowadził towaru do zgodności z umową;</p>
<p>3)    nadal występuje brak zgodności towaru z umową pomimo, że Sprzedający próbował doprowadzić towar do zgodności z umową;</p>
<p>4)    brak zgodności towaru z umową jest na tyle istotny, że uzasadnia obniżenie ceny albo odstąpienie od umowy bez uprzedniego skorzystania z możliwości naprawy lub wymiany towaru;</p>
<p>5)    z oświadczenia Sprzedającego lub okoliczności wyraźnie wynika, że nie doprowadzi on towaru do zgodności z umową w rozsądnym czasie lub bez nadmiernych niedogodności dla Konsumenta czy Przedsiębiorcy na prawach konsumenta. </p>
<ol>
<li><p>Konsument lub Przedsiębiorca na prawach konsumenta nie może odstąpić od umowy sprzedaży, jeżeli brak zgodności towaru z umową jest nieistotny.</p>
</li>
<li><p>Zgłoszenie reklamacyjne może być złożone w dowolnej formie, m.in. na Formularzu reklamacyjnym znajdującym się na stronie Sklepu. Zgłoszenie należy przekazać na dane kontaktowe Sprzedającego.</p>
</li>
</ol>
<p><strong>§ 8. Prawo odstąpienia od umowy - Zwrot towaru</strong></p>
<ol>
<li>Konsument lub Przedsiębiorca na prawach konsumenta, który zawarł ze Sprzedającym umowę sprzedaży jest uprawniony do odstąpienia od umowy (tj. zwrotu zakupionego towaru) bez podawania przyczyny i ponoszenia kosztów, za wyjątkiem tych opisanych poniżej, w terminie 14 dni od daty otrzymania towaru.</li>
<li>Kupujący uprawniony do odstąpienia od umowy sprzedaży, o którym mowa w ust. 1,  powinien to zrobić poprzez złożenie Sprzedającemu oświadczenia o odstąpieniu w dowolnej formie, w tym poprzez jeden z  poniższych sposobów:</li>
</ol>
<p>a.     z wykorzystaniem „Formularza zwrotu&quot; udostępnionego na stronie Sklepu;</p>
<p>b.     poprzez oświadczenie na formularzu, którego wzór stanowi <a href="https://sip.legalis.pl/document-view.seam?documentId=mfrxilrtg4ytsobsgq3tcltqmfyc4nzrga3dgnrxgu">załącznik nr 2</a>do ustawy o Prawach konsumenta;</p>
<p>c.     poprzez wysłanie oświadczenia na adres e-mail: <a href="&#x6d;&#x61;&#105;&#x6c;&#116;&#x6f;&#x3a;&#x62;&#105;&#117;&#x72;&#111;&#x40;&#112;&#114;&#x65;&#115;&#x73;&#98;&#97;&#114;&#46;&#x70;&#108;">&#x62;&#105;&#117;&#x72;&#111;&#x40;&#112;&#114;&#x65;&#115;&#x73;&#98;&#97;&#114;&#46;&#x70;&#108;</a> lub pocztą tradycyjną na adres: Press Bar Sp. z o. o, ul. Główna 14, 42-280 Częstochowa;</p>
<ol>
<li>Kupujący (uprawniony do odstąpienia od umowy sprzedaży), który chce skorzystać z ustawowego prawa odstąpienia, powinien złożyć oświadczenie wraz z dowodem zakupu, którym jest np. paragon czy faktura. </li>
<li>Zwracany towar powinien być w stanie niezmienionym tj. nie może nosić śladów użytkowania, być uszkodzony czy niekompletny. Wskazanym jest, aby posiadał oryginalne metki, plomby, oznaczenia itp.</li>
<li>Kupujący uprawniony do odstąpienia od umowy sprzedaży, ponosi tylko koszty bezpośredniego zwrotu towaru do Sprzedającego tj. koszty odesłania Towaru za pośrednictwem firmy kurierskiej, Poczty Polskiej czy innego profesjonalnego podmiotu świadczącego takie usługi. </li>
<li>Jeśli Kupujący złożył skutecznie oświadczenie o odstąpieniu od umowy sprzedaży to umowę tę uznaje się za niezawartą, a Konsument albo Przedsiębiorca na prawach konsumenta,  zobowiązani są zwrócić sobie to, co świadczyli wzajemnie w związku z zawartą umową. </li>
<li>Konsument lub Przedsiębiorca na prawach konsumenta odpowiada za zmniejszenie wartości towaru będące wynikiem korzystania z niego w sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania towaru.</li>
<li>Sprzedający dokonuje zwrotu  ceny uiszczonej przez Kupującego w sposób w jaki zapłatę tę otrzymał, chyba, że Konsument lub Przedsiębiorca na prawach konsumenta, wyrazi zgodę na zwrot w inny sposób, nie generujący kosztów po stronie Konsumenta lub Przedsiębiorcy. Gdy cena była zapłacona kurierowi, jej zwrot  następuje na rachunek bankowy wskazany przez Konsumenta czy Przedsiębiorcę na prawach konsumenta. </li>
<li>Prawo odstąpienia od umowy sprzedaży nie przysługuje w przypadkach wyszczególnionych w art. 38 ustawy, w tym między innymi dotyczy umowy w której przedmiotem świadczenia jest towar nieprefabrykowany, wyprodukowany według specyfikacji konsumenta lub służący zaspokojeniu jego zindywidualizowanych potrzeb (np. wyprodukowane na zamówienie siłowniki pneumatyczne, odcinki węży, sprężyny gazowe oraz pozostałe produkty wykonywane lub sprowadzane na indywidualne zamówienie Kupującego). </li>
</ol>
<p><strong>§ 9. Dane osobowe</strong></p>
<ol>
<li><p>Administratorem Państwa Danych Osobowych jest Press Bar Sp. z o. o. z siedzibą w Częstochowie, przy ul. Głównej 14.  Z Administratorem można skontaktować się pod adresem e-mail: <a href="&#109;&#x61;&#105;&#x6c;&#x74;&#x6f;&#x3a;&#98;&#105;&#117;&#x72;&#111;&#x40;&#112;&#114;&#x65;&#115;&#115;&#98;&#x61;&#x72;&#46;&#x70;&#x6c;">&#98;&#105;&#117;&#x72;&#111;&#x40;&#112;&#114;&#x65;&#115;&#115;&#98;&#x61;&#x72;&#46;&#x70;&#x6c;</a>;</p>
</li>
<li><p>Więcej wiadomości na temat przetwarzania Państwa danych osobowych można znaleźć w dokumencie pod nazwą „Polityka prywatności&quot; znajdującym się na stronie <a href="http://www.pressbar.pl/">pressbar.pl</a></p>
</li>
</ol>
<p><strong>§ 10. Wymogi techniczne</strong></p>
<p>Dla korzystania ze Sklepu niezbędne jest urządzenie wyposażone w przeglądarkę internetową obsługującą pliki cookies z dostępem do sieci Internet, a dla założenia Konta i dokonywania zakupów także aktywne konto e-mail.</p>
<p><strong>§ 11. Konto</strong></p>
<ol>
<li>Konto to usługa świadczona drogą elektroniczną, ułatwiająca Kupującemu realizację Zamówienia poprzez zapisanie i przechowywanie danych adresowych Kupującego niezbędnych do realizacji zamówienia. Konto pozwala Kupującemu m.in. na sprawdzanie statusu Zamówienia, przeglądanie historii Zamówień oraz samodzielną edycję swoich danych w Koncie.</li>
<li>Założenie Konta jest dobrowolne i bezpłatne. </li>
<li>W celu założenia Konta należy: zapoznać się z niniejszym regulaminem, wypełnić formularz na stronie Sklepu i wysłać go do Sprzedającego, ustawić hasło. Z chwilą dokonania tych czynności zostaje zawarta pomiędzy Kupującym a Sprzedającym, umowa o prowadzenie Konta na czas nieokreślony.</li>
<li>Użytkownik w każdym czasie może zrezygnować z usługi prowadzenia konta, bez podawania przyczyny i ponoszenia jakichkolwiek kosztów. W tym celu Użytkownik powinien wysłać Sprzedającemu na adres e-mail biuro@pressbar.pl stosowne oświadczenie. </li>
</ol>
<p><strong>§ 12. Postanowienia końcowe</strong></p>
<ol>
<li>Umowa sprzedaży zawierana w oparciu o niniejszy regulamin dotyczy konkretnego i jednorazowego zamówienia - jest zawierana w celu i na czas jego realizacji. Kolejne umowy sprzedaży zawierane są na podstawie niniejszego regulaminu do momentu poinformowania Kupującego przez Sprzedawcę o jego zmianie.</li>
<li><p>Umowy zawierane w Sklepie zawierane są w języku polskim.</p>
</li>
<li><p>Użytkownik zobowiązany jest korzystać ze Sklepu w sposób zgodny z przepisami prawa, postanowieniami Regulaminu oraz zasadami przyjętymi w sieci Internet.</p>
</li>
<li><p>Zakazane jest dostarczanie i przekazywanie treści o charakterze bezprawnym, w tym treści propagujących przemoc, zniesławiających lub naruszających dobra osobiste i inne prawa osób trzecich.</p>
</li>
<li><p>W przypadku ewentualnych sporów sądem właściwym do ich rozstrzygnięcia będzie sąd właściwy miejscowo dla siedziby Sprzedającego.</p>
</li>
<li>Sprzedający ma prawo do zmiany Regulaminu z ważnych przyczyn, za którą uważa się zwłaszcza zmianę przepisów prawa. Każda zmiana będzie ogłoszona na stronie internetowej <a href="http://www.pressbar.pl/">pressbar.pl</a> z 7 dniowym wyprzedzeniem, chyba że zmiana ta wymaga natychmiastowego wdrożenia z uwagi na interes Kupujących.</li>
</ol>


      </div>
    </div>
  );
}

export default Regulamin;

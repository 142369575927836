import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AppDispatch } from "../../../../../Store/store";
import {
  getAdminOrderDetails,
  selectAdminOrder,
  selectSetOrderAsPaidError,
  setOrderAsAccepted,
  setOrderAsFinished,
  setOrderAsPaid,
  setOrderAsReadyToPickUp,
} from "../../../../../Store/Slices/admin/Orders/Orders";
import Confirm from "../../../../../Common/Modals/Confirm/Confirm";
import DatePicker from "../../../../../Common/Date/DatePicker";
import OrderPage from "../../../../../Common/OrderPage/OrderPage";
import ProductList from "../../Products/ProductList";
import { setOrderAsPaidDisplay } from "../../../../../Store/Slices/User/User";

function OrderDetailsPage() {
  const { id } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const order = useSelector(selectAdminOrder);

  const [showSetAsAccepted, setShowAsAccepted] = useState(false);
  const [showSetAsReady, setShowAsReady] = useState(false);
  const [showAsFinished, setShowAsFinished] = useState(false);
  const [dpdCode, setDpdCode] = useState("");
  const [sendData, setSendData] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendData(event.target.value);
  };

  const setOrderAsPaidError = useSelector(selectSetOrderAsPaidError);

  useEffect(() => {
    if (id) {
      dispatch(getAdminOrderDetails(id));
    }
  }, [id, dispatch]);

  return (
    <motion.div
      className="my-10 w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {order.order && (
        <div className="w-full mb-10 border-2 p-4">
          <h2 className="text-2xl font-semibold">
            Zarządzaj zamówieniem nr: {order.order.Order_number}
          </h2>
          <div className="flex space-x-2">
            <span className="font-semibold">Status: </span>
            <span>
              {order.order.Status_id === 1
                ? "nowe"
                : order.order.Status_id === 2
                ? "Oczekiwanie na płatność"
                : order.order.Status_id === 3
                ? "Płatność na termin"
                : order.order.Status_id === 4
                ? "Zaakceptowane"
                : order.order.Status_id === 5
                ? "Gotowe do obioru"
                : order.order.Status_id === 6
                ? "Gotowe do wysyłki"
                : "Zakończone"}
            </span>
          </div>
          {(order.order.Status_id === 1 ||
            order.order.Status_id === 2 ||
            order.order.Status_id === 3) && (
            <div className="flex items-center space-x-2 w-full">
              <span className="font-semibold">Wybierz date dostawy: </span>
              <DatePicker date={sendData} handleChange={handleChange} />
            </div>
          )}
          {(order.order.Status_id === 1 ||
            order.order.Status_id === 2 ||
            order.order.Status_id === 3) && (
            <button
              className="bg-primary text-white p-2 rounded-lg"
              onClick={() => {
                setShowAsAccepted(true);
              }}
            >
              Oznacz zamówienie jako w realizacji
            </button>
          )}
          {order.order.Status_id === 4 && order.order.Delivery_type !== 4 && (
            <input
              value={dpdCode}
              onChange={(e) => {
                setDpdCode(e.target.value);
              }}
              placeholder="Numer listu przewozowego"
            />
          )}
          {order.order.Status_id === 4 && (
            <button
              className="bg-primary text-white p-2 rounded-lg"
              onClick={() => {
                if (dpdCode !== "" || order.order.Delivery_type === 4) {
                  setShowAsReady(true);
                }
              }}
            >
              Oznacz zamówienie jako gotowe/spakowane
            </button>
          )}
          {(order.order.Status_id === 5 || order.order.Status_id === 6) && (
            <button
              className="bg-primary text-white p-2 rounded-lg"
              onClick={() => {
                setShowAsFinished(true);
              }}
            >
              Oznacz zamówienie jako zakończone
            </button>
          )}
          {(
            <button
              className="bg-primary text-white p-2 rounded-lg"
              onClick={() => {
                dispatch(setOrderAsPaid({id: id??'', paidStatus: (order.order.Is_paid===1?0:1)}));
                dispatch(setOrderAsPaidDisplay(order.order.Is_paid===1?0:1));
              }}
            >
              Zmień na {order.order.Is_paid===1?'nieopłacone':'opłacone'}
            </button>
          )}
          {setOrderAsPaidError!==null&&setOrderAsPaidError!==''&&<p>{setOrderAsPaidError}</p>}
          <Link to={`/admin/orders/edit/${id}`}>
            <button className="bg-primary text-white p-2 rounded-lg">
              Edytuj zamówienie
            </button>
          </Link>
        </div>
      )}
      <div className="w-full">
        <OrderPage />
      </div>
      {showSetAsAccepted && (
        <Confirm
          handleClose={() => {
            setShowAsAccepted(false);
          }}
          handleConfirm={() => {
            dispatch(
              setOrderAsAccepted({ id: id ?? "-1", sendData: sendData })
            );
            setShowAsAccepted(false);
          }}
          text={"Czy na pewno akceptujesz zamówienie?"}
        />
      )}
      {showSetAsReady && (
        <Confirm
          handleClose={() => {
            setShowAsReady(false);
          }}
          handleConfirm={() => {
            dispatch(
              setOrderAsReadyToPickUp({ id: id ?? "-1", dpdCode: dpdCode })
            );
            setShowAsReady(false);
          }}
          text={"Czy na pewno zamówienie jest spakowane/gotowe do odbioru?"}
        />
      )}
      {showAsFinished && (
        <Confirm
          handleClose={() => {
            setShowAsFinished(false);
          }}
          handleConfirm={() => {
            dispatch(setOrderAsFinished(id ?? "-1"));
            setShowAsFinished(false);
          }}
          text={"Czy na pewno zamówienie jest zakończone?"}
        />
      )}
      <p>
        {false&&order.products &&
          order.products.map((element) => {
            return (
              <p>
                <ProductList product={element} />
              </p>
            );
          })}
      </p>
    </motion.div>
  );
}

export default OrderDetailsPage;

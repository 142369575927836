import React from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";

export default function Offer() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col bg-primary items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full"
    >
      <Link
        to={"/search?searchQuery=&category=1"}
        className="relative hover:brightness-110 p-10 hover:shadow-md transition-all brightness-100 bg-primary flex items-center justify-center w-full h-full group"
      >
        <h2 className="max-w-[1024px] sm:text-7xl text-5xl font-display text-center text-white">
          Sprawdź naszą ofertę
        </h2>
        <SearchIcon
          className="text-neutral-50 group-hover:translate-x-32 absolute left-1/2 opacity-0 group-hover:opacity-100"
          fontSize="large"
          id="search-icon"
          style={{ scale: "2.5", transition: "all 0.5s" }}
        />
      </Link>
    </motion.div>
  );
}

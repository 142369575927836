import React, { useEffect } from "react";
import OrderPage from "../../../Common/OrderPage/OrderPage";
import { initializeOrder } from "../../../Store/Slices/Order/Order";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Store/store";

function Summary() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(initializeOrder());
  }, []);

  useEffect(() => {
    try{
      const getCookie = function(name:string) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return "";
      }

      const getUserId = function() {
        const userCookie = getCookie("twd_u");
        return userCookie === "" ? "" : userCookie;
      }

      const userId = getUserId();
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `https://trafficscanner.pl/ws2/index.php/?action=noscript&dt=77&cid=3775&user_id=${userId}`, true);
      xhr.send();

      const externalScript = loadScript('https://trafficscanner.pl/ws2/script/setSaleCookie.js');

      return () => {
        // Usunięcie skryptu, jeśli został dodany
        if (externalScript) {
          document.body.removeChild(externalScript);
        }
      };
    }
    catch(e){
       
    }

  }, []);

  const loadScript = (url:string) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return script;
  };

  return (
    <div className="relative w-full my-10">
      <h1 className="text-xl sm:text-3xl lg:text-5xl font-bold mb-4 text-center">
        Dziękujemy za złożenie zamówienia!
      </h1>
      <div className="p-5">
        <OrderPage isSummary={true} />
      </div>
    </div>
  );
}

export default Summary;

import React, { useState } from 'react';

function DatePicker({date, handleChange}:{date: string, handleChange: React.ChangeEventHandler<HTMLInputElement> }) {


  return (
    <div>
      <input type="date" value={date} onChange={handleChange} />
    </div>
  );
}

export default DatePicker;
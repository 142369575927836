import React from "react";
import { SimiliarProductsType } from "../../../Models/Product";
import { useSelector } from "react-redux";
import {
  selectSimilarProducts,
  selectSimilarProductsState,
} from "../../../Store/Slices/Products/Products";
import { StateType } from "../../../Models/State";
import ProductCard from "../../../Common/ProductCard/ProductCard";

function SimiliarProducts() {
  const similiarProducts: SimiliarProductsType[] = useSelector(
    selectSimilarProducts
  );
  const SimiliarProductsState: StateType = useSelector(
    selectSimilarProductsState
  );

  /*const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);*/

  if (SimiliarProductsState.hasLoaded && similiarProducts.length === 0) {
    return <div className="h-10 w-full"></div>
  }
  return (
    <div className="w-full h-fit">
      {SimiliarProductsState.hasLoaded && (
        <div className="w-full h-fit px-2 sm:px-5 overflow-x-hidden">
          <div className="w-full h-fit rounded-lg  p-2 mb-8 overflow-x-hidden">
            <h2 className="text-xl lg:text-2xl font-bold text-neutral-950">
              Inne produkty, które mogą Cię zainteresować
            </h2>
            {Array.isArray(similiarProducts) &&
              similiarProducts.length > 0 &&
              similiarProducts.map(
                (type) =>
                  type.products.length > 0 && (
                    <div key={type.label} className="w-full">
                      <span className="text-lg font-bold text-neutral-950 border-b-2 border-primary">
                        {type.label}
                      </span>
                      <div className="w-auto flex shrink-0 space-x-2 overflow-x-scroll flex-nowrap py-3 snap-start snap-mandatory snap-always">
                        {Array.isArray(type.products) &&
                          type.products.length > 0 &&
                          type.products.map((product) => (
                            <ProductCard
                              key={product.Id}
                              product={product}
                              shrink={false}
                              mobile={/*windowWidth<760*/false}
                              productDetails={true}
                            />
                          ))}
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SimiliarProducts;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../../server";
import { StateType, initialState } from "../../../../Models/State";
import { ProductDisplay } from "../../../../Models/Product";
import {
  OrderWithProducts,
  OrderModel,
} from "../../../../Models/Order";


type adminOrdersSliceState = {
  orders: {
    ordersList: OrderModel[];
    numberOfOrders: number;
    state: StateType;
  };
  order: {
    order: OrderWithProducts;
    state: StateType;
  };
  ordersEditing: {
    product: ProductDisplay,
    productsToAdd: ProductDisplay[]
  };
  removeProductState: StateType;
  fetchProductByCatalogNameState: StateType;
  setOrderAsPaidError: string|null;
  setProductQuantityState: StateType;
};

type ProductOperation = {
  name: string;
  product_id: string;
  newValueQuantity?: string;
  newValueAmount?: string;
};

export const getRecentOrders = createAsyncThunk(
  "adminOrders/fetchOrders",
  async () => {
    try {
      const response = await axios.get<OrderModel[]>(
        `${server}/api/Orders/last`,
        { withCredentials: true }
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getAdminOrderDetails = createAsyncThunk(
  "adminOrders/fetchOrder",
  async (id: string) => {
    try {
      const response = await axios.get<OrderWithProducts>(
        `${server}/api/Orders/get/${id}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const removeProductFromOrder = createAsyncThunk(
  'adminOrders/removeProductFromOrder',
  async({productId, orderId}:{productId:number, orderId: number})=>{
    try{
      const response = await axios.post(`${server}/api/admin/Orders/product/remove`,
        {productId, orderId},
        {withCredentials: true}
      );
      return productId;
    }
    catch(e){
      throw e;
    }
  }
)

export const setOrderAsAccepted = createAsyncThunk(
  "adminOrders/setOrderAsAccepted",
  async ({ id, sendData }: { id: string; sendData: string }) => {
    try {
      await axios.post(
        `${server}/api/Orders/accept/${id}`,
        { sendData: sendData },
        { withCredentials: true }
      );
      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const setOrderAsFinished = createAsyncThunk(
  "adminOrders/setOrderAsFinished",
  async (id: string) => {
    try {
      await axios.post(
        `${server}/api/Orders/finish/${id}`,
        {},
        { withCredentials: true }
      );
      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const setOrderAsReadyToPickUp = createAsyncThunk(
  "adminOrders/setOrderAsReadyToPickUp",
  async ({ id, dpdCode }: { id: string; dpdCode?: string }) => {
    try {
      await axios.post(
        `${server}/api/Orders/pickup/ready/${id}`,
        { dpdCode: dpdCode === "" ? undefined : dpdCode },
        { withCredentials: true }
      );
      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const setOrderAsPaid = createAsyncThunk(
  'adminOrders/setOrderAsPaid',
  async({id, paidStatus}:{id: string, paidStatus: number})=>{
    try{
      await axios.post(
        `${server}/api/Orders/admin/setAsPaid`,
        {orderId: id, paidStatus: paidStatus},
        {withCredentials: true}
      );
      return {id, paidStatus};
    }
    catch(e){
      throw e;
    }
  }
)

export const fetchProductByCatalogNumber = createAsyncThunk(
  'adminOrders/fetchProductByCatalogNumber',
  async({catalogNumber, userId}:{catalogNumber: string, userId: number})=>{
    try{
      const response = await axios.get(`
      ${server}/api/Products/catalogNumber?catalogName=${catalogNumber.replaceAll('/', '*1*')}&userId=${userId}`);
      return response.data as ProductDisplay;
    }
    catch(e){
      throw e;
    }
  }
)

export const setProductQuantity = createAsyncThunk(
  'adminOrders/setProductQuantity',
  async({productId, quantity, orderId}:{productId: number, quantity: number, orderId: string})=>{
    try{

      await axios.post(`${server}/api/Orders/edit/quantity`, 
        {productId, orderId, quantity},
        {withCredentials: true}
      );

      return {
        productId,
        quantity,
        orderId
      }

    }
    catch(e){
      throw e;
    }
  }
)

const adminOrdersSlice = createSlice({
  name: "adminOrders",
  initialState: {
    orders: {
      ordersList: [],
      numberOfOrders: 0,
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    order: {
      order: {},
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    ordersEditing: {
      product: {},
      productsToAdd: []
    },
    removeProductState: initialState,
    fetchProductByCatalogNameState: initialState,
    setOrderAsPaidError: null,
    setProductQuantityState: initialState
  } as unknown as adminOrdersSliceState,
  reducers: {
    addToProductsToAdd: (state, action) => {
      state.ordersEditing.productsToAdd.push(action.payload);
    }
  },
  extraReducers(builder) {
    builder.addCase(getRecentOrders.pending, (state, action) => {
      state.orders.state.isLoading = true;
      state.orders.state.failedLoading = false;
      state.orders.state.hasLoaded = false;
      state.orders.state.error = null;
    });
    builder.addCase(getRecentOrders.fulfilled, (state, action) => {
      state.orders.state.isLoading = false;
      state.orders.state.failedLoading = false;
      state.orders.state.hasLoaded = true;
      state.orders.ordersList = action.payload;
      state.orders.numberOfOrders = action.payload.length;
    });
    builder.addCase(getRecentOrders.rejected, (state, action) => {
      state.orders.state.isLoading = false;
      state.orders.state.failedLoading = true;
      state.orders.state.hasLoaded = false;
      state.orders.state.error = action.error;
    });
    builder.addCase(getAdminOrderDetails.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(getAdminOrderDetails.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      state.order.order = action.payload;
    });
    builder.addCase(getAdminOrderDetails.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsAccepted.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(setOrderAsAccepted.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      state.order.order.order.Status_id = 4;
    });
    builder.addCase(setOrderAsAccepted.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsReadyToPickUp.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(setOrderAsReadyToPickUp.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      if (parseInt(state.order.order.order.Delivery_type?.toString()) === 4) {
        state.order.order.order.Status_id = 5;
      } else {
        state.order.order.order.Status_id = 6;
      }
    });
    builder.addCase(setOrderAsReadyToPickUp.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsFinished.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(setOrderAsFinished.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      state.order.order.order.Status_id = 7;
    });
    builder.addCase(setOrderAsFinished.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsPaid.pending, (state, action) => {
      state.setOrderAsPaidError = null;
    });
    builder.addCase(setOrderAsPaid.fulfilled, (state, action) => {
      state.order.order.order.Is_paid = action.payload.paidStatus;
    });
    builder.addCase(setOrderAsPaid.rejected, (state, action) => {
      state.setOrderAsPaidError = action.error.message??"Wystąpił błąd";
    });
    builder.addCase(fetchProductByCatalogNumber.pending, (state, action) => {
      state.fetchProductByCatalogNameState.isLoading = true;
      state.fetchProductByCatalogNameState.failedLoading = false;
      state.fetchProductByCatalogNameState.hasLoaded = false;
      state.fetchProductByCatalogNameState.error = null;
    });
    builder.addCase(fetchProductByCatalogNumber.fulfilled, (state, action) => {
      state.fetchProductByCatalogNameState.isLoading = false;
      state.fetchProductByCatalogNameState.failedLoading = false;
      state.fetchProductByCatalogNameState.hasLoaded = true;
      for(var element of state.order.order.products){
        if(element.Id===action.payload.Id){
          element=action.payload;
        }
      }
    });
    builder.addCase(fetchProductByCatalogNumber.rejected, (state, action) => {
      state.fetchProductByCatalogNameState.isLoading = false;
      state.fetchProductByCatalogNameState.failedLoading = true;
      state.fetchProductByCatalogNameState.hasLoaded = false;
      state.fetchProductByCatalogNameState.error = action.error;
    });
    builder.addCase(removeProductFromOrder.pending, (state, action) => {
      state.removeProductState.isLoading = true;
      state.removeProductState.failedLoading = false;
      state.removeProductState.hasLoaded = false;
      state.removeProductState.error = null;
    });
    builder.addCase(removeProductFromOrder.fulfilled, (state, action) => {
      state.removeProductState.isLoading = false;
      state.removeProductState.failedLoading = false;
      state.removeProductState.hasLoaded = true;
      state.order.order.products=state.order.order.products.filter(element=>{
        return element.Id!==action.payload;
      });
    });
    builder.addCase(removeProductFromOrder.rejected, (state, action) => {
      state.removeProductState.isLoading = false;
      state.removeProductState.failedLoading = true;
      state.removeProductState.hasLoaded = false;
      state.removeProductState.error = action.error;
    });
    builder.addCase(setProductQuantity.pending, (state, action) => {
      state.setProductQuantityState.isLoading = true;
      state.setProductQuantityState.failedLoading = false;
      state.setProductQuantityState.hasLoaded = false;
      state.setProductQuantityState.error = null;
    });
    builder.addCase(setProductQuantity.fulfilled, (state, action) => {
      state.setProductQuantityState.isLoading = false;
      state.setProductQuantityState.failedLoading = false;
      state.setProductQuantityState.hasLoaded = true;
      var updatedProducts = [];
      for(var element of state.order.order.products){
        if(element.Id===action.payload.productId&&state.order.order.order.Id){
          element.Quantity = action.payload.quantity;
        }
        updatedProducts.push(element);
      }
      state.order.order.products = updatedProducts;
    });
    builder.addCase(setProductQuantity.rejected, (state, action) => {
      state.setProductQuantityState.isLoading = false;
      state.setProductQuantityState.failedLoading = true;
      state.setProductQuantityState.hasLoaded = false;
      state.setProductQuantityState.error = action.error;
    });
  },
});


export const selectAdminOrders = (state: {
  adminOrders: adminOrdersSliceState;
}): OrderModel[] => {
  return state.adminOrders.orders.ordersList;
};

export const selectAdminNumberOfOrders = (state: {
  adminOrders: adminOrdersSliceState;
}): number => state.adminOrders.orders.numberOfOrders;

export const selectAdminOrder = (state: {
  adminOrders: adminOrdersSliceState;
}): OrderWithProducts => state.adminOrders.order.order;

export const selectRecentOrdersState = (state: {
  adminOrders: adminOrdersSliceState;
}): StateType => state.adminOrders.orders.state;

export const selectAdminOrderState = (state: {
  adminOrders: adminOrdersSliceState;
}): StateType => state.adminOrders.order.state;

export const selectEditingOrdersAddedProduct = (state: {
  adminOrders: adminOrdersSliceState;
}): ProductDisplay => state.adminOrders.ordersEditing.product;

export const selectProductsToAdd = (state: {
  adminOrders: adminOrdersSliceState
}): ProductDisplay[] => state.adminOrders.ordersEditing.productsToAdd;

export const selectSetOrderAsPaidError = (state: {
  adminOrders: adminOrdersSliceState
}): string|null => state.adminOrders.setOrderAsPaidError;

export const selectRemoveProductState = (state: {
  adminOrders: adminOrdersSliceState
}): StateType => state.adminOrders.removeProductState;

export const selectFetchProductByCatNumberState = (state: {
  adminOrders: adminOrdersSliceState
}): StateType => state.adminOrders.fetchProductByCatalogNameState;


export const {
  addToProductsToAdd
} = adminOrdersSlice.actions;

export default adminOrdersSlice.reducer;

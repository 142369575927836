import React from 'react';
import { motion } from "framer-motion";
import BadgeIcon from "@mui/icons-material/Badge";
import Gallery from '../Product/Gallery/Gallery';


export default function Sprezarkownie() {

    const oursSprezarkownie = [
        {
            Id: 0,
            Name: 'Sprężarkownia 120kW',
            Description: 'Sprężarkownia zawiera dwie sprężarki (45kW oraz 75kW) oraz zbiornik 2m3.',
            Images: [
                'https://pressbarimages.b-cdn.net/sprezarkownie/20240625_131256.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20240625_131318.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20240625_131321.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20240625_131331.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20240625_131356.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20240625_131551.jpg'
            ]
        },
        {
            Id: 1,
            Name: 'Sprężarkownia 45kW',
            Description: 'Sprężarkownia zawiera sprężarkę (45kW) wraz z kanałami odprowadzenia gorącego powietrza z przepustnicami na halę.',
            Images: [
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174358.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174404.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174431.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174445.jpg'
            ]
        },
        {
            Id: 2,
            Name: 'Sprężarkownia 45kW',
            Description: 'Sprężarkownia zawiera sprężarkę (45kW) wraz z kanałami odprowadzenia gorącego powietrza z przepustnicami na halę.',
            Images: [
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174358.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174404.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174431.jpg',
                'https://pressbarimages.b-cdn.net/sprezarkownie/20230905_174445.jpg'
            ]
        },
    ];

    return (
        <motion.div
            className="flex flex-col justify-center items-center w-full space-y-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{padding:'15px'}}
        >
            <div className="w-full my-10 bg-primary-darker">
                <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
                >
                    <h1 className="max-w-[1024px] mt-10 sm:text-7xl text-5xl font-display text-center text-white mb-2">
                        Wykonane sprężarkownie
                    </h1>
                </motion.div>
            </div>
            <div style={{display: 'flex', flexFlow: 'wrap'}}>
                {
                    oursSprezarkownie.map(element=>{
                        return(
                            <div style={{width: '33%'}}>
                                <div className="w-full h-96" id="gallery">
                                    <Gallery 
                                        images={element.Images.map((image: any) => ({
                                        Image_url: image,
                                        Product_group_id: 0,
                                        Order_number: 0,
                                    }))}/>
                                </div>
                                <div className="w-full p-4 flex flex-col justify-center">
                                    <div className="flex items-center text-end justify-between w-full space-x-2">
                                    <BadgeIcon />
                                    <h1 className="block text-sm sm:text-base font-bold">
                                        {element.Name}
                                    </h1>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </motion.div>
    )
}

import { Link } from "react-router-dom";
import { ArticleLandingPage } from "../../../Models/Article";
import BadgeIcon from "@mui/icons-material/Badge";

export default function LandingPageArticles({
  landingPageArticles,
}: {
  landingPageArticles: ArticleLandingPage[];
}) {
  return (
    <div className="w-full h-fit" id="articles">
      {true && (
        <div className="">
          <div className="w-full h-fit rounded-lg overflow-x-hidden">
            <div className="w-full">
              <span className="text-5xl font-display font-bold text-neutral-950 border-b-2 border-primary">
                {"Artykuły"}
              </span>
              <div className="flex shrink-0 space-x-2 overflow-x-scroll flex-nowrap py-3 snap-start snap-mandatory snap-always">
                {Array.isArray(landingPageArticles) &&
                  landingPageArticles.length > 0 &&
                  landingPageArticles.map((article) => (
                    <Link
                      className={`mr-2 shrink-0 shadow-md hover:shadow-lg flex flex-col justify-end snap-center relative z-10 overflow-hidden rounded-lg h-80 w-60 flex-grow transition-all`}
                      key={article.Id}
                      to={`/article/${article.Id}`}
                    >
                      <div
                        className="w-full h-1/2 z-0 bg-white"
                        style={{
                          position: "relative",
                          backgroundImage: `url(${
                            article.Image_url !== ""
                              ? article.Image_url
                              : "/Images/no_photography_FILL0_wght400_GRAD0_opsz24.png"
                          })`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <div className="w-full h-1/2 p-4 flex flex-col justify-center">
                        <div className="flex items-center text-end justify-between w-full space-x-2">
                          <BadgeIcon />
                          <h1 className="block text-sm sm:text-base font-bold">
                            {article.Title}
                          </h1>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

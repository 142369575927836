import React from "react";
import { Link } from "react-router-dom";
import ButtonPrimaryToLighter from "../../../Common/Buttons/ButtonPrimaryToLighter";
import RegisterForm from "../RegisterForm/RegisterForm";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

function RegisterBox() {
  const [isFormOpen, setIsFormOpen] = React.useState(false);

  return (
    <div className="lg:sticky lg:top-32 flex flex-col bg-neutral-100 rounded-lg shadow-md p-2 w-full lg:w-1/2">
      <div className="flex flex-col items-center w-full justify-center my-2 ">
        <img src="/Images/PRESS_BAR_LOGO.png" alt="logo" className="h-14" />
        <h1 className="text-center flex items-center space-x-2">
          <span className="text-4xl font-bold">Utwórz konto</span>
          <PersonAddIcon />
        </h1>
      </div>
      <div className="flex flex-col space-y-2">
        <ButtonPrimaryToLighter
          text={"Za pomocą emaila"}
          size={"medium"}
          Icon={EmailIcon}
          onClick={() => {
            if (!isFormOpen) {
              window.scrollTo(0, 0);
              setIsFormOpen(true);
            } else {
              setIsFormOpen(false);
            }
          }}
        />
        {isFormOpen && <RegisterForm />}
      </div>
      <h1 className="text-xl font-black my-1 font-mono text-center">
        Posiadasz już konto?{" "}
        <Link to="/login" className="underline hover:no-underline">
          Zaloguj się
        </Link>
      </h1>
    </div>
  );
}

export default RegisterBox;

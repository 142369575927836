import React, { useState } from "react";
import DropDownList from "../../../../Common/DropDownList/DropDownList";
import { useDispatch, useSelector } from "react-redux";
import {
  ParameterChoice,
  getChoiceForParameter,
  selectParameterChoices,
  selectProductsChoices,
} from "../../../../Store/Slices/Products/Products";
import { useNavigate } from "react-router-dom";
import { ProductModel } from "../../../../Models/Product";
import { AppDispatch } from "../../../../Store/store";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  element: ParameterChoice;
  product: ProductModel;
  parameterId: number | null;
  setParameterId: (id: number | null) => void;
};

function Parameter({ element, product, parameterId, setParameterId }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [showListDropdown, setShowListDropdown] = useState(false);

  const parameterChoices = useSelector(selectParameterChoices);
  const productParameters = useSelector(selectProductsChoices);

  return (
    <div className="relative flex flex-col lg:flex-row lg:items-center lg:justify-between space-x-2">
      <span className="text-lg font-semibold whitespace-nowrap">
        {element.ParameterName}:
      </span>
      <button
        className={`p-1 border-dashed hover:border-solid border-2 appearance-none block w-full lg:w-1/2 text-base text-neutral-950 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all`}
        onClick={() => {
          if (parameterId === element.ParameterId && showListDropdown) {
            setShowListDropdown(false);
            return;
          }
          const choices: {
            Parameter_id: number;
            Choice_id: number;
          }[] = [];
          for (const elem of productParameters) {
            if (elem.ParameterId !== element.ParameterId && elem.On_product) {
              choices.push({
                Parameter_id: elem.ParameterId,
                Choice_id: elem.ChoiceId,
              });
            }
          }
          dispatch(
            getChoiceForParameter({
              Category_id: product?.details?.Category_id ?? -1,
              parameters: choices,
            })
          );
          setParameterId(element.ParameterId);
          setShowListDropdown(true);
        }}
      >
        <div className="relative w-full flex items-center justify-evenly">
          <span className="text-neutral-700 group-hover:text-neutral-950 text-xl font-bold">
            {element.ChoiceName}
          </span>
          <ExpandMoreIcon />
        </div>
      </button>

      {parameterId === element.ParameterId &&
        parameterChoices &&
        showListDropdown &&
        parameterChoices.length > 1 && (
          <DropDownList
            options={parameterChoices
              .filter((list) => {
                return list.Id !== product.details.Id;
              })
              .map((list) => {
                return {
                  name: list.Name,
                  value: list.Catalog_name.toString(),
                  icon: null,
                };
              })}
            selectedOption=""
            customClasses="max-h-60 overflow-y-scroll"
            whiteSpaceWrap={true}
            onClick={(value) => {
              navigate(`/product/${value.replaceAll("/", "*1*")}`);
            }}
          />
        )}
    </div>
  );
}

export default Parameter;

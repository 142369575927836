import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Store/store";
import {
  fetchDeliveryTypes,
  selectChosenDeliveryType,
  selectDeliveryTypes,
  selectDeliveryTypesState,
  setDeliveryType,
} from "../../../../../../Store/Slices/Order/Order";
import LoadingAnimation from "../../../../../../Common/LoadingAnimation/LoadingAnimation";
import { DeliveryType } from "../../../../../../Models/Order";
import ErrorMessage from "../../../../../../Common/Messages/ErrorMessage/ErrorMessage";
import { selectCartPrice } from "../../../../../../Store/Slices/Cart/Cart";

function Delivery() {
  const dispatch = useDispatch<AppDispatch>();

  const deliveryTypes = useSelector(selectDeliveryTypes);
  const chosenDelivery = useSelector(selectChosenDeliveryType);

  const state = useSelector(selectDeliveryTypesState);
  const priceProducts = useSelector(selectCartPrice);

  useEffect(() => {
    dispatch(fetchDeliveryTypes());
  }, [state.failedLoading]);

  return (
    <div className="w-full relative">
      <h1 className="text-xl font-semibold">Wybierz formę dostawy:</h1>
      {state.isLoading && <LoadingAnimation color={"primary"} type="local" />}
      {state.failedLoading && (
        <ErrorMessage
          message="Nie udało się załadować form dostawy"
          error={state.error}
        />
      )}
      <div className="w-full rounded-lg p-3 border-2">
        {state.hasLoaded && (
          <div className="w-full flex items-center flex-wrap">
            {deliveryTypes.map((deliveryType: DeliveryType) => {
              return (
                <div
                  key={deliveryType.Id}
                  className="flex items-center space-x-1 mr-3"
                >
                  <input
                    type="radio"
                    name="deliveryType"
                    defaultChecked={chosenDelivery?.Id === deliveryType.Id}
                    value={deliveryType.Id.toString()}
                    id={deliveryType.Id.toString()}
                    required={true}
                    className="w-5 h-5"
                    onClick={() => {
                      dispatch(
                        setDeliveryType({
                          deliveryType: deliveryType.Id,
                          price: priceProducts,
                        })
                      );
                    }}
                  />
                  <label
                    htmlFor={deliveryType.Id.toString()}
                    className="flex items-center space-x-1 cursor-pointer"
                  >
                    <span className="material-symbols-outlined text-2xl">
                      {deliveryType.Icon}
                    </span>
                    <span className="text-xl font-semibold whitespace-nowrap">
                      {deliveryType.Name} (
                      {deliveryType.Id === 1 && priceProducts >= 500
                        ? "0.00"
                        : deliveryType.Price}{" "}
                      PLN)
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Delivery;

import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../Store/Slices/User/User";
import Input from "../../../../Common/Input/Input";
import NotificationsIcon from "@mui/icons-material/Notifications";

function NotifyWhenAvailable() {

  return (
    <div className="flex flex-col space-y-2 w-full rounded-lg border-2 border-primary border-spacing-2 border-dashed p-2">
      <div className="w-full h-full flex items-center justify-center space-x-2">
        <span className="text-base">
          Zadzwoń pod numer <a href="tel:+48506162825" style={{ fontWeight: "bold" }}>
          +48 506 162 825
          </a> lub wyślij wiadomość na{" "}
          <a href="mailto:biuro@pressbar.pl" style={{ fontWeight: "bold" }}>
            biuro@pressbar.pl
          </a>
        </span>
      </div>
    </div>
  );
}

export default NotifyWhenAvailable;

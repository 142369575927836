import React, { useCallback } from "react";
import { ProductDisplay } from "../../../Models/Product";
import AddToCartButton from "../../Modals/AddToCart/AddToCartButton";
import { addToCart } from "../../../Store/Slices/Cart/Cart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

type Props = {
  product: ProductDisplay;
  showCartControls?: boolean;
};

function ProductControls({ product, showCartControls = true }: Props) {
  const [quantity, setQuantity] = React.useState("1");

  const decrement = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (parseInt(quantity) > 1) {
        setQuantity((parseInt(quantity) - 1).toString());
      }
    },
    []
  );

  const increment = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setQuantity((parseInt(quantity) + 1).toString());
    },
    []
  );

  const zero = useCallback((e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.stopPropagation();
    if (quantity.toString() === "") {
      setQuantity("1");
    }
  }, []);

  const passedValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = parseInt(e.target.value);
    setQuantity(value.toString());
  }, []);

  return (
    <div className="flex flex-col-reverse justify-end items-end justify-self-end space-x-1 text-2xl">
      {
        <AddToCartButton
          width="w-auto"
          addToCart={addToCart}
          children={
            <div className="align-self-end h-10 w-10 rounded-full bg-white bg-opacity-40 backdrop-blur-sm flex items-center justify-center">
              <div className="drop-shadow-lg hover:text-green-500 text-3xl ">
                <AddShoppingCartIcon />
              </div>
            </div>
          }
          products={[
            {
              product: product,
              quantity: parseInt(quantity),
            },
          ]}
        />
      }
    </div>
  );
}

export default React.memo(ProductControls, (prevProps, nextProps) => {
  // Zwróć true, jeśli re-render nie jest potrzebny, false w przeciwnym razie
  return prevProps.product.Catalog_name === nextProps.product.Catalog_name;
  // Dodaj inne warunki porównujące propsy, które są ważne dla twojego komponentu
});

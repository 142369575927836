import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const mobileImages = [
  "https://pressbarimages.b-cdn.net/banery/429823138_739059031539306_8843880955599530695_n.jpg",
  "https://pressbarimages.b-cdn.net/banery/431052636_1849421618813270_6320315678181740713_n.jpg",
];

const images = [
  "https://pressbarimages.b-cdn.net/banery/Baner%201Obszar%20kompozycji%201%20kopia%203.jpg",
  "https://pressbarimages.b-cdn.net/banery/Baner%201Obszar%20kompozycji%201%20kopia.jpg",
  'https://pressbarimages.b-cdn.net/banery/Baner%201Obszar%20kompozycji%201%20kopia%202.jpg',
  'https://pressbarimages.b-cdn.net/banery/Baner%201Obszar%20kompozycji%201.jpg'
];

const urls = ["article/2", "article/7"];

function Banner() {
  const [currentImage, setCurrentImage] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const nextImage = () => {
    const newIndex = (currentImage + 1) % images.length;
    scrollToIndex(newIndex);
    setCurrentImage(newIndex);
  };

  const previousImage = () => {
    const newIndex = (currentImage - 1 + images.length) % images.length;
    scrollToIndex(newIndex);
    setCurrentImage(newIndex);
  };

  const handleThumbnailClick = (index: number) => {
    scrollToIndex(index);
    setCurrentImage(index);
  };

  const scrollToIndex = (index: number) => {
    if (scrollContainerRef.current) {
      const imageWidth = scrollContainerRef.current.offsetWidth;
      scrollContainerRef.current.scrollTo({
        left: index * imageWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const scrollLeft = scrollContainerRef.current.scrollLeft;
        const imageWidth = scrollContainerRef.current.offsetWidth;
        const newIndex = Math.floor(scrollLeft / imageWidth);
        setCurrentImage(newIndex);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [images]);

  const startAutoScroll = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(nextImage, 7000);
    }
  };

  const stopAutoScroll = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll();
  }, [currentImage]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative w-full flex items-center justify-center">
      <div
        className="w-full h-full overflow-x-scroll snap-x snap-mandatory flex no-scrollbar"
        ref={scrollContainerRef}
        onMouseEnter={stopAutoScroll}
        onMouseLeave={startAutoScroll}
      >
        {width >= 1024
          ? images.map((image, index) => (
              <img
                alt={`Banner ${index}`}
                key={index}
                src={image}
                className={`shrink-0 h-full w-full snap-start`}
                onClick={() => {
                  navigate(urls[index]);
                }}
              />
            ))
          : mobileImages.map((image, index) => (
              <img
                alt={`Banner ${index}`}
                key={index}
                src={image}
                className={`shrink-0 h-full w-full snap-start`}
                onClick={() => {
                  navigate(urls[index]);
                }}
              />
            ))}
      </div>
      <div className="flex absolute bottom-2 left-[50%] transform translate-x-[-50%] justify-center py-2">
        {width >= 1024
          ? images.map((image, index) => (
              <div
                key={index}
                onClick={() => handleThumbnailClick(index)}
                className={`transition-all hover:cursor-pointer hover:opacity-100 active:opacity-100 opacity-50 w-4 h-4 rounded-full mx-1 ${
                  currentImage === index ? "bg-gray-950" : "bg-gray-400"
                }`}
              ></div>
            ))
          : mobileImages.map((image, index) => (
              <div
                key={index}
                onClick={() => handleThumbnailClick(index)}
                className={`transition-all hover:cursor-pointer hover:opacity-100 active:opacity-100 opacity-50 w-4 h-4 rounded-full mx-1 ${
                  currentImage === index ? "bg-gray-950" : "bg-gray-400"
                }`}
              ></div>
            ))}
      </div>
    </div>
  );
}

export default Banner;

import React from "react";
import { ProductModel } from "../../../Models/Product";
import Description from "./Description/Description";
import Specification from "./TechnicalData/TechnicalData";

type Props = {
  product: ProductModel;
};

function InfoBelow({ product }: Props) {
  return (
    <div className="w-full">
      {false && product.details.Description !== "" && (
        <Description description={product.details.Description} />
      )}
      <Specification technicalData={product.technical_data} />
    </div>
  );
}

export default InfoBelow;

import React from "react";
import { ProductDisplay } from "../../../Models/Product";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";

type Props = {
  product: ProductDisplay;
  productQuantity: number;
};

function PriceQuantity({ product, productQuantity }: Props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <div className="flex items-center justify-center p-2 space-x-1">
      <h1 className="flex items-center space-x-1 whitespace-nowrap text-sm sm:text-base font-bold text-neutral-950">
        {((product.Price_Discount !== null &&
          product.Price_Discount !== undefined) ||
          (product.Price_Individual !== null &&
            product.Price_Individual !== undefined) ||
          (product.Category_Discount !== null &&
            product.Category_Discount !== undefined)) &&
        isLoggedIn ? (
          <></>
        ) : (
          <span>
            {product.Price !== undefined
              ? product.Price.toFixed(2)
              : product.Amount !== undefined
              ? product.Amount.toFixed(2)
              : 0}
          </span>
        )}
        {isLoggedIn &&
          product.Price_Individual !== null &&
          product.Price_Individual !== undefined && (
            <span className="underline decoration-primary decoration-2">
              {product.Price_Individual?.toFixed(2)}
            </span>
          )}
        {isLoggedIn &&
          (product.Price_Individual === null ||
            product.Price_Individual === undefined) &&
          product.Price_Discount !== null &&
          product.Price_Discount !== undefined && (
            <span className="underline decoration-green-600 decoration-2">
              {(
                (product.Price !== undefined
                  ? product.Price
                  : product.Amount !== undefined
                  ? product.Amount
                  : 0) *
                (1 - product.Price_Discount / 100)
              ).toFixed(2)}
            </span>
          )}
        {isLoggedIn &&
          (product.Price_Individual === null ||
            product.Price_Individual === undefined) &&
          (product.Price_Discount === null ||
            product.Price_Discount === undefined) &&
          product.Category_Discount !== null &&
          product.Category_Discount !== undefined && (
            <span className="underline decoration-orange-600 decoration-2">
              {(
                (product.Price !== undefined
                  ? product.Price
                  : product.Amount !== undefined
                  ? product.Amount
                  : 0) *
                (1 - product.Category_Discount / 100)
              ).toFixed(2)}
            </span>
          )}
        <span>PLN</span>
      </h1>
      <h1 className="text-xs sm:text-sm text-neutral-500 whitespace-nowrap">
        {" "}
        x {productQuantity}
      </h1>
    </div>
  );
}

export default PriceQuantity;
